import React, { useEffect, useState } from "react";
import { returnServicosFinanceiros } from "./functions";

import { LoadingButton } from "../loadingButtom";

import { AiOutlineReload } from "react-icons/ai";
import { MultiSelect } from 'primereact/multiselect';

interface CadastroServicos {
  setIdsServicosFinanceiros: React.Dispatch<
    React.SetStateAction<Array<number>>
  >;
}

interface ServiçoProps {
  id: number,
  nome: string,
  descricao: string,
  ativo: boolean
}


export const CadastroServicos = ({
  setIdsServicosFinanceiros,
}: CadastroServicos) => {
  const [servicos, setServicos] = useState<Array<ServiçoProps>>([]);
  const [servicosEscolhidos, setServicosEscolhidos] = useState<Array<ServiçoProps>>([]);
  const [loadingServicosFinanceiros, setLoadingServicosFinanceiros] = useState<boolean>(false);
  const [erroServicos, setErroServicos] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false)


  useEffect(() => {
    returnServicosFinanceiros(setLoadingServicosFinanceiros, setServicos, setErroServicos);
  }, [reload]);

  function handleSelect(e: ServiçoProps[]) {
    setServicosEscolhidos(e);

    setIdsServicosFinanceiros(e.map((item) => {
      return item.id
    }))

  }


  return (
    <div>
      <h3 className="TextoTitulo">SERVIÇOS</h3>
      <div className="Area_SelecaoServico">
        <div>
          <div style={{ marginTop: 20 }} className="Area_SelecaoServico">
            <h5 className="TextoSubTitulo">Escolha o seu tipo de serviço ideal</h5>
          </div>
          {loadingServicosFinanceiros ? <LoadingButton /> : servicos.length > 0 &&
            <MultiSelect value={servicosEscolhidos} options={servicos} onChange={(e) => handleSelect(e.value)} optionLabel="nome" placeholder="Escolha seu serviço" maxSelectedLabels={2} />
          }
        </div>
        {erroServicos && <>
          <p>Ocorreu um erro. Por favor tente novamente.</p>
          <AiOutlineReload
            onClick={() => setReload(!reload)}
          />
        </>
        }
        <div style={{ marginTop: 20, width: "30%" }}>
          <h3 className="servicosSelecionados">Serviços Selecionados</h3>
          {servicosEscolhidos.length > 0 ?
            servicosEscolhidos.map((item) => {
              return <div>
                <h5 className="TextoSubTitulo">{item.nome}</h5>
                <p className="descricaoServico">{item.descricao}</p>
              </div>
            })
            :
            <p className="descricaoServico">Nenhum serviço selecionado</p>
          }
        </div>
      </div>
    </div>
  );
};
