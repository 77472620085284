import { obterContaParaRecebimento } from "../../services/cliente";
import { renovacaoToken } from "../../services/usuario";
import { obterDadosUsuario2, guardarDadosUsuario2 } from "../../services/cookies";
import React from "react";



export const pegarFormasDePagamento = (setListaDeFormasDePagamento: React.Dispatch<any[]>, setErro: React.Dispatch<any>, setDesconectar: React.Dispatch<any>, setLoading: React.Dispatch<boolean>) => {
    const { id, token, tokenRenovacao, email } = obterDadosUsuario2()
    setLoading(true)
    setErro(false)

    obterContaParaRecebimento(id, token).then(res => {
        const formasDePagamento = res.data.resultado.contas
        setListaDeFormasDePagamento(formasDePagamento)
    }).catch(err => {
        if (err.toString() === "Error: Request failed with status code 401") {
            renovacaoToken(token, tokenRenovacao).then(res => {
                guardarDadosUsuario2(
                    res.data.camposAdicionais.tokenAcesso,
                    res.data.camposAdicionais.tokenRenovacao,
                    email.toString(),
                    id.toString()
                );
                obterContaParaRecebimento(id, res.data.camposAdicionais.tokenAcesso).then(res => {
                    setListaDeFormasDePagamento(res.data.resultado.contas)
                }).catch(() => setErro(true))
            }).catch(() => setDesconectar(true))
        }
        else {
            setErro(true)
        }
    }).finally(() => setLoading(false))

}