import React, { useState } from 'react'
import { DateFormatter } from '../../../functions/DateFormatter'
import './style.css'


import { AiFillEye } from "react-icons/ai";
import { Button } from "../../Button";
import { ModalComprovante } from '../../modais/ModalComprovante';
import { VscFilePdf } from 'react-icons/vsc';

interface RequisicaoExtrato {
    "dados": {
        "dataExecucao": Date,
        "dataSolicitacao": Date,
        "quantidadeCotas": number,
        "tipo": "Aporte" | "Saque" | "TaxaPerformance" | "TaxaAdministracao",
        "status": "EmEspera" | "Efetuado" | "Rejeitado",
        "valor": number,
        "urlImagemComprovante": string,
    };
    dataExecucao: boolean
}

export const CardExtrato = ({ dados, dataExecucao }: RequisicaoExtrato) => {
    const status: string = dados.status === "EmEspera" ? "Em Espera" : dados.status
    const [comprovanteModal, setComprovanteModal] = useState<boolean>(false);

    var cor: string;
    switch (dados.status) {
        case 'Efetuado':
            cor = "#4ed809"
            break;
        case 'Rejeitado':
            cor = "#c50505"
            break;
        default:
            cor = "#545557"
    }

    const exibirPDF = () => {
        setComprovanteModal(true);
    }

    return (
        <div className="caixa1 disposicaoElementos">
            <fieldset >
                <div className="espacamentoHeaderExtrato">
                    <strong id='statusExtrato' style={{ color: cor }}>{status.toUpperCase()}</strong>
                    <strong>
                        {dataExecucao
                            ? DateFormatter(dados.dataExecucao).toString() === "01/01/0001" ? "Em espera." : DateFormatter(dados.dataExecucao)
                            : DateFormatter(dados.dataSolicitacao)}
                    </strong>
                </div>
                <strong className="dadosIntermediariosCard" >Tipo de solicitação: {dados.tipo === "TaxaPerformance" ? "Taxa de Performance" : dados.tipo === "TaxaAdministracao" ? "Taxa de Administração" : dados.tipo}</strong>
                <strong className="dadosIntermediariosCard">Quantidade de Cotas: {dados.quantidadeCotas.toString().match(/^-?\d+(?:\.\d{0,4})?/)}</strong>
                <strong id="valorExtrato">Valor: R$ {dados.valor.toFixed(2)}</strong>
                {dados.urlImagemComprovante && (
                    //<VscFilePdf id="pdfIcon" onClick={exibirPDF} />
                    <Button
                        className="btn__cinza"
                        onClick={exibirPDF}
                        titleButton={
                            <>
                                <AiFillEye className="iconButton" />
                                Ver Comprovante
                            </>
                        }
                    />
                )}
            </fieldset>
            {comprovanteModal && (
                <ModalComprovante
                    modal={comprovanteModal}
                    setModal={setComprovanteModal}
                    comprovanteBase64={dados.urlImagemComprovante}
                />
            )}
        </div>
    )
}