import React from "react";
import "./styles.css";

import { Button } from "../../Button";


interface CardRelatorioProps {
  link: string;
  titulo: string;
  subTitulo: string;
}
export const CardRelatorio = ({
  link,
  titulo,
  subTitulo,
}: CardRelatorioProps) => {

  return (
    <fieldset className="caixaRelatorios__fieldset__global conteudo conteudo__div container_CardRelatorio">
      <h5 className="conteudo__div__titulo conteudo__div__titulo_CardRelatorio">
        {titulo}
      </h5>
      <p className="conteudo__texto">{subTitulo}</p>
          {link !== '/fechamento' ?
              <Button
                  onClick={() => window.location.href = (link)}
                  // className=""
                  data-bs-toggle="modal"
                  data-bs-target="#acessar"
                  titleButton="ACESSAR"
              />
              :
              <>
                  <div className="botoesCardHomeGestor">
                      <Button
                          onClick={() => window.location.href = ('/funcionario/clientes-private')}
                          data-bs-toggle="modal"
                          data-bs-target="#acessar"
                          titleButton="PRIVATE"
                      />
                      <Button
                          onClick={() => window.location.href = ('/fechamento')}
                          data-bs-toggle="modal"
                          data-bs-target="#acessar"
                          titleButton="ASSESSORIA"
                      />
                  </div>
              </>
          }
    </fieldset>
  );
};
