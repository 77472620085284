import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Accordion from "react-bootstrap/Accordion";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Faq.css'
import { Header } from "../../../components/Header";

const Faq = () => {

    return (
        <>
            <Navbar />
            <div className="container">
                <Header titulo="FAQ" />
                <div className="caixaFaq">
                    <Accordion defaultActiveKey={"0"}>
                        <Accordion.Item className="accordion-item col-md-12 p-3 m-auto" eventKey="1" >
                            <Accordion.Header>Em quanto tempo o saque cairá na minha conta?</Accordion.Header>
                            <Accordion.Body>
                                O prazo para efetivação dos saques é de 7 dias úteis.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="accordion-item col-md-12 p-3 m-auto" eventKey="2">
                            <Accordion.Header>Por que a minha cota é parcialmente reduzida todo mês?</Accordion.Header>
                            <Accordion.Body>
                                Porque os descontos das taxas de performance (20% sobre os lucros) e administração (0,16% sobre o capital total), são feitos mensalmente e descontados em cotas. A taxa de performance só existe quando há fechamento de mês com lucros. Acesse o relatório sobre o método de “come-cotas” na aba “Relatórios” para saber mais.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </>
    );
};

export default Faq;