import React from 'react'
import { Button } from '../../Button'
import "../CardListarUsuarios/CardListarUsuarios.css";

import { useNavigate } from 'react-router-dom'

interface CardListarUsuariosProps {
    nome: string,
    setNomeCliente: React.Dispatch<string>,
    ativo: string,
    tipo: string,
    idCliente: string,
    setModalDadosPessoais: React.Dispatch<React.SetStateAction<boolean>>,
    setModalHistoricoFinanceiro: React.Dispatch<React.SetStateAction<boolean>>,
    setIdCliente: React.Dispatch<string>;
}

export const CardListarUsuarios = ({ nome, setNomeCliente, ativo, tipo, idCliente, setModalDadosPessoais, setModalHistoricoFinanceiro, setIdCliente, }: CardListarUsuariosProps) => {

    const navigate = useNavigate()

    return (
        <>
            <div className="caixa1 caixaListarUsuarios">
                <fieldset>
                    <div className="FieldsetDivTexto fieldsetListarUsuarios">
                        <h5>{nome}</h5>
                        <br />
                        {/* <a>Ativo: <b>{ativo}</b></a> */}
                        {/* <p>Tipo: {tipo}</p> */}
                    </div>
                    <div className="divButton buttonListarUsuarios">
                        <Button className="btnAlterar sizeButton colorButton"
                            onClick={() => {
                                setModalDadosPessoais(true)
                                setIdCliente(idCliente)
                            }}
                            titleButton={"Dados Pessoais"}
                        />
                        <Button className="btnAlterar sizeButton"
                            onClick={() => {
                                setModalHistoricoFinanceiro(true)
                                setIdCliente(idCliente)
                                setNomeCliente(nome)
                            }}
                            titleButton={"Histórico Financeiro"}
                        />
                        <Button className="btnAlterar sizeButton"
                            onClick={() => navigate(`dashboards/${idCliente}/${nome}`)}
                            titleButton={"Dashboards"}
                        />
                    </div>
                </fieldset>
            </div>
        </>
    )
}