import React from "react";
import { alterarStatusDaConta } from "../../../services/cliente";
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { renovacaoToken } from "../../../services/usuario";


export async function handleStatus(idCredencial: string, statusAprovacao: number, setLoading: React.Dispatch<boolean>, reload: boolean, setReload: React.Dispatch<boolean>) {
    setLoading(true);

    const { token, tokenRenovacao, email, urlAvatar, id } = obterDadosUsuario2();
    await alterarStatusDaConta(idCredencial, token, false, statusAprovacao)
        .catch(async (e) => {
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao).then(async (res) => {
                    await guardarDadosUsuario2(
                        res.data.camposAdicionais.tokenAcesso,
                        res.data.camposAdicionais.tokenRenovacao,
                        email.toString(),
                        id.toString(),
                        urlAvatar,
                    );
                    await alterarStatusDaConta(idCredencial, res.data.camposAdicionais.tokenAcesso, false, statusAprovacao).then(() => {

                    })
                })
            }
        }).finally(() => {
            setLoading(false)
            setReload(!reload);
        })
};