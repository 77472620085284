import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, PluginOptionsByType } from 'chart.js';
import { BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";

import { MensagemGraficoVazio } from "../MensagemGraficoVazio";

import "./styles.css";
import AnimateHeight from "react-animate-height";
import { _DeepPartialObject } from "chart.js/types/utils";

interface GraficoPizzaProps {
    tituloGrafico: string;
    labels: string[];
    porcentagens: number[];
    colorArray: string[];
    plugins?: _DeepPartialObject<PluginOptionsByType<"pie">>;
    subTituloGrafico?: string;
    mobile?: boolean;
}

export const GraficoPizza = ({
    tituloGrafico,
    labels,
    porcentagens,
    colorArray,
    plugins,
    subTituloGrafico,
    mobile
}: GraficoPizzaProps) => {

    ChartJS.register(ArcElement, Tooltip, Legend);

    const data: ChartData<"pie", number[], string> = {
        labels: labels,
        datasets: [
            {
                data: porcentagens,
                backgroundColor: colorArray,
                borderColor: "#fff",
                borderWidth: 1,
            },
        ],
    };

    const [dashboardAberta, setDashboardAberta] = useState(true);

    return (
        <div className="section">
            <div
                className="section-header"
                onClick={() => setDashboardAberta(!dashboardAberta)}
            >
                <h3>{tituloGrafico}</h3>
                <span>
                    {dashboardAberta ? (
                        <BsCaretUpFill className="arrowUp" />
                    ) : (
                        <BsCaretDownFill className="arrowDown" />
                    )}
                </span>
            </div>
            <h6>{subTituloGrafico ? subTituloGrafico : ""}</h6>
            <AnimateHeight
                duration={500}
                height={dashboardAberta ? 'auto' : 0}
            >
                {porcentagens.length ?
                    <div className={"divDashboardAtivos gapGraficoPizza" + mobile ? "" : "tamanhoGrafico"}>
                        <Pie
                            data={data}
                            width={'350'}
                            height={'350'}
                            options={{
                                maintainAspectRatio: false,
                                plugins: plugins ? plugins : undefined
                            }} />
                    </div>
                    :
                    <MensagemGraficoVazio />
                }
            </AnimateHeight>
        </div>
    );
};