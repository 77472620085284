import React from "react";

import { useNavigate } from "react-router-dom";
import { BsFillPatchCheckFill } from "react-icons/bs";

import { Button } from "../../../components/Button";

import "./Fechamento.css";

export interface SucessoFechamentoProps {
  clientePrivate: boolean
}

export const SucessoFechamento = ({ clientePrivate }: SucessoFechamentoProps) => {
  const navigate = useNavigate();
  return (
    <div className="caixa1">
      <fieldset>
        <div className="div_text_erro_fechamento">
          <BsFillPatchCheckFill className="IconFalha_erro_fechamento" />
          <h2 className="mensagemDeErro_erroFechamento">
            Fechamento enviado com sucesso.
          </h2>
        </div>
        <Button
          classNameDiv="button_erro_fechamentos"
          titleButton={"confirmar"}
          onClick={() => navigate(clientePrivate ? "/funcionario/clientes-private" : "/gerenciar/historico-fechamento")}
        />
      </fieldset>
    </div>
  );
};
