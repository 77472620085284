import React, { useState } from "react";

import "./CadastroFuncionario.css";


import { handleConfirm, handleFile } from "./functions";
import { Header } from "../../../components/Header";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading";
import { handleUserName } from "../../../components/CadastroAcesso/functions";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";

const CadastroFuncionario = () => {
    const [nome, setNome] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [imagemPerfil, setImagemPerfil] = useState<any>();
    const [erro, setErro] = useState<any>({
        erro: false,
        mensagem: "",
    });
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [modalDesconectar, setModalDesconectar] = useState(false);



    return (
        <>
            <Navbar />
            <div id="container">
                {loading && <Loading />}
                <Header titulo="Cadastro de Funcionários" />
                <div id="divFormPF" className="titulo1">
                    <fieldset>
                        {!sucesso ? (
                            <form onSubmit={(e) => handleConfirm(e, nome, userName, email, imagemPerfil, setLoading, setSucesso, erro, setErro, setModalDesconectar)}>
                                <Input
                                    id="txtNome"
                                    type="text"
                                    value={nome}
                                    onChange={(e) => setNome(e.target.value)}
                                    placeholder="Nome Completo"
                                    label="Nome Completo"
                                    required
                                />
                                <Input
                                    id="txtNome"
                                    type="text"
                                    value={userName}
                                    onChange={(e: any) => handleUserName(e.target.value, setErro, setUserName)}
                                    placeholder="Nome de usuário"
                                    label="Nome de usuário"
                                    required
                                />
                                <Input
                                    id="txtEmail"
                                    type="email"
                                    value={email}
                                    onChange={(e: any) => setEmail(e.target.value)}
                                    placeholder="E-mail"
                                    label="E-mail"
                                    required
                                />
                                <div className="Area_Botoes">
                                    <label className="DocumentosAnexos">
                                        Foto de perfil do funcionário
                  </label>
                                    <Input
                                        id="file"
                                        type="file"
                                        multiple
                                        onChange={(e) => handleFile(e, setImagemPerfil)}
                                        className="DocumentosAnexos_Botao"
                                        accept="image/*,.pdf"
                                    />
                                    {erro.erro && (
                                        <p className="MensagemDeErro">{erro.mensagem}</p>
                                    )}
                                    <Button
                                        onClick={(e) => handleConfirm(e, nome, userName, email, imagemPerfil, setLoading, setSucesso, erro, setErro, setModalDesconectar)}
                                        classNameButton="btnAlterar botao"
                                        titleButton="Concluir"
                                        type="submit"
                                    />
                                </div>
                            </form>
                        ) : (
                                <div className="container_sucesso">
                                    <h3 className="titulo">Funcionário cadastrado com sucesso!</h3>
                                    <p className="mensagem">
                                        {`Um E-mail foi enviado para o/a ${userName} com a senha para o
                  acesso ao sistema.`}
                                    </p>
                                    <Button
                                        onClick={() => setSucesso(!sucesso)}
                                        classNameButton="btnAlterar botao"
                                        titleButton="Concluir"
                                    />
                                </div>
                            )}
                    </fieldset>
                </div>
            </div>
            <Footer />
            <ModalDesconectar modal={modalDesconectar} />
        </>
    );
};

export default CadastroFuncionario;