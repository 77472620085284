import React from "react";
import { obterUsuariosEmEspera } from "../../../services/cliente";
import { obterDadosUsuario2, guardarDadosUsuario2 } from "../../../services/cookies";
import { renovacaoToken } from "../../../services/usuario";
import { Cliente } from "./GerenciarCadastro";


export const retornarUsuariosEmEspera = async (
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErro: React.Dispatch<React.SetStateAction<boolean>>,
    setDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setUsuariosEmEspera: React.Dispatch<React.SetStateAction<Cliente[]>>
) => {
    const dadosUsuario = obterDadosUsuario2();

    await obterUsuariosEmEspera(dadosUsuario.token).then(res => {
        const listaUsuariosEmEspera = res.data.resultado.clientes
        setUsuariosEmEspera(listaUsuariosEmEspera)
    }).catch(async (err) => {
        if (err.toString() === "Error: Request failed with status code 401") {
            await renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao).then(async (res) => {
                guardarDadosUsuario2(
                    res.data.camposAdicionais.tokenAcesso,
                    res.data.camposAdicionais.tokenRenovacao,
                    dadosUsuario.email.toString(),
                    dadosUsuario.id.toString()
                );
                await obterUsuariosEmEspera(res.data.camposAdicionais.tokenAcesso).then(res => {
                    setUsuariosEmEspera(res.data.resultado.clientes)
                }).catch(() => setErro(true))
            }).catch(() => setDesconectar(true))
        }
        else {
            setErro(true)
        }
    }).finally(() => setLoading(true))
}