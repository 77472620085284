import { guardarDadosUsuario, guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { operacaoFinanceiraAprovacao } from "../../../services/operacaoFinanceira";
import { renovacaoToken } from "../../../services/usuario";


export function handleMaskCotas(
    e: string,
    setQuantidadeCotas: (value: React.SetStateAction<number>) => void,
    setQuantidadeCotasMask: (value: React.SetStateAction<string>) => void,
) {
    e = e.replace(/[^,.0-9]/g, "");
    e = e.replace(",", ".");
    var count = 0;
    for (let i = 0; i <= e.length; i++) {
        if (e[i] === ".") {
            count = count + 1;
            if (count >= 2) {
                e = e.replace(/[.]/, "");
            }
        }
    }

    setQuantidadeCotas(Number(e));
    setQuantidadeCotasMask(e);
}

export async function handleStatusAprovarAporte(
    e: React.FormEvent,
    setLoadingAprovar: (value: React.SetStateAction<boolean>) => void,
    idCredenciaisCliente: string,
    IdServicoFinanceiro: number,
    dataSolicitacao: Date,
    status: "EmEspera" | "Efetuado" | "Rejeitado",
    valor: number,
    tipo: "Aporte" | "Saque",
    quantidadeCotas: number,
    comprovante: string | ArrayBuffer,
    setModal: (modal: boolean) => void,
    setReload: (value: React.SetStateAction<boolean>) => void,
    setErroAprovar: (value: React.SetStateAction<boolean>) => void,

) {
    e.preventDefault();
    let token = obterDadosUsuario2().token;
    let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
    let email = obterDadosUsuario2().email;
    let id = obterDadosUsuario2().id;

    setLoadingAprovar(true);

    await operacaoFinanceiraAprovacao(
        idCredenciaisCliente,
        IdServicoFinanceiro,
        dataSolicitacao,
        status,
        valor,
        tipo,
        token,
        quantidadeCotas,
        comprovante
    )
        .then(() => {
            setLoadingAprovar(false);
            setModal(false);
            setReload((value) => !value);
        })
        .catch(async (e) => {
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await operacaoFinanceiraAprovacao(
                            idCredenciaisCliente,
                            IdServicoFinanceiro,
                            dataSolicitacao,
                            status,
                            valor,
                            tipo,
                            res2.data.camposAdicionais.tokenAcesso,
                            quantidadeCotas,
                            comprovante
                        )
                            .then((res2) => {
                                setLoadingAprovar(false);
                                setModal(false);
                                setReload((value) => !value);
                            })
                            .catch((e2) => {
                                setErroAprovar(true);
                                setModal(false);
                                setLoadingAprovar(false);
                            });
                    })
                    .catch((err3) => {
                        setModal(true);
                    });
            } else {
                setErroAprovar(true);
                setLoadingAprovar(false);
            }
        });
};

export const handleComprovanteUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setComprovante: (value: React.SetStateAction<string | ArrayBuffer>) => void,
) => {
    let file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        setComprovante(reader.result);
    };
};