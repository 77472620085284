import React from "react";
import { guardarDadosUsuario, guardarDadosUsuario2, obterDadosUsuario2, removerDadosUsuario, removerDadosUsuario2 } from "../../../services/cookies";
import { incluirTemplateEmail } from "../../../services/gestor";
import { renovacaoToken } from "../../../services/usuario";

export async function submitTemplateEmail(assunto: string, conteudoHtml: string, conteudoTexto: string, tipo: string, setLoading: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setModalDesconectar: React.Dispatch<boolean>) {
    setLoading(true);
    const token = obterDadosUsuario2().token;
    const tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
    const email = obterDadosUsuario2().email;
    const id = obterDadosUsuario2().id;
    await incluirTemplateEmail(
        assunto,
        conteudoHtml,
        conteudoTexto,
        tipo,
        token
    )
        .then((res) => {
            setLoading(false);
            setErro(false);
        })
        .catch(async (e) => {
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await incluirTemplateEmail(
                            assunto,
                            conteudoHtml,
                            conteudoTexto,
                            tipo,
                            res2.data.camposAdicionais.tokenAcesso
                        )
                            .then(() => {
                                setLoading(false);
                                setErro(false);
                            })
                            .catch((e2) => {
                                setLoading(false);
                                setErro(true);
                            });
                    })
                    .catch((err3) => {
                        removerDadosUsuario();
                        removerDadosUsuario2();
                        setLoading(false);
                        setErro(true);
                        // navigate("/");
                        setModalDesconectar(true);
                    });
            } else {
                setLoading(false);
                setErro(true);
            }
        });
}
