import React, { useState, useContext } from "react";
import "./reset.css";
import "./Login.css";
import { login } from "./functions";

import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import ModalRedefinicaoSenha from "../../components/modais/ModalRedefinicaoSenha/ModalRedefinicaoSenha";
import { LoadingButton } from "../../components/loadingButtom";
import { Erro } from "../Cliente/AlteracaoSenha/AlteracaoSenha";

import { usuarioContext, UsuarioContextType } from "../../context/tipoUsuario";

import ImagemLogo from "../../assets/img/omega_header_menu.png";

import { Link, useNavigate } from "react-router-dom";
import { handleReenviar } from "../Cliente/ConfirmacaoEmail/functions";


export interface Credenciais {
  email: string,
  senha: string,
  error?: boolean,
}

const Login = () => {
  const [credenciais, setCredenciais] = useState<Credenciais>({
    email: "",
    senha: "",
  });
  const [modalAlterarSenha, setModalAlterarSenha] = useState<boolean>(false);
  const [error, setError] = useState<Erro>({
    erro: false,
    mensagem: "",
  });
  const [loading, setLoading] = useState(false);
  const [botaoReenviarEmail, setBotaoReenviarEmail] = useState(false);
  const [reenviar, setReenviar] = useState<boolean>(false);
  const [erroReenviar, setErroReenviar] = useState<boolean>(false);

  const { atualizarUsuario, removerUsuario } = useContext<UsuarioContextType>(usuarioContext);
  const navigate = useNavigate();


  return (
    <div className="telaLogin">
      <div className="LogoCabecalhoArea">
        <img
          alt="Logo da Omega digital assets"
          className="LogoCabecalho"
          src={ImagemLogo}
        />
      </div>
      <div className="AreaLogin">
        <div id="divForm" className="inputsLogin">
          <fieldset>
            <form
              onSubmit={(e) => login(
                e,
                setLoading,
                setBotaoReenviarEmail,
                setCredenciais,
                credenciais,
                navigate,
                removerUsuario,
                atualizarUsuario,
                setError,
              )}
              className="container-form"
            >
              <Input
                type="email"
                label="E-mail"
                placeholder=" "
                value={credenciais.email}
                onChange={(e) => {
                  setCredenciais({
                    ...credenciais,
                    email: e.target.value,
                  });
                }}
              />
              <Input
                type="password"
                label="Senha"
                placeholder=" "
                senha
                required
                value={credenciais.senha}
                onChange={(e) => {
                  setCredenciais({
                    ...credenciais,
                    senha: e.target.value,
                  });
                }}
              />
              <div className="AreaLogin_Botao">
                {loading ? (
                  <LoadingButton />
                ) : (
                  <button
                    className="botaoLogin"
                    onClick={(e) => login(
                      e,
                      setLoading,
                      setBotaoReenviarEmail,
                      setCredenciais,
                      credenciais,
                      navigate,
                      removerUsuario,
                      atualizarUsuario,
                      setError,
                    )}
                    // titleButton="ENTRAR" 
                    type="submit" >
                    Entrar
                  </button>
                )}
                {error.erro && (
                  <p className="MensagemDeErro">{error.mensagem}</p>
                )}
                {botaoReenviarEmail && (
                  erroReenviar ?
                    <p className="MensagemDeErro">Erro ao reenviar o email. Tente novamente mais tarde.</p>
                    :
                    reenviar ?
                      <p className="MensagemDeErro">Email reenviado com sucesso.</p>
                      :
                      <p className="MensagemDeErro MensagemClicavel" onClick={() => handleReenviar(credenciais.email, "2", setReenviar, setErroReenviar)}>Ou clique aqui para reenviar o email de confirmação.</p>
                )}
              </div>

              <div className="Area_EsqueceuSenha">
                <div className="AlterarSenha">
                  <p>Esqueceu a senha?</p>
                  <Button
                    className="btnAlterarSenha"
                    onSubmit={() => setModalAlterarSenha(true)}
                    onClick={() => setModalAlterarSenha(true)}
                    titleButton="Altere a senha"
                  />
                </div>
                <div className="AbrirConta">
                  <p>Ainda não tem conta?</p>
                  <Link to="/cliente/cadastro">Abra uma conta</Link>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
      <ModalRedefinicaoSenha
        mostrar={modalAlterarSenha}
        fecharModal={() => setModalAlterarSenha(false)}
      />
    </div>
  );
};

export default Login;
