import React, { useEffect, useState } from "react";
import "./style.css";

import { Cotacoes } from "../../../pages/Gestor/Fechamento/Fechamento";
import { adicionarPorcentagemDoAtivoNaCarteiraNoAtivo, handleDelete } from "./functions";

interface CardFechamentoProps {
  sigla: string;
  ativo: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  total: number;
  manual?: boolean;
  removerNovoAtivoOffline: (index: number) => void;
  index: number;
  listaCotacoes?: Cotacoes[];
  setListaCotacoes?: (cotacao: Cotacoes[]) => void;
}

export const CardFechamento = ({
  ativo,
  quantidade,
  valorUnitario,
  valorTotal,
  total,
  removerNovoAtivoOffline,
  index,
  manual,
  listaCotacoes,
  sigla,
  setListaCotacoes,
}: CardFechamentoProps) => {

  const [porcentagem, setPorcentagem] = useState<number>(
    (100 * quantidade) / valorTotal
  );

  useEffect(() => {
    setPorcentagem((100 * quantidade) / valorTotal);
    adicionarPorcentagemDoAtivoNaCarteiraNoAtivo(listaCotacoes, index, ativo, porcentagem, quantidade, sigla, valorUnitario, manual, setListaCotacoes);
  }, [valorTotal]);

  return (
    <div className="caixa1 espacamentoCardFechamento">
      <fieldset>
        {manual && (
          <div className="delete-button-div">
            <span
              className="delete-button-card-fechamento"
              onClick={() => handleDelete(index, removerNovoAtivoOffline)}
            >
              X
            </span>
          </div>
        )}
        <div className="tituloCardFechamento">
          {/* {imagemAtivo && (
            <img className="imagemAtivoFechamento" src={imagemAtivo} />
          )} */}
          <h4>{ativo}</h4>
        </div>
        <div className="conteudoCardFechamento">
          <h6>Quantidade: {quantidade.toPrecision(3)}</h6>
          <h6>Valor Unitario do Ativo: US$ {valorUnitario.toFixed(2)}</h6>
          <h6>
            Porcentagem:{" "}
            {porcentagem < 0.000999
              ? porcentagem.toFixed(3)
              : porcentagem.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}
            %
          </h6>
        </div>
        <div className="valorFechamento">
          <strong>Valor Total: US$ {total.toPrecision(3)}</strong>
        </div>
      </fieldset>
    </div>
  );
};

