import React from "react";
import { obterOperacoesFinceirasPendentesPorTipo } from "../../../services/cliente";
import { guardarDadosUsuario, guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { renovacaoToken } from "../../../services/usuario";
import { RespostaOperacaoFinanceira } from "./ValidacaoAporte";

export const obterOperacaoFinanceira = (IdServicoFinanceiro: number, OperacaoFinanceira: string, StatusOperacaoFinanceira: string, setData: React.Dispatch<Array<RespostaOperacaoFinanceira>>, setLoadingPage: React.Dispatch<boolean>, setErroPage: React.Dispatch<boolean>, setModalDesconectar: React.Dispatch<boolean>) => {
    obterOperacoesFinceirasPendentesPorTipo(
        IdServicoFinanceiro,
        OperacaoFinanceira,
        StatusOperacaoFinanceira,
        obterDadosUsuario2().token
    )
        .then((res) => {
            setData(res.data.resultado.clientes);
            setLoadingPage(false);
            setErroPage(false);
        })
        .catch(async (e) => {
            let token = obterDadosUsuario2().token;
            let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
            let email = obterDadosUsuario2().email;
            let id = obterDadosUsuario2().id;
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await obterOperacoesFinceirasPendentesPorTipo(
                            IdServicoFinanceiro,
                            OperacaoFinanceira,
                            StatusOperacaoFinanceira,
                            res2.data.camposAdicionais.tokenAcesso
                        )
                            .then((res2) => {
                                setData(res2.data.resultado.clientes);
                                setLoadingPage(false);
                                setErroPage(false);
                            })
                            .catch((e2) => {
                                setErroPage(true);
                                setLoadingPage(false);
                            });
                    })
                    .catch((err3) => {
                        setModalDesconectar(true);
                    });
            } else {
                setErroPage(true);
                setLoadingPage(false);
            }
        });
}