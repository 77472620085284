import React, { useState, useEffect } from "react";

import "./Fechamento.css";

import { adicionarNovoAtivoOffline, calcularTotalDeCotasDoDia, calcularTotalDoFechamentoDoDiaUSD, handleConfirm, obterCotacoes, obterFundoFinaceiroPrivate, obterIdFundoFinanceiroAssessoria, obterValorReal, removerNovoAtivoOffline, handleConfirmPrivate, obterCotacoesPrivate } from "./functions";

import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import Footer from "../../../components/Footer/Footer";
import { TabelaFechamento } from "../../../components/Tabelas/TabelaFechamento";
import { ModalNovoAtivoFechamento } from "../../../components/modais/ModalNovoAtivoFechamento";
import { CardFechamento } from "../../../components/Cards/CardFechamento";
import { Loading } from "../../../components/Loading";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { ErroFechamento } from "./erroFechamento";
import { SucessoFechamento } from "./sucessoFechamento";

import { AiOutlineReload, AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Button } from "../../../components/Button";
import { LoadingButton } from "../../../components/loadingButtom";
import { voltarSlide, proximoSlide } from "../../Cliente/Extrato/functions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { mascaraBRL, mascaraUSD, transformarValorParaBRLEmInput } from "../../../functions/MascarasMonetarias";

import * as error from "../../../pages/Cliente/AlteracaoSenha/AlteracaoSenha"
import { useParams } from "react-router-dom";

const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    variableWidth: true,
    respondTo: 'slider',
    rows: 2,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlide" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlide" style={{ display: 'none !important' }}></div>
};

export interface Cotacoes {
    nome: string;
    porcentagemAtivo?: number;
    quantidadeAtivo: number;
    sigla: string;
    valorTotalAtivo: number;
    valorUnitarioAtivo: number;
    adicionadoManualmente?: boolean;
    origem?: string;
}

const Fechamento = () => {
    const [modalNovoAtivoFechamento, setModalNovoAtivoFechamento] =
        useState<boolean>(false);
    const [carregando, setCarregando] = useState(false);
    const [cotacoes, setCotacoes] = useState<Array<Cotacoes>>([]);
    const [valorTotal, setValorTotal] = useState<number>(0);
    const [totalUsd, setTotalUsd] = useState(0);
    const [totalBRL, setTotalBRL] = useState(0);
    const [valorReal, setValorReal] = useState<number>(0);
    const [fundoFinaceiroId, setFundoFinaceiroId] = useState<string>('');
    const [servicoFinaceiroId, setServicoFinaceiroId] = useState<number>(1);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [erro, setErro] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [desconectar, setDesconectar] = useState<boolean>(false);
    const [totalCotaDia, setTotalCotaDia] = useState<number>(0);
    const [valorCotaDia, setValorCotaDia] = useState<string>("");
    const [taxaPrivate, setTaxaPrivate] = useState<number>(0);
    const [erroCotaVazia, setErroCotaVazia] = useState<boolean>(false);
    const [erroTaxaVazia, setErroTaxaVazia] = useState<boolean>(false);
    const [cobrarTaxa, setCobrarTaxa] = useState<boolean>(false);
    const [taxaAssessoria, setTaxaAssessoria] = useState<number>(0);
    const [error, setError] = useState<error.Erro>({ erro: false, mensagem: "" });

    const { idCredenciais } = useParams();

    const chamadaApi = async () => {
        if (!idCredenciais) {
            setCarregando(true);
            await calcularTotalDeCotasDoDia(servicoFinaceiroId, totalBRL, setTotalCotaDia, setErro)
            await obterCotacoes(setCotacoes, setValorTotal, setCarregando, setDesconectar)
            await obterIdFundoFinanceiroAssessoria(setFundoFinaceiroId, setErro, setDesconectar)
            setServicoFinaceiroId(1)
        }
        else {
            setCarregando(true);
            await obterCotacoesPrivate(idCredenciais, setCotacoes, setValorTotal, setCarregando, setDesconectar)
            await obterFundoFinaceiroPrivate(idCredenciais, setFundoFinaceiroId, setErro, setDesconectar)
            setServicoFinaceiroId(3)
        }
    }


    useEffect(() => {
        setErro(false);
        setSucesso(false);
        obterValorReal(setValorReal, setError);
    }, [reload]);

    useEffect(() => {
        chamadaApi()
    }, [idCredenciais])

    useEffect(() => {
        setTotalBRL(totalUsd * valorReal);
    }, [totalUsd]);

    useEffect(() => {
        calcularTotalDoFechamentoDoDiaUSD(cotacoes, setTotalUsd);
    }, [cotacoes]);

    useEffect(() => {
        calcularTotalDeCotasDoDia(servicoFinaceiroId, totalBRL, setTotalCotaDia, setErro)
    }, [fundoFinaceiroId]);


    return (
        <>
            {carregando && <Loading />}
            <Navbar />
            <Header>
                <div className="div_header_fechamento">
                    <h2>Fechamento</h2>
                </div>
            </Header>
            {!erro ? (
                !sucesso ? (
                    <div className="caixa1">
                        <fieldset>
                            <div id="fechamentoDesktop">
                                <table id="tblFechamento" className="tabelaFechamento">
                                    <thead>
                                        <tr className="tabelaFechamentoHeader">
                                            <th className="tableTituloFechamento">
                                                <strong>Ativo</strong>
                                            </th>
                                            <th className="tableTituloFechamento colunaQuantidade">
                                                <strong>Quantidade</strong>
                                            </th>

                                            <th className="tableTituloFechamento">
                                                <strong>Valor unitário do ativo</strong>
                                            </th>
                                            <th className="tableTituloFechamento colunaPorcentagemAtivo">
                                                <strong>Porcentagem na carteira</strong>
                                            </th>
                                            <th className="tableTituloFechamento">
                                                <strong>Total</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tbodyPrincipal">
                                        {cotacoes.map((cotacao, index) => {
                                            return (
                                                <TabelaFechamento
                                                    key={`${cotacao.nome}${cotacao.valorTotalAtivo}`}
                                                    ativo={cotacao.nome}
                                                    quantidade={cotacao.quantidadeAtivo}
                                                    valorUnitario={cotacao.valorUnitarioAtivo}
                                                    valorTotal={valorTotal}
                                                    total={cotacao.valorTotalAtivo}
                                                    manual={cotacao.adicionadoManualmente}
                                                    removerNovoAtivoOffline={() => removerNovoAtivoOffline(index, cotacoes, setCotacoes, setValorTotal, valorTotal)}
                                                    index={index}
                                                    listaCotacoes={cotacoes}
                                                    setListaCotacoes={setCotacoes}
                                                    sigla={cotacao.sigla}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div id="fechamentoMobile">
                                <div className="divBotoesExtratoMobile">
                                    <Button
                                        className="btn__cinza btnMargin"
                                        onClick={() => voltarSlide()}
                                        titleButton={
                                            <>
                                                <AiOutlineArrowLeft className="iconButtonExtrato" />
                                            </>
                                        }
                                    />
                                    <Button
                                        className="btn__cinza btnMargin"
                                        onClick={() => proximoSlide()}
                                        titleButton={
                                            <>
                                                <AiOutlineArrowRight className="iconButtonExtrato" />
                                            </>
                                        }
                                    />
                                </div>
                                <div className="boxSlideExtrato">
                                    {erro ? (
                                        <div>
                                            <span className="formatacaoLoadingExtrato">
                                                Ocorreu um erro. Clique no botão para tentar novamente.
                                            </span>
                                            <Button
                                                className="btn__cinza btnMargin"
                                                onClick={() => setReload(!reload)}
                                                titleButton={
                                                    <>
                                                        <AiOutlineReload
                                                            className="iconButtonExtrato"
                                                            onClick={() => setReload(!reload)} />
                                                    </>
                                                }
                                            />
                                        </div>
                                    ) : carregando ? (
                                        <span className="formatacaoLoadingExtrato">
                                            <LoadingButton />
                                        </span>
                                    ) : (
                                        <Slider {...settings}>
                                            {cotacoes.length !== 0 ? (
                                                cotacoes.map((cotacao, index) => (
                                                    <CardFechamento
                                                        key={`${cotacao.nome}${cotacao.valorTotalAtivo}`}
                                                        ativo={cotacao.nome}
                                                        quantidade={cotacao.quantidadeAtivo}
                                                        valorUnitario={cotacao.valorUnitarioAtivo}
                                                        valorTotal={valorTotal}
                                                        total={cotacao.valorTotalAtivo}
                                                        manual={cotacao.adicionadoManualmente}
                                                        removerNovoAtivoOffline={() => removerNovoAtivoOffline(index, cotacoes, setCotacoes, setValorTotal, valorTotal)}
                                                        index={index}
                                                        listaCotacoes={cotacoes}
                                                        setListaCotacoes={setCotacoes}
                                                        sigla={cotacao.sigla}
                                                    />
                                                ))) : <span>Nenhum registro encontrado no momento.</span>
                                            }
                                        </Slider>
                                    )}
                                </div>
                            </div>
                            <table
                                id="tblFechamentoFooter"
                                className="tabelaFechamento footerTabelaFechamento"
                            >
                                <tfoot>
                                    <tr className="corDaLinhaDaTabelaFechamento">
                                        <td
                                            className="textoValorDoFechamentoFechamento paddingFooterFechamento"
                                            colSpan={5}
                                        >
                                            Total do fechamento do dia (USD):
                                            <label className="fw-bold lblTotalFechamentoUSD">
                                                &nbsp; US$ {" "}
                                                {mascaraUSD(totalUsd.toFixed(2))}
                                            </label>
                                        </td>
                                    </tr>
                                    <tr className="corDaLinhaDaTabelaFechamento">
                                        <td
                                            className="textoValorDoFechamentoFechamento paddingFooterFechamento"
                                            colSpan={5}
                                        >
                                            Total do fechamento do dia (BRL):{" "}
                                            <label className="fw-bold lblTotalFechamentoBRL">
                                                &nbsp; R$ {" "}
                                                {mascaraBRL(totalBRL.toFixed(2))}
                                            </label>
                                        </td>
                                    </tr>

                                    {!idCredenciais &&
                                        <tr className="corDaLinhaDaTabelaFechamento">
                                            <td
                                                className="textoValorDoFechamentoFechamento paddingFooterFechamento"
                                                colSpan={5}
                                            >
                                                Valor da cota do dia:  &nbsp;
                                                <label className="fw-bold lblTotalCotaFechamento">
                                                    <input
                                                        style={{ marginLeft: "6px", borderColor: "none" }}
                                                        maxLength={13}
                                                        type='text'
                                                        id="totalCotaDia"
                                                        autoComplete="off"
                                                        name="totalCotaDia"
                                                        value={"R$ " + valorCotaDia}
                                                        onChange={(e) => {
                                                            setTotalCotaDia(Number(transformarValorParaBRLEmInput(e.target.value, setValorCotaDia).replace(/\./g, '').replace(/,/g, '.')));
                                                            setValorCotaDia(transformarValorParaBRLEmInput(e.target.value, setValorCotaDia));
                                                            setTaxaAssessoria(Number(e.target.value))
                                                        }}
                                                    />
                                                </label>
                                            </td>
                                        </tr>
                                    }

                                    <tr className="corDaLinhaDaTabelaFechamento">
                                        <td
                                            className="textoValorDoFechamentoFechamento paddingFooterFechamento"
                                            colSpan={5}
                                        >
                                            <input
                                                type="checkbox"
                                                id="chkCobrarTaxas"
                                                name="chkCobrarTaxas"
                                                onChange={(e) => setCobrarTaxa(e.target.checked)}
                                            />
                                            <label htmlFor="chkCobrarTaxas" className="espacamentoCheckbox">
                                                Fazer cobranca de taxas
                                            </label>
                                        </td>
                                        {
                                            !idCredenciais &&
                                            cobrarTaxa &&
                                            <td
                                                className="textoValorDoFechamentoFechamento paddingFooterFechamento"
                                                colSpan={5}
                                            >
                                                Taxa:  &nbsp;
                                                <label className="fw-bold lblTotalCotaFechamento">
                                                    <input
                                                        style={{ display: "inline", width: "2.5rem", height: "2rem", textAlign: "center", borderColor: erroTaxaVazia ? "#e89b9b" : "none" }}
                                                        maxLength={3}
                                                        type='text'
                                                        id="taxaCobrada"
                                                        autoComplete="off"
                                                        name="taxaCobrada"
                                                        value={taxaAssessoria}
                                                        onChange={(e) => { setTaxaAssessoria(Number(e.target.value)) }}
                                                    />
                                                    <span style={{ marginLeft: "0.5rem" }}>%</span>
                                                    <p style={{ color: "#e06c6c", marginLeft: "6px", display: erroTaxaVazia ? "inline" : "none" }}>Preencha o valor da Taxa a cobrar antes de confirmar.</p>
                                                </label>
                                            </td>
                                        }
                                        {idCredenciais &&
                                            cobrarTaxa &&
                                            <td
                                                className="textoValorDoFechamentoFechamento paddingFooterFechamento"
                                                colSpan={5}
                                            >
                                                Taxa:  &nbsp;
                                                <label className="fw-bold lblTotalCotaFechamento">
                                                    <input
                                                        style={{ display: "inline", width: "2.5rem", height: "2rem", textAlign: "center", borderColor: erroTaxaVazia ? "#e89b9b" : "none" }}
                                                        maxLength={3}
                                                        type='text'
                                                        id="taxaCobrada"
                                                        autoComplete="off"
                                                        name="taxaCobrada"
                                                        value={taxaPrivate}
                                                        onChange={(e) => { setTaxaPrivate(Number(e.target.value)) }}
                                                    />
                                                    <span style={{ marginLeft: "0.5rem" }}>%</span>
                                                    <p style={{ color: "#e06c6c", marginLeft: "6px", display: erroTaxaVazia ? "inline" : "none" }}>Preencha o valor da Taxa a cobrar antes de confirmar.</p>
                                                </label>
                                            </td>
                                        }
                                    </tr>
                                </tfoot>
                            </table>

                            <div
                                id="divBotoes"
                                style={{ display: "flex", justifyContent: "center" }}
                            >
                                <button
                                    className="btn__cinza"
                                    onClick={() => setModalNovoAtivoFechamento(true)}
                                    type="button"
                                    id="btnAdicionarAtivo"
                                    style={{ width: "150px", lineHeight: "15px", height: "35px" }}
                                >
                                    Adicionar Ativo
                                </button>
                                <ModalNovoAtivoFechamento
                                    modal={modalNovoAtivoFechamento}
                                    setModal={() => setModalNovoAtivoFechamento(false)}
                                    handleConfirm={(ativo) => adicionarNovoAtivoOffline(ativo, cotacoes, setCotacoes, setValorTotal, valorTotal)}
                                />
                                <button
                                    className="btn__laranja"
                                    type="button"
                                    id="btnConfirmar"
                                    style={{ width: "150px", lineHeight: "25px" }}
                                    onClick={() => {
                                        idCredenciais ?
                                            handleConfirmPrivate(taxaPrivate, cobrarTaxa, cotacoes, fundoFinaceiroId, servicoFinaceiroId, totalBRL, totalUsd, setErro, setSucesso, setDesconectar, setCarregando, valorTotal)
                                            :
                                            handleConfirm(taxaAssessoria, cobrarTaxa, cotacoes, fundoFinaceiroId, servicoFinaceiroId, totalBRL, totalUsd, totalCotaDia, setTotalCotaDia, setErro, setSucesso, setDesconectar, setCarregando, setErroCotaVazia);
                                    }}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </fieldset>
                    </div>
                ) : (
                    <SucessoFechamento clientePrivate={idCredenciais ? true : false} />
                )
            ) : (
                <ErroFechamento handleClick={() => setReload(!reload)} />
            )}
            <Footer />
            {desconectar && <ModalDesconectar modal={desconectar} />}
        </>
    );
};

export default Fechamento;
