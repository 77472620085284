import React, { useEffect, useState } from "react";

import { obterDadosCadastrais } from "./functions";
import "./styles.css"

import { Button } from "../../Button";
import { Input } from "../../Input";
import { Cliente } from '../../DadosClientePF/index'
import { LoadingButton } from "../../loadingButtom";

import Modal from "react-bootstrap/Modal";
import { ErroTabela } from "../../Erros/ErroTabela";
import { AiOutlineHome, AiOutlineIdcard } from "react-icons/ai";
import { ModalVerDocumento } from "../ModalVerDocumento";
import { DateFormatter } from "../../../functions/DateFormatter";

interface modalProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    idCliente?: string
}

interface Documentacao {
    tipoDocumento: string;
    nomeArquivo: string;
    tamanho: string;
    dataUpload: string;
    urlImagem: string;
}

export interface Usuario {
    nome?: string;
    cpf?: string;
    rg?: string;
    orgaoExpeditor?: string;
    ufOrgaoExpeditorRg?: string;
    nacionalidade?: string;
    dataNascimento?: string;
    renda?: number;
    rua?: string;
    complemento?: string;
    bairro?: string;
    cidade?: string;
    estado?: string;
    imagensDocumentacao?: Documentacao[];
}

export const ModalDadosPessoaisCliente = ({ modal, setModal, idCliente }: modalProps) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [erro, setErro] = useState<boolean>(false)
    const [desconectar, setDesconectar] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false)
    const [modalImagemDocumento, setModalImagemDocumento] = useState<boolean>(false);
    const [urlDocumento, setUrlDocumento] = useState<string>('');
    const [usuario, setUsuario] = useState<Cliente>();

    useEffect(() => {
        obterDadosCadastrais(idCliente, setUsuario, setLoading, setErro, setDesconectar)
    }, [reload])

    return (
        <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Dados do Cliente</Modal.Title>
            </Modal.Header>
            {erro ? <ErroTabela handleClick={() => setReload(!reload)} className="formatacaoGrafico" /> :
                <>

                    {loading ?
                        <div className="caixa1">
                            <LoadingButton />
                        </div>
                        :
                        <Modal.Body>
                            <div className="areaBtnDadosPessoais">

                                <Button
                                    className="btnModalDadosCliente"
                                    onClick={() => {
                                        setUrlDocumento(usuario.imagensDocumentacao[0].urlImagem);
                                        setModalImagemDocumento(true);
                                    }}
                                    titleButton={
                                        <>
                                            <AiOutlineIdcard className="iconButton centerButton" />
                                            RG
                                        </>
                                    }
                                />
                                <Button
                                    className="btnModalDadosCliente btnMobile"
                                    onClick={() => {
                                        setUrlDocumento(usuario.imagensDocumentacao[1].urlImagem);
                                        setModalImagemDocumento(true);
                                    }}
                                    titleButton={
                                        <>
                                            <AiOutlineHome className="iconButton centerButton iconCasa" />
                                            Comprovante de Residência
                                        </>
                                    }
                                />
                                <Button
                                    className="btnModalDadosCliente"
                                    onClick={() => {
                                        setUrlDocumento(usuario.imagensDocumentacao[2].urlImagem);
                                        setModalImagemDocumento(true);
                                    }}
                                    titleButton={
                                        <>
                                            <AiOutlineIdcard className="iconButton centerButton" />
                                            CPF
                                        </>
                                    }
                                />
                            </div>

                            <Input disabled label="Nome:" placeholder=" " id={"nome"} value={usuario?.nome ? usuario?.nome : ''} />
                            <Input disabled label="CPF:" placeholder=" " id={"cpf"} value={usuario?.cpf ? usuario?.cpf : ''} />
                            <Input disabled label="RG:" placeholder=" " id={"rg"} value={usuario?.rg ? usuario?.rg : ''} />
                            <Input disabled label="Orgao Expeditor:" placeholder=" " id={"orgaoExpeditor"} value={usuario?.orgaoExpeditor ? usuario?.orgaoExpeditor : ''} />
                            <Input disabled label="Nacionalidade: " placeholder=" " id={"nacionalidade"} value={usuario?.nacionalidade ? usuario?.nacionalidade : ''} />
                            <Input disabled label="Data de Nascimento:" placeholder=" " id={"dataNascimento"} value={usuario?.dataNascimento ? DateFormatter(usuario?.dataNascimento) : ''} />
                            <Input disabled label="Renda:" placeholder=" " id={"renda"} value={usuario?.renda ? usuario?.renda : ''} />
                            <Input disabled label="Estado:" placeholder=" " id={"estado"} value={usuario?.estado ? usuario?.estado : ''} />
                            <Input disabled label="Cidade:" placeholder=" " id={"cidade"} value={usuario?.cidade ? usuario?.cidade : ''} />
                            <Input disabled label="Rua:" placeholder=" " id={"rua"} value={usuario?.rua ? usuario?.rua : ''} />
                            <Input disabled label="Bairro:" placeholder=" " id={"bairro"} value={usuario?.bairro ? usuario?.bairro : ''} />
                            <Input disabled label="Complemento:" placeholder=" " id={"complemento"} value={usuario?.complemento ? usuario?.complemento : ''} />
                        </Modal.Body>
                    }
                </>
            }
            {modalImagemDocumento &&
                <ModalVerDocumento
                    modal={modalImagemDocumento}
                    setModal={() => setModalImagemDocumento(false)}
                    urlImagemDocumento={urlDocumento}
                />
            }
            <Modal.Footer>
                <Button
                    classNameButton="btnCancelar"
                    onClick={() => setModal(false)}
                    titleButton="Fechar"
                />
            </Modal.Footer>
        </Modal>
    );
};
