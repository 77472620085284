import React, { HTMLProps, useState } from "react";
import "./styles.css";

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  classNameButton?: string;
  classNameDiv?: string;
  titleButton: string | React.ReactNode;
  able?: boolean;
  type?: "button" | "submit" | "reset";
}
export const Button = ({
  classNameButton,
  classNameDiv,
  titleButton,
  type,
  able,
  ...rest
}: ButtonProps) => {
  return (
    <div
      style={{ textAlign: "center", display: "flex", justifyContent: "center" }}
      className={classNameDiv}
    >
      <button
        type={type ? type : "button"}
        className={classNameButton ? classNameButton : "btnAlterar"}
        {...rest}
      >
        {titleButton}
      </button>
    </div>
  );
};
