import React from 'react'
import Navbar from '../../../components/Navbar/Navbar'
import './Relatorios.css'
import Footer from '../../../components/Footer/Footer'
import { Header } from '../../../components/Header'
import { CardRelatorio } from '../../../components/Cards/CardRelatorios'

const Relatorios = () => {
  return (
    <>
      <Navbar />
      <div className="container">
        <Header titulo='Relatórios' />
        <div className="caixaRelatorios linha1">
          <div className="caixaRelatorios__fieldset_left">
            <CardRelatorio
              titulo='Análise – Perfil de investimento'
              subTitulo='Preencha o formulário, para análise do seu perfil de
                        investimento, clicando no botão abaixo.'
              link='https://docs.google.com/forms/d/e/1FAIpQLSc5QK4bFPBxnDJiht8oBRa-ZdDITFkzIR9WcypCgGP0Gk1cAg/viewform'
            />
          </div>
          <div className="caixaRelatorios__fieldset_right">
            <CardRelatorio
              titulo='Entenda o método de “come-cotas”'
              subTitulo='Leia o relatório para entender como utilizamos as cotas
                      para fazer a gestão do seu capital.'
              link="https://omegainvestimentos.com.br/wp-content/uploads/2020/10/come-cotas.pdf"
            />
          </div>
        </div>
        <div className="caixaRelatorios linha2">
          <div className="caixaRelatorios__fieldset_left">
            <CardRelatorio
              titulo='Histórico de rendimentos 2018 – 2020'
              subTitulo='Consulte este relatório para ver o nosso histórico de
                      retornos desde 2018.'
              link="https://omegainvestimentos.com.br/wp-content/uploads/2021/03/lamina-portal_.pdf"
            />
          </div>
          <div className="caixaRelatorios__fieldset_right">
            <CardRelatorio
              titulo='Valuing bitcoin – grayscale (english)'
              subTitulo='Leia este relatório em inglês para entender como a
                      GrayScale faz um valuation do Bitcoin.'
              link="https://omegainvestimentos.com.br/wp-content/uploads/2021/03/Grayscale_Valuing_Bitcoin.pdf"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Relatorios
