import React, { useState } from "react";
import { handleComprovanteUpload, handleMaskCotas, handleStatusAprovarAporte } from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { LoadingButton } from "../../loadingButtom";

import Modal from "react-bootstrap/Modal";

interface modalProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
  setModalDesconectar: (modal: boolean) => void;
  IdServicoFinanceiro: number;
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  setErroAprovar: React.Dispatch<React.SetStateAction<boolean>>;
  dataSolicitacao: Date;
  idCredenciaisCliente: string;
  valor: number;
  tipo: "Aporte" | "Saque";
  status: "EmEspera" | "Efetuado" | "Rejeitado";
}

export const ModalAprovar = ({
  modal,
  setModal,
  setModalDesconectar,
  IdServicoFinanceiro,
  setReload,
  setErroAprovar,
  dataSolicitacao,
  idCredenciaisCliente,
  status,
  tipo,
  valor,
}: modalProps) => {
  const [loadingAprovar, setLoadingAprovar] = useState<boolean>(false);
  const [quantidadeCotas, setQuantidadeCotas] = useState<number>(0);
  const [quantidadeCotasMask, setQuantidadeCotasMask] = useState<string>("");
  const [comprovante, setComprovante] = useState<string | ArrayBuffer | null>();

  return (
    <Modal show={modal} onHide={() => setModal(false)}>
      <form onSubmit={(e) => handleStatusAprovarAporte(e, setLoadingAprovar, idCredenciaisCliente, IdServicoFinanceiro, dataSolicitacao, status, valor, tipo, quantidadeCotas, comprovante, setModal, setReload, setErroAprovar)}>
        <Modal.Header closeButton>
          {IdServicoFinanceiro !== 3 ?
            <Modal.Title>
              Insira a quantidade de cotas
              {tipo === "Saque" && " e o comprovante de transferência"}:
            </Modal.Title>
            :
            <Modal.Title>
              Aprovar operação
              {tipo === "Saque" && " e o comprovante de transferência"}:
            </Modal.Title>
          }
        </Modal.Header>
        <Modal.Body>
          {IdServicoFinanceiro !== 3 && <Input
            label="Quantidade: "
            value={quantidadeCotasMask}
            onChange={(e) => handleMaskCotas(e.target.value, setQuantidadeCotas, setQuantidadeCotasMask)}
          />}
          {tipo === "Saque" && (
            <Input
              label="Comprovante: "
              type="file"
              onChange={(e) => handleComprovanteUpload(e, setComprovante)}
              accept="image/*,.pdf"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          {loadingAprovar ? (
            <LoadingButton />
          ) : (
            <Button
              disabled={quantidadeCotas === 0 ? (IdServicoFinanceiro === 3 ? false : true) : false}
              classNameButton="btnConfirmar"
              onClick={(e) => handleStatusAprovarAporte(e, setLoadingAprovar, idCredenciaisCliente, IdServicoFinanceiro, dataSolicitacao, status, valor, tipo, quantidadeCotas, comprovante, setModalDesconectar, setReload, setErroAprovar)}
              titleButton="Confirmar"
              type="submit"
            />
          )}
          <Button
            classNameButton="btnCancelar"
            onClick={() => setModal(false)}
            titleButton="Fechar"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
