import React from "react";
import Image from "../../assets/img/Tela_Carregamento_BG_TRANSPARENTE.gif";
import useWindowDimensions from "../../functions/UseWindowDimensions";

import "./styles.css";

export const Loading = () => {
  const { height, width } = useWindowDimensions();
  return (
    <div
      className="container-loading"
    //   style={{
    //     position: "absolute",
    //     top: "-10%",
    //     left: 0,
    //     right: 0,
    //     textAlign: "center",
    //     justifyContent: "center",
    //     alignContent: "center",
    //     backgroundColor: "#c0c0c0ee",
    //     height: height * 1.15,
    //     paddingTop: height * 0.2,
    //     width: width,
    //     zIndex: 10,
    //   }}
    >
      <img
        src={Image}
        alt='Logo da omega, escrito "carregando..." em baixo, com os pontos alterando sua cor'
        height={height >= width ? 200 : 500}
        width={height >= width ? 400 : 1000}
        className="imagemLoading"
      />
    </div>
  );
};
