import React from 'react';
import './style.css'

import { Button } from '../../Button';

interface CardServicosFinanceirosProps {
    id: any,
    nome: string,
    descricao: string,
    ativo: any,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    setLabelDescricao: React.Dispatch<React.SetStateAction<string>>,
    setLabelNome: React.Dispatch<React.SetStateAction<string>>,
    setIdServicoParaAtualizar: React.Dispatch<React.SetStateAction<number>>,
    setAtivo: React.Dispatch<React.SetStateAction<boolean>>,
    setTipo: React.Dispatch<React.SetStateAction<string>>,
}

export const CardServicosFinanceiros = ({ id, nome, descricao, ativo,
    setModal, setLabelDescricao, setLabelNome,
    setIdServicoParaAtualizar, setAtivo, setTipo
}: CardServicosFinanceirosProps) => {

    return (
        <div className="caixa1">
            <fieldset >
                <strong className="posicionamentoServicoFinanceiro">{ativo ? "Ativo" : "Inativo"}</strong>
                <strong className='tituloServicoFinanceiro'>{nome}</strong>
                <strong className='descricaoServicoFinanceiro'>{descricao}</strong>
                <Button
                    onClick={() => {
                        setLabelDescricao(descricao);
                        setLabelNome(nome);
                        setIdServicoParaAtualizar(id);
                        setAtivo(ativo);
                        setTipo("atualizar");
                        setModal(true);
                    }}
                    titleButton="Alterar"
                />
            </fieldset>
        </div>
    )
}