import { Cotacoes } from "../../../pages/Gestor/Fechamento/Fechamento";


export const confirmar = (
    setError: (value: React.SetStateAction<boolean>) => void,
    setNome: (value: React.SetStateAction<string>) => void,
    setSigla: (value: React.SetStateAction<string>) => void,
    setValor: (value: React.SetStateAction<string>) => void,
    setQuantidade: (value: React.SetStateAction<string>) => void,
    setModal: (modal: boolean) => void,
    nome: string,
    quantidade: string,
    valor: string,
    sigla: string,
    handleConfirm: (ativo: Cotacoes) => void,

) => {
    setError(false);
    if (
        nome.trim() &&
        quantidade &&
        valor &&
        sigla.trim() &&
        !isNaN(Number(valor))
    ) {
        handleConfirm({
            nome,
            sigla,
            valorTotalAtivo: Number(valor) * Number(quantidade),
            valorUnitarioAtivo: Number(valor),
            quantidadeAtivo: Number(quantidade),
            porcentagemAtivo: null,
            adicionadoManualmente: true,
        });
        handleReset(setNome, setSigla, setValor, setQuantidade, setError, setModal);
    } else {
        setError(true);
    }
};

export const handleReset = (
    setNome: (value: React.SetStateAction<string>) => void,
    setSigla: (value: React.SetStateAction<string>) => void,
    setValor: (value: React.SetStateAction<string>) => void,
    setQuantidade: (value: React.SetStateAction<string>) => void,
    setError: (value: React.SetStateAction<boolean>) => void,
    setModal: (modal: boolean) => void,
) => {
    setNome("");
    setSigla("");
    setValor(null);
    setQuantidade(null);
    setError(false);
    setModal(false);
};