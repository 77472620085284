import React, { useState, useEffect } from "react";
import './ModalHistoricoFinanceiro.css';

import { Button } from "../../Button";

import Modal from "react-bootstrap/Modal";
import { AiFillPrinter, AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineReload, AiOutlineSearch } from "react-icons/ai";
import { exibirExtratoEmModal, pegarServicosFinanceirosDisponiveis } from "./functions";
import { RequisicaoExtrato } from "../../../pages/Cliente/Extrato/Extrato";
import { voltarSlide, proximoSlide, criaPDF } from "../../../pages/Cliente/Extrato/functions";
import { LoadingButton } from "../../loadingButtom";
import { CardExtrato } from "../../Cards/CardExtrato";
import { TabelaExtrato } from "../../Tabelas/TabelaExtrato";

import Slider from "react-slick";
import Dropdown from "react-bootstrap/Dropdown";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
registerLocale("pt", pt);

interface modalHistoricoFinanceiroProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    idCliente?: string;
    nomeCliente?: string;
}

export const ModalHistoricoFinanceiro = ({
    modal,
    setModal,
    idCliente,
    nomeCliente
}: modalHistoricoFinanceiroProps) => {

    const [dataInicial, setDataInicial] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [dataFinal, setDataFinal] = useState<Date>(new Date());
    const [dados, setDados] = useState<Array<RequisicaoExtrato>>([]);
    const [dataExecucao, setDataExecucao] = useState<boolean>(false);
    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);

    const [erroPage, setErroPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [reloadPage, setReloadPage] = useState<boolean>(true);
    const [servicosFinanceiros, setServicosFinanceiros] = useState<usuarioServicoFinanceiroTipo[]>();
    const [servicoSelecionado, setServicoSelecionado] = useState<number>();
    const [servicoInicial, setServicoInicial] = useState<number>();

    useEffect(() => {
        pegarServicosFinanceirosDisponiveis(dataInicial, dataFinal, idCliente, setDados, setErroPage, setLoading, setModalDesconectar, setServicosFinanceiros, setServicoSelecionado);
    }, []);

    useEffect(() => {
         exibirExtratoEmModal(dataInicial, dataFinal, idCliente, setDados, setErroPage, setLoading, setModalDesconectar, servicoSelecionado, servicoInicial);
    }, [dataInicial, dataFinal, servicoSelecionado, reloadPage]);

    const settings = {
        dots: false,
        infinite: false,
        swipeToSlide: false,
        slidesToShow: 1,
        draggable: false,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        variableWidth: true,
        respondTo: 'slider',
        rows: 3,
        slidesPerRow: 1,
        nextArrow: <div id="nextArrowSlide" style={{ display: 'none !important' }}></div>,
        prevArrow: <div id="prevArrowSlide" style={{ display: 'none !important' }}></div>
    };

    return (
        <Modal show={modal} onHide={() => setModal(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>
                    <p className="nomeClienteModal">Cliente: {nomeCliente}</p>
                    <p className="descricaoModalHistoricoFinanceiro">Selecione o período e fundo desejado:</p>
                    <div className="divDatepickerModal">
                        <DatePicker
                            className="inputData"
                            maxDate={(new Date(new Date().setDate(new Date().getDate() - 1)))}
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={dataInicial}
                            onChange={(dataInicio: Date) => setDataInicial(dataInicio)}
                        />
                        <DatePicker
                            className="inputData"
                            maxDate={(new Date(new Date().setDate(new Date().getDate())))}
                            minDate={dataInicial}
                            dateFormat="dd/MM/yyyy"
                            locale="pt"
                            selected={dataFinal}
                            onChange={(dataFim: Date) => setDataFinal(dataFim)}
                        />
                        <select className="selectServicoFinanceiro" onChange={e => { setServicoSelecionado(parseInt(e.target.value)); }}>
                            {servicosFinanceiros && servicosFinanceiros.map((item, index) => {
                                return (
                                    <option key={item.idServicoFinanceiro} value={item.idServicoFinanceiro}>
                                        {item.servicoFinanceiro}
                                    </option>
                                )
                            })}
                        </select>
                        <AiOutlineSearch className="PesquisarHistoricoFinanceiro estiloPesquisar" onClick={() => setReloadPage(!reloadPage)} />

                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className="mobileExtrato">
                    <fieldset>
                        <div className="divBotoesExtratoMobile">
                            <Button
                                className="btn__cinza btnMargin"
                                onClick={() => voltarSlide()}
                                titleButton={
                                    <>
                                        <AiOutlineArrowLeft className="iconButtonExtrato" />
                                    </>
                                }
                            />
                            <Button
                                className="btn__cinza btnMargin"
                                onClick={() => proximoSlide()}
                                titleButton={
                                    <>
                                        <AiOutlineArrowRight className="iconButtonExtrato" />
                                    </>
                                }
                            />
                        </div>
                        <div className="boxSlideExtrato">
                            {erroPage ? (
                                <div>
                                    <span className="formatacaoLoadingExtrato">
                                        Ocorreu um erro. Clique no botão para tentar novamente.
                                    </span>
                                    <Button
                                        className="btn__cinza btnMargin"
                                        onClick={() => proximoSlide()}
                                        titleButton={
                                            <>
                                                <AiOutlineReload
                                                    className="iconButtonExtrato"
                                                    onClick={() => setReloadPage(!reloadPage)} />
                                            </>
                                        }
                                    />
                                </div>
                            ) : loading ? (
                                <span className="formatacaoLoadingExtrato">
                                    <LoadingButton />
                                </span>
                            ) : (
                                <Slider {...settings}>
                                    {dados.length != 0 ? (
                                        dados.map((value: RequisicaoExtrato) => (
                                            <CardExtrato
                                                dataExecucao={dataExecucao}
                                                key={value.dataSolicitacao.toString() + value.valor}
                                                dados={value}
                                            />
                                        ))
                                    ) : <span>Nenhum registro encontrado no momento.</span>
                                    }
                                </Slider>
                            )}
                        </div>
                    </fieldset>
                </div>

                <div className="desktopExtrato">
                    <fieldset>
                        <table id="tblTransacoes" className="tabela">
                            <thead>
                                <tr className="textPeriodoMobile">
                                    <th className="table-secondary tableTitulo tableData colunas">
                                        <h6>Status</h6>
                                    </th>
                                    <th className="table-secondary tableTitulo tableData colunas">
                                        <h6 style={{ color: "#f7931b" }}>{dataExecucao ? "DATA EXECUÇÃO" : "DATA SOLICITAÇÃO"}</h6>
                                    </th>
                                    <th className="table-secondary tableTitulo tableTipo colunas">
                                        <h6>Tipo</h6>
                                    </th>
                                    {servicoSelecionado !== 3 && <th className="table-secondary tableTitulo tableCotas colunas">
                                        <h6>Cotas</h6>
                                    </th>}
                                    <th className="table-secondary tableTitulo tableOperacao colunas">
                                        <h6>Valor da Operação</h6>
                                    </th>
                                    <th id="colunaComprovante" className="table-secondary tableTitulo tableOperacao colunas">
                                        <h6>Ver Comprovante</h6>
                                    </th>
                                </tr>
                            </thead>
                            {erroPage ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={6}>
                                            <span>Ocorreu um erro. Por favor tente novamente.  </span>
                                            <AiOutlineReload
                                                onClick={() => setReloadPage(!reloadPage)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>

                            ) : loading ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={6}>
                                            <span className="formatacaoLoadingExtrato">
                                                <LoadingButton />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : dados.length != 0 ? (
                                dados.map((value: RequisicaoExtrato) => (
                                    <tbody>
                                        <TabelaExtrato
                                            dataExecucao={dataExecucao}
                                            servicoFinanceiro={servicoSelecionado}
                                            key={value.dataSolicitacao.toString() + value.valor}
                                            fonteBold={false}
                                            dados={value}
                                        />
                                    </tbody>

                                ))
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan={6}>
                                            <span className="formatacaoLoadingExtrato">
                                                Nenhum dado encontrado no momento para o período.
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </fieldset>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn__laranja"
                    onClick={() => criaPDF()}
                    style={{ display: "inline-flex" }}
                    titleButton={
                        <>
                            <AiFillPrinter className="iconButton" />
                            Imprimir
                        </>
                    }
                />
                <Button
                    classNameButton="btnCancelar"
                    onClick={() => setModal(false)}
                    titleButton="Fechar"
                />
            </Modal.Footer>
        </Modal>
    );
};
