import React from "react";
import { obterServicoFinanceiroPorStatus } from "../../../services/usuario";

export interface servicoFinanceiroProps {
    id: number,
    nome: string,
    descricao: string,
    ativo: boolean,
}

export interface obterServicosFinanceirosProps {
    setListaServicosFinanceiros: React.Dispatch<servicoFinanceiroProps[]>,
    setErro: React.Dispatch<boolean>
}

export const obterServicosFinanceiros = ({ setListaServicosFinanceiros, setErro }: obterServicosFinanceirosProps) => {
    setErro(false)
    obterServicoFinanceiroPorStatus()
        .then(res => {
            setListaServicosFinanceiros(res.data.resultado.usuario)
        })
        .catch(err => {
            setErro(true)
        })
}