
import React from "react"
import { atualizarDadosPessoaisPF } from "../../../services/cliente"
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies"
import { renovacaoToken } from "../../../services/usuario"
import imageCompression from "browser-image-compression";


import { Erro } from "../../../pages/Cliente/AlteracaoSenha/AlteracaoSenha";

export const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setImagem: React.Dispatch<any>,
    setError: React.Dispatch<React.SetStateAction<Erro>>,

) => {
    let imagemOriginal = e.target.files[0];

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1300,
        useWebWorker: true
    }
    try {
        const imagemComprimida = await imageCompression(imagemOriginal, options);

        var reader = new FileReader();
        reader.readAsDataURL(imagemComprimida);
        reader.onload = function () {
            setImagem(reader.result);
        };
    } catch (error) {
        setError({
            erro: true,
            mensagem: 'Ocorreu um erro na sua solicitação. Por favor tente novamente.'
        })
    }
}

export const salvarFotoPerfil = async (dadosUsuario: any, fotoDoUsuario: string, setLoading: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setSucesso: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, reload: () => void) => {
    setLoading(true)
    const { email, id, token, tokenRenovacao } = obterDadosUsuario2()

    const fotoUsuario = fotoDoUsuario || fotoDoUsuario !== '' ? fotoDoUsuario : dadosUsuario.fotoUsuario

    await atualizarDadosPessoaisPF(id, token, fotoUsuario, dadosUsuario?.rua, dadosUsuario?.complemento, dadosUsuario?.bairro, dadosUsuario?.cidade, dadosUsuario?.estado, dadosUsuario?.cep, dadosUsuario?.profissao, dadosUsuario?.renda, dadosUsuario?.estadoCivil).then(() => {
        setSucesso(true)
        reload()
        const { email, id, token, tokenRenovacao } = obterDadosUsuario2()
        guardarDadosUsuario2(token, tokenRenovacao, email, id, fotoUsuario)
    }).catch(err => {
        if (err.toString() === "Error: Request failed with status code 401") {
            renovacaoToken(token, tokenRenovacao).then(res => {
                guardarDadosUsuario2(res.data.camposAdicionais.tokenAcesso, res.data.camposAdicionais.tokenRenovacao, email, id)
                atualizarDadosPessoaisPF(id, token, fotoUsuario, dadosUsuario?.rua, dadosUsuario?.complemento, dadosUsuario?.bairro, dadosUsuario?.cidade, dadosUsuario?.estado, dadosUsuario?.cep, dadosUsuario?.profissao, dadosUsuario?.renda, dadosUsuario?.estadoCivil).then(() => {
                    reload()
                    setSucesso(true)
                    guardarDadosUsuario2(token, tokenRenovacao, email, id, fotoUsuario)
                })
            }).catch(() => setDesconectar(true))
        } else {
            setErro(true)
        }
    }).finally(() => setLoading(false))
}