import React from "react";
import "./HomeGestor.css";

import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import { CardHomeGestor } from "../../../components/Cards/CardHomeGestor";
import { CardRelatorio } from "../../../components/Cards/CardRelatorios";
import { ItensHomeGestorLinha1, ItensHomeGestorLinha2, ItensHomeGestorLinha3, itens } from './ItensHomeGestor'

import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Button } from "../../../components/Button";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { voltarSlide, proximoSlide } from "../../Cliente/Extrato/functions";

const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    variableWidth: true,
    respondTo: 'slider',
    rows: 3,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlide" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlide" style={{ display: 'none !important' }}></div>
};

const HomeGestor = () => {
    return (
        <>
            <Navbar />
            <Header titulo="Home" />

            <div id="desktopHomeGestor">
                <div className="caixa_home_gestor">
                    {itens.map((item, index) => {
                        return (
                            <div key={index} >
                                <CardHomeGestor
                                    key={item.titulo}
                                    titulo={item.titulo}
                                    subTitulo={item.subtitulo}
                                    link={item.link}
                                />
                            </div>
                        );
                    })}
                </div>

                {/* <div className="caixaRelatorios widthHome linha1">
                    {ItensHomeGestorLinha1.map((item, index) => {
                        return (
                            <div key={index} className={item.classnameDiv}>
                                <CardRelatorio
                                    key={item.titulo}
                                    titulo={item.titulo}
                                    subTitulo={item.subtitulo}
                                    link={item.link}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="caixaRelatorios widthHome linha2">
                    {ItensHomeGestorLinha2.map((item, index) => {
                        return (
                            <div key={index} className={item.classnameDiv}>
                                <CardRelatorio
                                    key={item.titulo}
                                    titulo={item.titulo}
                                    subTitulo={item.subtitulo}
                                    link={item.link}
                                />
                            </div>
                        );
                    })}
                </div>

                <div className="caixaRelatorios widthHome linha3">
                    {ItensHomeGestorLinha3.map((item, index) => {
                        return (
                            <div key={index} className={item.classnameDiv}>
                                <CardRelatorio
                                    key={item.titulo}
                                    titulo={item.titulo}
                                    subTitulo={item.subtitulo}
                                    link={item.link}
                                />
                            </div>
                        );
                    })}
                </div> */}

                <Footer />

            </div>

            <div className="caixa1 caixaMobileHome" id="mobileHomeGestor">
                <div className="divBotoesExtratoMobile">
                    <Button
                        className="btn__cinza btnMargin"
                        onClick={() => voltarSlide()}
                        titleButton={
                            <>
                                <AiOutlineArrowLeft className="iconButtonExtrato" />
                            </>
                        }
                    />
                    <Button
                        className="btn__cinza btnMargin"
                        onClick={() => proximoSlide()}
                        titleButton={
                            <>
                                <AiOutlineArrowRight className="iconButtonExtrato" />
                            </>
                        }
                    />
                </div>

                <div className="boxSlideExtrato">
                    <Slider {...settings}>
                        {ItensHomeGestorLinha1.map((item, index) => {
                            return (
                                <div key={index} className={item.classnameDiv + ' boxMarginHomeGestor'}>
                                    <CardRelatorio
                                        key={item.titulo}
                                        titulo={item.titulo}
                                        subTitulo={item.subtitulo}
                                        link={item.link}
                                    />
                                </div>
                            );
                        })}

                        {ItensHomeGestorLinha2.map((item, index) => {
                            return (
                                <div key={index} className={item.classnameDiv + ' boxMarginHomeGestor'}>
                                    <CardRelatorio
                                        key={item.titulo}
                                        titulo={item.titulo}
                                        subTitulo={item.subtitulo}
                                        link={item.link}
                                    />
                                </div>
                            );
                        })}

                        {ItensHomeGestorLinha3.map((item, index) => {
                            return (
                                <div key={index} className={item.classnameDiv + ' boxMarginHomeGestor'}>
                                    <CardRelatorio
                                        key={item.titulo}
                                        titulo={item.titulo}
                                        subTitulo={item.subtitulo}
                                        link={item.link}
                                    />
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default HomeGestor;
