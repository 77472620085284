import React from "react";
import { obterServicoFinanceiroPorStatus } from "../../../services/usuario";

export const obterServicos = (setLoading: React.Dispatch<boolean>, filtro: boolean, setListaDeServicos: React.Dispatch<any[]>, setErro: React.Dispatch<boolean>) => {
    setLoading(true);
    setErro(false)
    setListaDeServicos([])
    obterServicoFinanceiroPorStatus(filtro)
        .then((res) => {
            setListaDeServicos(res.data.resultado.usuario);
        })
        .catch((error) => {
            if (error.response.data.mensagem === "Nenhum serviço financeiro encontrado.") { }
            else {
                setErro(true);
            }
        })
        .finally(() => setLoading(false));
};