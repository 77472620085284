

export function TestarSegurancaSenha(
    senha: string,
    setValidacao: (value: React.SetStateAction<boolean>) => void,
    setTamanhoSenha: (value: React.SetStateAction<boolean>) => void,
    setCaracterEspecial: (value: React.SetStateAction<boolean>) => void,
    setLetraMaiuscula: (value: React.SetStateAction<boolean>) => void,
    setLetraMinuscula: (value: React.SetStateAction<boolean>) => void,
    setNumeros: (value: React.SetStateAction<boolean>) => void,

) {
    const existeNumero = "\\d"; // ta certo
    const exiteLetraMinuscula = "[a-z]"; //ta certo
    const existeLetraMaiuscula = "[A-Z]"; //ta certo
    const existeCaractereEspecial = "[@!#$%^&*()/\\\\]"; //ta certo
    const tamanhoSenha = 7;
    if (
        senha.length >= tamanhoSenha &&
        senha.match(existeCaractereEspecial) &&
        senha.match(existeLetraMaiuscula) &&
        senha.match(exiteLetraMinuscula) &&
        senha.match(existeNumero)
    )
        setValidacao(false);
    else {
        if (senha.length >= tamanhoSenha) setTamanhoSenha(true);
        else setTamanhoSenha(false);
        if (senha.match(existeCaractereEspecial)) setCaracterEspecial(true);
        else setCaracterEspecial(false);
        if (senha.match(existeLetraMaiuscula)) setLetraMaiuscula(true);
        else setLetraMaiuscula(false);
        if (senha.match(exiteLetraMinuscula)) setLetraMinuscula(true);
        else setLetraMinuscula(false);
        if (senha.match(existeNumero)) setNumeros(true);
        else setNumeros(false);

        setValidacao(true);
    }
}