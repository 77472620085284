import React from 'react'
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs'

interface ArrowProps {
    down: boolean;
    size?: number;
    className?: string;
    color?: string
}

export const Arrow = ({ down, size, className, color }: ArrowProps) => {
    return <span>
        {down ? (
            <BsCaretDownFill className={className ? className : ''} style={{ fontSize: size ? size : 10, color: color ? color : 'white' }} />
        ) : (
            <BsCaretUpFill className={className ? className : ''} style={{ fontSize: size ? size : 10, color: color ? color : 'white' }} />
        )}
    </span>
}