import {
    guardarDadosUsuario,
    guardarDadosUsuario2,
    obterDadosUsuario2,
} from "../../../services/cookies";
import { operacaoFinanceiraAprovacao } from "../../../services/operacaoFinanceira";
import { renovacaoToken } from "../../../services/usuario";

export async function handleStatus(
    idCredenciaisCliente: string,
    dataSolicitacao: Date,
    status: string,
    valor: number,
    tipo: string,
    IdServicoFinanceiro: number,
    setLoadingAprovar: React.Dispatch<React.SetStateAction<boolean>>,
    setErroAprovar: React.Dispatch<React.SetStateAction<boolean>>,
    setReload: React.Dispatch<React.SetStateAction<boolean>>,
    setModal: React.Dispatch<React.SetStateAction<boolean>>
) {
    let token = obterDadosUsuario2().token;
    let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
    let email = obterDadosUsuario2().email;
    let id = obterDadosUsuario2().id;

    setLoadingAprovar(true);
    await operacaoFinanceiraAprovacao(
        idCredenciaisCliente,
        IdServicoFinanceiro,
        dataSolicitacao,
        status,
        valor,
        tipo,
        token,
        0
    )
        .then(() => {
            setLoadingAprovar(false);
            setReload((value) => !value);
        })
        .catch(async (e) => {
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await operacaoFinanceiraAprovacao(
                            idCredenciaisCliente,
                            IdServicoFinanceiro,
                            dataSolicitacao,
                            status,
                            valor,
                            tipo,
                            res2.data.camposAdicionais.tokenAcesso,
                            0
                        )
                            .then((res2) => {
                                setLoadingAprovar(false);
                                setReload((value) => !value);
                            })
                            .catch((e2) => {
                                setErroAprovar(true);
                                setLoadingAprovar(false);
                            });
                    })
                    .catch((err3) => {
                        setModal(true);
                    });
            } else {
                setErroAprovar(true);
                setLoadingAprovar(false);
            }
        });
}

export async function handleStatusAprovarAportePrivate(
    setLoadingAprovar: (value: React.SetStateAction<boolean>) => void,
    idCredenciaisCliente: string,
    IdServicoFinanceiro: number,
    dataSolicitacao: Date,
    status: "EmEspera" | "Efetuado" | "Rejeitado",
    valor: number,
    tipo: "Aporte" | "Saque",
    quantidadeCotas: number,
    comprovante: string | ArrayBuffer,
    setReload: (value: React.SetStateAction<boolean>) => void,
    setErroAprovar: (value: React.SetStateAction<boolean>) => void,

) {
    let token = obterDadosUsuario2().token;
    let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
    let email = obterDadosUsuario2().email;
    let id = obterDadosUsuario2().id;

    setLoadingAprovar(true);

    await operacaoFinanceiraAprovacao(
        idCredenciaisCliente,
        IdServicoFinanceiro,
        dataSolicitacao,
        status,
        valor,
        tipo,
        token,
        quantidadeCotas,
        comprovante
    )
        .then(() => {
            setLoadingAprovar(false);
            setReload((value) => !value);
        })
        .catch(async (e) => {
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await operacaoFinanceiraAprovacao(
                            idCredenciaisCliente,
                            IdServicoFinanceiro,
                            dataSolicitacao,
                            status,
                            valor,
                            tipo,
                            res2.data.camposAdicionais.tokenAcesso,
                            quantidadeCotas,
                            comprovante
                        )
                            .then((res2) => {
                                setLoadingAprovar(false);
                                setReload((value) => !value);
                            })
                            .catch((e2) => {
                                setErroAprovar(true);
                                setLoadingAprovar(false);
                            });
                    })
                    .catch((err3) => {
                    });
            } else {
                setErroAprovar(true);
                setLoadingAprovar(false);
            }
        });
};