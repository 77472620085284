import React from "react";
import "./ErroTabela.css";

import { BsFillPatchExclamationFill } from "react-icons/bs";

import { Button } from "../../../components/Button";
import { AiOutlineReload } from "react-icons/ai";

interface ErroTabelaProps {
    mensagem?: string,
    titleButton?: any,
    handleClick: () => void,
    className: "formatacaoTabela" | "formatacaoGrafico";
}

export const ErroTabela = ({
    mensagem,
    titleButton,
    handleClick,
    className
}: ErroTabelaProps) => {
    return (
        <tr className={`PosicaoConsolidadaValores ${className}`}>
            <td colSpan={4} className="table-secondary tableConteudo alignConteudo a">
                <BsFillPatchExclamationFill className="IconFalha_erro_fechamento" />
                <span className="spanDadosTabela" id="spnValorCota">{mensagem ? mensagem : "Algo deu errado, Aguarde alguns instantes e tente novamente mais tarde."}</span>
                <Button
                    classNameDiv="button_erro_fechamentos"
                    titleButton={titleButton ? titleButton : <>
                        <AiOutlineReload className="iconButtonDashboard" />
                    </>}
                    onClick={handleClick}
                />
            </td>
        </tr>
    );
};
