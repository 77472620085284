import React from 'react'
import './styles.css';


interface SelectNacionalidadeProps {
    setNacionalidade?: React.Dispatch<React.SetStateAction<string>>,
    Nacionalidade?: string,
    onChange?: React.ChangeEventHandler<HTMLSelectElement>, 
}
export const SelectNacionalidade = ({ setNacionalidade, Nacionalidade, onChange }: SelectNacionalidadeProps) => {
    return (
        <select className="selectNacionalidade" placeholder="Pais" onChange={(e) => onChange ? onChange(e) : setNacionalidade(e.target.value)}  >
            {Nacionalidade ?
                <option className="Pais" value={`${Nacionalidade}`} defaultChecked>{Nacionalidade}</option>
                :
                <option className="Pais" value="" defaultChecked>País de Nascimento</option>
            }
            <option className="Pais" value="Brasil">Brasil</option>
            <option className="Pais" value="Afeganistão">Afeganistão</option> 
            <option className="Pais" value="África do Sul">África do Sul</option>
            <option className="Pais" value="Albânia">Albânia</option>
            <option className="Pais" value="Alemanha">Alemanha</option>
            <option className="Pais" value="Andorra">Andorra</option>
            <option className="Pais" value="Angola">Angola</option>
            <option className="Pais" value="Anguilla">Anguilla</option>
            <option className="Pais" value="Antilhas Holandesas">Antilhas Holandesas</option>
            <option className="Pais" value="Antárctida">Antárctida</option>
            <option className="Pais" value="Antígua e Barbuda">Antígua e Barbuda</option>
            <option className="Pais" value="Argentina">Argentina</option>
            <option className="Pais" value="Argélia">Argélia</option>
            <option className="Pais" value="Armênia">Armênia</option>
            <option className="Pais" value="Aruba">Aruba</option>
            <option className="Pais" value="Arábia Saudita">Arábia Saudita</option>
            <option className="Pais" value="Austrália">Austrália</option>
            <option className="Pais" value="Áustria">Áustria</option>
            <option className="Pais" value="Azerbaijão">Azerbaijão</option>
            <option className="Pais" value="Bahamas">Bahamas</option>
            <option className="Pais" value="Bahrein">Bahrein</option>
            <option className="Pais" value="Bangladesh">Bangladesh</option>
            <option className="Pais" value="Barbados">Barbados</option>
            <option className="Pais" value="Belize">Belize</option>
            <option className="Pais" value="Benim">Benim</option>
            <option className="Pais" value="Bermudas">Bermudas</option>
            <option className="Pais" value="Bielorrússia">Bielorrússia</option>
            <option className="Pais" value="Bolívia">Bolívia</option>
            <option className="Pais" value="Botswana">Botswana</option>
            <option className="Pais" value="Brunei">Brunei</option>
            <option className="Pais" value="Bulgária">Bulgária</option>
            <option className="Pais" value="Burkina Faso">Burkina Faso</option>
            <option className="Pais" value="Burundi">Burundi</option>
            <option className="Pais" value="Butão">Butão</option>
            <option className="Pais" value="Bélgica">Bélgica</option>
            <option className="Pais" value="Bósnia e Herzegovina">Bósnia e Herzegovina</option>
            <option className="Pais" value="Cabo Verde">Cabo Verde</option>
            <option className="Pais" value="Camarões">Camarões</option>
            <option className="Pais" value="Camboja">Camboja</option>
            <option className="Pais" value="Canadá">Canadá</option>
            <option className="Pais" value="Catar">Catar</option>
            <option className="Pais" value="Cazaquistão">Cazaquistão</option>
            <option className="Pais" value="Chade">Chade</option>
            <option className="Pais" value="Chile">Chile</option>
            <option className="Pais" value="China">China</option>
            <option className="Pais" value="Chipre">Chipre</option>
            <option className="Pais" value="Colômbia">Colômbia</option>
            <option className="Pais" value="Comores">Comores</option>
            <option className="Pais" value="Coreia do Norte">Coreia do Norte</option>
            <option className="Pais" value="Coreia do Sul">Coreia do Sul</option>
            <option className="Pais" value="Costa do Marfim">Costa do Marfim</option>
            <option className="Pais" value="Costa Rica">Costa Rica</option>
            <option className="Pais" value="Croácia">Croácia</option>
            <option className="Pais" value="Cuba">Cuba</option>
            <option className="Pais" value="Dinamarca">Dinamarca</option>
            <option className="Pais" value="Djibouti">Djibouti</option>
            <option className="Pais" value="Dominica">Dominica</option>
            <option className="Pais" value="Egito">Egito</option>
            <option className="Pais" value="El Salvador">El Salvador</option>
            <option className="Pais" value="Emirados Árabes Unidos">Emirados Árabes Unidos</option>
            <option className="Pais" value="Equador">Equador</option>
            <option className="Pais" value="Eritreia">Eritreia</option>
            <option className="Pais" value="Escócia">Escócia</option>
            <option className="Pais" value="Eslováquia">Eslováquia</option>
            <option className="Pais" value="Eslovênia">Eslovênia</option>
            <option className="Pais" value="Espanha">Espanha</option>
            <option className="Pais" value="Estados Federados da Micronésia">Estados Federados da Micronésia</option>
            <option className="Pais" value="Estados Unidos">Estados Unidos</option>
            <option className="Pais" value="Estônia">Estônia</option>
            <option className="Pais" value="Etiópia">Etiópia</option>
            <option className="Pais" value="Fiji">Fiji</option>
            <option className="Pais" value="Filipinas">Filipinas</option>
            <option className="Pais" value="Finlândia">Finlândia</option>
            <option className="Pais" value="França">França</option>
            <option className="Pais" value="Gabão">Gabão</option>
            <option className="Pais" value="Gana">Gana</option>
            <option className="Pais" value="Geórgia">Geórgia</option>
            <option className="Pais" value="Gibraltar">Gibraltar</option>
            <option className="Pais" value="Granada">Granada</option>
            <option className="Pais" value="Gronelândia">Gronelândia</option>
            <option className="Pais" value="Grécia">Grécia</option>
            <option className="Pais" value="Guadalupe">Guadalupe</option>
            <option className="Pais" value="Guam">Guam</option>
            <option className="Pais" value="Guatemala">Guatemala</option>
            <option className="Pais" value="Guernesei">Guernesei</option>
            <option className="Pais" value="Guiana">Guiana</option>
            <option className="Pais" value="Guiana Francesa">Guiana Francesa</option>
            <option className="Pais" value="Guiné">Guiné</option>
            <option className="Pais" value="Guiné Equatorial">Guiné Equatorial</option>
            <option className="Pais" value="Guiné-Bissau">Guiné-Bissau</option>
            <option className="Pais" value="Gâmbia">Gâmbia</option>
            <option className="Pais" value="Haiti">Haiti</option>
            <option className="Pais" value="Honduras">Honduras</option>
            <option className="Pais" value="Hong Kong">Hong Kong</option>
            <option className="Pais" value="Hungria">Hungria</option>
            <option className="Pais" value="Ilha Bouvet">Ilha Bouvet</option>
            <option className="Pais" value="Ilha de Man">Ilha de Man</option>
            <option className="Pais" value="Ilha do Natal">Ilha do Natal</option>
            <option className="Pais" value="Ilha Heard e Ilhas McDonald">Ilha Heard e Ilhas McDonald</option>
            <option className="Pais" value="Ilha Norfolk">Ilha Norfolk</option>
            <option className="Pais" value="Ilhas Cayman">Ilhas Cayman</option>
            <option className="Pais" value="Ilhas Cocos (Keeling)">Ilhas Cocos (Keeling)</option>
            <option className="Pais" value="Ilhas Cook">Ilhas Cook</option>
            <option className="Pais" value="Ilhas Feroé">Ilhas Feroé</option>
            <option className="Pais" value="Ilhas Geórgia do Sul e Sandwich do Sul">Ilhas Geórgia do Sul e Sandwich do Sul</option>
            <option className="Pais" value="Ilhas Malvinas">Ilhas Malvinas</option>
            <option className="Pais" value="Ilhas Marshall">Ilhas Marshall</option>
            <option className="Pais" value="Ilhas Menores Distantes dos Estados Unidos">Ilhas Menores Distantes dos Estados Unidos</option>
            <option className="Pais" value="Ilhas Salomão">Ilhas Salomão</option>
            <option className="Pais" value="Ilhas Virgens Americanas">Ilhas Virgens Americanas</option>
            <option className="Pais" value="Ilhas Virgens Britânicas">Ilhas Virgens Britânicas</option>
            <option className="Pais" value="Ilhas Åland">Ilhas Åland</option>
            <option className="Pais" value="Indonésia">Indonésia</option>
            <option className="Pais" value="Inglaterra">Inglaterra</option>
            <option className="Pais" value="Índia">Índia</option>
            <option className="Pais" value="Iraque">Iraque</option>
            <option className="Pais" value="Irlanda do Norte">Irlanda do Norte</option>
            <option className="Pais" value="Irlanda">Irlanda</option>
            <option className="Pais" value="Irã">Irã</option>
            <option className="Pais" value="Islândia">Islândia</option>
            <option className="Pais" value="Israel">Israel</option>
            <option className="Pais" value="Itália">Itália</option>
            <option className="Pais" value="Iêmen">Iêmen</option>
            <option className="Pais" value="Jamaica">Jamaica</option>
            <option className="Pais" value="Japão">Japão</option>
            <option className="Pais" value="Jersey">Jersey</option>
            <option className="Pais" value="Jordânia">Jordânia</option>
            <option className="Pais" value="Kiribati">Kiribati</option>
            <option className="Pais" value="Kuwait">Kuwait</option>
            <option className="Pais" value="Laos">Laos</option>
            <option className="Pais" value="Lesoto">Lesoto</option>
            <option className="Pais" value="Letônia">Letônia</option>
            <option className="Pais" value="Libéria">Libéria</option>
            <option className="Pais" value="Liechtenstein">Liechtenstein</option>
            <option className="Pais" value="Lituânia">Lituânia</option>
            <option className="Pais" value="Luxemburgo">Luxemburgo</option>
            <option className="Pais" value="Líbano">Líbano</option>
            <option className="Pais" value="Líbia">Líbia</option>
            <option className="Pais" value="Macau">Macau</option>
            <option className="Pais" value="Macedônia">Macedônia</option>
            <option className="Pais" value="Madagáscar">Madagáscar</option>
            <option className="Pais" value="Malawi">Malawi</option>
            <option className="Pais" value="Maldivas">Maldivas</option>
            <option className="Pais" value="Mali">Mali</option>
            <option className="Pais" value="Malta">Malta</option>
            <option className="Pais" value="Malásia">Malásia</option>
            <option className="Pais" value="Marianas Setentrionais">Marianas Setentrionais</option>
            <option className="Pais" value="Marrocos">Marrocos</option>
            <option className="Pais" value="Martinica">Martinica</option>
            <option className="Pais" value="Mauritânia">Mauritânia</option>
            <option className="Pais" value="Maurícia">Maurícia</option>
            <option className="Pais" value="Mayotte">Mayotte</option>
            <option className="Pais" value="Moldávia">Moldávia</option>
            <option className="Pais" value="Mongólia">Mongólia</option>
            <option className="Pais" value="Montenegro">Montenegro</option>
            <option className="Pais" value="Montserrat">Montserrat</option>
            <option className="Pais" value="Moçambique">Moçambique</option>
            <option className="Pais" value="Myanmar">Myanmar</option>
            <option className="Pais" value="México">México</option>
            <option className="Pais" value="Mônaco">Mônaco</option>
            <option className="Pais" value="Namíbia">Namíbia</option>
            <option className="Pais" value="Nauru">Nauru</option>
            <option className="Pais" value="Nepal">Nepal</option>
            <option className="Pais" value="Nicarágua">Nicarágua</option>
            <option className="Pais" value="Nigéria">Nigéria</option>
            <option className="Pais" value="Niue">Niue</option>
            <option className="Pais" value="Noruega">Noruega</option>
            <option className="Pais" value="Nova Caledônia">Nova Caledônia</option>
            <option className="Pais" value="Nova Zelândia">Nova Zelândia</option>
            <option className="Pais" value="Níger">Níger</option>
            <option className="Pais" value="Omã">Omã</option>
            <option className="Pais" value="Palau">Palau</option>
            <option className="Pais" value="Palestina">Palestina</option>
            <option className="Pais" value="Panamá">Panamá</option>
            <option className="Pais" value="Papua-Nova Guiné">Papua-Nova Guiné</option>
            <option className="Pais" value="Paquistão">Paquistão</option>
            <option className="Pais" value="Paraguai">Paraguai</option>
            <option className="Pais" value="País de Gales">País de Gales</option>
            <option className="Pais" value="Países Baixos">Países Baixos</option>
            <option className="Pais" value="Peru">Peru</option>
            <option className="Pais" value="Pitcairn">Pitcairn</option>
            <option className="Pais" value="Polinésia Francesa">Polinésia Francesa</option>
            <option className="Pais" value="Polônia">Polônia</option>
            <option className="Pais" value="Porto Rico">Porto Rico</option>
            <option className="Pais" value="Portugal">Portugal</option>
            <option className="Pais" value="Quirguistão">Quirguistão</option>
            <option className="Pais" value="Quênia">Quênia</option>
            <option className="Pais" value="Reino Unido">Reino Unido</option>
            <option className="Pais" value="República Centro-Africana">República Centro-Africana</option>
            <option className="Pais" value="República Checa">República Checa</option>
            <option className="Pais" value="República Democrática do Congo">República Democrática do Congo</option>
            <option className="Pais" value="República do Congo">República do Congo</option>
            <option className="Pais" value="República Dominicana">República Dominicana</option>
            <option className="Pais" value="Reunião">Reunião</option>
            <option className="Pais" value="Romênia">Romênia</option>
            <option className="Pais" value="Ruanda">Ruanda</option>
            <option className="Pais" value="Rússia">Rússia</option>
            <option className="Pais" value="Saara Ocidental">Saara Ocidental</option>
            <option className="Pais" value="Saint Martin">Saint Martin</option>
            <option className="Pais" value="Saint-Barthélemy">Saint-Barthélemy</option>
            <option className="Pais" value="Saint-Pierre e Miquelon">Saint-Pierre e Miquelon</option>
            <option className="Pais" value="Samoa Americana">Samoa Americana</option>
            <option className="Pais" value="Samoa">Samoa</option>
            <option className="Pais" value="Santa Helena, Ascensão e Tristão da Cunha">Santa Helena, Ascensão e Tristão da Cunha</option>
            <option className="Pais" value="Santa Lúcia">Santa Lúcia</option>
            <option className="Pais" value="Senegal">Senegal</option>
            <option className="Pais" value="Serra Leoa">Serra Leoa</option>
            <option className="Pais" value="Seychelles">Seychelles</option>
            <option className="Pais" value="Singapura">Singapura</option>
            <option className="Pais" value="Somália">Somália</option>
            <option className="Pais" value="Sri Lanka">Sri Lanka</option>
            <option className="Pais" value="Suazilândia">Suazilândia</option>
            <option className="Pais" value="Sudão">Sudão</option>
            <option className="Pais" value="Suriname">Suriname</option>
            <option className="Pais" value="Suécia">Suécia</option>
            <option className="Pais" value="Suíça">Suíça</option>
            <option className="Pais" value="Svalbard e Jan Mayen">Svalbard e Jan Mayen</option>
            <option className="Pais" value="São Cristóvão e Nevis">São Cristóvão e Nevis</option>
            <option className="Pais" value="São Marino">São Marino</option>
            <option className="Pais" value="São Tomé e Príncipe">São Tomé e Príncipe</option>
            <option className="Pais" value="São Vicente e Granadinas">São Vicente e Granadinas</option>
            <option className="Pais" value="Sérvia">Sérvia</option>
            <option className="Pais" value="Síria">Síria</option>
            <option className="Pais" value="Tadjiquistão">Tadjiquistão</option>
            <option className="Pais" value="Tailândia">Tailândia</option>
            <option className="Pais" value="Taiwan">Taiwan</option>
            <option className="Pais" value="Tanzânia">Tanzânia</option>
            <option className="Pais" value="Terras Austrais e Antárticas Francesas">Terras Austrais e Antárticas Francesas</option>
            <option className="Pais" value="Território Britânico do Oceano Índico">Território Britânico do Oceano Índico</option>
            <option className="Pais" value="Timor-Leste">Timor-Leste</option>
            <option className="Pais" value="Togo">Togo</option>
            <option className="Pais" value="Tonga">Tonga</option>
            <option className="Pais" value="Toquelau">Toquelau</option>
            <option className="Pais" value="Trinidad e Tobago">Trinidad e Tobago</option>
            <option className="Pais" value="Tunísia">Tunísia</option>
            <option className="Pais" value="Turcas e Caicos">Turcas e Caicos</option>
            <option className="Pais" value="Turquemenistão">Turquemenistão</option>
            <option className="Pais" value="Turquia">Turquia</option>
            <option className="Pais" value="Tuvalu">Tuvalu</option>
            <option className="Pais" value="Ucrânia">Ucrânia</option>
            <option className="Pais" value="Uganda">Uganda</option>
            <option className="Pais" value="Uruguai">Uruguai</option>
            <option className="Pais" value="Uzbequistão">Uzbequistão</option>
            <option className="Pais" value="Vanuatu">Vanuatu</option>
            <option className="Pais" value="Vaticano">Vaticano</option>
            <option className="Pais" value="Venezuela">Venezuela</option>
            <option className="Pais" value="Vietname">Vietname</option>
            <option className="Pais" value="Wallis e Futuna">Wallis e Futuna</option>
            <option className="Pais" value="Zimbabwe">Zimbabwe</option>
            <option className="Pais" value="Zâmbia">Zâmbia</option>
        </select>
    )
}