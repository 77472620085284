import React from 'react'

import { ErroTabela } from '../../Erros/ErroTabela'
import { Button } from '../../Button';

import Modal from "react-bootstrap/Modal";

interface ErroProps {
    handleReload: () => void;
    handleClose: () => void;
}

export const Erro = ({ handleReload, handleClose }: ErroProps) => {
    return (
        <>
            <Modal.Body>
                <ErroTabela className='formatacaoGrafico' handleClick={handleReload} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} titleButton="Fechar" />
            </Modal.Footer>
        </>
    )
}