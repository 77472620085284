import ImagemOmegaImpressao from "../../../assets/img/logo.png";
import { obterExtratoDoCliente, getClienteServicosFinanceiros } from "../../../services/cliente";
import { obterDadosUsuario2, guardarDadosUsuario, guardarDadosUsuario2 } from "../../../services/cookies";
import { renovacaoToken } from "../../../services/usuario";
import { RequisicaoExtrato } from "../../../pages/Cliente/Extrato/Extrato";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";

export const pegarServicosFinanceirosDisponiveis = async (
    dataInicial: Date,
    dataFinal: Date,
    idCliente: string,
    setDados: React.Dispatch<React.SetStateAction<RequisicaoExtrato[]>>,
    setErroPage: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setServicosFinanceiros: React.Dispatch<React.SetStateAction<usuarioServicoFinanceiroTipo[]>>,
    setServicoInicial: React.Dispatch<React.SetStateAction<number>>
) => {
    setLoading(true)
    await getClienteServicosFinanceiros(
        obterDadosUsuario2().token,
        idCliente
    )
        .then((res) => {
            setServicosFinanceiros(res.data.resultado.clientes);
            let primeiroServicoIndex = res.data.resultado.clientes[0].idServicoFinanceiro;
            setServicoInicial(primeiroServicoIndex);
            exibirExtratoEmModal(dataInicial, dataFinal, idCliente, setDados, setErroPage, setLoading, setModalDesconectar, primeiroServicoIndex, primeiroServicoIndex, obterDadosUsuario2().token);
            setLoading(false);
        })
        .catch(async (e) => {
            let token = obterDadosUsuario2().token;
            let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
            let email = obterDadosUsuario2().email;
            let id = obterDadosUsuario2().id;
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await getClienteServicosFinanceiros(
                            obterDadosUsuario2().token,
                            idCliente
                        )
                            .then((res2) => {
                                setServicosFinanceiros(res2.data.resultado.clientes);
                                let primeiroServicoIndex = res2.data.resultado.clientes[0].idServicoFinanceiro;
                                console.log(primeiroServicoIndex);

                                setServicoInicial(primeiroServicoIndex);
                                exibirExtratoEmModal(dataInicial, dataFinal, idCliente, setDados, setErroPage, setLoading, setModalDesconectar, primeiroServicoIndex, primeiroServicoIndex, res2.data.camposAdicionais.tokenAcesso);
                                setLoading(false);
                            })
                            .catch((e2) => {
                                setErroPage(true);
                                setLoading(false);
                            });
                    })
                    .catch((err3) => {
                        setModalDesconectar(true);
                    });
            } else {
                setErroPage(true);
                setLoading(false);
            }
        })
};

export const exibirExtratoEmModal = async (
    dataInicial: Date,
    dataFinal: Date,
    idCliente: string,
    setDados: React.Dispatch<React.SetStateAction<RequisicaoExtrato[]>>,
    setErroPage: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    servicoSelecionado: number,
    servicoInicial?: number,
    token?: string,
) => {
    setLoading(true);
    await obterExtratoDoCliente(
        idCliente,
        servicoSelecionado ? servicoSelecionado : servicoInicial,
        token ? token : obterDadosUsuario2().token,
        dataInicial,
        dataFinal
    )
        .then((res) => {
            setDados(res.data.resultado.extrato);
            setErroPage(false);
            setLoading(false);
        })
        .catch(async (e) => {
            let token = obterDadosUsuario2().token;
            let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
            let email = obterDadosUsuario2().email;
            let id = obterDadosUsuario2().id;
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await obterExtratoDoCliente(
                            idCliente,
                            servicoSelecionado ? servicoSelecionado : servicoInicial,
                            res2.data.camposAdicionais.tokenAcesso,
                            dataInicial,
                            dataFinal
                        )
                            .then((res2) => {
                                setDados(res2.data.resultado.extrato);
                                setErroPage(false);
                                setLoading(false);
                            })
                            .catch((e2) => {
                                setErroPage(true);
                                setLoading(false);
                            });
                    })
                    .catch((err3) => {
                        setModalDesconectar(true);
                    });
            } else {
                setErroPage(true);
                setLoading(false);
            }
        });
};