import React from "react";
import { AiOutlineSolution, AiOutlineHistory, AiOutlineAudit } from 'react-icons/ai';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/Button";
import "./styles.css";

export interface TabelaListarClientesPrivateProps {
  idCliente: string,
  nome: string;
  email: string;
}


export const TabelaListarClientesPrivate = ({ nome, email, idCliente }: TabelaListarClientesPrivateProps) => {

  const navigate = useNavigate()



  return (
    <>
      <tr className="tabelaLinha" style={{ textAlign: 'center' }}>
        <th>{nome}</th>
        <th>{email}</th>
        <th className="colunaBotoes colunaCadastro">
          <Button
            onClick={() => navigate(`/fechamento/${idCliente}`)}
            className="btn__transparente botaoCircularPrivate botaoEsq"
            titleButton={
              <>
                <AiOutlineAudit className="iconButtonExtrato" />
                <p className="textoBotaoPrivate">Fechamento</p>
              </>
            }
          />
          <Button
            onClick={() => navigate(`/gerenciar/historico-fechamento/${idCliente}/${nome}`)}
            className="btn__transparente botaoCircularPrivate botaoDir"
            titleButton={
              <>
                <p className="textoBotaoPrivate">Histórico</p>
                <AiOutlineHistory className="iconButtonExtrato" />
              </>
            }
          />
        </th>

      </tr>
    </>
  )
}
