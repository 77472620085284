import { api } from "./api";

export const obterIdFundoAcessoria = (token: string) => {
    const url = `/FundoFinanceiro/Assessoria`

    return api.get(url, {
        headers: {
            Authorization: "Bearer " + token,
        },
    })
}