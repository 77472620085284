import React, { useEffect, useState } from "react";
import "../CardFechamento/style.css";

import { Cotacoes } from "../../../pages/Gestor/Fechamento/Fechamento";
import { DateFormatter } from "../../../functions/DateFormatter"
import { Button } from "../../Button";

interface CardModalHistoricoFechamentoProps {
    sigla: string;
    ativo: string;
    quantidade: number;
    valorUnitario: number;
    quantidadeTotal?: number;
    total: number;
    manual?: boolean;
    porcentagemDoAtivoNaCarteira?: number;
    removerNovoAtivoOffline?: (index: number) => void;
    index: number;
    listaCotacoes?: Cotacoes[];
    setListaCotacoes?: (cotacao: Cotacoes[]) => void;
}

export const CardModalHistoricoFechamento = ({
    ativo,
    quantidade,
    valorUnitario,
    total,
    porcentagemDoAtivoNaCarteira
}: CardModalHistoricoFechamentoProps) => {

    return (
        <div className="caixa1 espacamentoCardFechamento">
            <fieldset>
                <div className="tituloCardFechamento">
                    <h4>{ativo}</h4>
                </div>
                <div className="conteudoCardFechamento">
                    <h6>Quantidade: R$ {quantidade.toFixed(6)}</h6>

                    <h6>Valor Unitario: {valorUnitario.toFixed(2)}</h6>

                    <h6>Porcentagem na Carteira: {porcentagemDoAtivoNaCarteira < 0.000999
                        ? porcentagemDoAtivoNaCarteira.toFixed(3)
                        : porcentagemDoAtivoNaCarteira.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}
                        %
                    </h6>

                    <h6>Total: R$ {total.toFixed(2)}</h6>

                </div>
            </fieldset>
        </div>
    );
};
