import React, { useState } from "react";
import { obterServiçosFinanceiros } from "../services/cookies";

export type usuarioServicoFinanceiroTipo =
    { id: "", idServicoFinanceiro: 0, servicoFinanceiro: '' }
    |
    { id: string, idServicoFinanceiro: 1, servicoFinanceiro: 'Assessoria' }
    |
    { id: string, idServicoFinanceiro: 3, servicoFinanceiro: 'Private' }
    |
    { id: string, idServicoFinanceiro: 2, servicoFinanceiro: 'Custódia' }


export type usuarioServicoFinanceiroContextType = {
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo;
    atualizarServicoFinanceiro: (servicoFinanceiro: usuarioServicoFinanceiroTipo) => void;
    removerServicoFinanceiro: () => void;
    reloadServicoFinanceiro: boolean;
};

export const ServicoFinanceiroClienteContext = React.createContext<usuarioServicoFinanceiroContextType>({
    usuarioServicoFinanceiro: { id: "", idServicoFinanceiro: 0, servicoFinanceiro: '' },
    atualizarServicoFinanceiro: (servicoFinanceiro: usuarioServicoFinanceiroTipo) => { },
    removerServicoFinanceiro: () => { },
    reloadServicoFinanceiro: false
});

export const ServicoFinanceiroClienteProvider = ({ children }: { children: React.ReactNode }) => {

    const servicoFinanceiroLocalStorage = obterServiçosFinanceiros() ? obterServiçosFinanceiros()[0] : { id: "", idServicoFinanceiro: 0, servicoFinanceiro: '' }

    const [usuarioServicoFinanceiro, setUsuarioServicoFinanceiro] = useState<usuarioServicoFinanceiroTipo>(servicoFinanceiroLocalStorage);
    const [reloadServicoFinanceiro, setReloadServicoFinanceiro] = useState<boolean>(false);

    const atualizarServicoFinanceiro = (item: usuarioServicoFinanceiroTipo) => {
        setUsuarioServicoFinanceiro(item);
        setReloadServicoFinanceiro(!reloadServicoFinanceiro);
    };

    const removerServicoFinanceiro = () => {
        setUsuarioServicoFinanceiro({ id: "", idServicoFinanceiro: 0, servicoFinanceiro: '' });
    };

    return (
        <ServicoFinanceiroClienteContext.Provider
            value={{ usuarioServicoFinanceiro, atualizarServicoFinanceiro, removerServicoFinanceiro, reloadServicoFinanceiro }}
        >
            {children}
        </ServicoFinanceiroClienteContext.Provider>
    );
};