import React, { useEffect, useState } from "react";

import './styles.css'

import { obterDadosPessoais } from "./functions";

import { LoadingButton } from "../loadingButtom";
import { ImagemPerfil } from '../ImagemPerfil'
import ImagemUsuario from '../../assets/img/default_user.png'
import { obterDadosUsuario2 } from "../../services/cookies";
import { ErroTabela } from "../Erros/ErroTabela";
import { ModalDesconectar } from "../modais/ModalDesconectar";
import { Button } from "../Button";
import { ModalAlterarDadosCadastraisPJ } from "../modais/ModalAlterarDadosCadastraisPJ";

import { useNavigate } from "react-router-dom";

export interface DadosClientePJProps {
  setModalAlterar: React.Dispatch<any>,
  dadosCadastraisAberto: boolean
}

export interface ClientePJProp {
  endereco: {
    cep: string,
    rua: string,
    complemento: string,
    bairro: string,
    cidade: string,
    estado: string,
  },
  nome: string,
  fotoUsuario: string | null,
  caminhoAvatar: string | null,
  cnpj: string,
  razaoSocial: string,
}

export const DadosClientePJ = ({ setModalAlterar, dadosCadastraisAberto }: DadosClientePJProps) => {

  const [dadosPessoais, setDadosPessoais] = useState<ClientePJProp>()
  const [srcFotoPerfil, setSrcFotoPerfil] = useState<string>(ImagemUsuario)
  const [loading, setLoading] = useState<boolean>(false)
  const [erro, setErro] = useState<boolean>(false)
  const [desconectar, setDesconectar] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [modal, setModal] = useState<boolean>(false)


  useEffect(() => {
    dadosCadastraisAberto && obterDadosPessoais(setDadosPessoais, setLoading, setDesconectar, setErro)
  }, [reload, dadosCadastraisAberto])

  useEffect(() => {
    const fotoPerfil = obterDadosUsuario2().urlAvatar;
    const srcFotoPerfil = fotoPerfil === "null" || !fotoPerfil ? ImagemUsuario : fotoPerfil
    setSrcFotoPerfil(srcFotoPerfil);
  }, [])

  const navigate = useNavigate();

  return (
    <>
      {loading ?
        <div className="caixa-dados-pj">
          <LoadingButton />
        </div> :
        <>
          {erro ? <ErroTabela className="formatacaoGrafico" handleClick={() => setReload(!reload)} /> :
            <div className="caixa-dados-pj">
              <ImagemPerfil imagem={srcFotoPerfil} />
              <div>
                {dadosPessoais?.nome && <p>Nome: {' ' + dadosPessoais?.nome}</p>}
                {dadosPessoais?.cnpj && <p>CNPJ: {' ' + dadosPessoais?.cnpj}</p>}
                {dadosPessoais?.razaoSocial && <p>Razão Social: {' ' + dadosPessoais?.razaoSocial}</p>}
                {dadosPessoais?.endereco.estado && <p>Estado: {' ' + dadosPessoais?.endereco.estado}</p>}
                {dadosPessoais?.endereco.cidade && <p>Cidade: {' ' + dadosPessoais?.endereco.cidade}</p>}
                {dadosPessoais?.endereco.bairro && <p>Bairro: {' ' + dadosPessoais?.endereco.bairro}</p>}
                {dadosPessoais?.endereco.rua && <p>Rua: {' ' + dadosPessoais?.endereco.rua}</p>}
                {dadosPessoais?.endereco.complemento && <p>Complemento: {' ' + dadosPessoais?.endereco.complemento}</p>}
              </div>
              <div className="container-button-PJ">
                <Button classNameButton="btn__laranja" titleButton="Alterar" onClick={() => setModal(true)} />
                <Button classNameButton="btn__laranja" titleButton="Trocar Senha" onClick={() => navigate("/cliente/alteracaoSenha")} />
              </div>
            </div>
          }
        </>
      }
      {desconectar && <ModalDesconectar modal={desconectar} />}
      {modal && <ModalAlterarDadosCadastraisPJ setReload={setReload} reload={reload} modal={modal} setModal={setModal} dados={{ bairro: dadosPessoais?.endereco.bairro, cep: dadosPessoais?.endereco.cep, cidade: dadosPessoais?.endereco.cidade, complemento: dadosPessoais?.endereco.complemento, estado: dadosPessoais?.endereco.estado, rua: dadosPessoais?.endereco.rua }} />}
    </>
  )
}