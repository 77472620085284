import React, { useState, useEffect } from "react";

import "./DadosCadastrais.css";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { ModalAlterarDadosCadastrais } from "../../../components/modais/ModalAlterarDadosCadastrais";
import { ModalNovaFormaDePagamento } from "../../../components/modais/ModalNovaFormaDePagamento";
import { Header } from "../../../components/Header";
import { DadosBancarios } from "../../../components/DadosBancarios";
import { DadosClientePF } from "../../../components/DadosClientePF";
import { DadosClientePJ } from "../../../components/DadosClientePJ";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";

import { obterPerfilUsuario, obterTipoUsuario } from '../../../services/cookies'

import AnimateHeight from "react-animate-height";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";

const DadosCadastrais = () => {
  const [modalAlterar, setModalAlterar] = useState<boolean>(false);
  const [modalNovaFormaDePagamento, setModalNovaFormaDePagamento] =
    useState<boolean>(false);
  const [dadosCadastraisAberto, setDadosCadastraisAberto] = useState(false);

  const [reloadDadosCadastrais, setReloadDadosCadastrais] = useState<boolean>(false)
  const [perfil, setPerfil] = useState<string>('')
  const [desconectar, setDesconectar] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)

  const [tipoUsuario, setTipoUsuario] = useState<string>('')

  useEffect(() => {
    setPerfil(obterPerfilUsuario())
  }, [])

  useEffect(() => {
    setTipoUsuario(obterTipoUsuario())
  }, [])


  return (
    <>
      <Navbar />

      <div id="container">
        <Header titulo={"Meus Dados"} />
        <div id="divFormPF" className="titulo1" style={{ display: "normal" }}>
          <fieldset>
            <div
              className="section-header"
              onClick={() => setDadosCadastraisAberto(!dadosCadastraisAberto)}
            >
              <h3>Dados Cadastrais</h3>
              <span>
                {dadosCadastraisAberto ? (
                  <BsCaretUpFill className="arrowUp" />
                ) : (
                  <BsCaretDownFill className="arrowDown" />
                )}
              </span>
            </div>
            <div className="section">
              <AnimateHeight
                duration={500}
                height={dadosCadastraisAberto ? 'auto' : 0}
              >

                {tipoUsuario === "Fisica" ?
                  <DadosClientePF reload={reloadDadosCadastrais} setReload={setReloadDadosCadastrais} dadosCadastraisAberto={dadosCadastraisAberto} setDadosCadastraisAberto={setDadosCadastraisAberto} setModalAlterar={setModalAlterar} />
                  :
                  <DadosClientePJ setModalAlterar={setModalAlterar} dadosCadastraisAberto={dadosCadastraisAberto} />
                }
              </AnimateHeight>
            </div>
            {(perfil === "UsuarioAssessoria" || perfil === "UsuarioCustodia") && <DadosBancarios handleReload={() => setReload(!reload)} reload={reload} setModalNovaFormaDePagamento={setModalNovaFormaDePagamento} />}
          </fieldset>
        </div>
        {modalAlterar &&
          <ModalAlterarDadosCadastrais
            modal={modalAlterar}
            setModal={setModalAlterar}
            reload={() => setReloadDadosCadastrais(!reloadDadosCadastrais)}
          />
        }
        {modalNovaFormaDePagamento &&
          <ModalNovaFormaDePagamento
            modal={modalNovaFormaDePagamento}
            setModal={setModalNovaFormaDePagamento}
            handleReload={() => setReload(!reload)}
          />
        }
      </div>

      <Footer />
      {desconectar && <ModalDesconectar modal={desconectar} />}
    </>
  );
};

export default DadosCadastrais;
