import React from "react";
import { obterValorDoReal } from "../../../services/api";
import {
  guardarDadosUsuario2,
  obterDadosUsuario2,
} from "../../../services/cookies";
import {
  ativosFinanceirosProps,
  obterCotacoesDiarias,
  salvarFechamento,
  salvarFechamentoPrivate,
} from "../../../services/fechamento";
import { obterTotalDeCotas } from "../../../services/gestor";
import {
  obterFundoFinanceiro,
  renovacaoToken,
} from "../../../services/usuario";
import { Cotacoes } from "./Fechamento";

import { Erro } from "../../../pages/Cliente/AlteracaoSenha/AlteracaoSenha";
import { obterIdFundoAcessoria } from "../../../services/fundoFinanceiro";

export const calcularTotalDeCotasDoDia = async (
  servicoFinaceiroId: number,
  totalBRL: number,
  setTotalCotaDia: React.Dispatch<number>,
  setErro: React.Dispatch<boolean>
) => {
  const cotasDia = await obterTotalDeCotas(servicoFinaceiroId)
    .then((res) => {
      const totalDeCotas = res.data.resultado.totalCotas;

      if (totalDeCotas === 0) {
        setTotalCotaDia(totalDeCotas);
        return totalDeCotas;
      } else {
        const totalDeCotasDoDia: number = totalBRL / totalDeCotas;

        setTotalCotaDia(totalDeCotasDoDia);

        return totalDeCotasDoDia;
      }
    })
    .catch((err) => setErro(true));

  return cotasDia;
};

export const obterValorReal = async (
  setValorReal: React.Dispatch<number>,
  setError: React.Dispatch<React.SetStateAction<Erro>>
) => {
  await obterValorDoReal()
    .then((res) => setValorReal(res.data.USDBRL.ask))
    .catch((err) =>
      setError({
        erro: true,
        mensagem:
          "Ocorreu um erro na sua solicitação. Por favor tente novamente.",
      })
    );
};

export const obterCotacoes = async (
  setCotacoes: React.Dispatch<Cotacoes[]>,
  setValorTotal: React.Dispatch<number>,
  setCarregando: React.Dispatch<boolean>,
  setDesconectar: React.Dispatch<boolean>
) => {
  await obterCotacoesDiarias(obterDadosUsuario2().token)
    .then((res) => {
      setCotacoes(res.data.resultado.cotacoes);
      setValorTotal(
        res.data.resultado.cotacoes.reduce(
          (p: number, a: Cotacoes) => p + a.valorTotalAtivo,
          0
        )
      );
    })
    .catch(async (err) => {
      if (err.toString() === "Error: Request failed with status code 401") {
        const dadosUsuario = obterDadosUsuario2();
        await renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
          .then(async (res) => {
            guardarDadosUsuario2(
              res.data.camposAdicionais.tokenAcesso,
              res.data.camposAdicionais.tokenRenovacao,
              dadosUsuario.email.toString(),
              dadosUsuario.id.toString()
            );

            await obterCotacoesDiarias(
              res.data.camposAdicionais.tokenAcesso
            ).then((res) => {
              setCotacoes(res.data.resultado.cotacoes);
              setValorTotal(
                res.data.resultado.cotacoes.reduce(
                  (p: number, a: Cotacoes) => p + a.valorTotalAtivo,
                  0
                )
              );
            });
          })
          .catch(() => setDesconectar(true));
      }
    })
    .finally(() => setCarregando(false));
};

export const obterCotacoesPrivate = async (
  idCredenciais: string,
  setCotacoes: React.Dispatch<Cotacoes[]>,
  setValorTotal: React.Dispatch<number>,
  setCarregando: React.Dispatch<boolean>,
  setDesconectar: React.Dispatch<boolean>
) => {
  await obterCotacoesDiarias(obterDadosUsuario2().token, idCredenciais)
    .then((res) => {
      setCotacoes(res.data.resultado.cotacoes);
      setValorTotal(
        res.data.resultado.cotacoes.reduce(
          (p: number, a: Cotacoes) => p + a.valorTotalAtivo,
          0
        )
      );
    })
    .catch(async (err) => {
      if (err.toString() === "Error: Request failed with status code 401") {
        const dadosUsuario = obterDadosUsuario2();
        await renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
          .then(async (res) => {
            guardarDadosUsuario2(
              res.data.camposAdicionais.tokenAcesso,
              res.data.camposAdicionais.tokenRenovacao,
              dadosUsuario.email.toString(),
              dadosUsuario.id.toString()
            );

            await obterCotacoesDiarias(
              res.data.camposAdicionais.tokenAcesso,
              idCredenciais
            ).then((res) => {
              setCotacoes(res.data.resultado.cotacoes);
              setValorTotal(
                res.data.resultado.cotacoes.reduce(
                  (p: number, a: Cotacoes) => p + a.valorTotalAtivo,
                  0
                )
              );
            });
          })
          .catch(() => setDesconectar(true));
      }
    })
    .finally(() => setCarregando(false));
};

export const adicionarNovoAtivoOffline = (
  ativoNovo: Cotacoes,
  cotacoes: Cotacoes[],
  setCotacoes: React.Dispatch<React.SetStateAction<Cotacoes[]>>,
  setValorTotal: React.Dispatch<React.SetStateAction<number>>,
  valorTotal: number
) => {

  let novaListaCotacoes = cotacoes;
  novaListaCotacoes.map((ativoAntigo) => {
    ativoAntigo.porcentagemAtivo =
      (100 * ativoAntigo.valorTotalAtivo) /
      (ativoNovo.valorTotalAtivo + valorTotal);
  });

  let ativoComPorcentagemCerta = ativoNovo;
  ativoComPorcentagemCerta.porcentagemAtivo =
    (100 * ativoNovo.valorTotalAtivo) /
    (ativoNovo.valorTotalAtivo + valorTotal);

  setValorTotal((total) => total + ativoNovo.valorTotalAtivo);
  setCotacoes([...novaListaCotacoes, ativoComPorcentagemCerta]);
};

export const removerNovoAtivoOffline = (
  indexDoAtivo: number,
  cotacoes: Cotacoes[],
  setCotacoes: React.Dispatch<React.SetStateAction<Cotacoes[]>>,
  setValorTotal: React.Dispatch<React.SetStateAction<number>>,
  valorTotal: number
) => {
  const novaListaDeAtivos = cotacoes.filter(
    (cotacao, index) => indexDoAtivo !== index
  );
  setValorTotal((total) => total - cotacoes[indexDoAtivo].valorTotalAtivo);

  let cotacoesComPorcentagem = CalculaPorcentagem(
    novaListaDeAtivos,
    valorTotal - cotacoes[indexDoAtivo].valorTotalAtivo
  );

  setCotacoes(cotacoesComPorcentagem);
};

export const calcularTotalDoFechamentoDoDiaUSD = (
  cotacoes: Cotacoes[],
  setTotalUsd: React.Dispatch<number>
) => {
  let soma = 0;

  for (let i = 0; i < cotacoes.length; i++) {
    soma += cotacoes[i].valorTotalAtivo;
  }

  setTotalUsd(soma);
};

export const handleConfirm = async (
  taxaAssessoria: number,
  cobrarTaxas: boolean,
  cotacoes: Cotacoes[],
  idFundoFinanceiro: string,
  servicoFinaceiroId: number,
  totalBRL: number,
  totalUsd: number,
  totalCotaDia: number,
  setTotalCotaDia: React.Dispatch<number>,
  setErro: React.Dispatch<boolean>,
  setSucesso: React.Dispatch<boolean>,
  setDesconectar: React.Dispatch<boolean>,
  setCarregando: React.Dispatch<boolean>,
  setErroCotaVazia?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setCarregando(true);

  //const cotasDia = await calcularTotalDeCotasDoDia(servicoFinaceiroId, totalBRL, setTotalCotaDia, setErro)
  const cotasDia = totalCotaDia;

  const dataAtual = new Date();

  const novaListaDeAtivos: ativosFinanceirosProps[] = cotacoes.map(
    (cotacao: Cotacoes) => ({
      nome: cotacao.nome,
      sigla: cotacao.sigla,
      porcentagemDoAtivoNaCarteira: cotacao.valorTotalAtivo * 100 / totalUsd,
      quantidade: cotacao.quantidadeAtivo,
      valorUnitario: cotacao.valorUnitarioAtivo,
      valorTotal: cotacao.valorTotalAtivo,
      origem: cotacao.adicionadoManualmente ? "Manual" : "API",
    })
  );

  const taxaAssessoriaDouble = taxaAssessoria / 100;

  const fechamento = {
    data: dataAtual,
    ativosFinanceiros: novaListaDeAtivos,
    valorTotalDolar: Number(totalUsd.toFixed(2)),
    valorCota: cotasDia,
    rendimentoDiario: 0,
    valorTotalReal: Number(totalBRL.toFixed(2)),
    idResponsavel: obterDadosUsuario2().id,
    cobrarTaxas,
    taxaAssessoria: taxaAssessoriaDouble
  };

  await salvarFechamento(
    fechamento,
    obterDadosUsuario2().token,
    idFundoFinanceiro,
    servicoFinaceiroId
  )
    .then((res) => {
      setSucesso(true);
    })
    .catch((err) => {
      if (err.toString() === "Error: Request failed with status code 401") {
        const dadosUsuario = obterDadosUsuario2();
        renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
          .then((res) => {
            guardarDadosUsuario2(
              res.data.camposAdicionais.tokenAcesso,
              res.data.camposAdicionais.tokenRenovacao,
              dadosUsuario.email.toString(),
              dadosUsuario.id.toString()
            );

            salvarFechamento(
              fechamento,
              res.data.camposAdicionais.tokenAcesso,
              idFundoFinanceiro,
              servicoFinaceiroId
            )
              .then((res) => {
                setSucesso(true);
              })
              .catch(() => setErro(true));
          })
          .catch(() => setDesconectar(true));
      } else {
        setErro(true);
      }
    })
    .finally(() => setCarregando(false));
};

export const obterFundoFinaceiroPrivate = async (
  idCredenciais: string,
  setIdFundoFinaceiro: React.Dispatch<string>,
  setErro: React.Dispatch<boolean>,
  setDesconectar: React.Dispatch<boolean>
) => {
  const { token } = obterDadosUsuario2();

  await obterFundoFinanceiro(token, idCredenciais)
    .then((res) => {
      const fundoPrivate = res.data.resultado.clientes.filter(
        (fundo: { id: string; servicoFinanceiro: string }) =>
          fundo.servicoFinanceiro === "Private"
      )[0];
      setIdFundoFinaceiro(fundoPrivate.id);
    })
    .catch((err) => {
      if (err.toString() === "Error: Request failed with status code 401") {
        const dadosUsuario = obterDadosUsuario2();
        renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
          .then((res) => {
            guardarDadosUsuario2(
              res.data.camposAdicionais.tokenAcesso,
              res.data.camposAdicionais.tokenRenovacao,
              dadosUsuario.email.toString(),
              dadosUsuario.id.toString()
            );
            obterFundoFinanceiro(
              res.data.camposAdicionais.tokenAcesso,
              idCredenciais
            ).then((res) => {
              const fundoPrivate = res.data.resultado.clientes.filter(
                (fundo: { id: string; servicoFinanceiro: string }) =>
                  fundo.servicoFinanceiro === "Private"
              )[0];
              setIdFundoFinaceiro(fundoPrivate.id);
            });
          })
          .catch(() => setDesconectar(true));
      } else {
        setErro(true);
      }
    });
};

export const obterIdFundoFinanceiroAssessoria = async (
  setIdFundoFinaceiro: React.Dispatch<string>,
  setErro: React.Dispatch<boolean>,
  setDesconectar: React.Dispatch<boolean>
) => {
  const { token } = obterDadosUsuario2();

  await obterIdFundoAcessoria(token)
    .then((res) => {
      setIdFundoFinaceiro(res.data.resultado.fundoFinanceiro.id);
    })
    .catch((err) => {
      if (err.toString() === "Error: Request failed with status code 401") {
        const dadosUsuario = obterDadosUsuario2();
        renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
          .then((res) => {
            guardarDadosUsuario2(
              res.data.camposAdicionais.tokenAcesso,
              res.data.camposAdicionais.tokenRenovacao,
              dadosUsuario.email.toString(),
              dadosUsuario.id.toString()
            );
            obterIdFundoAcessoria(res.data.camposAdicionais.tokenAcesso).then(
              (res) => {
                setIdFundoFinaceiro(res.data.resultado.fundoFinanceiro.id);
              }
            );
          })
          .catch(() => setDesconectar(true));
      } else {
        setErro(true);
      }
    });
};

export const handleConfirmPrivate = async (
  taxaPrivate: number,
  cobrarTaxas: boolean,
  cotacoes: Cotacoes[],
  idFundoFinanceiro: string,
  servicoFinaceiroId: number,
  totalBRL: number,
  totalUsd: number,
  setErro: React.Dispatch<boolean>,
  setSucesso: React.Dispatch<boolean>,
  setDesconectar: React.Dispatch<boolean>,
  setCarregando: React.Dispatch<boolean>,
  valorTotal: number,
) => {
  setCarregando(true);

  const dataAtual = new Date();

  const taxaPrivateDouble = taxaPrivate / 100;

  const novaListaDeAtivos: ativosFinanceirosProps[] =
    cotacoes.map((cotacao: Cotacoes) => ({
      nome: cotacao.nome,
      sigla: cotacao.sigla,
      porcentagemDoAtivoNaCarteira: cotacao.valorTotalAtivo * 100 / totalUsd,
      quantidade: cotacao.quantidadeAtivo,
      valorUnitario: cotacao.valorUnitarioAtivo,
      valorTotal: cotacao.valorTotalAtivo,
      origem: cotacao.adicionadoManualmente ? "Manual" : "API",
    }));

  const fechamento = {
    data: dataAtual,
    valorTotalDolar: Number(totalUsd.toFixed(2)),
    valorTotalReal: Number(totalBRL.toFixed(2)),
    valorCota: 0,
    rendimentoDiario: 0,
    ativosFinanceiros: novaListaDeAtivos,
    idResponsavel: obterDadosUsuario2().id,
    cobrarTaxas,
    taxaAssessoria: taxaPrivateDouble,
    taxaPerformance: taxaPrivateDouble,
  };

  await salvarFechamentoPrivate(
    obterDadosUsuario2().token,
    fechamento,
    idFundoFinanceiro,
    servicoFinaceiroId
  )
    .then(() => {
      setSucesso(true);
    })
    .catch((err) => {
      if (err.toString() === "Error: Request failed with status code 401") {
        const dadosUsuario = obterDadosUsuario2();
        renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
          .then((res) => {
            guardarDadosUsuario2(
              res.data.camposAdicionais.tokenAcesso,
              res.data.camposAdicionais.tokenRenovacao,
              dadosUsuario.email.toString(),
              dadosUsuario.id.toString()
            );

            salvarFechamentoPrivate(
              res.data.camposAdicionais.tokenAcesso,
              fechamento,
              idFundoFinanceiro,
              servicoFinaceiroId
            )
              .then(() => {
                setSucesso(true);
              })
              .catch(() => setErro(true));
          })
          .catch(() => setDesconectar(true));
      } else {
        setErro(true);
      }
    })
    .finally(() => setCarregando(false));
};

export const CalculaPorcentagem = (
  cotacoes: Cotacoes[],
  valorTotal: number
) => {
  let novaListaCotacoes = cotacoes;
  novaListaCotacoes.map((ativoAntigo) => {
    ativoAntigo.porcentagemAtivo =
      (100 * ativoAntigo.valorTotalAtivo) / valorTotal;
  });

  return novaListaCotacoes;
};
