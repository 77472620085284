import React from "react";
import { ClientePJProp } from ".";
import { obterDadosPessoaisPJ } from "../../services/cliente";
import { guardarDadosUsuario2, obterDadosUsuario2 } from '../../services/cookies'
import { renovacaoToken } from "../../services/usuario"

export const obterDadosPessoais = async (setDadosCadastrais: React.Dispatch<ClientePJProp>, setLoading: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>) => {

    setLoading(true)
    setDesconectar(false)
    setErro(false)

    const { email, id, token, tokenRenovacao, urlAvatar } = obterDadosUsuario2()
    await obterDadosPessoaisPJ(token, id)
        .then(res => { setDadosCadastrais(res.data.resultado.dadosPessoais) })
        .catch(async (err) => {
            if (err.toString() === "Error: Request failed with status code 401") {
                const { email, id, token, tokenRenovacao, urlAvatar } = obterDadosUsuario2()
                await renovacaoToken(token, tokenRenovacao).then(async (res) => {
                    guardarDadosUsuario2(
                        res.data.camposAdicionais.tokenAcesso,
                        res.data.camposAdicionais.tokenRenovacao,
                        email.toString(),
                        id.toString()
                    );
                    await obterDadosPessoaisPJ(res.data.camposAdicionais.tokenAcesso, id).then(res => {
                        setDadosCadastrais(res.data.resultado.dadosPessoais)
                    })
                }).catch(() => {
                    setDesconectar(true)
                })

            } else {
                setErro(true)
            }
        })
        .finally(() => setLoading(false))
}