import React, { useState, useEffect } from "react";

import "../ListarFuncionarios/ListarFuncionarios.css";

import { paginarLista, obterFuncionarios, ativarFuncionario, desativarFuncionario } from "./functions";
import { proximoSlide, voltarSlide } from "../../Cliente/Extrato/functions";

import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { Button } from "../../../components/Button";

import { TabelaListarFuncionarios } from "../../../components/Tabelas/TabelaListarFuncionarios";
import { CardListarFuncionarios } from "../../../components/Cards/CardListarFuncionarios";
import { ErroTabela } from "../../../components/Erros/ErroTabela";
import { LoadingButton } from "../../../components/loadingButtom";
import { SelectStatusFuncionario } from "../../../components/select/SelectStatusFuncionario";

import { Pagination } from "../../../components/Pagination";

import Slider from "react-slick";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    respondTo: 'slider',
    rows: 3,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>,

};
export interface Funcionario {
    idCredenciais: string;
    nome: string;
}

//TODO: Adicionar o numero de paginas dinâmico,
const ListarFuncionarios = () => {
    const [listaFuncionarios, setListaFuncionarios] = useState<Funcionario[]>([])
    const [listaFuncionariosPaginada, setListaFuncionariosPaginada] = useState<Funcionario[]>([])
    const [erro, setErro] = useState<boolean>(false)
    const [loading, setLoaing] = useState<boolean>(false)
    const [desconectar, setDesconectar] = useState<boolean>(false)
    const [tamanhoPagina] = useState<number>(2000)
    const [numeroPagina, setNumeroPagina] = useState<number>(1)
    const [reload, setReload] = useState<boolean>(false)
    const [numeroDePaginas, setNumeroDePaginas] = useState<number>(1)
    const [numeroPaginaAntiga, setnumeroPaginaAntiga] = useState<number>(0)
    const [statusFuncionario, setStatusFuncionario] = useState<boolean>(true)



    useEffect(() => {
        obterFuncionarios(numeroPagina, tamanhoPagina, listaFuncionarios, setListaFuncionarios, setNumeroDePaginas, setErro, setLoaing, setDesconectar, statusFuncionario)
    }, [reload, numeroPaginaAntiga, statusFuncionario])

    useEffect(() => {
        if (numeroPagina > numeroPaginaAntiga) {
            setnumeroPaginaAntiga(numeroPagina)
        }
        paginarLista(listaFuncionarios, numeroPagina, tamanhoPagina, setListaFuncionariosPaginada)
    }, [numeroPagina, loading])




    return (
        <>
            <Navbar />

            <div id="container">
                <Header titulo="Listar Funcionários" >
                    <div className="container-select-ListarFuncionarios">
                        <SelectStatusFuncionario setStatus={setStatusFuncionario} />
                    </div>
                </Header>
                <div className="desktopListarFuncionarios">
                    <div className="caixa1">
                        <fieldset>
                            {/* <Pagination paginaAtual={numeroPagina} numeroDePaginas={numeroDePaginas} setPagina={setNumeroPagina} /> */}
                            {loading ?
                                <div className="caixa1">
                                    <LoadingButton />
                                </div>
                                :
                                <>

                                    <table className="tabela">
                                        <thead>
                                            <tr className="gerenciarCadastroTabela">
                                                <th className="table-secondary tableTitulo tableData colunas">
                                                    <strong>Nome</strong>
                                                </th>
                                                <th className="table-secondary tableTitulo tableCotas colunas">
                                                    <strong>Status</strong>
                                                </th>
                                                <th className="table-secondary tableTitulo tableOperacao colunas">
                                                    <strong>Ação</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {erro ? <ErroTabela handleClick={() => setReload(!reload)} className="formatacaoGrafico" /> :
                                                <>
                                                    {
                                                        listaFuncionariosPaginada.length !== 0 ? (
                                                            listaFuncionariosPaginada.map(({ nome, idCredenciais }, index) =>
                                                                <TabelaListarFuncionarios
                                                                    key={idCredenciais}
                                                                    ativar={() => ativarFuncionario(idCredenciais, setReload, reload, setDesconectar, setErro)}
                                                                    desativar={() => desativarFuncionario(idCredenciais, setReload, reload, setDesconectar, setErro)}
                                                                    nome={nome}
                                                                    status={statusFuncionario}
                                                                />
                                                            )) : (
                                                            <tr>
                                                                <td colSpan={5} style={{ textAlign: "center", }}>
                                                                    <p>Nenhum resultado encontrado no momento.</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </>
                            }
                        </fieldset>
                    </div>
                </div>
                <div className="mobileListarFuncionarios">
                    <div className="caixa1">
                        <fieldset>
                            {loading && numeroPagina === 1 ?
                                <div className="caixa1">
                                    <LoadingButton />
                                </div>
                                :
                                <>
                                    {erro ?
                                        <div className="caixa1">
                                            <ErroTabela handleClick={() => setReload(!reload)} className="formatacaoGrafico" />
                                        </div>
                                        :
                                        <div>
                                            <div className="divBotoesExtratoMobile">
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => voltarSlide("prevArrowSlideListaUsuarios")}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowLeft className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => proximoSlide('nextArrowSlideListaUsuarios')}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowRight className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <Slider afterChange={(currentSlide: number) => {
                                                if (numeroDePaginas * tamanhoPagina > listaFuncionarios.length && currentSlide === Number((tamanhoPagina * numeroPagina / 3 - 1).toFixed(0))) {
                                                    setNumeroPagina(numeroPagina + 1)
                                                }
                                            }}
                                                {...settings}>
                                                {
                                                    listaFuncionarios.length !== 0 ? (
                                                        listaFuncionarios.map(({ nome, idCredenciais }) => <CardListarFuncionarios key={idCredenciais} ativar={() => {
                                                            ativarFuncionario(idCredenciais, setReload, reload, setDesconectar, setErro)
                                                        }

                                                        } desativar={() => desativarFuncionario(idCredenciais, setReload, reload, setDesconectar, setErro)} nome={nome} status={statusFuncionario} />)
                                                    ) : (
                                                            <p>Nenhum resultado encontrado no momento.</p>
                                                        )
                                                }
                                            </Slider>
                                        </div>
                                    }
                                </>
                            }
                        </fieldset>
                    </div>

                </div>

            </div>
            <Footer />
            {desconectar && <ModalDesconectar modal={desconectar} />}
        </>
    );
}

export default ListarFuncionarios;