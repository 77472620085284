import ImagemOmegaImpressao from "../../../assets/img/logo.png";

export const criaPDF = () => {
    const tabela = document.getElementById("tblHistoricoFechamento")!.innerHTML;
    const rodapeTabela = document.getElementById("tblHistoricoFechamentoFooter")!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
        style +
        "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
        style +
        "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABE�ALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(
        `<div><img src="${ImagemOmegaImpressao}"></img></div>`
    );
    win.document.write("<table>");
    win.document.write(tabela);
    win.document.write(rodapeTabela); // RODAPE DA TABELA NO FIM DAS PAGINAS
    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    win.document.close(); // FECHA A JANELA
    setTimeout(function () {
        win.print(); // IMPRIME O CONTEUDO
    }, 500);
};