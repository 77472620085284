import React, { useState } from "react";
import { handleMaskTelefonePJ, handleMaskCEPPJ, handleEnderecoPJ, handleMaskCNPJ } from "./functions";
import 'primeicons/primeicons.css';
import "./CadastroPJ.css";

import { ModalCadastroSocio } from "../modais/ModalCadastroSocio";
import { Input } from "../Input";
import { UsuarioPJ, Socios } from "../../pages/Cliente/Cadastro/Cadastro";
import { SelectEstado } from "../select/SelectEstados/SelectEstados";

interface CadastroPJProps {
    setUsuarioPJ: React.Dispatch<React.SetStateAction<UsuarioPJ>>,
    socios: Socios[],
    setSocios: React.Dispatch<React.SetStateAction<Socios[]>>,
}

function CadastroPessoaJuridica({ setUsuarioPJ, socios, setSocios }: CadastroPJProps) {
    const [modalCadastroSocio, setModalCadastroSocio] = useState<boolean>(false);

    const [CEP, setCEP] = useState<string>("");
    const [bairro, setBairro] = useState<string>("");
    const [cidade, setCidade] = useState<string>("");
    const [estado, setEstado] = useState<string>("");
    const [rua, setRua] = useState<string>("");
    const [complemento, setComplemento] = useState<string>("");

    const [maskTelefone, setMaskTelefone] = useState<string>("");
    const [maskCNPJ, setMaskCNPJ] = useState<string>("");
    const [maskCEP, setMaskCEP] = useState<string>("");

    const removerSocios = (indexDoSocio: number, socios: Socios[], setSocios: React.Dispatch<React.SetStateAction<Socios[]>>) => {
        const novaListaDeSocios = socios.filter(
            (socios, index) => indexDoSocio !== index
        );
        setSocios(novaListaDeSocios);
    };

    return (
        <div className="AreaCadastro">
            <div className="inputsCadastroPessoaJuridica dadosEmpresa">
                <Input
                    required={true}
                    placeholder="Nome da empresa"
                    label="Nome da empresa"
                    onChange={(e) => {
                        setUsuarioPJ((usuario) => {
                            usuario.nome = e.target.value
                            return usuario
                        })
                    }}
                />
                <Input
                    required={true}
                    placeholder="CNPJ"
                    label="CNPJ"
                    maxLength={14}
                    value={maskCNPJ}
                    onChange={(e) => { handleMaskCNPJ(e.target.value, setUsuarioPJ, setMaskCNPJ) }}
                />
                <Input
                    required={true}
                    placeholder="Razão Social"
                    label="Razão Social"
                    onChange={(e) => {
                        setUsuarioPJ((usuario) => {
                            usuario.razaoSocial = e.target.value
                            return usuario
                        })
                    }}
                />
                <Input
                    required={true}
                    placeholder=" "
                    label="Telefone"
                    maxLength={15}
                    value={maskTelefone}
                    onChange={(e) => { handleMaskTelefonePJ(e.target.value, setUsuarioPJ, setMaskTelefone) }}
                />
                <Input
                    required={true}
                    placeholder=" "
                    label="CEP"
                    maxLength={10}
                    value={maskCEP}
                    onChange={(e) => { handleMaskCEPPJ(e.target.value, setCEP, setRua, setBairro, setEstado, setCidade, setUsuarioPJ, setMaskCEP, rua, bairro, cidade, complemento, estado, CEP) }}
                />
                <div className="orgaoExpedidor">
                    <div className="inputMenor">
                        <Input
                            required={true}
                            placeholder=" "
                            label="Cidade"
                            value={cidade}
                            onChange={(e) => {
                                setCidade(e.target.value);
                                handleEnderecoPJ(setUsuarioPJ, rua, bairro, cidade, complemento, estado, CEP);
                            }}
                        />
                    </div>
                    <SelectEstado setUf={setEstado} UF={estado} />
                </div>
                <Input
                    required={true}
                    placeholder=" "
                    label="Endereço"
                    value={rua}
                    onChange={(e) => {
                        setRua(e.target.value);
                        handleEnderecoPJ(setUsuarioPJ, rua, bairro, cidade, complemento, estado, CEP);
                    }}
                />
                <Input
                    required={true}
                    placeholder=" "
                    label="Bairro"
                    value={bairro}
                    onChange={(e) => {
                        setBairro(e.target.value);
                        handleEnderecoPJ(setUsuarioPJ, rua, bairro, cidade, complemento, estado, CEP);
                    }}
                />
                <Input
                    required={true}
                    placeholder=" "
                    label="Complemento e número"
                    value={complemento}
                    onChange={(e) => {
                        setComplemento(e.target.value);
                        handleEnderecoPJ(setUsuarioPJ, rua, bairro, cidade, e.target.value, estado, CEP);
                    }}
                />
            </div>

            <div className="inputsCadastroPessoaJuridica dadosSocios">
                <div className="areaSocios">
                </div>

                <div className="areaBotaoSocio">
                    <button
                        className="btn__cinza"
                        onClick={() => setModalCadastroSocio(true)}
                        type="button"
                        id="btnAdicionarSocio"
                        style={{ width: "220px" }}
                    >
                        Adicionar Socio
                    </button>
                </div>

                <ModalCadastroSocio
                    modal={modalCadastroSocio}
                    setModal={() => setModalCadastroSocio(false)}
                    socios={socios}
                    setSocios={setSocios}
                />

                <div className="container-lista-socios">
                    <h6 className="titulo-lista-socios">Lista de Sócios</h6>
                    <table className="lista-socios">
                        <thead></thead>

                        <tbody>
                            {
                                socios.map(({ nome }, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{nome}</td>
                                            <td className="coluna-botao-remover">
                                                <button className="botao-remover-socio" onClick={() => (removerSocios(index, socios, setSocios))}><i className="pi pi-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
}

export default CadastroPessoaJuridica;
