import React from "react";

import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

import LegalSidebarImage from "../../assets/img/omega_header_menu.png";

export const LegalSidebarData = [
    {
        title: 'Terms Of Use',
        path: '/legal/termsofuse',
        cName: 'nav-text',
    },
    {
        title: 'Privacy Policy',
        path: '/legal/privacypolicy',
        cName: 'nav-text',
    },
    {
        title: 'Fees',
        path: '/legal/fees',
        cName: 'nav-text',
    },
    {
        title: 'Voltar ao Login',
        path: '/',
        cName: 'nav-text',
    },
];

const LegalSidebar = ({ legalSidebar, showLegalSidebar }: any) => {
    return (
        <nav
            style={{ zIndex: "999" }}
            className={legalSidebar ? "nav-menu active" : "nav-menu"}
        >
            <ul
                style={{ paddingLeft: "0px" }}
                className="nav-menu-itens"
                onClick={showLegalSidebar}
            >
                <li className="navbar-toggle">
                    <div className="sidebar-header">
                        <img src={LegalSidebarImage} className="d-xl-block img-fluid logo" />
                    </div>
                    <Link to="#" className="menu-bars">
                        <AiOutlineClose />
                    </Link>
                </li>
                {LegalSidebarData.map((item, index) => {
                    return (
                        <li key={index} className={item.cName}>
                            <Link to={item.path}>
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default LegalSidebar;
