import { log } from "console";
import { NavigateFunction } from "react-router-dom";
import {
  incluirPessoaFisica,
  verificarDisponibilidadeCpf,
  incluirPessoaJuridica,
  verificarDisponibilidadeCnpj,
} from "../../../services/cliente";
import {
  verificarDisponibilidadeEmail,
  verificarDisponibilidadeUserName,
} from "../../../services/usuario";
import { Erro } from "../AlteracaoSenha/AlteracaoSenha";
import {
  Endereco,
  ImagensDocumentacao,
  Usuario,
  UsuarioPJ,
  Socios,
} from "./Cadastro";

export async function verificarUserName(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  userName: string,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  email: string,
  setActive: (value: React.SetStateAction<number>) => void
) {
  setLoading(true);
  await verificarDisponibilidadeUserName(userName)
    .then(async () => {
      setError({
        erro: false,
        mensagem: "",
      });
      setLoading(false);
    })
    .catch((e) => {
      let mensagem =
        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
      if (e.response.data.codigo) {
        switch (e.response.data.codigo) {
          case 400:
            mensagem = "Esse Username já está sendo utilizado.";
            setError({
              erro: true,
              mensagem,
            });
            break;
          case 404:
            setError({
              erro: false,
              mensagem: "",
            });
            verificarEmail(setLoading, setError, email, setActive);
            break;
          default:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            setError({
              erro: true,
              mensagem,
            });
            break;
        }
      } else {
        setError({
          erro: true,
          mensagem,
        });
      }
      setLoading(false);
    });
}

export async function verificarEmail(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  email: string,
  setActive: (value: React.SetStateAction<number>) => void
) {
  setLoading(true);
  await verificarDisponibilidadeEmail(email)
    .then(async () => {
      setLoading(false);
      setError({
        erro: false,
        mensagem: "",
      });
    })
    .catch((e) => {
      let mensagem =
        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
      if (e.response.data.codigo) {
        switch (e.response.data.codigo) {
          case 400:
            mensagem = "Esse Email já está sendo utilizado.";
            setError({
              erro: true,
              mensagem,
            });
            break;
          case 404:
            setError({
              erro: false,
              mensagem: "",
            });
            setActive((value) => value + 1);
            break;
          default:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            setError({
              erro: true,
              mensagem,
            });
            break;
        }
      } else {
        setError({
          erro: true,
          mensagem,
        });
      }
      setLoading(false);
    });
}

export async function verificarCpf(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  CPF: string,
  setActive: (value: React.SetStateAction<number>) => void
) {
  setLoading(true);
  await verificarDisponibilidadeCpf(CPF)
    .then(async () => {
      setLoading(false);
      setError({
        erro: false,
        mensagem: "",
      });
    })
    .catch((e) => {
      let mensagem =
        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
      if (e.response.status) {
        switch (e.response.status) {
          case 400:
            mensagem = "Esse CPF já está cadastrado.";
            setError({
              erro: true,
              mensagem,
            });
            break;
          case 404:
            setError({
              erro: false,
              mensagem: "",
            });
            setActive((value) => value + 1);
            break;
          default:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            setError({
              erro: true,
              mensagem,
            });
            break;
        }
      } else {
        setError({
          erro: true,
          mensagem,
        });
      }
      setLoading(false);
    });
}

export async function verificarCnpj(
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  CNPJ: string,
  setActive: (value: React.SetStateAction<number>) => void
) {
  setLoading(true);
  await verificarDisponibilidadeCnpj(CNPJ)
    .then(async () => {
      setLoading(false);
      setError({
        erro: false,
        mensagem: "",
      });
    })
    .catch((e) => {
      let mensagem =
        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
      if (e.response.data.codigo) {
        switch (e.response.data.codigo) {
          case 400:
            mensagem = "Esse CNPJ já está cadastrado.";
            setError({
              erro: true,
              mensagem,
            });
            break;
          case 404:
            setError({
              erro: false,
              mensagem: "",
            });
            setActive((value) => value + 1);
            break;
          default:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            setError({
              erro: true,
              mensagem,
            });
            break;
        }
      } else {
        setError({
          erro: true,
          mensagem,
        });
      }
      setLoading(false);
    });
}

export const onSubmitPF = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  userName: string,
  email: string,
  senha: string,
  imagemPerfil: string | ArrayBuffer | null,
  usuarioPF: Usuario,
  idsServicosFinanceiros: number[],
  imagensDocumentacao: ImagensDocumentacao[],
  navigate: NavigateFunction
) => {
  setLoading(true);
  await incluirPessoaFisica({
    email,
    senha,
    userName,
    imagemPerfil,
    ...usuarioPF,
    idsServicosFinanceiros,
    imagensDocumentacao,
  })
    .then(() => {
      setLoading(false);
      navigate("/confirmacao-usuario");
    })
    .catch((error) => {
      console.error("Erro incluirPessoaFisica ", error);
      let mensagem =
        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
      if (error.response.data.title) {
        switch (error.response.data.status) {
          case 400:
            mensagem =
              "Algo deu errado. Verifique se todos os campos foram preenchidos.";
            break;
          default:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
        }
      }

      if (error.response.data.codigo) {
        switch (error.response.data.codigo) {
          case 400:
            error.response.data.mensagem ===
              "Usuário já existe em nossa base de dados"
              ? (mensagem = "Esse Email já está sendo utilizado.")
              : (mensagem = "Esse Username já está sendo utilizado.");
            break;
          case 500:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          case 501:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          case 502:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          case 503:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          default:
            mensagem =
              "Algo deu errado. Verifique se todos os campos foram preenchidos.";
            break;
        }
      }
      setError({
        erro: true,
        mensagem,
      });
      setLoading(false);
    })
    .finally(() => setLoading(false));
};

export const onSubmitPJ = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  userName: string,
  email: string,
  senha: string,
  imagemPerfil: string | ArrayBuffer | null,
  usuarioPJ: UsuarioPJ,
  socios: Socios[],
  idsServicosFinanceiros: number[],
  imagensDocumentacao: ImagensDocumentacao[],
  navigate: NavigateFunction
) => {
  setLoading(true);
  usuarioPJ.socios = socios;
  console.log(usuarioPJ.socios);
  console.log(socios);

  await incluirPessoaJuridica({
    email,
    senha,
    userName,
    imagemPerfil,
    ...usuarioPJ,
    idsServicosFinanceiros,
    imagensDocumentacao,
  })
    .then(() => {
      setLoading(false);
      navigate("/confirmacao-usuario");
    })
    .catch((error) => {
      console.error("Erro incluirPessoaFisica ", error);
      let mensagem =
        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
      if (error.response.data.title) {
        switch (error.response.data.status) {
          case 400:
            mensagem =
              "Algo deu errado. Verifique se todos os campos foram preenchidos.";
            break;
          default:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
        }
      }

      if (error.response.data.codigo) {
        switch (error.response.data.codigo) {
          case 400:
            error.response.data.mensagem ===
              "Usuário já existe em nossa base de dados"
              ? (mensagem = "Esse Email já está sendo utilizado.")
              : (mensagem = "Esse Username já está sendo utilizado.");
            break;
          case 500:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          case 501:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          case 502:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          case 503:
            mensagem =
              "Algo deu errado aguarde um instante e tente novamente mais tarde.";
            break;
          default:
            mensagem =
              "Algo deu errado. Verifique se todos os campos foram preenchidos.";
            break;
        }
      }
      setError({
        erro: true,
        mensagem,
      });
      setLoading(false);
    })
    .finally(() => setLoading(false));
};

export const verificarSeEmailEValido = (email: string) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  if (reg.test(email) === false) {
    return false;
  } else {
    return true;
  }
};

export const habilitarBotaoProximoPF = (
  active: number,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  userName: string,
  email: string,
  senha: string,
  senhaConfirmada: string,
  imagePerfil: string | ArrayBuffer | null,
  usuarioPF: Usuario,
  idsServicosFinanceiros: number[],
  imagensDocumentacao: ImagensDocumentacao[]
) => {
  switch (active) {
    case 1:
      if (
        userName !== "" &&
        email !== "" &&
        senha !== "" &&
        senhaConfirmada !== ""
      ) {
        if (senha === senhaConfirmada) {
          if (verificarSeEmailEValido(email)) {
            return true;
          } else {
            setError({
              erro: true,
              mensagem: "O e-mail digitado não é válido.",
            });
            return false;
          }
        } else {
          setError({
            erro: true,
            mensagem: "Senha e confirmação de senha não são iguais.",
          });
          return false;
        }
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
    case 2:
      if (
        usuarioPF.nome !== "" &&
        usuarioPF.cpf !== "" &&
        usuarioPF.rg !== "" &&
        usuarioPF.orgaoExpeditorRg !== "" &&
        usuarioPF.ufOrgaoExpeditor !== "" &&
        usuarioPF.profissao !== "" &&
        usuarioPF.renda !== 0 &&
        usuarioPF.telefone !== "" &&
        usuarioPF.dataNascimento !== "" &&
        usuarioPF.nacionalidade !== "" &&
        usuarioPF.endereco.bairro !== "" &&
        usuarioPF.endereco.cep !== "" &&
        usuarioPF.endereco.cidade !== "" &&
        usuarioPF.endereco.complemento !== "" &&
        usuarioPF.endereco.estado !== "" &&
        usuarioPF.endereco.rua !== ""
      ) {
        return true;
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
    case 3:
      if (idsServicosFinanceiros.length !== 0) {
        setError({
          erro: false,
          mensagem: "",
        });
        return true;
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
    case 4:
      if (
        imagensDocumentacao.length >= 3
      ) {
        setError({
          erro: false,
          mensagem: "",
        });
        return true;
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
  }
};

export const habilitarBotaoProximoPJ = (
  active: number,
  setError: React.Dispatch<React.SetStateAction<Erro>>,
  userName: string,
  email: string,
  senha: string,
  senhaConfirmada: string,
  imagePerfil: string | ArrayBuffer | null,
  usuarioPJ: UsuarioPJ,
  idsServicosFinanceiros: number[],
  imagensDocumentacao: ImagensDocumentacao[]
) => {
  switch (active) {
    case 1:
      if (
        userName !== "" &&
        email !== "" &&
        senha !== "" &&
        senhaConfirmada !== ""
      ) {
        if (senha === senhaConfirmada) {
          if (verificarSeEmailEValido(email)) {
            return true;
          } else {
            setError({
              erro: true,
              mensagem: "O e-mail digitado não é válido.",
            });
            return false;
          }
        } else {
          setError({
            erro: true,
            mensagem: "Senha e confirmação de senha não são iguais.",
          });
          return false;
        }
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
    case 2:
      if (
        usuarioPJ.nome !== "" &&
        usuarioPJ.cnpj !== "" &&
        usuarioPJ.razaoSocial !== "" &&
        usuarioPJ.telefone !== "" &&
        usuarioPJ.status !== false &&
        usuarioPJ.endereco !== null &&
        usuarioPJ.socios !== null
      ) {
        return true;
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
    case 3:
      if (idsServicosFinanceiros.length !== 0) {
        setError({
          erro: false,
          mensagem: "",
        });
        return true;
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
    case 4:
      if (
        imagensDocumentacao.length >= 3
      ) {
        setError({
          erro: false,
          mensagem: "",
        });
        return true;
      } else {
        setError({
          erro: true,
          mensagem:
            "Verifique se todos os campos foram preenchidos corretamente.",
        });
        return false;
      }
  }
};
