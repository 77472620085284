import React, { useState } from "react";
import "../CardFechamento/style.css";


import { ModalHistoricoFechamento } from "../../modais/ModalHistoricoFechamento";
import { Fechamentos } from "../../../pages/Gestor/HistoricoFechamento/HistoricoFechamento";
import { DateFormatter } from "../../../functions/DateFormatter"

import { AiFillEye } from "react-icons/ai";
import { Button } from "../../Button";


interface CardHistoricoFechamentoProps {
    dados: Fechamentos;
}

export const CardHistoricoFechamento = ({
    dados
}: CardHistoricoFechamentoProps) => {

    const [comprovanteModal, setComprovanteModal] = useState<boolean>(false);

    const exibirPDF = () => {
        setComprovanteModal(true);
    };

    return (
        <>
            <div className="caixa1 espacamentoCardFechamento">
                <fieldset>
                    <div className="tituloCardFechamento">
                        <h4>{DateFormatter(dados.data)}</h4>
                    </div>
                    <div className="conteudoCardFechamento">
                        <h6>Valor do Fechamento:</h6>
                        <h6>R$ {dados.valorTotalReal.toFixed(2)}</h6>

                        <h6>Gestor: {dados.responsavel ? dados.responsavel.nome : ""}</h6>

                        <Button
                            className="btn__cinza buttonComprovante"
                            onClick={exibirPDF}
                            titleButton={
                                <>
                                    <AiFillEye className="iconButton" />
                                    <strong className="marginButton">Ver Fechamento</strong>
                                </>
                            }
                        />

                    </div>
                </fieldset>
            </div>

            {comprovanteModal && (
                <ModalHistoricoFechamento
                    modal={comprovanteModal}
                    setModal={setComprovanteModal}
                    cotacoes={dados.ativosFinanceiros}
                    valorTotalReal={dados.valorTotalReal}
                    valorTotalDolar={dados.valorTotalDolar}
                    valorCota={dados.valorCota}
                />
            )}

        </>

    );
};
