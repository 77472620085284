import {
  guardarDadosUsuario,
  guardarDadosUsuario2,
  obterDadosUsuario2,
} from "../../../services/cookies";
import { obterUltimoFechamento } from "../../../services/fechamento";
import { renovacaoToken } from "../../../services/usuario";
import { ativoFinanceiro } from "./Ativos";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { getClienteServicosFinanceiros } from "../../../services/cliente";

export const randomColor = (
  porcentagem: number[],
  colorArray: string[],
  setColorArray: React.Dispatch<React.SetStateAction<string[]>>
) => {
  porcentagem.map((value, index) => {
    //newArray.push("#" + Math.floor(Math.random() * 16777215).toString(16));

    colorArray.push("#EA3A23");
    colorArray.push("#F3AF30");
    colorArray.push("#EF7C29");
    colorArray.push("#FBE73A");
    colorArray.push("#DAED39");
    colorArray.push("#75CE36");
    colorArray.push("#4DA338");
    colorArray.push("#61C37A");
    colorArray.push("#65D1B1");
    colorArray.push("#94DBDD");
    colorArray.push("#A7D9FE");
    colorArray.push("#3982D1");
    colorArray.push("#8AA0E9");
    colorArray.push("#8C79EC");
    colorArray.push("#955ACE");
    colorArray.push("#BC6CCF");
    colorArray.push("#D34486");
  });
  setColorArray(colorArray);
};

export const exibirAtivos = async (
  setLabels: React.Dispatch<React.SetStateAction<string[]>>,
  setPorcentagem: React.Dispatch<React.SetStateAction<number[]>>,
  setValorUnitario: React.Dispatch<React.SetStateAction<number[]>>,
  setValorTotal: React.Dispatch<React.SetStateAction<number[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setErroPage: React.Dispatch<React.SetStateAction<boolean>>,
  setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
  colorArray: string[],
  setColorArray: React.Dispatch<React.SetStateAction<string[]>>,
  usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo
) => {
  await obterUltimoFechamento(
    obterDadosUsuario2().token,
    usuarioServicoFinanceiro.idServicoFinanceiro,
    obterDadosUsuario2().id
  )
    .then((res) => {
      handleDataAndLabels(
        res.data.resultado.fechamento.ativosFinanceiros,
        setLabels,
        setPorcentagem,
        setValorUnitario,
        setValorTotal,
        colorArray,
        setColorArray
      );
      setErroPage(false);
    })
    .catch(async (e) => {
      let token = obterDadosUsuario2().token;
      let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
      let email = obterDadosUsuario2().email;
      let id = obterDadosUsuario2().id;
      if (e.toString() === "Error: Request failed with status code 401") {
        await renovacaoToken(token, tokenRenovacao)
          .then(async (res2) => {
            guardarDadosUsuario(
              res2.data.camposAdicionais.tokenAcesso,
              res2.data.camposAdicionais.tokenRenovacao,
              email.toString(),
              id.toString()
            );
            guardarDadosUsuario2(
              res2.data.camposAdicionais.tokenAcesso,
              res2.data.camposAdicionais.tokenRenovacao,
              email.toString(),
              id.toString()
            );
            await obterUltimoFechamento(
              res2.data.camposAdicionais.tokenAcesso,
              1,
              obterDadosUsuario2().id
            )
              .then((res2) => {
                handleDataAndLabels(
                  res2.data.resultado.fechamento.ativosFinanceiros,
                  setLabels,
                  setPorcentagem,
                  setValorUnitario,
                  setValorTotal,
                  colorArray,
                  setColorArray
                );
                setErroPage(false);
              })
              .catch((e2) => {
                setErroPage(true);
              });
          })
          .catch((err3) => {
            setModalDesconectar(true);
          });
      } else {
        setErroPage(true);
      }
    })
    .finally(() => setLoading(false));
};

function handleDataAndLabels(
  ativosFinanceiros: ativoFinanceiro[],
  setLabels: React.Dispatch<React.SetStateAction<string[]>>,
  setPorcentagem: React.Dispatch<React.SetStateAction<number[]>>,
  setValorUnitario: React.Dispatch<React.SetStateAction<number[]>>,
  setValorTotal: React.Dispatch<React.SetStateAction<number[]>>,
  colorArray: string[],
  setColorArray: React.Dispatch<React.SetStateAction<string[]>>
) {
  let labels: string[] = [];
  let porcentagem: number[] = [];
  let valoresTotais: number[] = [];
  let valoresUnitarios: number[] = [];

  ativosFinanceiros
    .filter((item) => {
      return item.porcentagemDoAtivoNaCarteira >= 1;
    })
    .map((ativoFinanceiro) => {
      labels.push(ativoFinanceiro.sigla);
      porcentagem.push(ativoFinanceiro.porcentagemDoAtivoNaCarteira);
      valoresTotais.push(ativoFinanceiro.valorTotal);
      valoresUnitarios.push(ativoFinanceiro.valorUnitario);
    });

  setLabels(labels);
  setPorcentagem(porcentagem);
  setValorUnitario(valoresUnitarios);
  setValorTotal(valoresTotais);
  randomColor(porcentagem, colorArray, setColorArray);
}
