import React from 'react'
import { renovacaoToken } from '../../../services/usuario'
import { obterTodosClientes } from '../../../services/gestor'
import { obterDadosUsuario2, guardarDadosUsuario2 } from '../../../services/cookies'
import { getTodosClienteServicosFinanceiros } from '../../../services/cliente'

export interface Cliente {
    nome: string;
    idCredenciais: string;
    email: string;
}

export const obterClientes = (setListaClientes: React.Dispatch<Cliente[]>, setListaPrivates: React.Dispatch<Cliente[]>, setLoading: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>) => {
    setLoading(true);

    const { email, urlAvatar, id, token, tokenRenovacao, } = obterDadosUsuario2()


    obterTodosClientes(token)
        .then(res => {
            setListaClientes(res.data.resultado.clientes)
            getTodosClienteServicosFinanceiros(token, 3, 1, 1000).then((data) => {setListaPrivates(data.data.resultado.clientes)})
        })
        .catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao)
                    .then(res => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        )
                        obterTodosClientes(res.data.camposAdicionais.tokenAcesso,)
                            .then(res => {
                                setListaClientes(res.data.resultado.clientes)
                            })
                    })
                    .catch(() => setDesconectar(true))
            } else {
                setErro(true)
            }
        })
        .finally(() => setLoading(false))
}
