import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import LegalSidebar from "../LegalSidebar/LegalSidebar";
import { IconContext } from "react-icons";
import SidebarImage from "../../assets/img/omega_header_menu.png";
import "./LegalNavbar.css";

const LegalNavbar = () => {
    const [legalSidebar, setLegalSidebar] = useState(false);
    const showLegalSidebar = () => setLegalSidebar(!legalSidebar);

    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <div className="navbar">
                    <Link to="#" className="manu-bars">
                        <FaBars onClick={showLegalSidebar} color={"#313a46"} />
                    </Link>
                </div>
                <nav
                    style={{ zIndex: "999" }}
                    className={legalSidebar ? "nav-menu active" : "nav-menu"}
                >
                    <ul
                        style={{ paddingLeft: "0px" }}
                        className="nav-menu-itens"
                        onClick={showLegalSidebar}
                    >
                        <li className="navbar-toggle">
                            <div className="sidebar-header">
                                <img src={SidebarImage} className="d-xl-block img-fluid logo" />
                            </div>
                            <Link to="#" className="menu-bars">
                                <AiOutlineClose />
                            </Link>
                        </li>
                        <LegalSidebar legalSidebar={legalSidebar} showLegalSidebar={showLegalSidebar} />
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    );
};

export default LegalNavbar;
