import React from "react";
import { listaClientesSelectProps } from ".";
import { getTodosClienteServicosFinanceiros } from "../../../services/cliente";
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { obterServicoFinanceiroPorStatus, renovacaoToken } from "../../../services/usuario";
import { TabelaListarClientesPrivateProps } from "../../Tabelas/TabelaClientesPrivate";



export interface obterClientesProps {
    setListaClientes: React.Dispatch<listaClientesSelectProps[]>,
    setErro: React.Dispatch<boolean>,
    idServicoFinanceiro: number,
    numeroPagina: number,
    tamanhoPagina: number,
    setLoading: React.Dispatch<boolean>,
    setDesconectar: React.Dispatch<boolean>
}

export const obterClientes = ({
    setListaClientes,
    setErro,
    idServicoFinanceiro,
    numeroPagina,
    tamanhoPagina,
    setLoading,
    setDesconectar,
}: obterClientesProps) => {
    setErro(false)
    setLoading(true)

    const { email, urlAvatar, id, token, tokenRenovacao, } = obterDadosUsuario2()

    getTodosClienteServicosFinanceiros(token, idServicoFinanceiro, numeroPagina, tamanhoPagina)
        .then(res => {
            setListaClientes(res.data.resultado.clientes)
        })
        .catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao)
                    .then(res => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        )

                        getTodosClienteServicosFinanceiros(res.data.camposAdicionais.tokenAcesso, idServicoFinanceiro, numeroPagina, tamanhoPagina)
                            .then(res => {
                                setListaClientes(res.data.resultado.clientes)

                            })
                    })
                    .catch(() => setDesconectar(true))
            } else {
                setErro(true)
            }
        })
        .finally(() => setLoading(false))
}