import { Cotacoes } from "../../../pages/Gestor/Fechamento/Fechamento";


export const adicionarPorcentagemDoAtivoNaCarteiraNoAtivo = (
    listaCotacoes: Cotacoes[],
    index: number,
    ativo: string,
    porcentagem: number,
    quantidade: number,
    sigla: string,
    valorUnitario: number,
    manual: boolean,
    setListaCotacoes: (cotacao: Cotacoes[]) => void,

) => {
    const listaTemporaria = listaCotacoes;
    listaTemporaria[index] = {
        ...listaCotacoes[index],
        nome: ativo,
        porcentagemAtivo: porcentagem,
        quantidadeAtivo: quantidade,
        sigla,
        valorTotalAtivo: quantidade * valorUnitario,
        valorUnitarioAtivo: valorUnitario,
        origem: manual ? "Sistema" : "",
    };

    setListaCotacoes(listaTemporaria);
};

export const handleDelete = (
    index: number,
    removerNovoAtivoOffline: (index: number) => void
) => {
    removerNovoAtivoOffline(index);
};