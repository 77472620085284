import React, { useState } from 'react'
import './CardGerenciarCadastro.css';

import { Button } from '../../Button'
import { LoadingButton } from "../../../components/loadingButtom";
import { alterarStatusDaConta } from "../../../services/cliente";

import { AiOutlineCheckCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { obterDadosUsuario2 } from '../../../services/cookies';
import { Cliente } from '../../../pages/Gestor/GerenciarCadastro/GerenciarCadastro';
import { handleStatus } from '../../Tabelas/TabelaGerenciarCadastro/functions';

interface CardGerenciarCadastroProps {
    usuario: Cliente,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    setIdCliente: React.Dispatch<React.SetStateAction<string>>,
    setReload: React.Dispatch<React.SetStateAction<boolean>>
    reload: boolean
}

export const CardGerenciarCadastro = ({ usuario, setModal, setIdCliente, setReload, reload }: CardGerenciarCadastroProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const dadosUsuario = obterDadosUsuario2();



    return (
        <>
            <div className="caixa1 caixaGerenciarCadastro">
                <fieldset>
                    <div className="FieldsetDivTexto fieldsetGerenciarCadastro">
                        <h5>{usuario.nome}</h5>
                        <br />
                        <p>Pessoa Fisica</p>
                    </div>
                    {!loading && <div className="divButton buttonGerenciarCadastro">
                        <Button
                            onClick={() => handleStatus(usuario.idCredenciais, 1, setLoading, reload, setReload)}
                            className="btn__verde botaoCircular cardBtn"
                            titleButton={
                                <>
                                    <AiOutlineCheckCircle className="iconButtonExtrato" />
                                    <p className="textoBotao">Aprovar</p>
                                </>
                            }
                        />
                        <Button
                            onClick={() => handleStatus(usuario.idCredenciais, 0, setLoading, reload, setReload)}
                            className="btn__vermelho botaoCircular cardBtn"
                            titleButton={
                                <>
                                    <p className="textoBotao">Reprovar</p>
                                    <AiOutlineMinusCircle className="iconButtonExtrato" />
                                </>
                            }
                        />
                    </div>
                    }
                    <Button
                        onClick={() => { setIdCliente(usuario.idCredenciais); setModal(true); }}
                        className="btn__cinza fonteBotoes"
                        titleButton="Dados Pessoais"
                    />
                </fieldset>
            </div>
        </>
    )
}