import React, { useState } from "react";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input/index";
import "../TemplateEmail/TemplateEmail.css";
import { Button } from "../../../components/Button";
import { incluirTemplateEmail } from "../../../services/gestor";
import { renovacaoToken } from "../../../services/usuario";
import { Loading } from "../../../components/Loading";
import { setDefaultResultOrder } from "dns";
import {
  guardarDadosUsuario,
  guardarDadosUsuario2,
  pegarDadosUsuario,
  obterDadosUsuario2,
  removerDadosUsuario,
  removerDadosUsuario2,
} from "../../../services/cookies";
import { useNavigate } from "react-router-dom";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { submitTemplateEmail } from "./functions";

const TemplateEmail = () => {
  const [assunto, setAssunto] = useState<string>("");
  const [conteudoHtml, setConteudoHtml] = useState<string>("");
  const [conteudoTexto, setConteudoTexto] = useState<string>("");
  const [tipo, setTipo] = useState<string>("3");
  const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<boolean>(false);

  const navigate = useNavigate();


  return !modalDesconectar ? (
    <>
      <Navbar />

      <div id="container">
        {loading && <Loading />}
        <Header titulo="Crie seu e-mail" />
        <div id="divForm" className="titulo1">
          <fieldset>
            <form>
              <h4>Assunto</h4>
              <Input
                id="txtNome"
                type="text"
                value={assunto}
                onChange={(e: any) => setAssunto(e.target.value)}
                placeholder="Assunto"
                label="Assunto"
                required
              />
              <h4>Título</h4>
              <Input
                id="txtNome"
                type="text"
                value={conteudoHtml}
                onChange={(e: any) => setConteudoHtml(e.target.value)}
                placeholder="HTML"
                label="HTML"
                required
              />
              <h4>Texto</h4>
              <Input
                id="txtNome"
                type="text"
                value={conteudoTexto}
                onChange={(e: any) => setConteudoTexto(e.target.value)}
                placeholder="Texto"
                label="Texto"
                required
              />
              <h4>Tipo</h4>
              <div className="form-floating mb-3">
                <select className="tipoSelecaoEmail">
                  <option value={"1"}>Confirmação Email Cliente</option>
                  <option value={"2"}>Confirmação Email Funcionário</option>
                  <option selected value={"3"}>
                    Redefinicao Senha
                  </option>
                </select>
              </div>
              {erro && (
                <p>
                  Algo deu errado. Verifique se todos os campos foram
                  preenchidos.
                </p>
              )}
              <Button
                titleButton="Enviar"
                className="btnAlterar"
                type="button"
                onClick={() => {
                  submitTemplateEmail(assunto, conteudoHtml, conteudoTexto, tipo, setLoading, setErro, setModalDesconectar);
                }}
              />
            </form>
          </fieldset>
        </div>
      </div>

      <Footer />
    </>
  ) : (
    <ModalDesconectar modal={modalDesconectar} />
  );
};

export default TemplateEmail;
