import React from "react";

import { BsFillPatchExclamationFill } from "react-icons/bs";

import { Button } from "../../../components/Button";

import "./Fechamento.css";

interface ErroFechamentoProps {
  handleClick: () => void;
}

export const ErroFechamento = ({ handleClick }: ErroFechamentoProps) => {
  return (
    <div className="caixa1">
      <fieldset>
        <div className="div_text_erro_fechamento">
          <BsFillPatchExclamationFill className="IconFalha_erro_fechamento" />
          <h2 className="mensagemDeErro_erroFechamento">
            Algo deu errado, Aguarde alguns instantes e tente novamente mais
            tarde.
          </h2>
        </div>
        <Button
          classNameDiv="button_erro_fechamentos"
          titleButton={"Tentar Novamente"}
          onClick={handleClick}
        />
      </fieldset>
    </div>
  );
};
