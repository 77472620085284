import React from "react";
import "./App.css";
import Routes from "./navigators/Routes/Routes";
import UsuarioProvider from "./context/tipoUsuario";

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import { ServicoFinanceiroClienteProvider } from "./context/servicoFinanceiroCliente";

const App = () => {
  return (
    <ServicoFinanceiroClienteProvider>
      <UsuarioProvider>
        <Routes />
      </UsuarioProvider>
    </ServicoFinanceiroClienteProvider>
  );
};

export default App;
