import React from "react";

export interface SelectStatusFuncionarioProps {
    setStatus: React.Dispatch<boolean>
}

export const SelectStatusFuncionario = ({ setStatus }: SelectStatusFuncionarioProps) => {
    return <select className="selectMenor" onChange={(e) => setStatus(e.target.value === "ativo" ? true : false)}>
        <option value={'ativo'}>Ativo</option>
        <option value={'inativo'}>Inativo</option>
    </select>
}