import React from "react";
import { alterarDadosCadastraisPJ } from "../../../services/cliente";
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { renovacaoToken } from "../../../services/usuario";

export const salvarAlteracoes = async (rua: string, complemento: string, bairro: string, cidade: string, estado: string, cep: string, setSucesso: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, setLoading: React.Dispatch<boolean>) => {
    setLoading(true)

    const { email, id, token, tokenRenovacao, urlAvatar } = obterDadosUsuario2()
    await alterarDadosCadastraisPJ(token, id, rua, complemento, bairro, cidade, estado, cep)
        .then(res => setSucesso(true))
        .catch(async (err) => {
            if (err.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao).then(async (res) => {
                    guardarDadosUsuario2(res.data.camposAdicionais.tokenAcesso, res.data.camposAdicionais.tokenRenovacao, email, id)

                    await alterarDadosCadastraisPJ(res.data.camposAdicionais.tokenAcesso, id, rua, complemento, bairro, cidade, estado, cep)
                        .then(res => setSucesso(true))
                        .catch(() => setErro(true))

                }).catch(() => setDesconectar(true))
            } else {
                setErro(true)
            }
        }).finally(() => setLoading(false))

}