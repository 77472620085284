import React, { useEffect, useState } from "react";
import { TabelaListarClientesPrivateProps } from "../../Tabelas/TabelaClientesPrivate";
import { obterClientes } from "./functions";
import './styles.css'

export interface SelectServicoFinanceiroProps {
    className?: string,
    setIdCliente: React.Dispatch<string>,
    idServicoFinanceiro: number,
    setLoadingGrafico: React.Dispatch<React.SetStateAction<boolean>>,
    setErrorGrafico: React.Dispatch<React.SetStateAction<boolean>>,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>
}

export interface listaClientesSelectProps {

    idCredenciais: string
    nome: string,
    email: string

}

export const SelectCliente = ({
    setIdCliente,
    idServicoFinanceiro,
    setErrorGrafico,
    setLoadingGrafico,
    setModalDesconectar,
    className
}: SelectServicoFinanceiroProps) => {

    const [numeroPagina, setNumeroPagina] = useState<number>(1);
    const [tamanhoPagina, setTamanhoPagina] = useState<number>(100);
    const [listaClientes, setListaClientes] = useState<listaClientesSelectProps[]>([]);




    useEffect(() => {

        setListaClientes([])
        !(idServicoFinanceiro === 1) &&
            obterClientes({ setListaClientes, idServicoFinanceiro, setErro: setErrorGrafico, numeroPagina, tamanhoPagina, setLoading: setLoadingGrafico, setDesconectar: setModalDesconectar })
    }, [idServicoFinanceiro])

    return (
        !(idServicoFinanceiro === 1) &&
        (

            <select
                className={className ? className : "selectMenor SelectServicoFinanceiro"}
                onChange={(e) => setIdCliente(e.target.value)}>
                <option
                    selected
                    value="">Escolha um cliente </option>
                {listaClientes.map((cliente, index) => <option key={cliente.idCredenciais}
                    value={cliente.idCredenciais}>{cliente.nome}</option>)}
            </select>


        )

    )
}