import { api } from './api'

export const operacaoFinanceiraAporteSaque = (
  idCredenciaisCliente: string,
  idServicoFinanceiroConta: number,
  valor: number,
  tipo: string,
  token: string | boolean,
  comprovante?: string | ArrayBuffer | null
) => {
  const url = "/OperacaoFinanceira";
  let dataSolicitacao = new Date();
  let imagemComprovante: string = comprovante ? comprovante.toString() : null

  const operacaoFinanceira = {
    dataSolicitacao,
    valor,
    tipo,
    imagemComprovante,
  };

  return api.post(
    url,
    { idCredenciaisCliente, idServicoFinanceiroConta, operacaoFinanceira },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const operacaoFinanceiraAprovacao = (
  idCredenciaisCliente: string,
  idServicoFinanceiro: number,
  dataSolicitacao: Date,
  status: string,
  valor: number,
  tipo: string,
  token: string | boolean,
  quantidadeCotas?: number,
  comprovante?: string | ArrayBuffer
) => {
  const url = "/OperacaoFinanceira";
  let dataExecucao: Date = new Date();
  let imagemComprovante: string = comprovante ? comprovante.toString() : "";

  const operacaoFinanceira = {
    dataSolicitacao,
    dataExecucao,
    valor,
    tipo,
    status,
    quantidadeCotas,
    imagemComprovante,
  };

  return api.put(
    url,
    { idCredenciaisCliente, idServicoFinanceiro, operacaoFinanceira },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};
