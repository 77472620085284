import ImagemOmegaImpressao from "../../../assets/img/logo.png";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { obterExtratoDoCliente } from "../../../services/cliente";
import { guardarDadosUsuario, guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { renovacaoToken } from "../../../services/usuario";
import { RequisicaoExtrato } from "./Extrato";

export const criaPDF = () => {
    const minhaTabela = document.getElementById("tblTransacoes")!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
        style +
        "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
        style +
        "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABEÇALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(
        `<div><img src="${ImagemOmegaImpressao}"></img></div>`
    );
    win.document.write("<table>");
    win.document.write(minhaTabela);
    var removerColuna = win.document.getElementById("colunaComprovante");  //SELECIONA O HEADER DE COMPROVANTE
    removerColuna.parentNode.removeChild(removerColuna);  //REMOVE O HEADER DE COMPROVANTE
    const removerDadosColuna = win.document.querySelectorAll('.verComprovante'); //REMOVE OS BOTOES DE COMPROVANTE
    removerDadosColuna.forEach(verComprovante => {
        verComprovante.remove();
    });

    const TextoSelectData = win.document.querySelector('.select-data div button strong').textContent
    win.document.querySelector('.select-data').innerHTML = TextoSelectData
    // console.log(selectData)
    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    win.document.close(); // FECHA A JANELA
    setTimeout(function () {
        win.print(); // IMPRIME O CONTEUDO
    }, 500);
};

export const exibirExtrato = async (
    dataInicial: Date,
    dataFinal: Date,
    setDados: React.Dispatch<React.SetStateAction<RequisicaoExtrato[]>>,
    setErroPage: React.Dispatch<React.SetStateAction<boolean>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo

) => {
    setLoading(true);
    await obterExtratoDoCliente(
        obterDadosUsuario2().id,
        usuarioServicoFinanceiro.idServicoFinanceiro,
        obterDadosUsuario2().token,
        dataInicial,
        dataFinal
    )
        .then((res) => {
            setDados(res.data.resultado.extrato);
            setErroPage(false);
            setLoading(false);
        })
        .catch(async (e) => {
            let token = obterDadosUsuario2().token;
            let tokenRenovacao = obterDadosUsuario2().tokenRenovacao;
            let email = obterDadosUsuario2().email;
            let id = obterDadosUsuario2().id;
            if (e.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res2) => {
                        guardarDadosUsuario(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString()
                        );
                        await obterExtratoDoCliente(
                            id,
                            1,
                            res2.data.camposAdicionais.tokenAcesso,
                            dataInicial,
                            dataFinal
                        )
                            .then((res2) => {
                                setDados(res2.data.resultado.extrato);
                                setErroPage(false);
                                setLoading(false);
                            })
                            .catch((e2) => {
                                setErroPage(true);
                                setLoading(false);
                            });
                    })
                    .catch((err3) => {
                        setModalDesconectar(true);
                    });
            } else {
                setErroPage(true);
                setLoading(false);
            }
        });
};

export function proximoSlide(idButton?: string) {
    document.getElementById(idButton ? idButton : 'nextArrowSlide').click();
}

export function voltarSlide(idButton?: string) {
    document.getElementById(idButton ? idButton : 'prevArrowSlide').click();
}