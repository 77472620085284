import React from "react";
import { buscarDadosCep } from "../../../services/api";
import { atualizarDadosPessoaisPF } from "../../../services/cliente";
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { renovacaoToken } from "../../../services/usuario";


export const checkCEP = async (
    e: string,
    setEndereco: (value: React.SetStateAction<string>) => void,
    setBairro: (value: React.SetStateAction<string>) => void,
    setCidade: (value: React.SetStateAction<string>) => void,
    setEstado: (value: React.SetStateAction<string>) => void,
    cep: string,
) => {
    buscarDadosCep(e).then((res) => {
        setEndereco(res.data.logradouro);
        setBairro(res.data.bairro);
        setCidade(res.data.localidade);
        setEstado(res.data.uf);
    });
};

export const alterarDadosPessoais = async (dadosUsuario: any, fotoDoUsuario: string, ruaUsuario: string, complementoDoEndereco: string, bairroUsuario: string, cidadeUsuario: string, estadoUsuario: string, cepUsuario: string, profissaoUsuario: string, rendaUsuario: number, estadoCivilUsuario: string, setLoading: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setSucesso: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, reload: () => void) => {
    setLoading(true)
    const { email, id, token, tokenRenovacao } = obterDadosUsuario2()

    const fotoUsuario = fotoDoUsuario || fotoDoUsuario !== '' ? fotoDoUsuario : dadosUsuario.fotoUsuario
    const rua = ruaUsuario || ruaUsuario !== '' ? ruaUsuario : dadosUsuario?.rua;
    const complemento = complementoDoEndereco || complementoDoEndereco !== '' ? complementoDoEndereco : dadosUsuario?.complemento;
    const bairro = bairroUsuario || bairroUsuario !== '' ? bairroUsuario : dadosUsuario?.bairro;
    const cidade = cidadeUsuario || cidadeUsuario !== '' ? cidadeUsuario : dadosUsuario?.cidade;
    const estado = estadoUsuario || estadoUsuario !== '' ? estadoUsuario : dadosUsuario?.estado;
    const cep = cepUsuario || cepUsuario !== '' ? cepUsuario : dadosUsuario?.cep;
    const profissao = profissaoUsuario || profissaoUsuario !== '' ? profissaoUsuario : dadosUsuario?.profissao;
    const renda = rendaUsuario || rendaUsuario !== 0 ? rendaUsuario : dadosUsuario?.renda;
    const estadoCivil = estadoCivilUsuario || estadoCivilUsuario !== '' ? estadoCivilUsuario : dadosUsuario?.estadoCivil;

    await atualizarDadosPessoaisPF(id, token, fotoUsuario, rua, complemento, bairro, cidade, estado, cep, profissao, renda, estadoCivil).then(() => {
        setSucesso(true)
        reload()
    }).catch(err => {
        if (err.toString() === "Error: Request failed with status code 401") {
            renovacaoToken(token, tokenRenovacao).then(res => {
                guardarDadosUsuario2(res.data.camposAdicionais.tokenAcesso, res.data.camposAdicionais.tokenRenovacao, email, id)
                atualizarDadosPessoaisPF(id, res.data.camposAdicionais.tokenAcesso, fotoUsuario, rua, complemento, bairro, cidade, estado, cep, profissao, renda, estadoCivil).then(() => {
                    setSucesso(true)
                    reload()
                })
            }).catch(() => setDesconectar(true))
        } else {
            setErro(true)
        }
    }).finally(() => setLoading(false))


}

export const handleSubmit = (event: React.FormEvent, dadosUsuario: any, fotoUsuario: string, rua: string, complemento: string, bairro: string, cidade: string, estado: string, cep: string, profissao: string, renda: number, estadoCivil: string, setLoading: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setSucesso: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, reload: () => void) => {
    event?.preventDefault();
    alterarDadosPessoais(dadosUsuario, fotoUsuario, rua, complemento, bairro, cidade, estado, cep, profissao, renda, estadoCivil, setLoading, setErro, setSucesso, setDesconectar, reload)

}

export function handleMaskCEP(
    e: any,
    setRua: (value: React.SetStateAction<string>) => void,
    setBairro: (value: React.SetStateAction<string>) => void,
    setEstado: (value: React.SetStateAction<string>) => void,
    setCidade: (value: React.SetStateAction<string>) => void,
    setMaskCEP: (value: React.SetStateAction<string>) => void,
    CEP: string
) {
    e = e.replace(/\D/g, "");
    checkCEP(e, setRua, setBairro, setCidade, setEstado, CEP);
    e = e.replace(/^(\d{2})(\d)/g, "$1.$2");
    e = e.replace(/(\d{3})(\d)/, "$1-$2");

    setMaskCEP(e);
}

export function handleMaskRenda(
    e: string,
    setRendaMask: (value: React.SetStateAction<string>) => void,
    setRenda: React.Dispatch<number>
) {
    e = e.replace(/[^,.0-9]/g, "");
    e = e.replace(",", ".");
    var count = 0;
    for (let i = 0; i <= e.length; i++) {
        if (e[i] === ".") {
            count = count + 1;
            if (count >= 2) {
                e = e.replace(/[.]/, "");
            }
        }
    }
    setRenda(Number(e))
    e = "R$ " + e;
    setRendaMask(e);

}

export const limparCampos = (
    setRua: React.Dispatch<string>,
    setComplemento: React.Dispatch<string>,
    setBairro: React.Dispatch<string>,
    setCidade: React.Dispatch<string>,
    setEstado: React.Dispatch<string>,
    setCep: React.Dispatch<string>,
    setProficao: React.Dispatch<string>,
    setRenda: React.Dispatch<number>,
    setMaskRenda: React.Dispatch<string>,
    setMaskCEP: React.Dispatch<string>,
    setEstadoCivil: React.Dispatch<string>,
    setErro: React.Dispatch<boolean>,
    setSucesso: React.Dispatch<boolean>) => {
    setRua('')
    setComplemento('')
    setBairro('')
    setCidade('')
    setEstado('')
    setCep('')
    setProficao('')
    setRenda(0)
    setEstadoCivil('')
    setMaskCEP('')
    setMaskRenda('')
    setErro(false)
    setSucesso(false)
}