import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight, AiFillPrinter } from "react-icons/ai";
import Slider from "react-slick";
import { Button } from "../../../components/Button";
import { CardListarUsuariosApp } from "../../../components/Cards/CardListarUsuariosApp";
import { ErroTabela } from "../../../components/Erros/ErroTabela";
import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import { LoadingButton } from "../../../components/loadingButtom";
import Navbar from "../../../components/Navbar/Navbar";
import { TabelaListarClientesApp } from "../../../components/Tabelas/TabelaListarClientesApp";
import { criaPDF, proximoSlide, voltarSlide } from "../../Cliente/Extrato/functions";
import { FuncoesListarClientesApp } from "./functions";


const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    respondTo: 'slider',
    rows: 3,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>
};

export interface ClienteApp {
    Id: string,
    Nome: string,
    Email: string,
    UserName: string,
}

export const ListarClientesApp = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [erro, setErro] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [listaClientesApp, setListaClientesApp] = useState<ClienteApp[]>([]);

    const { obterTodosUsuarioApp, criaPDFUsersApp } = FuncoesListarClientesApp({ setErro, setListaClientesApp, setLoading })


    useEffect(() => {
        obterTodosUsuarioApp()
    }, [reload])

    return (
        <>
            <Navbar />

            <div id="container">
                <Header titulo="Usuários App" />
                <div className="desktopListarUsuarios">
                    <div className="caixa1">
                        <fieldset>
                            {loading ?
                                <div className="caixa1">
                                    <LoadingButton />
                                </div>
                                :
                                <>
                                    {erro ? <ErroTabela className="formatacaoTabela" handleClick={() => setReload(!reload)} /> :
                                        <table className="tabela" id="tabelaUsersApp">
                                            <thead>
                                                <tr className="gerenciarCadastroTabela">
                                                    <th className="table-secondary tableTitulo tableData colunas">
                                                        <strong>Nome</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableCotas colunas">
                                                        <strong>Email</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableCotas colunas">
                                                        <strong>UserName</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    listaClientesApp.length !== 0 ? (
                                                        listaClientesApp.map(({ Id, Nome, Email, UserName }) =>
                                                            <TabelaListarClientesApp key={Id} nome={Nome} email={Email} userName={UserName} />
                                                        )) : (
                                                            <tr>
                                                                <td colSpan={5} style={{ textAlign: "center", }}>
                                                                    <p>Nenhum resultado encontrado no momento.</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </>
                            }
                        </fieldset>
                    </div>
                </div>
                <div className="mobileListarUsuarios">
                    <div className="container-mobile-ListarUsuarios">

                        {erro ?
                            <div className="caixa1">
                                <ErroTabela className="formatacaoGrafico" handleClick={() => setReload(!reload)} />
                            </div>
                            :
                            <>
                                {loading ?
                                    <div className="caixa1">
                                        <LoadingButton />
                                    </div>
                                    :
                                    <div className="caixa1">
                                        <fieldset  >

                                            <div className="divBotoesExtratoMobile">
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => voltarSlide("prevArrowSlideListaUsuarios")}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowLeft className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                                <Button
                                                    className="btn__laranja btnMargin"
                                                    onClick={criaPDFUsersApp}
                                                    titleButton={'Imprimir'}
                                                />
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => proximoSlide('nextArrowSlideListaUsuarios')}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowRight className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <Slider {...settings}>
                                                {
                                                    listaClientesApp.length !== 0 ? (
                                                        listaClientesApp.map(({ Id, Nome, Email, UserName }) =>
                                                            <CardListarUsuariosApp key={Id} nome={Nome} email={Email} userName={UserName} />
                                                        )) : (
                                                            <p>Nenhum resultado encontrado no momento.</p>
                                                        )
                                                }
                                            </Slider>
                                        </fieldset>
                                    </div>
                                }
                            </>
                        }
                    </div>

                </div>
                <Button
                    className="btnAlterar btnMargin desktopExtrato"
                    onClick={criaPDFUsersApp}
                    style={{ display: "inline-flex" }}
                    titleButton={
                        <>
                            <AiFillPrinter className="iconButton" />
                            Imprimir
                        </>
                    }
                />

            </div>

            <Footer />
        </>
    )
}