import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./AporteSaque.css";
import { ModalAporte } from "../../../components/modais/ModalAporte";
import { ModalSaque } from "../../../components/modais/ModalSaque";
import { Header } from "../../../components/Header";
import { Button } from "../../../components/Button";
import { obterDadosUsuario2 } from "../../../services/cookies";

export interface Dados {
    token: string;
    tokenRenovacao: string;
    email: string;
    id: string;
}

const AporteSaque = () => {
    const [modalAporte, setModalAporte] = useState<boolean>(false);
    const [modalSaque, setModalSaque] = useState<boolean>(false);
    let dados: Dados = obterDadosUsuario2();
    return (
        <>
            <Navbar />
            <div id="container">
                <Header titulo={"Aporte / Saque"} />
                <div className="caixa1">
                    <fieldset>
                        <div className="FieldsetDivTexto">
                            <h5>Escolha a transação e preencha as informações para realizar sua solicitação:</h5>
                            <br />
                        </div>
                        <div className="divButton">
                            <Button onClick={() => setModalAporte(true)} titleButton="Aporte" />
                            <ModalAporte modal={modalAporte} dadosUsuario={dados} setModal={() => setModalAporte(false)} />
                            <Button onClick={() => setModalSaque(true)} titleButton="Saque" />
                            <ModalSaque modal={modalSaque} setModal={() => setModalSaque(false)} dadosUsuario={dados} />
                        </div>
                    </fieldset>
                </div>
            </div>
        </>
    );
};

export default AporteSaque;