import React, { useState } from "react";
import "./styles.css";
import { confirmar, handleReset } from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { Cotacoes } from "../../../pages/Gestor/Fechamento/Fechamento";

import Modal from "react-bootstrap/Modal";

interface modalProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
  handleConfirm: (ativo: Cotacoes) => void;
}

export const ModalNovoAtivoFechamento = ({
  modal,
  setModal,
  handleConfirm,
}: modalProps) => {
  const [nome, setNome] = useState<string>("");
  const [quantidade, setQuantidade] = useState<string>("");
  const [valor, setValor] = useState<string>("");
  const [sigla, setSigla] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  return (
    <Modal show={modal} onHide={() => handleReset(setNome, setSigla, setValor, setQuantidade, setError, setModal)}>
      <Modal.Header closeButton>
        <Modal.Title>Novo Ativo Financeiro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          label="Nome:"
          placeholder="Nome:"
          id={"nome"}
          onChange={(e) => setNome(e.target.value)}
          value={nome}
        />
        <Input
          label="Sigla:"
          placeholder="Sigla:"
          id={"sigla"}
          onChange={(e) => setSigla(e.target.value)}
          value={sigla}
        />
        <Input
          label="Quantidade:"
          placeholder="Quantidade:"
          id={"quantidade"}
          onChange={(e) => setQuantidade(e.target.value.replace(",", ".").replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))}
          value={quantidade}
        />
        <Input
          label="Valor unitário:"
          placeholder="Valor unitário:"
          id={"valor"}
          onChange={(e) => setValor(e.target.value.replace(",", ".").replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))}
          value={valor}
        />
        {error && (
          <div className="div-mensagem-erro-modal-fechamento">
            <span className="mensagem-erro-modal-fechamento">
              Verifique os campos acima
            </span>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          classNameButton="btn__laranja"
          onClick={() => confirmar(setError, setNome, setSigla, setValor, setQuantidade, setModal, nome, quantidade, valor, sigla, handleConfirm)}
          titleButton="Confirmar"
        />
        <Button
          classNameButton="btnCancelar"
          onClick={() => setModal(false)}
          titleButton="Fechar"
        />
      </Modal.Footer>
    </Modal>
  );
};
