import { solicitarRedefinicaoDeSenha } from "../../../services/usuario";
import { Mensagem } from "./ModalRedefinicaoSenha";


export const handleFechar = (
    e: React.FormEvent,
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setEmail: (value: React.SetStateAction<string>) => void
) => {
    e.preventDefault();
    setSucesso(false);
    setEmail("");
};

export const solicitarRedefinicao = (
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    email: string,
    setMensagem: (value: React.SetStateAction<Mensagem>) => void,
    mensagem: Mensagem

) => {
    solicitarRedefinicaoDeSenha(email)
        .then((res) => {
            setSucesso(true);
        })
        .catch((error) => {
            setMensagem({
                ...mensagem,
                mostrar: true,
                mensagem:
                    "Não foi possível realizar sua solicitação, verifique seu e-mail e tente novamente mais tarde.",
            });
        });
};
