import React, { useState } from "react";
import './ModalVerDocumento.css';

import Modal from "react-bootstrap/Modal";

interface modalProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    urlImagemDocumento: string;
}
export const ModalVerDocumento = ({
    modal,
    setModal,
    urlImagemDocumento,
}: modalProps) => {

    const [tamanhoImagem, setTamanhoImagem] = useState<boolean>(false);
    console.log(urlImagemDocumento);


    return (
        <Modal show={modal} onHide={() => setModal(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Ver Documento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="imagemModalComprovante">
                    <iframe
                        src={urlImagemDocumento}
                        className='pdfComprovante'
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};
