import React, { useState, useContext, useEffect } from "react";
import "./Navbar.css";

import SideBar from "../Sidebar/Sidebar";
import { LoadingButton } from "../../components/loadingButtom";
import { UsuarioContextType, usuarioContext } from "../../context/tipoUsuario"

import ImagemUsuario from "../../assets/img/default_user.png";
import SidebarImage from "../../assets/img/omega_header_menu.png";

import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineClose } from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";

// import { usuarioContext, UsuarioContextType } from "../../context/tipoUsuario";


// import { useLogout } from "../../functions/Logout";

import {
  removerDadosUsuario,
  removerDadosUsuario2,
  obterDadosUsuario2,
  apagarPerfilUsuario,
  apagarTipoUsuario,
  obterServiçosFinanceiros
} from "../../services/cookies";
import { ServicoFinanceiroClienteContext, usuarioServicoFinanceiroContextType, usuarioServicoFinanceiroTipo } from "../../context/servicoFinanceiroCliente";

const Navbar = () => {
  const [sidebar, setSidebar] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const fotoPerfil = obterDadosUsuario2().urlAvatar;
  const srcFotoPerfil = fotoPerfil == "null" || !fotoPerfil ? ImagemUsuario : fotoPerfil

  const servicosFinanceiro: usuarioServicoFinanceiroTipo[] = obterServiçosFinanceiros();

  const { removerUsuario } = useContext<UsuarioContextType>(usuarioContext);
  const { atualizarServicoFinanceiro, usuarioServicoFinanceiro } = useContext<usuarioServicoFinanceiroContextType>(ServicoFinanceiroClienteContext);

  // const { atualizarUsuario } = useContext<UsuarioContextType>(usuarioContext);

  const navigate = useNavigate();

  const logout = () => {
    removerDadosUsuario();
    removerDadosUsuario2();
    apagarPerfilUsuario()
    apagarTipoUsuario()
    navigate("/");
  };
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <Link to="#" className="manu-bars">
            <FaBars onClick={showSidebar} color={"#313a46"} />
          </Link>
          <div className='servicoFinanceiro'>
            <p>
              {usuarioServicoFinanceiro.servicoFinanceiro}
            </p>
            <Dropdown >
              {!loadingButton ?
                <Dropdown.Toggle variant="tranparent" id="dropdown-basic">
                  <img
                    alt="user_image"
                    src={srcFotoPerfil}
                    className="imagemUsuario"
                  />
                </Dropdown.Toggle>
                :
                <LoadingButton />
              }
              <Dropdown.Menu>
                {servicosFinanceiro && servicosFinanceiro.map((item) => {
                  return (
                    <Dropdown.Item key={item.idServicoFinanceiro} onClick={() => atualizarServicoFinanceiro(item)}>
                      {item.servicoFinanceiro}
                    </Dropdown.Item>
                  )
                })}
                <Dropdown.Item href="/dados-cadastrais">
                  Dados Cadastrais
                </Dropdown.Item>

                <Dropdown.Item onClick={logout}>
                  Logout
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <nav
          style={{ zIndex: "999" }}
          className={sidebar ? "nav-menu active" : "nav-menu"}
        >
          <ul
            style={{ paddingLeft: "0px" }}
            className="nav-menu-itens"
            onClick={showSidebar}
          >
            <li className="navbar-toggle">
              <div className="sidebar-header">
                <img
                  alt="side_bar"
                  src={SidebarImage}
                  className="d-xl-block img-fluid logo"
                />
              </div>
              <Link to="#" className="menu-bars">
                <AiOutlineClose />
              </Link>
            </li>
          </ul>
        </nav>
        <SideBar sidebar={sidebar} showSidebar={showSidebar} />
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
