import React, { useState } from 'react'

import './styles.css'

import { Arrow } from '../../arrow';

import AnimateHeight from 'react-animate-height';
import { useNavigate } from 'react-router-dom'
import { CardSubItemSidebar } from '../CardSubItemSidebar';

export interface CardSidebarProps {
    title: string;
    path?: string;
    itens?: CardSidebarProps[];
    subItens?: CardSidebarProps[];
}

export const CardSidebar = ({ title, itens, path }: CardSidebarProps) => {
    const [itensOpen, setItensOpen] = useState<boolean>(false)
    const [subItensOpen, setSubItensOpen] = useState<boolean>(false)
    const [arrowColor, setArrowColor] = useState<string>('white')

    const navigate = useNavigate()

    return (
        <>
        <li onClick={() => path ? navigate(path) : setItensOpen(!itensOpen)}>
            <span onMouseEnter={() => setArrowColor('#f7931b')} onMouseLeave={() => setArrowColor('white')} className='nav-text margemItensSidebar'>{title} {itens && <Arrow className='arrow-cardsidebar' color={arrowColor} down={!itensOpen} />}</span>
        </li>
            <AnimateHeight
                duration={500}
                height={itensOpen ? 'auto' : 0}
            >
                {itens && itens.map((item, index) => (
                    item.subItens
                        ?
                        <CardSubItemSidebar key={index} path={item.path} subItens={item.subItens} title={item.title} />
                            :
                    <p key={index} className='nav-text nav-subtext' onClick={() => item.path ? navigate(item.path) : setItensOpen(!itensOpen)}>{item.title}</p>
                ))}
            </AnimateHeight>
            </>
    )

}