import React from 'react'

import './styles.css'

import { Button } from '../../Button';

import Modal from "react-bootstrap/Modal";
import { BsFillPatchCheckFill } from 'react-icons/bs';

interface SucessoProps {
    handleClose: () => void;
}

export const Sucesso = ({ handleClose }: SucessoProps) => {
    return (
        <>
            <Modal.Body className='caixa-sucesso-ModalAlterarDadosCadastrais'>
                <BsFillPatchCheckFill className='icone-sucesso-ModalAlterarDadosCadastrais' />
                <p>Dados cadastrais atualizados com sucesso.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} titleButton="Fechar" />
            </Modal.Footer>
        </>
    )
}