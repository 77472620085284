import React, {
  useContext,
  useEffect,
  PropsWithChildren,
  useState,
} from "react";

import { useNavigate, Navigate, useLocation } from "react-router-dom";

import { usuarioContext, UsuarioContextType } from "../../context/tipoUsuario";
import { renovacaoToken } from "../../services/usuario";
import {
  guardarDadosUsuario,
  guardarDadosUsuario2,
  obterDadosUsuario2,
  removerDadosUsuario,
  removerDadosUsuario2,
} from "../../services/cookies";

// import { Loading } from "../../components/Loading";

// import { useLogout } from "../../functions/Logout";

interface PrivateRouteProps {
  children: PropsWithChildren<JSX.Element>;
}

interface usuarioProps {
  token: string;
  tokenRenovacao: string;
  email: string;
  id: string;
}

export const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  const [carregado, setCarregando] = useState(false);

  // const { usuario, atualizarUsuario, removerUsuario } =
  //   useContext<UsuarioContextType>(usuarioContext);

  let location = useLocation();

  const navigate = useNavigate();
  // const location = useLocation();

  // const logout = useLogout();

  // useEffect(() => {
  //   verificarToken();
  // }, []);

  const verificarToken = async () => {
    const { email, id } = obterDadosUsuario2();
    await renovacaoToken(
      obterDadosUsuario2().token,
      obterDadosUsuario2().tokenRenovacao
    )
      .then(async (res) => {
        guardarDadosUsuario2(
          res.data.camposAdicionais.tokenAcesso,
          res.data.camposAdicionais.tokenRenovacao,
          email,
          id
        );
        guardarDadosUsuario(
          res.data.camposAdicionais.tokenAcesso,
          res.data.camposAdicionais.tokenRenovacao,
          email,
          id
        );
      })
      .catch(() => {
        // atualizarUsuario({ tipo: null, autenticado: false });
        removerDadosUsuario();
        removerDadosUsuario2();
        // setCarregando(true);
        navigate("/");
      })
      .finally(() => setCarregando(true));
    // }
  };

  return <>{children}</>;
};
