import React, { useContext } from "react";

import './Sidebar.css'

import { obterPerfilUsuario } from "../../services/cookies";
import { UsuarioContextType, usuarioContext } from '../../context/tipoUsuario'
import SidebarImage from "../../assets/img/omega_header_menu.png";

import { ItensCliente, ItensFuncionario, ItensGestor } from './ItensSideBar'


import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { CardSidebar } from "../Cards/CardSidebar";



const SideBar = ({ sidebar, showSidebar }: any) => {
  const perfil = obterPerfilUsuario()

  return (
    <nav
      style={{ zIndex: "999" }}
      className={sidebar ? "nav-menu active" : "nav-menu"}
    >
      <ul
        style={{ paddingLeft: "0px" }}
        className="nav-menu-itens"
      >
        <li className="navbar-toggle">
          <div className="sidebar-header">
            <img src={SidebarImage} className="d-xl-block img-fluid logo" />
          </div>
          <Link to="#" className="menu-bars" onClick={showSidebar}>
            <AiOutlineClose />
          </Link>
        </li>
        {perfil === "Administrador" && ItensGestor.map((item, index) => {
          return (
            <CardSidebar key={index} title={item.title} itens={item.itens} path={item.path} />
          );
        })}
        {perfil === "Funcionario" && ItensFuncionario.map((item, index) => {
          return (
            <CardSidebar key={index} title={item.title} itens={item.itens} path={item.path} />
          );
        })}
        {(perfil === "UsuarioAssessoria" || perfil === "UsuarioCustodia") && ItensCliente.map((item, index) => {
          return (
            <CardSidebar key={index} title={item.title} path={item.path} />
          );
        })}
      </ul>
    </nav >
  );
};

export default SideBar;
