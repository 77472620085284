import React from "react";

interface SelectTipoCahavePixProps {
  selectType: (type: string) => void;
  value: string
}

export const SelectTipoCahavePix = ({
  selectType, value, ...rest
}: SelectTipoCahavePixProps) => {
  return (
    <select
      {...rest}
      value={value}
      id="select-tipo-pix"
      className="form-select"
      onChange={(e) => selectType(e.target.value)}
      placeholder={"Chave Pix"}
    >
      <option value={""}>Chave Pix</option>
      <option value={"CPF/CNPJ"}>CPF/CNPJ</option>
      <option value={"Celular"}>Celular</option>
      <option value={"E-mail"}>E-mail</option>
      <option value={"Chave aleatória"}>Chave aleatória</option>
    </select>
  );
};
