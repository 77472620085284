import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import { MensagemGraficoVazio } from "../MensagemGraficoVazio";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    TooltipOptions,
    TooltipItem,
    BarElement,
} from 'chart.js';

import "./styles.css";
import AnimateHeight from "react-animate-height";
import { BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import { _DeepPartialObject } from "chart.js/types/utils";

interface GraficoBarraProps {
    tituloGrafico: string,
    labels: string[],
    nomeDado: string,
    porcentagensDado: number[];
    colorArray: string[]
}

export const GraficoBarra = ({
    tituloGrafico,
    labels,
    nomeDado,
    porcentagensDado,
    colorArray
}: GraficoBarraProps) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const dados = {
        labels,
        datasets: [
            {
                label: nomeDado,
                data: porcentagensDado,
                borderColor: colorArray ? colorArray : 'rgb(0, 0, 0, 0.5)',
                backgroundColor: colorArray ? colorArray : 'rgba(0, 0, 0, 0.8)',
            },
        ],
    };

    const [dashboardAberta, setDashboardAberta] = useState(true);

    return (
        <div className="section">
            <div
                className="section-header"
                onClick={() => setDashboardAberta(!dashboardAberta)}
            >
                <h3>{tituloGrafico}</h3>
                <span>
                    {dashboardAberta ? (
                        <BsCaretUpFill className="arrowUp" />
                    ) : (
                        <BsCaretDownFill className="arrowDown" />
                    )}
                </span>
            </div>
            <AnimateHeight
                duration={500}
                height={dashboardAberta ? 'auto' : 0}
            >
                {porcentagensDado.length ?
                    <div className="divDashboard">
                        <Bar options={{
                            indexAxis: 'y',
                            elements: {
                                bar: {
                                    borderWidth: 0,
                                },
                            },
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'right',
                                    display: false,
                                },
                                title: {
                                    display: true,
                                    text: tituloGrafico,
                                },
                            },
                        }} data={dados} height={'70%'} />
                    </div>
                    :
                    <MensagemGraficoVazio />
                }
            </AnimateHeight>
        </div>
    );
};