import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { incluirFuncionario } from "../../../services/gestor";
import { renovacaoToken } from "../../../services/usuario";
import { verificarSeEmailEValido } from "../../Cliente/Cadastro/functions";

export const handleFile = (event: any, setImagemPerfil: React.Dispatch<any>) => {
    let file = event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        setImagemPerfil(reader.result);
    };
};

export const handleConfirm = async (
    event: any,
    nome: string,
    userName: string,
    email: string,
    imagemPerfil: string,
    setLoading: React.Dispatch<boolean>,
    setSucesso: React.Dispatch<boolean>,
    erro: any,
    setErro: React.Dispatch<any>,
    setModalDesconectar: React.Dispatch<boolean>,
) => {
    event.preventDefault();
    setLoading(true);

    if (verificarSeEmailEValido(email)) {
        if (nome.trim() && userName.trim() && email.trim()) {
            const data = new Date();
            await incluirFuncionario(
                nome,
                userName,
                email,
                imagemPerfil,
                data,
                obterDadosUsuario2().token
            )
                .then((res) => {
                    setLoading(false);
                    setSucesso(true);
                })
                .catch((err) => {
                    if (err.toString() === "Error: Request failed with status code 401") {
                        renovacaoToken(
                            obterDadosUsuario2().token,
                            obterDadosUsuario2().tokenRenovacao
                        )
                            .then(async (res2) => {
                                guardarDadosUsuario2(
                                    res2.data.camposAdicionais.tokenAcesso,
                                    res2.data.camposAdicionais.tokenRenovacao,
                                    email,
                                    obterDadosUsuario2().id.toString()
                                );
                                await incluirFuncionario(
                                    nome,
                                    userName,
                                    email,
                                    imagemPerfil,
                                    data,
                                    res2.data.camposAdicionais.tokenAcesso
                                ).then(() => {
                                    setLoading(false);
                                    setSucesso(true);
                                });
                            })
                            .catch(() => {
                                setModalDesconectar(true);
                            });
                    } else {
                        let mensagem =
                            "Algo deu errado aguarde um instante e tente novamente mais tarde.";
                        if (err.response.data.title) {
                            switch (err.response.data.status) {
                                case 400:
                                    mensagem =
                                        "Algo deu errado. Verifique se todos os campos foram preenchidos.";
                                    break;
                                default:
                                    mensagem =
                                        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
                                    break;
                            }
                        }

                        if (err.response.data.codigo) {
                            switch (err.response.data.codigo) {
                                case 400:
                                    err.response.data.mensagem ===
                                        "Funcionário já existe em nossa base de dados"
                                        ? (mensagem = "Esse Email já está sendo utilizado.")
                                        : (mensagem = "Esse Username já está sendo utilizado.");
                                    break;
                                case 500:
                                    mensagem =
                                        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
                                    break;
                                case 501:
                                    mensagem =
                                        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
                                    break;
                                case 502:
                                    mensagem =
                                        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
                                    break;
                                case 503:
                                    mensagem =
                                        "Algo deu errado aguarde um instante e tente novamente mais tarde.";
                                    break;
                                default:
                                    mensagem =
                                        "Algo deu errado. Verifique se todos os campos foram preenchidos.";
                                    break;
                            }
                        }

                        setErro({
                            ...erro,
                            erro: true,
                            mensagem,
                        });
                        setLoading(false);
                    }
                }).finally(() => { setLoading(false) });
        } else {
            setLoading(false);
            setErro({
                ...erro,
                erro: true,
                mensagem: "Preencha todos os campos.",
            });
        }
    } else {
        setLoading(false);
        setErro({
            erro: true,
            mensagem: "O e-mail digitado não é válido.",
        });
    }

};
