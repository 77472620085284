import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { Button } from "../../Button";
import { ModalHistoricoFechamento } from "../../modais/ModalHistoricoFechamento";
import { Fechamentos } from "../../../pages/Gestor/HistoricoFechamento/HistoricoFechamento";
import { DateFormatter } from "../../../functions/DateFormatter"
import { mascaraBRL, mascaraUSD } from "../../../functions/MascarasMonetarias";


interface TabelaHistoricoFechamentoProps {
    idCredenciais?: string;
    dados: Fechamentos;
}

export const TabelaHistoricoFechamento = ({
    idCredenciais,
    dados
}: TabelaHistoricoFechamentoProps) => {

    const [comprovanteModal, setComprovanteModal] = useState<boolean>(false);

    const exibirPDF = () => {
        setComprovanteModal(true);
    };

    return (
        <>
            <tr id="trTabelaComDados" className="hoverTabelaValidacaoAporte">
                <th className="table-secondary tableConteudo tableData ">
                    <strong>{DateFormatter(dados.data)}</strong>
                </th>
                <th className="table-secondary tableConteudo tableTipo ">
                    <strong>{idCredenciais ? "$ " + mascaraUSD(dados.valorTotalDolar.toFixed(2)) : "R$ " + mascaraBRL(dados.valorTotalReal.toFixed(2))}</strong>
                </th>
                <th className="table-secondary tableConteudo tableOperacao ">
                    <strong>{dados.responsavel ? dados.responsavel.nome : ""}</strong>
                </th>
                <th className="tableConteudoStatus table-secondary tableOperacao verComprovante">
                    <Button
                        className="btn__cinza buttonComprovante bgTransparente"
                        onClick={exibirPDF}
                        titleButton={
                            <>
                                <AiFillEye className="iconButton " />
                                <strong className="marginButton"></strong>
                            </>
                        }
                    />
                </th>
            </tr>

            {comprovanteModal && (
                <ModalHistoricoFechamento
                    modal={comprovanteModal}
                    setModal={setComprovanteModal}
                    cotacoes={dados.ativosFinanceiros}
                    valorTotalReal={dados.valorTotalReal}
                    valorTotalDolar={dados.valorTotalDolar}
                    valorCota={dados.valorCota}
                />
            )}

        </>
    );
};
