import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../Button'
import { AiOutlineSolution, AiOutlineHistory, AiOutlineLineChart } from 'react-icons/ai';

interface TabelaListarUsuariosProps {
    nome: string;
    setNomeCliente: React.Dispatch<string>;
    email: string;
    ativo: string;
    servico: string;
    idCliente: string;
    setModalDadosPessoais: React.Dispatch<React.SetStateAction<boolean>>;
    setModalHistoricoFinanceiro: React.Dispatch<React.SetStateAction<boolean>>;
    setIdCliente: React.Dispatch<string>;
}

export const TabelaListarUsuarios = ({ nome, setNomeCliente, email, servico, ativo, idCliente, setModalDadosPessoais, setModalHistoricoFinanceiro, setIdCliente }: TabelaListarUsuariosProps) => {

    const navigate = useNavigate()

    return (
        <>
            <tr className="tabelaLinha" style={{ textAlign: 'center' }}>
                <th>{nome}</th>
                <th>{email}</th>
               <th>{servico}</th>
                <th>
                    <Button
                        className="btn__cinza buttonComprovante bgTransparente"
                        onClick={() => {
                            setModalDadosPessoais(true)
                            setIdCliente(idCliente)
                        }}
                        titleButton={
                            <>
                                <AiOutlineSolution className="iconButton " />
                            </>
                        }
                    />
                </th>
                <th>
                    <Button
                        onClick={() => {
                            setModalHistoricoFinanceiro(true)
                            setIdCliente(idCliente)
                            setNomeCliente(nome)
                        }}
                        className="btn__cinza buttonComprovante bgTransparente"
                        titleButton={
                            <>
                                <AiOutlineHistory className="iconButton " />
                            </>
                        }
                    />
                </th>
                <th>
                    <Button
                        onClick={() => navigate(`dashboards/${idCliente}/${nome}`)}
                        className="btn__cinza buttonComprovante bgTransparente"
                        titleButton={
                            <>
                                <AiOutlineLineChart className="iconButton " />
                            </>
                        }
                    />
                </th>
            </tr>
        </>
    )
}
