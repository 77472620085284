import { api } from './api'

export const incluirFuncionario = (
  nome: string,
  userName: string,
  email: string,
  imagemPerfil: string,
  dataContratacao: Date,
  token: string
) => {
  const funcionario = {
    nome,
    userName,
    email,
    imagemPerfil,
    dataContratacao,
  };

  const url = "/Funcionario";

  return api.post(
    url,
    { funcionario },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const incluirServicoFinanceiro = (
  nome: string,
  descricao: string,
  ativo: boolean = true,
  token: string
) => {
  const url = "/ServicoFinanceiro";
  const servicoFinanceiro = {
    nome,
    descricao,
    ativo,
  };

  return api.post(
    url,
    { servicoFinanceiro },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const editarServicoFinanceiro = (
  id: number,
  nome: string,
  descricao: string,
  ativo: boolean,
  token: string
) => {
  const url = "/ServicoFinanceiro";

  const servicoFinanceiro = { id, nome, descricao, ativo };

  return api.put(
    url,
    { servicoFinanceiro },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const incluirTemplateEmail = (
  assunto: string,
  conteudoHtml: string,
  conteudoTexto: string,
  tipo: string,
  token: string | boolean
) => {
  const url = "/TemplateEmail";

  const templateEmail = {
    assunto,
    conteudoHtml,
    conteudoTexto,
    tipo,
  };

  return api.post(
    url,
    { templateEmail },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const obterTemplateEmail = (tipo: string) => {
  const url = `/TemplateEmail?Tipo=${tipo}`;

  return api.get(url);
};

export const editarTemplateEmail = (
  assunto: string,
  conteudoHtml: string,
  conteudoTexto: string,
  tipo: string,
  token: string
) => {
  const url = "/TemplateEmail";

  const templateEmail = {
    assunto,
    conteudoHtml,
    conteudoTexto,
    tipo,
  };

  return api.put(
    url,
    { templateEmail },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const obterTotalDeCotas = (IdServicoFinanceiro: number) => {
  const url = `/Gerencial/TotalCotas?&IdServicoFinanceiro=${encodeURIComponent(
    IdServicoFinanceiro
  )}`;

  return api.get(url);
};

export const obterRankingDePosicoes = (NumeroPagina: number, TamanhoPagina: number, token: string) => {
    const url = `/Cliente/Ranking/Posicao?NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}`;

    return api.get(url, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });
};

export const obterTodosClientes = (token: string) => {
  const url = "/Cliente/Todos"

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  }
  )
}

export const obterTodosFuncionarios = (token: string, numeroPagina: number, tamanhoPagina: number, StatusFuncionario: boolean) => {

  const url = `/Funcionario/Todos?NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}&StatusFuncionario=${StatusFuncionario}`

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}
