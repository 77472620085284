import React from 'react'
import { Button } from '../../Button'
import "../CardListarUsuarios/CardListarUsuarios.css";
import { useNavigate } from 'react-router';

interface CardListarUsuariosPrivateProps {
    nome: string,
    email: string,
    idCliente: string;
}

export const CardListarUsuariosPrivate = ({ nome, idCliente, email }: CardListarUsuariosPrivateProps) => {

    const navigate = useNavigate()

    return (
        <>
            <div className="caixa1 caixaListarUsuarios">
                <fieldset>
                    <div className="FieldsetDivTexto fieldsetListarUsuarios">
                        <h5>{nome}</h5>
                        <h6>Email: {email}</h6>
                        <br />
                    </div>
                    <div className="divButton buttonListarUsuarios">
                        <Button className="btnAlterar sizeButton colorButton"
                            onClick={() => {
                                navigate(`/fechamento/${idCliente}`)
                            }}
                            titleButton={"Novo Fechamento"} />
                        <Button className="btnAlterar sizeButton"
                            onClick={() => {
                                navigate(`/gerenciar/historico-fechamento/${idCliente}/${nome}`)
                            }}
                            titleButton={"Histórico do Fundo"} />
                    </div>
                </fieldset>
            </div>
        </>
    )
}