import React, { useState } from "react";

import './styles.css'

import { Input } from "../../Input";

interface DadosBancariosProps {
    nomeFavorecido: string;
    setNomeFavorecido: React.Dispatch<string>;
    tipoDocumento: string;
    setTipoDocumento: React.Dispatch<string>;
    numeroDocumento: string;
    setNumeroDocumento: React.Dispatch<string>;
    numeroConta: string;
    setNumeroConta: React.Dispatch<string>;
    agencia: string;
    setAgencia: React.Dispatch<string>;
    nomeBanco: string;
    setNomeBanco: React.Dispatch<string>;
    codigoBanco: number;
    setCodigoBanco: React.Dispatch<number>
    apelido: string;
    setApledo: React.Dispatch<string>;
}

export const DadosBancarios = ({ agencia, codigoBanco, nomeBanco, nomeFavorecido, numeroConta, numeroDocumento, setAgencia, setCodigoBanco, setNomeBanco, setNomeFavorecido, setNumeroConta, setNumeroDocumento, setTipoDocumento, tipoDocumento, apelido, setApledo }: DadosBancariosProps) => {

    return <div>
        <Input placeholder="Apelido" label="Apelido" value={apelido} onChange={(e) => setApledo(e.target.value)} />
        <Input placeholder="Nome do favorecido" label="Nome do favorecido" value={nomeFavorecido} onChange={(e) => setNomeFavorecido(e.target.value)} />
        <Input placeholder="Número do documento do favorecido" label="Número do documento do favorecido" value={numeroDocumento} onChange={(e) => setNumeroDocumento(e.target.value)} />
        <Input placeholder="Tipo de documento do favorecido" label="Tipo de documento do favorecido" value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)} />
        <Input placeholder="Banco" label="Banco" value={nomeBanco} onChange={(e) => setNomeBanco(e.target.value)} />
        <Input placeholder="Agencia" label="Agencia" value={agencia} onChange={(e) => setAgencia(e.target.value)} />
        <Input placeholder="Conta" label="Conta" value={numeroConta} onChange={(e) => setNumeroConta(e.target.value)} />
        <Input placeholder="Código do banco" label="Código do banco" value={codigoBanco} onChange={(e) => setCodigoBanco(Number(e.target.value.replace(",", ".").replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')))} />
    </div>
}