import { Console } from "console";
import { FormEvent } from "react";
import { NavigateFunction } from "react-router-dom";
import { handleDocumentoUpload } from "../../components/CadastroDocumentos/functions";
import { IUsuario } from "../../context/tipoUsuario";
import { getClienteServicosFinanceiros, obterTipoCliente } from "../../services/cliente";
import { guardarDadosUsuario, guardarDadosUsuario2, salvarPerfilUsuario, obterPerfilUsuario, salvarTipoUsuario, obterDadosUsuario2, salvarServiçosFinanceiros } from "../../services/cookies";
import { fazerLogin, obterCredenciais, renovacaoToken } from "../../services/usuario";
import { Erro } from "../Cliente/AlteracaoSenha/AlteracaoSenha";
import { Credenciais } from "./Login";

export const login = async (
    e: FormEvent,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    setBotaoReenviarEmail: (value: React.SetStateAction<boolean>) => void,
    setCredenciais: (value: React.SetStateAction<Credenciais>) => void,
    credenciais: any,
    navigate: NavigateFunction,
    removerUsuario: () => void,
    atualizarUsuario: (usuario: IUsuario) => void,
    setError: (value: React.SetStateAction<Erro>) => void,

) => {
    e.preventDefault();
    setLoading(true);
    setCredenciais({ ...credenciais, error: false });
    await fazerLogin(credenciais)
        .then(async (res1) => {

            const { tokenAcesso, tokenRenovacao } = res1.data.camposAdicionais;
            await obterCredenciais(
                credenciais.email,
                res1.data.camposAdicionais.tokenAcesso
            ).then((res2) => {
                const { id, urlAvatar } = res2.data.resultado.credenciais;

                salvarPerfilUsuario(res2.data.resultado.credenciais.perfil[0])
                atualizarUsuario({ tipo: res2.data.resultado.credenciais.perfil[0], autenticado: true });
                guardarDadosUsuario2(
                    tokenAcesso,
                    tokenRenovacao,
                    credenciais.email,
                    id,
                    urlAvatar
                );
            })
                .then(() => obterTipoDoCliente(tokenAcesso))
                .then(() => obterServiçosFinanceirosCliente(tokenAcesso))
                .then(() => {
                    navigate("/home")
                    document.location.reload()
                })

        })
        .catch((error) => {
            removerUsuario();
            setLoading(false);
            let mensagem: string;
            switch (error.response.data.codigo) {
                case 401:
                    if (error.response.data.mensagem === "Cadastro de cliente não aprovado.") {
                        mensagem = "Usuário não aprovado ou em análise.";
                    } else if (error.response.data.mensagem === "E-mail do usuário não foi confirmado.") {
                        mensagem = "E-mail não confirmado, verifique sua caixa de entrada";
                        setBotaoReenviarEmail(true);
                    } else {
                        mensagem = "E-mail e/ou senha invalido.";
                    }
                    break;
                case 404:
                    if (error.response.data.mensagem === "Usuário não cadastrado no sistema.") {
                        mensagem = "Usuário não cadastrado no sistema."
                    } else {
                        mensagem = "E-mail e/ou senha invalido.";
                    }
                    break;
                case 500:
                    mensagem =
                        "Algo deu errado em nossos servidores, aguarde um instante e tente novamente mais tarde.";
                    break;
                default:
                    mensagem =
                        "Algo deu errado, aguarde um instante e tente novamente mais tarde.";
                    break;
            }
            setError({
                erro: true,
                mensagem,
            });
        }).finally(() => {
            setLoading(false)
        })
};

export const obterTipoDoCliente = async (token: string) => {
    const { email, id, tokenRenovacao, urlAvatar } = obterDadosUsuario2()

    await obterTipoCliente(token, id)
        .then(res => {
            salvarTipoUsuario(res.data.mensagem);
        }).catch(async (err) => {
            if (err.toString() === "Error: Request failed with status code 401") {
                const { email, id, token, tokenRenovacao, urlAvatar } = obterDadosUsuario2()
                await renovacaoToken(token, tokenRenovacao).then(async (res) => {
                    guardarDadosUsuario2(
                        res.data.camposAdicionais.tokenAcesso,
                        res.data.camposAdicionais.tokenRenovacao,
                        email.toString(),
                        id.toString()
                    );
                    await obterTipoCliente(token, id)
                        .then(res => {
                            salvarTipoUsuario(res.data.mensagem)
                        })
                })
            }
        })
}

export const obterServiçosFinanceirosCliente = async (token: string) => {

    const { id } = obterDadosUsuario2()


    await getClienteServicosFinanceiros(token, id)
        .then(res => {
            console.log(res.data);

            salvarServiçosFinanceiros(res.data.resultado.clientes);
        }).catch(async (err) => {
            console.log(err);
            if (err.toString() === "Error: Request failed with status code 401") {
                const { email, id, token, tokenRenovacao, urlAvatar } = obterDadosUsuario2()
                await renovacaoToken(token, tokenRenovacao).then(async (res) => {
                    guardarDadosUsuario2(
                        res.data.camposAdicionais.tokenAcesso,
                        res.data.camposAdicionais.tokenRenovacao,
                        email.toString(),
                        id.toString()
                    );
                    await getClienteServicosFinanceiros(token, id)
                        .then(res => {
                            salvarTipoUsuario(res.data.mensagem)
                        })
                })
            }
        })
}
