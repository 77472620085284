import React from "react";

import { AiOutlineMinusCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { Button } from "../../Button";

interface TabelaListarFuncionariosProps {
    nome: string,
    status: boolean,
    ativar: () => void,
    desativar: () => void,
}

export const TabelaListarFuncionarios = ({
    nome,
    status,
    ativar,
    desativar
}: TabelaListarFuncionariosProps) => {
    return (
        <>
            <tr id="trAvisoSemRegistros" className="hoverTabelaValidacaoAporte">
                <th className="table-secondary tableConteudo tableTipo ">
                    <strong>{nome}</strong>
                </th>
                <th className="table-secondary tableConteudo tableOperacao ">
                    <strong>{status ? "Ativo" : "Inativo"}</strong>
                </th>
                <th className="colunaBotoes colunaCadastro">
                    {!status ? (
                        <>
                            <Button
                                onClick={ativar}
                                className="btn__verde botaoCircular botaoEsq"
                                titleButton={
                                    <>
                                        <AiOutlineCheckCircle className="iconButtonExtrato" />
                                        <p className="textoBotao">Ativar</p>
                                    </>
                                }
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={desativar}
                                className="btn__vermelho botaoCircular botaoDir"
                                titleButton={
                                    <>
                                        <AiOutlineMinusCircle className="iconButtonExtrato" />
                                        <p className="textoBotao">   Desativar</p>
                                    </>
                                }
                            />
                        </>
                    )}
                </th>
            </tr>
        </>
    );
};
