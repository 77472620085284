import { Erro } from "../../pages/Cliente/AlteracaoSenha/AlteracaoSenha";

import { ImagensDocumentacao } from "../../pages/Cliente/Cadastro/Cadastro"
import imageCompression from "browser-image-compression";


export const handleDocumentoUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    tipoDocumento: string,
    setError: React.Dispatch<React.SetStateAction<Erro>>,
    setImagensDocumentacao: (value: React.SetStateAction<ImagensDocumentacao[]>) => void,
    imagensDocumentacao: ImagensDocumentacao[],
) => {
    let imagemOriginal = e.target.files[0];
    let date = new Date()
    let imagemAtual: ImagensDocumentacao

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1300,
        useWebWorker: true
    }

    if (imagemOriginal.type.includes("/pdf")) {
        var reader = new FileReader();
        reader.readAsDataURL(imagemOriginal);
        reader.onload = function () {
            imagemAtual = { tipoDocumento: tipoDocumento, conteudo: reader.result, nomeArquivo: imagemOriginal.name, tamanho: imagemOriginal.size.toString(), dataUpload: date.toISOString() }
            setImagensDocumentacao([...imagensDocumentacao, imagemAtual])
        };
    } else {
        try {
            const imagemComprimida = await imageCompression(imagemOriginal, options);

            var reader = new FileReader();
            reader.readAsDataURL(imagemComprimida);
            reader.onload = function () {
                imagemAtual = { tipoDocumento: tipoDocumento, conteudo: reader.result, nomeArquivo: imagemOriginal.name, tamanho: imagemOriginal.size.toString(), dataUpload: date.toISOString() }
                setImagensDocumentacao([...imagensDocumentacao, imagemAtual])
            };
        } catch (error) {
            setError({
                erro: true,
                mensagem: 'Ocorreu um erro na sua solicitação. Por favor tente novamente.'
            })
        }
    }
}