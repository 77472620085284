import React, { useContext, useEffect, useState } from 'react'

import "./DashboardsGestor.css";

import { gerarNumerosAleatorios, gerarCoresAleatorias, handleGraficosDashboards, obterIdFundoFinanceiroAssessoria, obterIdFundoFinanceiroPrivate, handleGraficosRankingClientes } from './functions';

import Navbar from "../../../components/Navbar/Navbar";

import { GraficoLinha } from '../../../components/Graficos/GraficoLinha';
import { GraficoPizza } from '../../../components/Graficos/GraficoPizza';
import { ErroTabela } from '../../../components/Erros/ErroTabela';
import { LoadingButton } from '../../../components/loadingButtom';

import { AiOutlineReload, AiOutlineSearch } from 'react-icons/ai';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { GraficoBarra } from '../../../components/Graficos/GraficoBarra';

import { Cliente } from '../ListarUsuarios/functions';
import { SelectCliente } from '../../../components/select/SelectCliente';
import { SelectServicoFinanceiro } from '../../../components/select/SelectServicoFinanceiro';

const DashboardsGestor = () => {
    const [porcentagens1, setPorcentagens1] = useState<Array<number>>(gerarNumerosAleatorios(1, 499, 13));
    const [porcentagens2, setPorcentagens2] = useState<Array<number>>(gerarNumerosAleatorios(1, 499, 13));
    const [colorArray, setColorArray] = useState<Array<string>>([]);

    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
    //gráfico fazer requisição
    const [dataInicial, setDataInicial] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 60)));
    const [dataFinal, setDataFinal] = useState<Date>(new Date());
    //gráfico de cliente - receber requisição
    const [rendimentosDia, setRendimentosDia] = useState<Array<number>>([])
    const [valoresTotais, setValoresTotais] = useState<Array<number>>([])
    const [valoresAcumulados, setValoresAcumulados] = useState<Array<number>>([])
    const [labels, setLabels] = useState<Array<string>>([])
    const [indexData, setIndexData] = useState<number>(1)
    //gráfico de ranking de clientes - receber requisição
    const [porcentagensRanking, setPorcentagensRanking] = useState<Array<number>>([])
    const [labelsRanking, setLabelsRanking] = useState<Array<string>>([])
    //gráfico tratamento de erro e loading
    const [loadingGrafico, setLoadingGrafico] = useState<boolean>(true);
    const [errorGrafico, setErrorGrafico] = useState<boolean>(false);
    const [reloadPage, setReloadPage] = useState<boolean>(true);

    const [idFundoFinaceiro, setIdFundoFinaceiro] = useState<string>("");
    const [servico, setServico] = useState<number>(1);
    const [idCliente, setIdCliente] = useState<string>("");
    const [indexDataPrivate, setIndexDataPrivate] = useState<number>(1);



    useEffect(() => {
        if (servico === 1) {
            setIdCliente("")
            obterIdFundoFinanceiroAssessoria(
                setLoadingGrafico,
                setErrorGrafico,
                dataInicial,
                dataFinal,
                setModalDesconectar,
                setRendimentosDia,
                setValoresTotais,
                setLabels,
                setValoresAcumulados
            )

        } else {
            !(idCliente === "") &&
                obterIdFundoFinanceiroPrivate(
                    setLoadingGrafico,
                    setErrorGrafico,
                    dataInicial,
                    dataFinal,
                    setModalDesconectar,
                    setRendimentosDia,
                    setValoresTotais,
                    setLabels,
                    setValoresAcumulados,
                    idCliente,
                )
        }
    }, [dataInicial, dataFinal, reloadPage, idCliente, servico])

    useEffect(() => {
        setIdCliente("")
    }, [servico])

    useEffect(() => {
        handleGraficosRankingClientes(
            setLoadingGrafico,
            setErrorGrafico,
            setModalDesconectar,
            setPorcentagensRanking,
            setLabelsRanking
        )
    }, [servico])

    return (
        <>
            <Navbar />
            <div className="caixaServico">
                <fieldset>
                    <div className="headerServicos">
                        <h2 className="textoNomeHeader">Dashboards Gerenciais</h2>
                        <div className="containerSelects">

                            <SelectServicoFinanceiro
                                className='selectCliente'
                                setServico={setServico}
                            />
                            <SelectCliente
                                className='selectCliente'
                                setIdCliente={setIdCliente}
                                setErrorGrafico={setErrorGrafico}
                                setLoadingGrafico={setLoadingGrafico}
                                setModalDesconectar={setModalDesconectar}
                                idServicoFinanceiro={servico}
                            />
                        </div>

                    </div>


                    <div id="divFieldsetHistoricoGraficos">
                        <h6 id="descricaoTexto">Selecione o período: </h6>
                        <div className='containerDatePicker'>
                            <DatePicker
                                className="inputData"
                                maxDate={(new Date(new Date().setDate(new Date().getDate() - 1)))}
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={dataInicial}
                                onChange={(dataInicio: Date) => setDataInicial(dataInicio)}
                            />
                            <DatePicker
                                className="inputData"
                                maxDate={(new Date(new Date().setDate(new Date().getDate())))}
                                minDate={dataInicial}
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={dataFinal}
                                onChange={(dataFim: Date) => setDataFinal(dataFim)}
                            />
                            <AiOutlineSearch className="PesquisarHistoricoFinanceiro" onClick={() => setReloadPage(!reloadPage)} />

                        </div>

                    </div>
                </fieldset>
            </div>

            <div className="caixa1">
                <fieldset>
                    {servico === 1 &&
                        <>
                            {errorGrafico ? <ErroTabela
                                handleClick={() => setReloadPage(!reloadPage)}
                                className="formatacaoGrafico"
                                titleButton={<>
                                    <>
                                        <AiOutlineReload className="iconButtonDashboard" />
                                    </>
                                </>
                                }
                            /> : !loadingGrafico ? <>
                                <GraficoLinha
                                    setIndexData={setIndexData}
                                    dadosGrafico={valoresTotais}
                                    labels={labels}
                                    nomeDado={"Evolução"}
                                    tituloGrafico='Performance Mensal da Assessoria Omega Capital'
                                    tituloTooltip={"Diferença percentual mensal"}
                                    dadoTooltip={'Valor percentual de diferença: ' + valoresTotais[indexData]?.toFixed(2) + "%"}
                                />
                            </> :
                                <LoadingButton />
                            }

                            {errorGrafico ? <ErroTabela
                                handleClick={() => setReloadPage(!reloadPage)}
                                className="formatacaoGrafico"
                                titleButton={<>
                                    <>
                                        <AiOutlineReload className="iconButtonDashboard" />
                                    </>
                                </>
                                }
                            /> : !loadingGrafico ? <>
                                <GraficoLinha
                                    setIndexData={setIndexData}
                                    dadosGrafico={valoresAcumulados}
                                    labels={labels}
                                    nomeDado={"Evolução"}
                                    tituloGrafico='Rendimento Acumulado da Assessoria Omega'
                                    tituloTooltip={"Valor acumulado percentual"}
                                    dadoTooltip={'Valor acumulado: ' + valoresAcumulados[indexData]?.toFixed(2) + "%"}
                                />
                            </> :
                                <LoadingButton />
                            }

                            <GraficoBarra
                                porcentagensDado={porcentagensRanking.slice(0, 10)}
                                labels={labelsRanking.slice(0, 10)}
                                nomeDado={"Posição em Cotas"}
                                tituloGrafico='Ranking de Cotas - Top 10'
                                colorArray={gerarCoresAleatorias(70, colorArray)}
                            />
                        </>}
                    {(servico !== 1 && idCliente !== "") &&

                        <>
                            {errorGrafico ? <ErroTabela
                                handleClick={() => setReloadPage(!reloadPage)}
                                className="formatacaoGrafico"
                                titleButton={<>
                                    <>
                                        <AiOutlineReload className="iconButtonDashboard" />
                                    </>
                                </>
                                }
                            /> : !loadingGrafico ? <>
                                <GraficoLinha
                                    setIndexData={setIndexDataPrivate}
                                    dadosGrafico={rendimentosDia}
                                    labels={labels}
                                    nomeDado={"Evolução"}
                                    tituloGrafico='Rendimento Acumulado da Assessoria Omega'
                                    tituloTooltip={"Valor acumulado percentual"}
                                    dadoTooltip={'Valor acumulado: ' + rendimentosDia[indexDataPrivate]?.toFixed(2) + "%"}
                                />
                            </> :
                                <LoadingButton />
                            }
                        </>
                    }
                </fieldset>
            </div>
        </>
    )
}

export default DashboardsGestor;