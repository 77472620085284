import React from "react";

import './styles.css'

import { Input } from "../../Input";
import { SelectTipoCahavePix } from "../../select/SelectTipoCahavePix";

interface PixProps {
    chavePix: string;
    setChavePix: React.Dispatch<string>;
    setTipoChave: React.Dispatch<string>;
    tipoChave: string;
    apelido: string;
    setApledo: React.Dispatch<string>;
}


export const Pix = ({ chavePix, setChavePix, setTipoChave, tipoChave, apelido, setApledo }: PixProps) => {

    return <div>
        <div className="caixa-SelectTipoCahavePix">
            <SelectTipoCahavePix value={tipoChave} selectType={setTipoChave} />
        </div>
        <Input placeholder="Chave Pix" label="Chave Pix" value={chavePix} onChange={(e) => setChavePix(e.target.value)} />
        <Input placeholder="Apelido" label="Apelido" value={apelido} onChange={(e) => setApledo(e.target.value)} />
    </div>
}