
export const mostrarPassword = (
    setPasswordShown: (value: React.SetStateAction<boolean>) => void,

) => {
    setPasswordShown((value) => !value);

};

export function checkRequiredInput(
    input: any,
    setchangeClassRequired: (value: React.SetStateAction<string>) => void,
    setrequiredInput: (value: React.SetStateAction<string>) => void,
    serStyleRequired: (value: any) => void,
    className: string,
) {
    if (input.length === 0) {
        setchangeClassRequired(className + " form-control");
        setrequiredInput(" - Campo Obrigatório!");
        serStyleRequired({ backgroundColor: "#fff1f1" });
    } else {
        setchangeClassRequired(className ? className : "form-control");
        setrequiredInput("");
        serStyleRequired({});
    }
}