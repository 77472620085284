import axios from "axios";
export const api = axios.create({
  baseURL: "https://api.omegainvestimentos.com.br/Sistema/",
});

export const apiApp = axios.create({
    baseURL: "https://api.omegainvestimentos.com.br/App/",
});

export const apiExternaCep = axios.create({
  baseURL: `https://viacep.com.br`,
});

export const buscarDadosCep = (cep: string) => {
  const url = `/ws/${cep}/json/`;
  return apiExternaCep.get(url);
};

export const apiAwesomeAPIDeMoedas = axios.create({
  baseURL: "https://economia.awesomeapi.com.br/",
});

export const obterValorDoReal = () => {
  const conversao = "USD-BRL";
  //opções EUR-BRL, BTC-BRL
  const url = `last/${conversao}`;

  return apiAwesomeAPIDeMoedas.get(url, { responseType: "json" });
};

