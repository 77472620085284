import React from 'react'

export const DateFormatter = (data: any) => {
    const dataFormatada = new Date(data);
    const dia = ("00" + dataFormatada.getDate()).slice(-2);
    const mes = ("00" + (dataFormatada.getMonth() + 1)).slice(-2);
    const ano = ("00" + dataFormatada.getFullYear()).slice(-2);
    return `${dia}/${mes}/${ano}`
}

export const DateFormatterMonthName = (data: any) => {
    const dataFormatada = new Date(data);
    const dia = ("00" + dataFormatada.getDate()).slice(-2);
    const mes = ("00" + (dataFormatada.getMonth() + 1)).slice(-2);
    const ano = ("00" + dataFormatada.getFullYear()).slice(-2);
    return `${dataFormatada.toLocaleString('en-US', { month: 'short' })}/${ano}`
}
