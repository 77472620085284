import React, { useState } from "react";
import { enviarChaveAPI, handleHide } from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { MensagemDeErro } from "../../RespostasDosFormularios/MensagemDeErro";
import { MensagemDeSucesso } from "../../RespostasDosFormularios/Sucesso";
import { ModalDesconectar } from "../ModalDesconectar";
import { LoadingButton } from "../../loadingButtom";
import { DadosUsuario } from "../ModalNovoServicoFinanceiro";

import Modal from "react-bootstrap/Modal";

interface modalProps {
    modal: boolean,
    setModal: (modal: boolean) => void,
    dadosUsuario: DadosUsuario,
    idCredenciais: string,
    setReload: React.Dispatch<React.SetStateAction<boolean>>,
    reload: boolean
}

export const ModalChavePrivateAPI = ({ modal, setModal, dadosUsuario, idCredenciais, reload, setReload }: modalProps) => {
    const [APIKey, setAPIKey] = useState<string>("");
    const [secretKey, setSecretKey] = useState<string>("");
    const [passPhrase, setPassPhrase] = useState<string>("");
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [falha, setFalha] = useState<boolean>(false);
    const [mensagem, setMensagem] = useState<string>("");
    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    return !modalDesconectar ? (
        <Modal show={modal} onHide={() => handleHide(setSucesso, setFalha, setAPIKey, setModal, setReload, reload)}>
            <form onSubmit={(e) => enviarChaveAPI(
                e,
                setLoading,
                dadosUsuario,
                idCredenciais,
                APIKey,
                secretKey,
                passPhrase,
                setMensagem,
                setSucesso,
                setFalha,
                setAPIKey,
                setSecretKey,
                setPassPhrase,
                setModalDesconectar
            )}>
                <Modal.Header closeButton>
                    <Modal.Title>Inserir dados da API Private</Modal.Title>
                </Modal.Header>
                {falha && <MensagemDeErro mensagem={mensagem} />}
                {sucesso ? (
                    <MensagemDeSucesso mensagem={mensagem} />
                ) : (
                    <>
                        <Modal.Body>
                            <Input
                                label="Insira a API Key:"
                                placeholder=" "
                                id={"APIKey"}
                                value={APIKey}
                                onChange={(e) => {
                                    setAPIKey(e.target.value)
                                }}
                            />
                            <Input
                                label="Insira o SecretKey da API:"
                                placeholder=" "
                                id={"secretKey"}
                                value={secretKey}
                                onChange={(e) => {
                                    setSecretKey(e.target.value)
                                }}
                            />
                            <Input
                                label="Insira o PassPhrase da API:"
                                placeholder=" "
                                id={"passPhrase"}
                                value={passPhrase}
                                onChange={(e) => {
                                    setPassPhrase(e.target.value)
                                }}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {loading ? (
                                <LoadingButton />
                            ) : (
                                <Button
                                    classNameButton="btnAlterar"
                                            onClick={(e) => enviarChaveAPI(
                                                e,
                                                setLoading,
                                                dadosUsuario,
                                                idCredenciais,
                                                APIKey,
                                                secretKey,
                                                passPhrase,
                                                setMensagem,
                                                setSucesso,
                                                setFalha,
                                                setAPIKey,
                                                setSecretKey,
                                                setPassPhrase,
                                                setModalDesconectar
                                            )}
                                    titleButton="Enviar"
                                    type="submit"
                                />
                            )}
                            <Button
                                classNameButton="btnCancelar"
                                onClick={() => setModal(false)}
                                titleButton="Cancelar"
                            />
                        </Modal.Footer>
                    </>
                )}
            </form>
        </Modal>
    ) : (
        <ModalDesconectar modal={modalDesconectar} />
    );
};
