import { api } from './api'

export const obterPosicaoConsolidade = (IdCredenciaisCliente: string, IdServicoFinaceiro: number, token: string) => {
    const url = `/HomeCliente?IdCredenciaisCliente=${IdCredenciaisCliente}&IdServicoFinaceiro=${IdServicoFinaceiro}`;

    return api.get(url, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });
};