import React, { useState } from "react";
import "./Footer.css";
// import ImagemUsuario from "../../assets/img/default_user.png";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";

const Navbar = () => {
    return (
        <>
            <div className="footer">

            </div>
        </>
    );
}

export default Navbar;
