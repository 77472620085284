import React, { ReactNode } from "react";
import './styles.css'

interface headerProps {
  titulo?: string;
  children?: ReactNode;
}
export const Header = ({ titulo, children }: headerProps) => {
  return (
    <div className="caixaAporteSaque">
      <fieldset>
        {titulo && (
          <div className="aportesaque__titulo">
            <h2>{titulo}</h2>
          </div>
        )}
        {children}
      </fieldset>
    </div>
  );
};
