import React, { useEffect, useState } from "react";
import "./CadastroPF.css";
import { handleDataDeNascimento, handleEndereco, handleMaskCEP, handleMaskCPF, handleMaskRenda, handleMaskRG, handleMaskTelefone } from "./functions";

import { Input } from "../Input";
import { SelectEstado } from "../select/SelectEstados/SelectEstados";
import { Usuario } from "../../pages/Cliente/Cadastro/Cadastro";
import { SelectNacionalidade } from "../select/SelectNacionalidade";

interface CadastroPFProps {
  setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
}

function CadastroPessoaFisica({
  setUsuarioPF,
}: CadastroPFProps) {
  const [CEP, setCEP] = useState<string>("");
  const [rua, setRua] = useState<string>("");
  const [bairro, setBairro] = useState<string>("");
  const [cidade, setCidade] = useState<string>("");
  const [estado, setEstado] = useState<string>("");
  const [complemento, setComplemento] = useState<string>("");

  const [maskDataDeNascimento, setMaskDataDeNascimento] = useState<string>("");
  const [maskRG, setMaskRG] = useState<string>("");
  const [rendaMask, setRendaMask] = useState<string>("");
  const [maskCPF, setMaskCPF] = useState<string>("");
  const [maskCEP, setMaskCEP] = useState<string>("");
  const [maskTelefone, setMaskTelefone] = useState<string>("");

  return (
    <div className="AreaCadastro">
      <div className="inputsCadastroPessoaFisica">
        <Input
          required={true}
          label="Nome Completo"
          placeholder=" "
          onChange={(e) => {
            setUsuarioPF((usuario) => {
              usuario.nome = e.target.value
              return usuario
            })
          }}
        />
        <Input
          required={true}
          label="CPF"
          placeholder=" "
          maxLength={14}
          value={maskCPF}
          onChange={(e) => { handleMaskCPF(e.target.value, setUsuarioPF, setMaskCPF) }}
        />
        <Input
          required={true}
          label="RG"
          placeholder=" "
          value={maskRG}
          onChange={(e) => { handleMaskRG(e.target.value, setUsuarioPF, setMaskRG) }}
        />
        <div className="orgaoExpedidor">
          <div className="inputMenor">
            <Input
              required={true}
              label="Órgão expedidor"
              placeholder="Órgão expedidor"
              onChange={(e) => {
                setUsuarioPF((usuario) => {
                  usuario.orgaoExpeditorRg = e.target.value
                  return usuario
                })
              }}
            />
          </div>
          <SelectEstado onChange={(e) => {
            setUsuarioPF((usuario) => {
              usuario.ufOrgaoExpeditor = e.target.value;
              return usuario
            })
          }} />
        </div>
        <SelectNacionalidade
          onChange={(e) => {
            setUsuarioPF((usuario) => {
              usuario.nacionalidade = e.target.value;
              return usuario
            })
          }}
        />
        <Input
          required={true}
          placeholder=" "
          label="Data de Nascimento"
          maxLength={10}
          value={maskDataDeNascimento}
          onChange={(e) => { handleDataDeNascimento(e.target.value, setMaskDataDeNascimento, setUsuarioPF,) }}
        />
        {/* <SelectEstadoCivil setEstadoCivil={setEstadoCivil} /> */}
        <Input
          required={true}
          value={rendaMask}
          placeholder=" "
          label="Renda"
          onChange={(e) => { handleMaskRenda(e.target.value, setUsuarioPF, setRendaMask) }}
        />
        <Input
          required={true}
          placeholder=" "
          label="Profissão"
          onChange={(e) => {
            setUsuarioPF((usuario) => {
              usuario.profissao = e.target.value
              return usuario
            })
          }}
        />
        <Input
          required={true}
          placeholder=" "
          label="Telefone Celular"
          maxLength={15}
          value={maskTelefone}
          onChange={(e) => { handleMaskTelefone(e.target.value, setUsuarioPF, setMaskTelefone) }}
        />
        <Input
          required={true}
          placeholder=" "
          label="CEP"
          maxLength={10}
          value={maskCEP}
          onChange={(e) => { handleMaskCEP(e.target.value, setCEP, setRua, setBairro, setEstado, setCidade, setUsuarioPF, setMaskCEP, rua, bairro, cidade, complemento, estado, CEP) }}
        />
        <div className="orgaoExpedidor">
          <div className="inputMenor">
            <Input
              required={true}
              placeholder=" "
              label="Cidade"
              value={cidade}
              onChange={(e) => {
                setCidade(e.target.value);
                handleEndereco(setUsuarioPF, rua, bairro, cidade, complemento, estado, CEP);
              }}
            />
          </div>
          <SelectEstado setUf={setEstado} UF={estado} />
        </div>
        <Input
          required={true}
          placeholder=" "
          label="Endereço"
          value={rua}
          onChange={(e) => {
            setRua(e.target.value);
            handleEndereco(setUsuarioPF, rua, bairro, cidade, complemento, estado, CEP);
          }}
        />
        <Input
          required={true}
          placeholder=" "
          label="Bairro"
          value={bairro}
          onChange={(e) => {
            setBairro(e.target.value);
            handleEndereco(setUsuarioPF, rua, bairro, cidade, complemento, estado, CEP);
          }}
        />
        <Input
          required={true}
          placeholder=" "
          label="Complemento e número"
          value={complemento}
          onChange={(e) => {
            setComplemento(e.target.value);
            handleEndereco(setUsuarioPF, rua, bairro, cidade, e.target.value, estado, CEP);
          }}
        />
      </div>
    </div>
  );
}

export default CadastroPessoaFisica;
