import React, { useState } from "react";
import "./Cadastro.css";
import "../../../pages/Login/reset.css";
import { verificarCpf, verificarUserName, habilitarBotaoProximoPF, habilitarBotaoProximoPJ, onSubmitPJ, onSubmitPF } from "./functions";

import { Button } from "../../../components/Button";
import { CadastroAcesso } from "../../../components/CadastroAcesso";
import { CadastroDadosCadastrais } from "../../../components/CadastroDadosCadastrais";
import { CadastroDocumentos } from "../../../components/CadastroDocumentos";
import { CadastroServicos } from "../../../components/CadastroServicos";
import { Loading } from "../../../components/Loading";
import { Erro } from "../AlteracaoSenha/AlteracaoSenha";

import ImagemLogo from "../../../assets/img/omega_header_menu.png";

import { Container } from "react-bootstrap";
import { MultiStepForm, Step } from "react-multi-form";
import { useNavigate } from "react-router-dom";


export interface ImagensDocumentacao {
    tipoDocumento: string;
    conteudo: string | ArrayBuffer | null;
    nomeArquivo: string;
    tamanho: string;
    dataUpload: string;
}
export interface Endereco {
    rua: string;
    bairro: string;
    cidade: string;
    estado: string;
    complemento: string;
    cep: string;
}

export interface Usuario {
    nome: string,
    cpf: string,
    rg: string,
    orgaoExpeditorRg: string,
    ufOrgaoExpeditor: string,
    profissao: string,
    renda: number,
    telefone: string,
    dataNascimento: string,
    nacionalidade: string,
    estadoCivil: "Solteiro" | "Casado" | "Divorciado" | "Separado" | "Viúvo",
    endereco: Endereco | undefined,
}

export interface UsuarioPJ {
    nome: string,
    razaoSocial: string,
    cnpj: string,
    status: boolean,
    telefone: string,
    endereco: Endereco | undefined,
    socios: Socios[]
}

export interface Socios {
    nome: string,
    endereco: Endereco | undefined,
}

function Cadastros() {
    const [active, setActive] = useState<number>(1);
    const [userName, setUserName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [senha, setSenha] = useState<string>("");
    const [senhaConfirmada, setSenhaConfirmada] = useState<string>("");
    const [opcaoSelecionada, setOpcaoSelecionada] = useState<string>("");
    const [imagePerfil, setImagePerfil] = useState<string | ArrayBuffer | null>("");
    const [idsServicosFinanceiros, setIdsServicosFinanceiros] = useState<Array<number>>([]);
    const [imagensDocumentacao, setImagensDocumentacao] = useState<Array<ImagensDocumentacao>>([]);
    const [loading, setLoading] = useState(false);
    const [compromisso, setCompromisso] = useState<boolean>(false);
    const [error, setError] = useState<Erro>({ erro: false, mensagem: "", });
    const [socios, setSocios] = useState<Socios[]>([]);
    const [usuarioPJ, setUsuarioPJ] = useState<UsuarioPJ>({
        nome: '',
        cnpj: "",
        endereco: {
            rua: '',
            bairro: '',
            cidade: '',
            estado: '',
            complemento: '',
            cep: '',
        },
        razaoSocial: "",
        status: true,
        telefone: "",
        socios: [
            {
                nome: "",
                endereco: {
                    rua: '',
                    bairro: '',
                    cidade: '',
                    estado: '',
                    complemento: '',
                    cep: '',
                },
            }
        ]
    });
    const [usuarioPF, setUsuarioPF] = useState<Usuario>({
        nome: "",
        cpf: '',
        rg: '',
        orgaoExpeditorRg: '',
        ufOrgaoExpeditor: '',
        profissao: '',
        renda: 0,
        telefone: '',
        dataNascimento: new Date().toISOString(),
        nacionalidade: '',
        estadoCivil: "Solteiro",
        endereco: {
            rua: '',
            bairro: '',
            cidade: '',
            estado: '',
            complemento: '',
            cep: '',
        }
    });

    const navigate = useNavigate();

    return (
        <div className="pagina">
            <Container className="Container">
                {loading && <Loading />}
                <div className="LogoCabecalhoArea">
                    <img
                        className="LogoCabecalho logo-cursor-pointer"
                        src={ImagemLogo}
                        alt="Logo Omega digital assets"
                        onClick={() => navigate("/")}
                    />
                </div>
                <Container style={{ width: "90%", marginLeft: "4%" }}>
                    <MultiStepForm
                        activeStep={active}
                        accentColor={"#f7931b"}
                    >
                        <Step label="ACESSO">
                            <CadastroAcesso
                                userName={userName}
                                setUserName={setUserName}
                                setEmail={setEmail}
                                setPassword={setSenha}
                                setImagePerfil={setImagePerfil}
                                senha={senha}
                                setSenhaConfirmada={setSenhaConfirmada}
                                setError={setError}
                            />
                        </Step>
                        <Step label="DADOS CADASTRAIS">
                            <CadastroDadosCadastrais
                                setUsuarioPF={setUsuarioPF}
                                setUsuarioPJ={setUsuarioPJ}
                                socios={socios}
                                setSocios={setSocios}
                                setOpcaoSelecionada={setOpcaoSelecionada}
                            />
                        </Step>
                        <Step label="SERVIÇOS">
                            <CadastroServicos
                                setIdsServicosFinanceiros={setIdsServicosFinanceiros}
                            />
                        </Step>
                        <Step label="DOCUMENTOS">
                            <CadastroDocumentos
                                setCompromisso={setCompromisso}
                                imagensDocumentacao={imagensDocumentacao}
                                setImagensDocumentacao={setImagensDocumentacao}
                                setError={setError}
                            />
                        </Step>
                    </MultiStepForm>
                </Container>
                {error.erro && <p className="MensagemDeErro">{error.mensagem}</p>}
                <div className="Container_Botoes">
                    {active > 1 && (
                        <div className="Area_Botoes">
                            <Button
                                onClick={() => {
                                    setActive(active - 1);
                                }}
                                titleButton="Anterior"
                            />
                        </div>
                    )}
                    <div className="Area_Botoes">
                        <Button
                            onClick={() => {

                                if (opcaoSelecionada == 'PessoaFisica') {

                                    if (habilitarBotaoProximoPF(
                                        active,
                                        setError,
                                        userName,
                                        email,
                                        senha,
                                        senhaConfirmada,
                                        imagePerfil,
                                        usuarioPF,
                                        idsServicosFinanceiros,
                                        imagensDocumentacao
                                    )) {
                                        active === 1 && verificarUserName(setLoading, userName, setError, email, setActive);
                                        active === 2 && setActive(active + 1);
                                        active === 3 && setActive(active + 1);
                                        active === 4 &&
                                            compromisso ?
                                            onSubmitPF(
                                                setLoading,
                                                setError,
                                                userName,
                                                email,
                                                senha,
                                                imagePerfil,
                                                usuarioPF,
                                                idsServicosFinanceiros,
                                                imagensDocumentacao,
                                                navigate
                                            ) :
                                            setError({
                                                erro: true,
                                                mensagem: "Aceite o termo de compromisso para seguir."
                                            })
                                    }
                                } else {
                                    if (habilitarBotaoProximoPJ(
                                        active,
                                        setError,
                                        userName,
                                        email,
                                        senha,
                                        senhaConfirmada,
                                        imagePerfil,
                                        usuarioPJ,
                                        idsServicosFinanceiros,
                                        imagensDocumentacao
                                    )) {
                                        active === 1 && verificarUserName(setLoading, userName, setError, email, setActive);
                                        active === 2 && setActive(active + 1);
                                        active === 3 && setActive(active + 1);
                                        active === 4 &&
                                            compromisso ?
                                            onSubmitPJ(
                                                setLoading,
                                                setError,
                                                userName,
                                                email,
                                                senha,
                                                imagePerfil,
                                                usuarioPJ,
                                                socios,
                                                idsServicosFinanceiros,
                                                imagensDocumentacao,
                                                navigate
                                            ) :
                                            setError({
                                                erro: true,
                                                mensagem: "Aceite o termo de compromisso para seguir."
                                            })
                                    }
                                }

                            }}
                            titleButton={active === 4 ? "Concluir" : "Próximo"}
                        />
                    </div>
                </div>
            </Container >
        </div>
    );
}
export default Cadastros;
