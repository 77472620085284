import { confirmacaoDeEmail, confirmacaoDeEmailReenviar, confirmacaoDeEmailApp } from "../../../services/usuario";

export function confirmaEmailVerificandoSender(sender: string, email: string, token: string, setConfirmado: React.Dispatch<React.SetStateAction<boolean>>, setErro: React.Dispatch<React.SetStateAction<boolean>>) {
    if (sender === "sys") {
        confirmacaoDeEmail(email, token)
            .then((res) => {
                setConfirmado(true);
                setErro(false);
            })
            .catch((error) => {
                setErro(true);
                setConfirmado(true);
            });
    } else if (sender === "app") {
        confirmacaoDeEmailApp(email, token)
            .then((res) => {
                setConfirmado(true);
                setErro(false);
            })
            .catch((error) => {
                setErro(true);
                setConfirmado(true);
            });
    } else {
        setErro(true);
        setConfirmado(true);
    }
}

export function handleReenviar(email: string, tipo: string, setReenviar: React.Dispatch<React.SetStateAction<boolean>>, setErroReenviar: React.Dispatch<React.SetStateAction<boolean>>) {
    confirmacaoDeEmailReenviar(email, tipo)
        .then(() => {
            setReenviar(true);
        })
        .catch((error) => {
            setErroReenviar(true);
        });
}
