import { DadosUsuario, Sucesso } from ".";
import { Erro } from "../../../pages/Cliente/AlteracaoSenha/AlteracaoSenha";
import { guardarDadosUsuario2, obterDadosUsuario2, removerDadosUsuario2 } from "../../../services/cookies";
import { editarServicoFinanceiro, incluirServicoFinanceiro } from "../../../services/gestor";
import { renovacaoToken } from "../../../services/usuario";


export const limparCampos = (
    setNome: (value: React.SetStateAction<string>) => void,
    setDescricao: (value: React.SetStateAction<string>) => void,
) => {
    setNome("");
    setDescricao("");
}

export const incluirServico = (
    setErro: (value: React.SetStateAction<Erro>) => void,
    erro: Erro,
    nome: string,
    descricao: string,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    estado: boolean,
    setNome: React.Dispatch<React.SetStateAction<string>>,
    setDescricao: React.Dispatch<React.SetStateAction<string>>,
    setSucesso: (value: React.SetStateAction<Sucesso>) => void,
    sucesso: Sucesso,
    reload: () => void,
    setModalDesconectar: (value: React.SetStateAction<boolean>) => void,
) => {
    setErro({
        ...erro,
        erro: false,
    });
    if (nome.trim() && descricao.trim()) {


        setLoading(true);
        incluirServicoFinanceiro(nome, descricao, estado, obterDadosUsuario2().token)
            .then((res) => {
                limparCampos(setNome, setDescricao);
                setSucesso({
                    ...sucesso,
                    sucesso: true,
                    mensagem: "Serviço financeiro adicionado com sucesso.",
                });
                reload();
            })
            .catch(async (err) => {
                if (err.toString() === "Error: Request failed with status code 401") {

                    const { token, tokenRenovacao } = obterDadosUsuario2()
                    await renovacaoToken(
                        token,
                        tokenRenovacao
                    )
                        .then(async (res2) => {
                            guardarDadosUsuario2(
                                res2.data.camposAdicionais.tokenAcesso,
                                res2.data.camposAdicionais.tokenRenovacao,
                                obterDadosUsuario2().email,
                                obterDadosUsuario2().id.toString()
                            );
                            await incluirServicoFinanceiro(
                                nome,
                                descricao,
                                estado,
                                res2.data.camposAdicionais.tokenAcesso
                            )
                                .then(() => {
                                    limparCampos(setNome, setDescricao);
                                    setSucesso({
                                        ...sucesso,
                                        sucesso: true,
                                        mensagem: "Serviço financeiro adicionado com sucesso.",
                                    });
                                    reload();
                                })
                                .catch(() => {
                                    removerDadosUsuario2();
                                    setModalDesconectar(true);
                                });
                        })
                        .catch(() => {
                            removerDadosUsuario2();
                            setModalDesconectar(true);
                        })
                        .finally(() => setLoading(false));
                } else if (err.response.data.Codigo === 500) {
                    setErro({
                        ...erro,
                        erro: true,
                        mensagem: "Algo deu errado, tente novamente mais tarde.",
                    });
                } else {
                    setErro({
                        ...erro,
                        mensagem: "Algo deu errado,",
                        erro: true,
                    });
                }
            }).finally(() => setLoading(false))
    } else {
        setLoading(false)
        setErro({
            ...erro,
            mensagem: "Todos os campos devem ser preenchidos",
            erro: true,
        });
    }
};

export const atualizarServico = (
    setErro: (value: React.SetStateAction<Erro>) => void,
    erro: Erro,
    id: number,
    nome: string,
    labelNome: string,
    descricao: string,
    labelDescricao: string,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    estado: boolean,
    setNome: React.Dispatch<React.SetStateAction<string>>,
    setDescricao: React.Dispatch<React.SetStateAction<string>>,
    setSucesso: (value: React.SetStateAction<Sucesso>) => void,
    sucesso: Sucesso,
    reload: () => void,
    setModalDesconectar: (value: React.SetStateAction<boolean>) => void,
) => {
    setLoading(true);
    setErro({
        ...erro,
        erro: false,
    });
    editarServicoFinanceiro(
        id,
        nome ? nome : labelNome,
        descricao ? descricao : labelDescricao,
        estado,
        obterDadosUsuario2().token
    )
        .then((res) => {
            setSucesso({
                ...sucesso,
                sucesso: true,
                mensagem: "Serviço financeiro atualizado com sucesso.",
            });
            limparCampos(setNome, setDescricao);
            reload();
        })
        .catch(async (err) => {
            if (err.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(obterDadosUsuario2().token, obterDadosUsuario2().tokenRenovacao)
                    .then((res2) => {
                        guardarDadosUsuario2(
                            res2.data.camposAdicionais.tokenAcesso,
                            res2.data.camposAdicionais.tokenRenovacao,
                            obterDadosUsuario2().email,
                            obterDadosUsuario2().id.toString()
                        );
                        editarServicoFinanceiro(
                            id,
                            nome,
                            descricao,
                            estado,
                            res2.data.camposAdicionais.tokenAcesso
                        )
                            .then((res) => {
                                limparCampos(setNome, setDescricao);
                                setSucesso({
                                    ...sucesso,
                                    sucesso: true,
                                    mensagem: "Serviço financeiro atualizado com sucesso.",
                                });
                                reload();
                            })
                            .catch(() => {
                                removerDadosUsuario2();
                                setModalDesconectar(true);
                            });
                    })
                    .catch(() => {
                        removerDadosUsuario2();
                        setModalDesconectar(true);
                    });
            } else if (err.response.data.Codigo === 500) {
                setErro({
                    ...erro,
                    erro: true,
                    mensagem: "Algo deu errado, tente novamente mais tarde.",
                });
            } else {
                setErro({
                    ...erro,
                    erro: true,
                    mensagem: "Algo deu errado.",
                });
            }
        })
        .finally(() => setLoading(false));
};

export const handleConfirm = (
    e: React.FormEvent,
    tipo: string,
    setErro: (value: React.SetStateAction<Erro>) => void,
    erro: Erro,
    id: number,
    nome: string,
    labelNome: string,
    descricao: string,
    labelDescricao: string,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    estado: boolean,
    setNome: React.Dispatch<React.SetStateAction<string>>,
    setDescricao: React.Dispatch<React.SetStateAction<string>>,
    setSucesso: (value: React.SetStateAction<Sucesso>) => void,
    sucesso: Sucesso,
    reload: () => void,
    setModalDesconectar: (value: React.SetStateAction<boolean>) => void,
    dadosUsuario: DadosUsuario,

) => {
    e.preventDefault();

    tipo === "criar" ?
        incluirServico(setErro, erro, nome, descricao, setLoading, estado, setNome, setDescricao, setSucesso, sucesso, reload, setModalDesconectar)
        :
        atualizarServico(setErro, erro, id, nome, labelNome, descricao, labelDescricao, setLoading, estado, setNome, setDescricao, setSucesso, sucesso, reload, setModalDesconectar);
};
