import React, { useState } from 'react';

import { Usuario, UsuarioPJ, Socios } from '../../pages/Cliente/Cadastro/Cadastro';
import CadastroPF from '../CadastroPF/CadastroPF';
import CadastroPJ from '../CadastroPJ/CadastroPJ';

interface CadastroDadosCadastraisProps {
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    setUsuarioPJ: React.Dispatch<React.SetStateAction<UsuarioPJ>>,
    socios: Socios[];
    setSocios: React.Dispatch<React.SetStateAction<Socios[]>>,
    setOpcaoSelecionada: React.Dispatch<React.SetStateAction<string>>,
}

export const CadastroDadosCadastrais = ({
    setUsuarioPF, setUsuarioPJ, socios, setSocios, setOpcaoSelecionada
}: CadastroDadosCadastraisProps) => {
    const [mostrarCadastroPF, setMostrarCadastroPF] = useState<boolean>(false);
    const [mostrarCadastroPJ, setMostrarCadastroPJ] = useState<boolean>(false);

    const PF = () => {
        setMostrarCadastroPF(true)
        setMostrarCadastroPJ(false)
        setOpcaoSelecionada('PessoaFisica')
    }

    const PJ = () => {
        setMostrarCadastroPF(false)
        setMostrarCadastroPJ(true)
        setOpcaoSelecionada('PessoaJuridica')
    }
    return (
        <div>
            <h3 className="TextoTitulo">DADOS CADASTRAIS</h3>
            <div className="Area_SelecaoServico">
                <div>
                    <input
                        type="radio"
                        value="pessoa física"
                        name="tipo de pessoa"
                        onClick={PF}
                    />
                    <label className="TextoSubTitulo">Pessoa Física</label>
                </div>
                <div>
                    <input
                        type="radio"
                        value="pessoa jurídica"
                        name="tipo de pessoa"
                        onClick={PJ}
                    />
                    <label className="TextoSubTitulo">Pessoa Jurídica</label>
                </div>
            </div>
            {mostrarCadastroPF &&
                <CadastroPF
                    setUsuarioPF={setUsuarioPF}
                />}
            {mostrarCadastroPJ &&
                <CadastroPJ
                    setUsuarioPJ={setUsuarioPJ}
                    socios={socios}
                    setSocios={setSocios}
                />
            }
        </div>
    )
}