export const ItensHomeGestorLinha1 = [
    {
        classnameDiv: 'caixaRelatorios__fieldset_left',
        titulo: 'Gerenciar Cadastro',
        subtitulo: 'Aprove ou reprove o cadastro do cliente e confira os documentos fornecidos.',
        link: '/gerenciar/cadastro',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_center',
        titulo: 'Ver Todos os Clientes',
        subtitulo: 'Veja uma lista de clientes e suas informações.',
        link: '/usuarios',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_right',
        titulo: 'Cadastrar Novo Funcionário',
        subtitulo: 'Cadastre um novo funcionário para utilizar o sistema.',
        link: '/funcionario/cadastro',
    }
];

export const ItensHomeGestorLinha2 = [
    {
        classnameDiv: 'caixaRelatorios__fieldset_left',
        titulo: 'Gestão Financeira - Validação de Aporte',
        subtitulo: 'Valide os aportes realizados pelos clientes e confira os recibos de depósitos.',
        link: '/validacao/aporte',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_center',
        titulo: 'Gestão Financeira - Solicitaçao de Saque',
        subtitulo: 'Valide os saques solicitados pelos clientes e envie os comprovantes.',
        link: '/solicitacoes/saque',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_right',
        titulo: 'Cadastrar Template Email',
        subtitulo: 'Cadastre um novo template de email no sistema.',
        link: '/gerenciar/template-email',
    }

];

export const ItensHomeGestorLinha3 = [
    {
        classnameDiv: 'caixaRelatorios__fieldset_left',
        titulo: 'Gestão Financeira - Fechamento',
        subtitulo: 'Faça o fechamento diário da carteira, preenchendo as informações necessárias.',
        link: '/fechamento',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_center',
        titulo: 'Gestão Financeira - Histórico Fechamento',
        subtitulo: 'Consulte os fechamentos de períodos anteriores.',
        link: '/gerenciar/historico-fechamento',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_right',
        titulo: 'Gerenciar Funcionários',
        subtitulo: 'Veja uma lista com todos os funcionários cadastrados.',
        link: '/funcionarios',
    },

];

export const itens = [
    {
        classnameDiv: 'caixaRelatorios__fieldset_left',
        titulo: 'Gerenciar Cadastro',
        subtitulo: 'Aprove ou reprove o cadastro do cliente e confira os documentos fornecidos.',
        link: '/gerenciar/cadastro',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_center',
        titulo: 'Ver Todos os Clientes',
        subtitulo: 'Veja uma lista de clientes e suas informações.',
        link: '/usuarios',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_right',
        titulo: 'Cadastrar Novo Funcionário',
        subtitulo: 'Cadastre um novo funcionário para utilizar o sistema.',
        link: '/funcionario/cadastro',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_left',
        titulo: 'Gestão Financeira - Validação de Aporte',
        subtitulo: 'Valide os aportes realizados pelos clientes e confira os recibos de depósitos.',
        link: '/validacao/aporte',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_center',
        titulo: 'Gestão Financeira - Solicitaçao de Saque',
        subtitulo: 'Valide os saques solicitados pelos clientes e envie os comprovantes.',
        link: '/solicitacoes/saque',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_right',
        titulo: 'Cadastrar Template Email',
        subtitulo: 'Cadastre novos template de email no sistema.',
        link: '/gerenciar/template-email',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_left',
        titulo: 'Gestão Financeira - Fechamento',
        subtitulo: 'Faça o fechamento diário da carteira, preenchendo as informações necessárias.',
        link: '/fechamento',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_center',
        titulo: 'Gestão Financeira - Histórico Fechamento',
        subtitulo: 'Consulte os fechamentos de períodos anteriores.',
        link: '/gerenciar/historico-fechamento',
    },
    {
        classnameDiv: 'caixaRelatorios__fieldset_right',
        titulo: 'Gerenciar Funcionários',
        subtitulo: 'Veja uma lista com todos os funcionários cadastrados.',
        link: '/funcionarios',
    },
]