import React, { useEffect, useState } from "react";
import "../TabelaFechamento/TabelaFechamento.css";

import { Cotacoes } from "../../../pages/Gestor/Fechamento/Fechamento";
import { mascaraUSD } from "../../../functions/MascarasMonetarias";

interface TabelaModalHistoricoFechamentoProps {
    sigla: string;
    ativo: string;
    quantidade: number;
    valorUnitario: number;
    quantidadeTotal?: number;
    total: number;
    fonteBold: boolean,
    manual?: boolean;
    porcentagemDoAtivoNaCarteira?: number;
    removerNovoAtivoOffline?: (index: number) => void;
    index: number;
    listaCotacoes?: Cotacoes[];
    setListaCotacoes?: (cotacao: Cotacoes[]) => void;
}

export const TabelaModalHistoricoFechamento = ({
    ativo,
    quantidade,
    valorUnitario,
    total,
    fonteBold,
    porcentagemDoAtivoNaCarteira
}: TabelaModalHistoricoFechamentoProps) => {

    return (
        <tr id="trAvisoSemRegistros" className="tabelaLinha">
            <th className="table-secondary tableConteudo">
                <h6>{ativo}</h6>
            </th>
            <th className="table-secondary tableConteudo">
                {fonteBold ?
                    <strong>{quantidade.toFixed(6)}</strong>
                    :
                    <h6>{quantidade.toFixed(6)}</h6>
                }
            </th>
            <th className="table-secondary tableConteudo">
                {fonteBold ?
                    <strong>US$ {mascaraUSD(valorUnitario.toFixed(2))}</strong>
                    :
                    <h6>US$ {mascaraUSD(valorUnitario.toFixed(2))}</h6>
                }
            </th>
            <th className="table-secondary tableConteudo">
                {fonteBold ?
                    <strong>
                        {porcentagemDoAtivoNaCarteira < 0.000999
                            ? porcentagemDoAtivoNaCarteira.toFixed(3)
                            : porcentagemDoAtivoNaCarteira.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}
                        %
                </strong>
                    :
                    <h6>{porcentagemDoAtivoNaCarteira < 0.000999
                        ? porcentagemDoAtivoNaCarteira.toFixed(3)
                        : porcentagemDoAtivoNaCarteira.toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}
                        %
                    </h6>
                }

            </th>
            <th className="table-secondary tableConteudo">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingRight: 10,
                    }}
                >
                    {fonteBold ?
                        <strong>US$ {mascaraUSD(total.toFixed(2))}</strong>
                        :
                        <h6>US$ {mascaraUSD(total.toFixed(2))}</h6>
                    }
                </div>
            </th>
        </tr>
    );
};
