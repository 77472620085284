import React, { useState } from 'react'

import './styles.css'

import { Arrow } from '../../arrow';

import AnimateHeight from 'react-animate-height';
import { useNavigate } from 'react-router-dom'
import { CardSidebarProps } from '../CardSidebar';

interface CardSubItemSidebarProps {
    title: string;
    path: string;
    subItens: CardSidebarProps[];
}

export const CardSubItemSidebar = ({ title, subItens, path, }: CardSubItemSidebarProps) => {
    const [subItensOpen, setSubItensOpen] = useState<boolean>(false)
    const [arrowColor, setArrowColor] = useState<string>('white')

    const navigate = useNavigate()

    return (
        <>
            <p className='nav-text nav-subtext'
                onMouseEnter={() => setArrowColor('#f7931b')}
                onMouseLeave={() => setArrowColor('white')}
                onClick={() => path ? navigate(path) : setSubItensOpen(!subItensOpen)}>
                {title}
                {subItens && <Arrow className='arrow-cardsidebar' color={arrowColor} down={!subItensOpen} />}
            </p>
            <AnimateHeight
                duration={500}
                height={subItensOpen ? 'auto' : 0}
            >
                {subItens && subItens.map((item, index) => (
                    <p key={index} className='nav-text nav-subItem' onClick={() => item.path ? navigate(item.path) : setSubItensOpen(!subItensOpen)}>{item.title}</p>
                ))}
            </AnimateHeight>
        </>
    )

}