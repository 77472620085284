import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { guardarDadosUsuario, guardarDadosUsuario2 } from "../../../services/cookies";
import { operacaoFinanceiraAporteSaque } from "../../../services/operacaoFinanceira";
import { renovacaoToken } from "../../../services/usuario";
import { DadosUsuario } from "../ModalNovoServicoFinanceiro";
import { alterarStatusDaConta } from "../../../services/cliente";

export const enviarChaveAPI = async (
    e: React.FormEvent,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    dadosUsuario: DadosUsuario,
    idCredenciais: string,
    APIKey: string,
    secretKey: string,
    passPhrase: string,
    setMensagem: (value: React.SetStateAction<string>) => void,
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setFalha: (value: React.SetStateAction<boolean>) => void,
    setAPIKey: (value: React.SetStateAction<string>) => void,
    setSecretKey: (value: React.SetStateAction<string>) => void,
    setPassPhrase: (value: React.SetStateAction<string>) => void,
    setModalDesconectar: (value: React.SetStateAction<boolean>) => void,
) => {
    e.preventDefault();

    setLoading(true);

    await alterarStatusDaConta(
        idCredenciais,
        dadosUsuario.token,
        true,
        1,
        APIKey,
        secretKey,
        passPhrase
    )
        .then((res) => {
            setMensagem(
                "API Key cadastrada com sucesso para o cliente selecionado."
            );
            setLoading(false);
            setSucesso(true);
            setFalha(false);
            setAPIKey("");
            setSecretKey("");
            setPassPhrase("");
        })
        .catch(async (error) => {
            switch (error.response.status) {
                case 400:
                    if (error.response.data.message === "O valor é obrigatório.") {
                        setMensagem(
                            "Verifique o preenchimento dos campos e tente novamente."
                        );
                    }
                    setFalha(true);
                    break;
                case (error.response.status === 401):
                    await renovacaoToken(
                        dadosUsuario.token,
                        dadosUsuario.tokenRenovacao
                    )
                        .then(async (res2) => {
                            guardarDadosUsuario(
                                res2.data.camposAdicionais.tokenAcesso,
                                res2.data.camposAdicionais.tokenRenovacao,
                                dadosUsuario.email.toString(),
                                dadosUsuario.id.toString()
                            );
                            guardarDadosUsuario2(
                                res2.data.camposAdicionais.tokenAcesso,
                                res2.data.camposAdicionais.tokenRenovacao,
                                dadosUsuario.email.toString(),
                                dadosUsuario.id.toString()
                            );
                            await alterarStatusDaConta(
                                idCredenciais,
                                dadosUsuario.token,
                                true,
                                1,
                                APIKey,
                                secretKey,
                                passPhrase
                            )
                                .then((res2) => {
                                    setMensagem(
                                        "API Key cadastrada com sucesso para o cliente selecionado."
                                    );
                                    setLoading(false);
                                    setSucesso(true);
                                    setFalha(false);
                                    setAPIKey("");
                                    setSecretKey("");
                                    setPassPhrase("");
                                })
                                .catch((e2) => {
                                    setLoading(false);
                                    setSucesso(false);
                                    setFalha(true);
                                    switch (e2.response.status) {
                                        case 400:
                                            if (
                                                APIKey === "" ||
                                                APIKey === null ||
                                                APIKey === undefined
                                            )
                                                setMensagem(
                                                    "Verifique o preenchimento dos campos e tente novamente."
                                                );
                                            break;
                                        case 401:
                                            setModalDesconectar(true);
                                            setMensagem(
                                                "Você foi desconectado. Faça o login e tente novamente!"
                                            );
                                            break;
                                        case 404:
                                            setMensagem(
                                                "Usuário não credenciado à operação solicitada."
                                            );
                                            break;
                                        case 500:
                                            setMensagem(
                                                "Algo deu errado, tente novamente mais tarde."
                                            );
                                            break;
                                    }
                                });
                        })
                        .catch((err3) => {
                            setModalDesconectar(true);
                        });
                    // setMensagem('Você foi desconectado. Faça o login e tente novamente!')
                    break;
                case 404:
                    setMensagem("Usuário não credenciado à operação solicitada.");
                    setFalha(true);
                    break;
                case 500:
                    setMensagem("Algo deu errado, tente novamente mais tarde.");
                    setFalha(true);
                    break;
            }
            setLoading(false);
            setAPIKey("");
            setSecretKey("");
            setPassPhrase("");
        }).finally(() => setLoading(false));
};

export const handleHide = (
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setFalha: (value: React.SetStateAction<boolean>) => void,
    setChaveApi: (value: React.SetStateAction<string>) => void,
    setModal: (modal: boolean) => void,
    setReload: React.Dispatch<React.SetStateAction<boolean>>,
    reload: boolean
) => {
    setModal(false);
    setSucesso(false);
    setFalha(false);
    setReload(!reload);
    setChaveApi("");
};