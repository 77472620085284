import { api } from "./api";
import { ImagensDocumentacao, Socios } from "../pages/Cliente/Cadastro/Cadastro";



interface usuario {
  "email": string,
  "senha": string,
  "userName": string,
  "imagemPerfil": string | ArrayBuffer,
  "nome": string,
  "cpf": string,
  "rg": string,
  "orgaoExpeditorRg": string,
  "ufOrgaoExpeditor": string,
  "profissao": string,
  "renda": number,
  "telefone": string,
  "dataNascimento": string,
  "nacionalidade": string,
  "idsServicosFinanceiros": number[],
  "endereco": {
    "rua": string,
    "complemento": string,
    "bairro": string,
    "cidade": string,
    "estado": string,
    "cep": string
  },
  "imagensDocumentacao": ImagensDocumentacao[],
  "estadoCivil": "Solteiro" | "Casado" | "Divorciado" | "Separado" | "Viúvo"
}

interface usuarioPJ {
  "nome": string,
  "email": string,
  "razaoSocial": string,
  "cnpj": string,
  "imagemPerfil": string | ArrayBuffer,
  "status": boolean,
  "userName": string,
  "senha": string,
  "telefone": string,
  "idsServicosFinanceiros": number[],
  "endereco": {
    "rua": string,
    "complemento": string,
    "bairro": string,
    "cidade": string,
    "estado": string,
    "cep": string
  },
  "imagensDocumentacao": ImagensDocumentacao[],
  "socios": Socios[]
}

export const incluirPessoaFisica = (usuario: usuario) => {

  const url = `/Cliente/PessoaFisica`;
  return api.post(url, { usuario });
};

export const incluirPessoaJuridica = (usuario: usuarioPJ) => {

  const url = `/Cliente/PessoaJuridica`;
  return api.post(url, { usuario });
};

export const verificarDisponibilidadeCpf = (cpf: string) => {
  const url = `/Cliente/Cpf/VerificarDisponibilidate?Cpf=${cpf}`;
  return api.get(url);
};

export const verificarDisponibilidadeCnpj = (cnpj: string) => {
  const url = `/Cliente/Cpf/VerificarDisponibilidate?Cpf=${cnpj}`;
  return api.get(url);
};

export const obterOperacoesFinceirasPendentesPorTipo = (
  IdServicoFinanceiro: number,
  TipoOperacaoFinaceira: string,
  StatusOperacaoFinaceira: string,
  token: string
) => {
  const url = `/Cliente/Filtar/OperacoesFinanceiras?IdServicoFinanceiro=${IdServicoFinanceiro}&OperacaoFinanceira=${TipoOperacaoFinaceira}&StatusOperacaoFinanceira=${StatusOperacaoFinaceira}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const obterExtratoDoCliente = (
  IdCredinciaisCliente: string,
  IdServicoFinanceiro: number,
  token: string,
  dataInicial: Date,
  dataFinal: Date
) => {
  const DataInicial = encodeURIComponent(dataInicial.toISOString());
  const DataFinal = encodeURIComponent(dataFinal.toISOString());
  // const DataFinal = `${ano}-${mes}-29T14%3A32%3A52.931Z`;
  // const DataInicial = `${ano.toString()}-${(mes + 1).toString()}-01T14%3A32%3A52.931Z`;
  const url = `/Cliente/Extrato?IdCredenciaisCliente=${IdCredinciaisCliente}&IdServicoFinaceiro=${IdServicoFinanceiro}&DataInicial=${DataInicial}&DataFinal=${DataFinal}`;
  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const obterContaParaRecebimento = (
  IdCredenciaisCliente: string,
  token: string
) => {
  const url = `/Cliente/ContaParaRecebimento?IdCredenciaisCliente=${IdCredenciaisCliente}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};


export const salvarContaParaRecebimento = (idCredenciaisUsuario: string, token: string, nomeFavorecido?: string, tipoDocumento?: string, numeroDocumento?: string, tipoChavePix?: string, chavePix?: string, numeroConta?: string, agencia?: string, nomeBanco?: string, codigoBanco?: number, apelido?: string) => {
  const url = "/Cliente/ContaParaRecebimento"

  const contaParaRecebimento = {
    idCredenciaisUsuario,
    conta: {
      nomeFavorecido,
      tipoDocumento,
      numeroDocumento,
      tipoChavePix,
      chavePix,
      numeroConta,
      agencia,
      nomeBanco,
      codigoBanco,
      apelido
    }
  }

  return api.post(url, contaParaRecebimento, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const obterUsuariosEmEspera = (
  token: string
) => {
  const url = `/Cliente/EmEspera`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const obterDadosPessoaisPF = (
  token: string,
  idCliente: string
) => {
  const url = `/Cliente/PessoaFisica/DadosPessoais?IdCredenciaisCliente=${idCliente}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const alterarStatusDaConta = (idCredenciaisUsuario: string, token: string, contaPrivate: boolean, status: number, APIKey?: string, secretKey?: string, passPhrase?: string) => {
    const url = `/Cliente/StatusCadastro?IdCredenciais=${idCredenciaisUsuario}&Status=${status}&ContaPrivate=${contaPrivate}&ApiKey=${APIKey}&SecretKey=${secretKey}&PassPhrase=${passPhrase}`;


    return api.put(url, {}, {
        headers: {
            Authorization: "Bearer " + token
        },
    }
    );
};

export const atualizarDadosPessoaisPF = (idCredenciaisCliente: string, token: string, fotoUsuario: string, rua: string, complemento: string, bairro: string, cidade: string, estado: string, cep: string, profissao: string, renda: number, estadoCivil: string) => {
  const url = "/Cliente/PessoaFisica/DadosPessoais";

  const dados = {
    idCredenciaisCliente,
    dadosPessoais: {
      fotoUsuario,
      rua,
      complemento,
      bairro,
      cidade,
      estado,
      cep,
      profissao,
      renda,
      estadoCivil
    }
  }

  return api.put(url, dados, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const contato = (
  token: string,
  emailRementente: string,
  nome: string,
  telefone: string,
  motivoContato: string,
  mensagem: string
) => {
  const url = `/Cliente/Contato/EnviarMensagem`;

  return api.post(url, { emailRementente, nome, telefone, motivoContato, mensagem }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getClienteServicosFinanceiros = (token: string, IdCredenciais: string) => {
  const url = `/Cliente/FundosFinanceiros?IdCredenciais=${IdCredenciais}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const obterTipoCliente = (token: string, IdCredenciais: string) => {
  const url = `/Cliente/TipoCliente?IdCredenciais=${IdCredenciais}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const obterDadosPessoaisPJ = (token: string, IdCredenciaisCliente: string) => {
  const url = `/Cliente/PessoaJuridica/DadosPessoais?IdCredenciaisCliente=${IdCredenciaisCliente}`

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const alterarDadosCadastraisPJ = (token: string, idCredenciais: string, rua: string, complemento: string, bairro: string, cidade: string, estado: string, cep: string) => {

  const url = "/Cliente/PessoaJuridica/DadosPessoais"

  const dadosPessoais = {
    idCredenciaisCliente: idCredenciais,
    dadosPessoais: {
      rua,
      complemento,
      bairro,
      cidade,
      estado,
      cep
    }
  }

  return api.put(url, dadosPessoais, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const getTodosClienteServicosFinanceiros = (
  token: string,
  idServicoFinanceiro: number,
  numeroPagina: number,
  tamanhoPagina: number
) => {
  const url = `/Cliente/Todos/ServicoFinanceiro?IdServicoFinanceiro=${idServicoFinanceiro}&NumeroPagina=${numeroPagina}&TamanhoPagina=${tamanhoPagina}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

