import React, { useEffect, useState } from "react";
import { obterServicosFinanceiros, servicoFinanceiroProps } from "./functions";
import './styles.css'

export interface SelectServicoFinanceiroProps {
    setServico: React.Dispatch<number>,
    className?: string
}

export const SelectServicoFinanceiro = ({ setServico, className }: SelectServicoFinanceiroProps) => {
    const [listaServicosFinanceiros, setListaServicosFinanceiros] = useState<servicoFinanceiroProps[]>([])
    const [erro, setErro] = useState<boolean>(false);

    useEffect(() => {
        obterServicosFinanceiros({ setListaServicosFinanceiros, setErro })
    }, [])

    return (
        <select className={className ? className : "selectMenor SelectServicoFinanceiro"} onChange={(e) => setServico(Number(e.target.value))}>
            {listaServicosFinanceiros.map(servico => <option value={servico.id}>{servico.nome}</option>)}
        </select>
    )
}