import React, { useRef } from 'react'
import { handleDocumentoUpload } from './functions';

import { Erro } from '../../pages/Cliente/AlteracaoSenha/AlteracaoSenha';

import { Input } from '../Input'
import { Link } from 'react-router-dom';

interface ImagensDocumentacao {
    tipoDocumento: string,
    conteudo: string | ArrayBuffer | null,
    nomeArquivo: string,
    tamanho: string,
    dataUpload: string,
}
interface CadastroDocumentos {
    imagensDocumentacao: Array<ImagensDocumentacao>,
    setImagensDocumentacao: React.Dispatch<React.SetStateAction<Array<ImagensDocumentacao>>>,
    setError: React.Dispatch<React.SetStateAction<Erro>>,
    setCompromisso: React.Dispatch<React.SetStateAction<boolean>>,

}

export const CadastroDocumentos = ({ imagensDocumentacao, setImagensDocumentacao, setError, setCompromisso }: CadastroDocumentos) => {
    const filesElement = useRef(null);

    return (
        <div>
            <h3 className="TextoTitulo">DOCUMENTAÇÃO</h3>
            <div className="Area_ServicoDocumentacao">
                <h5 className="TextoSubTitulo">
                    Anexe os documentos necessários
                </h5>
            </div>
            <div className="Area_DocumentosAnexos">
                <div>
                    <label className="DocumentosAnexos">RG</label>
                    <Input
                        className="DocumentosAnexos_Botao"
                        type="file"
                        onChange={(e) => {
                            let tipoDocumento: string = "RG"
                            handleDocumentoUpload(e, tipoDocumento, setError, setImagensDocumentacao, imagensDocumentacao)
                        }}
                        multiple
                        ref={filesElement}
                        accept="image/*,.pdf"
                    />
                </div>
                <div>
                    <label className="DocumentosAnexos">CPF</label>
                    <Input
                        className="DocumentosAnexos_Botao"
                        type="file"
                        onChange={(e: any) => {
                            let tipoDocumento: string = "CPF"
                            handleDocumentoUpload(e, tipoDocumento, setError, setImagensDocumentacao, imagensDocumentacao)
                        }}
                        multiple
                        ref={filesElement}
                        accept="image/*,.pdf"
                    />
                </div>
                <div>
                    <label className="DocumentosAnexos">
                        Comprovante de residência
                    </label>
                    <Input
                        className="DocumentosAnexos_Botao"
                        type="file"
                        onChange={(e) => {
                            let tipoDocumento: string = 'ComprovanteDeResidencia'
                            handleDocumentoUpload(e, tipoDocumento, setError, setImagensDocumentacao, imagensDocumentacao)
                        }}
                        multiple
                        ref={filesElement}
                        accept="image/*,.pdf"
                    />
                </div>
                <div>
                    <input
                        type="checkbox"
                        id="chkCobrarTaxas"
                        name="chkCobrarTaxas"
                        onChange={(e) => setCompromisso(e.target.checked)}
                    />
                    <label htmlFor="chkCobrarTaxas" className="espacamentoCheckbox">
                        Declaro que li e aceito os <Link target="_blank" rel="noopener noreferrer" to="/termos-de-uso">

                            termos de compromisso.

                        </Link>
                    </label>
                </div>
            </div>
        </div>
    )
}