import { buscarDadosCep } from "../../../services/api";
import { UsuarioPJ, Socios } from "../../../pages/Cliente/Cadastro/Cadastro";

export const mascaraCep = (
  e: any,
  cep: string,
  setCep: React.Dispatch<React.SetStateAction<string>>
) => {
  setCep(e);
  setCep(e.replace(/\D/g, "[0-9]{2}[.]?[0-9]{3}[-][0-9]{3}[-]"));
};

export const checkCEP = async (
  setEndereco: (value: React.SetStateAction<string>) => void,
  setBairro: (value: React.SetStateAction<string>) => void,
  setCidade: (value: React.SetStateAction<string>) => void,
  setEstado: (value: React.SetStateAction<string>) => void,
  cep: string
) => {
  buscarDadosCep(cep).then((res) => {
    setEndereco(res.data.logradouro);
    setBairro(res.data.bairro);
    setCidade(res.data.localidade);
    setEstado(res.data.uf);
  });
};

export const handleReset = (
  setNome: (value: React.SetStateAction<string>) => void,
  setCep: (value: React.SetStateAction<string>) => void,
  setEndereco: (value: React.SetStateAction<string>) => void,
  setBairro: (value: React.SetStateAction<string>) => void,
  setCidade: (value: React.SetStateAction<string>) => void,
  setEstado: (value: React.SetStateAction<string>) => void,
  setComplemento: (value: React.SetStateAction<string>) => void,
  setError: (value: React.SetStateAction<boolean>) => void,
  setModal: (modal: boolean) => void
) => {
  setNome("");
  setCep("");
  setEndereco("");
  setBairro("");
  setCidade("");
  setEstado("");
  setComplemento("");
  setError(false);
  setModal(false);
};
