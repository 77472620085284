import React, { useState } from "react";

import {
    mascaraCep,
    checkCEP,
    handleReset
} from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";

import Modal from "react-bootstrap/Modal";
import { Socios } from "../../../pages/Cliente/Cadastro/Cadastro";

interface modalProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    socios: Socios[];
    setSocios: React.Dispatch<React.SetStateAction<Socios[]>>
}

export const ModalCadastroSocio = ({
    modal,
    setModal,
    socios,
    setSocios,
}: modalProps) => {
    const [error, setError] = useState<boolean>(false);

    const [nome, setNome] = useState<string>("");
    const [cep, setCep] = useState<string>("");
    const [rua, setRua] = useState<string>("");
    const [bairro, setBairro] = useState<string>("");
    const [cidade, setCidade] = useState<string>("");
    const [estado, setEstado] = useState<string>("");
    const [complemento, setComplemento] = useState<string>("");

    function adicionarSocio() {
        setSocios([...socios,
        {
            nome,
            endereco:
            {
                rua,
                complemento,
                bairro,
                cidade,
                estado,
                cep
            }
        }]);
        handleReset(setNome, setRua, setComplemento, setBairro, setCidade, setEstado, setCep, setError, setModal);
    }

    function handleConfirm() {
        nome == "" || cep == "" || rua == "" || bairro == "" || cidade == "" || estado == "" || complemento == "" ?
            setError(true)
            :
            adicionarSocio()
    }

    return (
        <div>
            <Modal show={modal} onHide={() => handleReset(
                setNome,
                setCep,
                setRua,
                setBairro,
                setCidade,
                setEstado,
                setComplemento,
                setError,
                setModal
            )}>
                <Modal.Header closeButton>
                    <Modal.Title>Novo Socio</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Input required={true} type="text" label="Nome do Sócio" placeholder="Nome do Sócio" id={"nome"} onChange={(e) => setNome(e.target.value)} />
                    <Input required={true} label="CEP:" placeholder="CEP" id={"cep"} onChange={(e: any) => mascaraCep(
                        e.target.value,
                        cep,
                        setCep
                    )} onBlur={
                        () => checkCEP(
                            setRua,
                            setBairro,
                            setCidade,
                            setEstado,
                            cep
                        )}
                    />
                    <Input required={true} label="Cidade" placeholder="Cidade" id={"cidade"} value={cidade} onChange={(e) => setCidade(e.target.value)} />
                    <Input required={true} label="Estado" placeholder="Estado" id={"estado"} value={estado} onChange={(e) => setEstado(e.target.value)} />
                    <Input required={true} label="Bairro" placeholder="Bairro" id={"bairro"} value={bairro} onChange={(e) => setBairro(e.target.value)} />
                    <Input required={true} label="Endereco" placeholder="Endereco" id={"endereco"} value={rua} onChange={(e) => setRua(e.target.value)} />
                    <Input required={true} label="Número e Complemento" placeholder="Número e Complemento" id={"complemento"} onChange={(e: any) => setComplemento(e.target.value)} />

                    {
                        error === true &&
                        <p style={{ textAlign: "center", color: "red" }}>Verifique se todos os campos foram preenchidos corretamente.</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        classNameButton="btnConfirmar"
                        titleButton="Adicionar"
                        onClick={() => { handleConfirm() }}
                    />
                    <Button
                        classNameButton="btnCancelar"
                        onClick={() => { setModal(false); setError(false) }}
                        titleButton="Fechar"
                    />
                </Modal.Footer>

            </Modal>
        </div>
    );
};
