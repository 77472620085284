import React, { useState } from "react";

import './AlteracaoSenha.css'

import { salvarSenha } from "./functions";

import { Button } from "../../../components/Button";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { LoadingButton } from "../../../components/loadingButtom";
import Navbar from "../../../components/Navbar/Navbar";
import SegurancaDeSenha from "../../../components/SegurancaSenha/SegurancaSenha";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { Sucesso } from "./Sucesso";

export interface Erro {
  erro: boolean,
  mensagem: string
}

const AlteracaoSenha = () => {
  const [senhaAntiga, setSenhaAntiga] = useState<string>("");
  const [novaSenha, setNovaSenha] = useState<string>("");
  const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState<string>("");
  const [erro, setErro] = useState<Erro>({ erro: false, mensagem: "" });
  const [erroSenhaAtual, setErroSenhaAtual] = useState<Erro>({ erro: false, mensagem: "Insira uma senha valida e tente novamente." });
  const [sucesso, setSucesso] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [desconectar, setDesconectar] = useState<boolean>(false)

  return (
    <>
      <Navbar />
      <div id="container">
        <Header titulo={"Alterar Senha"} />
        <div id="divForm" className="titulo1">
          <fieldset>
            {sucesso ? <Sucesso /> :
              <form onSubmit={(e) => e.preventDefault()}>
                <Input
                  senha
                  required
                  placeholder=" "
                  label="Insira a senha antiga"
                  type="password"
                  onChange={(e) => setSenhaAntiga(e.target.value)}
                  value={senhaAntiga}
                />
                {erroSenhaAtual.erro && <p className="erro-senhaAtual">{erroSenhaAtual.mensagem}</p>}

                <Input
                  senha
                  required
                  placeholder=" "
                  label="Insira a nova Senha"
                  type="password"
                  onChange={(e) => setNovaSenha(e.target.value)}
                  value={novaSenha}
                />
                <SegurancaDeSenha senha={novaSenha} />
                <Input
                  senha
                  required
                  placeholder=" "
                  label="Confirme a nova Senha"
                  type="password"
                  onChange={(e) => setConfirmacaoNovaSenha(e.target.value)}
                  value={confirmacaoNovaSenha}
                />
                {erro.erro && <p className="MensagemDeErro">{erro.mensagem}</p>}
                {loading ? (
                  <div className="loading-container-AlteracaoSenha">
                    <LoadingButton />
                  </div>
                )
                  : (
                    <>
                      <Button titleButton="Alterar" onClick={() => salvarSenha(senhaAntiga, novaSenha, confirmacaoNovaSenha, setLoading, setSucesso, setErroSenhaAtual, erroSenhaAtual, setErro, erro, setDesconectar)} />
                    </>
                  )
                }
              </form>
            }
          </fieldset>
        </div>
      </div>
      {desconectar && <ModalDesconectar modal={desconectar} />}
    </>
  );
};

export default AlteracaoSenha;
