import React, { useState, useEffect } from "react";

import './styles.css'

import { handleMaskCEP, handleMaskCPF, handleMaskRenda, handleMaskTelefone, obterDadosCadastrais } from "./functions";

import { DateFormatter } from "../../functions/DateFormatter";

import { Button } from "../Button";
import { LoadingButton } from "../loadingButtom";
import { ErroTabela } from "../Erros/ErroTabela";
import { ModalDesconectar } from "../modais/ModalDesconectar";
import ImagemUsuario from '../../assets/img/default_user.png'
import { obterDadosUsuario2 } from "../../services/cookies";


import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";

import { ImagemPerfil } from "../ImagemPerfil";

interface DadosClienetPF {
    setDadosCadastraisAberto: React.Dispatch<boolean>;
    dadosCadastraisAberto: boolean
    setModalAlterar: React.Dispatch<boolean>;
    reload: boolean,
    setReload: React.Dispatch<boolean>
}

interface Documentacao {
    tipoDocumento: string;
    nomeArquivo: string;
    tamanho: string;
    dataUpload: string;
    urlImagem: string;
}

export interface Cliente {
    fotoUsuario: string;
    bairro: string;
    cidade: string;
    complemento: string;
    cpf: number;
    dataNascimento: string;
    email: string;
    estado: string;
    estadoCivil: string;
    nacionalidade: string;
    nome: string;
    orgaoExpeditor: string;
    renda: number;
    rg: number;
    rua: string;
    telefone: number
    ufOrgaoExpeditor: string;
    imagensDocumentacao?: Documentacao[];
}

export const DadosClientePF = ({ dadosCadastraisAberto, setDadosCadastraisAberto, setModalAlterar, reload, setReload }: DadosClienetPF) => {
    const [dadosUsuario, setDadosUsuario] = useState<Cliente>()
    const [loading, setLoading] = useState<boolean>(false)
    const [erro, setErro] = useState<boolean>(false)
    const [desconectar, setDesconectar] = useState<boolean>(false)
    const [reloadImagePerfil, setReloadImagePerfil] = useState<boolean>(false)
    const [srcFotoPerfil, setSrcFotoPerfil] = useState<string>(ImagemUsuario)


    useEffect(() => {
        const fotoPerfil = obterDadosUsuario2().urlAvatar;
        const srcFotoPerfil = fotoPerfil === "null" || !fotoPerfil ? ImagemUsuario : fotoPerfil
        setSrcFotoPerfil(srcFotoPerfil);
    }, [reloadImagePerfil])


    useEffect(() => {
        dadosCadastraisAberto && obterDadosCadastrais(setDadosUsuario, setLoading, setErro, setDesconectar)
    }, [reload, dadosCadastraisAberto])


    return <div className=" ">
        <>
            {erro ? <ErroTabela className="formatacaoGrafico" handleClick={() => setReload(!reload)} /> : <> {loading ? <div className="loading-area"><LoadingButton /></div> : <>
                <div className="caixa-dados-usuario">
                    <div className="container-imagem">
                        <ImagemPerfil reload={() => setReloadImagePerfil(!reloadImagePerfil)} editavel modalAlterar={true} imagem={srcFotoPerfil} />
                    </div>

                    <div className="dados-usuario">
                        {dadosUsuario?.nome && <p>Nome: {' ' + dadosUsuario?.nome}</p>}

                        {dadosUsuario?.email && <p>E-mail: {' ' + dadosUsuario?.email}</p>}

                        {(dadosUsuario?.telefone || dadosUsuario?.telefone === 0) && <p>Telefone: {' ' + handleMaskTelefone(dadosUsuario?.telefone.toString())}</p>}

                        {(dadosUsuario?.cpf || dadosUsuario?.cpf === 0) && <p>CPF: {' ' + handleMaskCPF(dadosUsuario?.cpf.toString())}</p>}

                        {dadosUsuario?.rg && <p>RG: {' ' + dadosUsuario.rg}</p>}

                        {(dadosUsuario?.orgaoExpeditor && dadosUsuario.ufOrgaoExpeditor) && <p>Órgão expeditor: {' ' + dadosUsuario?.orgaoExpeditor} - {' ' + dadosUsuario?.ufOrgaoExpeditor}</p>}

                        {(dadosUsuario?.renda || dadosUsuario?.renda === 0) && <p>Renda: {' ' + handleMaskRenda(dadosUsuario?.renda.toString())}</p>}

                        {dadosUsuario?.nacionalidade && <p>Nacionalidade: {' ' + dadosUsuario?.nacionalidade}</p>}

                        {dadosUsuario?.dataNascimento && <p>Data de nascimento: {' ' + DateFormatter(dadosUsuario?.dataNascimento)}</p>}

                        {dadosUsuario?.estadoCivil && <p>Estado civil: {' ' + dadosUsuario?.estadoCivil}</p>}

                        {dadosUsuario?.estado && <p>Estado:{' ' + dadosUsuario?.estado}</p>}

                        {dadosUsuario?.cidade && <p>Cidade: {' ' + dadosUsuario?.cidade}</p>}

                        {dadosUsuario?.bairro && <p>Bairro: {' ' + dadosUsuario?.bairro}</p>}

                        {dadosUsuario?.rua && <p>Rua: {' ' + dadosUsuario?.rua}</p>}

                        {dadosUsuario?.complemento && <p>Complemento: {' ' + dadosUsuario?.complemento}</p>}
                    </div>


                </div>

                <div
                    style={{ justifyContent: "center", flex: 1, display: "flex" }}
                >
                    <Button
                        className="btn__laranja"
                        onClick={() => setModalAlterar(true)}
                        titleButton="Alterar"
                    />
                    <Link
                        style={{ textDecoration: "none" }}
                        to="/cliente/alteracaoSenha"
                    >
                        <Button
                            className="btn__laranja"
                            titleButton="Trocar Senha"
                        />
                    </Link>
                </div>
            </>}</>}
        </>
        {desconectar && <ModalDesconectar modal={desconectar} />}
    </div>
}