import React from 'react'
import { BsFillPatchExclamationFill } from "react-icons/bs";
import "./styles.css"

interface MensagemErroProps {
    mensagem: string,
}
export const MensagemDeErro = ({ mensagem }: MensagemErroProps) => {
    return (
        <div className='ContainerFalha'>
            <BsFillPatchExclamationFill className="IconFalha" />
            <h4 className="textoResposta">{mensagem}</h4>
        </div>
    )
}