import React, { useEffect, useState } from "react";

import './styles.css'

import { salvarFotoPerfil } from './functions'

import { handleImageUpload } from "./functions";

import { Button } from "../../Button";
import { ImagemPerfil } from "../../ImagemPerfil";
import ImagemUsuario from '../../../assets/img/default_user.png'
import { LoadingButton } from "../../loadingButtom";

import Modal from "react-bootstrap/Modal";
import { Erro } from "./erro";
import { Sucesso } from "./Sucesso";
import { ModalDesconectar } from "../ModalDesconectar";
import { obterDadosCadastrais } from "../../DadosClientePF/functions";

import * as error from "../../../pages/Cliente/AlteracaoSenha/AlteracaoSenha"


interface ModalAlterarFotoDePerfilProps {
    modal: boolean;
    setModal: React.Dispatch<boolean>,
    reload: () => void;
}

export const ModalAlterarFotoDePerfil = ({ modal, setModal, reload }: ModalAlterarFotoDePerfilProps) => {
    const [imagem, setImagem] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [erro, setErro] = useState<boolean>(false)
    const [sucesso, setSucesso] = useState<boolean>(false)
    const [desconectar, setDesconectar] = useState<boolean>(false)

    const [dadosUsuario, setDadosUsuario] = useState<any>({})
    const [loadingDadosUsuario, setLoadingDadosUsuario] = useState<boolean>(false)

    const [error, setError] = useState<error.Erro>({ erro: false, mensagem: "" });

    useEffect(() => {
        obterDadosCadastrais(setDadosUsuario, setLoadingDadosUsuario, setErro, setDesconectar)
    }, [])

    return (
        <Modal show={modal} onHide={() => {
            setModal(false)
            setErro(false)
            setDesconectar(false)
            setSucesso(false)
            setImagem('')
        }}>
            <Modal.Header >
                <Modal.Title>Alterar Foto de Perfil</Modal.Title>
            </Modal.Header>
            {loadingDadosUsuario ? <Modal.Body className="container-loadingDadosUsuario"><LoadingButton /></Modal.Body> : <>
                {erro ? <Erro handleClose={() => {
                    setModal(false)
                    setErro(false)
                    setDesconectar(false)
                    setSucesso(false)
                    setImagem('')
                }} handleReload={() => setErro(false)} /> :
                    <>
                        {sucesso ? <Sucesso handleClose={() => {
                            setModal(false)
                            setErro(false)
                            setDesconectar(false)
                            setSucesso(false)
                            setImagem('')
                        }} /> :
                            <>
                                <Modal.Body className="container-ModalAlterarFotoDePerfil">
                                    <ImagemPerfil imagem={imagem ? imagem : ImagemUsuario} />
                                    <label htmlFor="button-file">
                                        <div className="button-alterar-foto">
                                            <p>Escolher Foto</p>
                                        </div>
                                    </label>
                                    <input id="button-file" accept="image/*" type="file" onChange={(e) => handleImageUpload(e, setImagem, setError)} />
                                </Modal.Body>
                                <Modal.Footer>
                                    {loading ?
                                        <LoadingButton /> :
                                        <>
                                            <Button titleButton='Salvar' onClick={() => salvarFotoPerfil(dadosUsuario, imagem, setLoading, setErro, setSucesso, setDesconectar, reload)} />
                                            <Button titleButton='Cancelar' onClick={() => {
                                                setModal(false)
                                                setErro(false)
                                                setDesconectar(false)
                                                setSucesso(false)
                                                setImagem('')
                                            }} />
                                        </>
                                    }
                                </Modal.Footer>
                            </>}
                    </>}
            </>

            }
            {desconectar && <ModalDesconectar modal={desconectar} />}
        </Modal>
    );
}
