import React, { useState } from 'react'

import "./styles.css"

import { ModalAlterarFotoDePerfil } from '../modais/ModalAlterarFotoDePerfil'
interface ImagemPerfilProps {
    imagem: string,
    modalAlterar?: boolean;
    editavel?: boolean
    reload?: () => void;
}

export const ImagemPerfil = ({ imagem, modalAlterar, editavel, reload }: ImagemPerfilProps) => {
    const [modal, setModal] = useState<boolean>(false)

    return (
        <>
            <div onClick={() => modalAlterar ? setModal(true) : null} className="">
                {editavel && <div className='text-edit' ><p>Editar Imagem</p></div>}
                <div className='container-imagem-perfil'>
                    <img alt="imagem de perfil" src={imagem} className='imagem-perfil' />
                </div>

            </div >
            {modal && <> <ModalAlterarFotoDePerfil reload={reload} modal={modal} setModal={setModal} />
            </>}
        </>
    )

}