import React from "react";
import ImagemOmegaImpressao from "../../../assets/img/logo.png";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { guardarDadosUsuario, guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies";
import { obterHistoricoFechamento } from "../../../services/fechamento";
import { obterIdFundoAcessoria } from "../../../services/fundoFinanceiro";
import { obterFundoFinanceiro, renovacaoToken } from "../../../services/usuario";
import { Fechamentos } from "./HistoricoFechamento";

export const criaPDFHistoricoFechamento = () => {
    const minhaTabela = document.getElementById("tblTransacoes")!.innerHTML;

    var style = "<style>";
    style = style + "table {width: 100%;font: 12px Calibri;}";
    style =
        style +
        "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
    style = style + "padding: 2px 3px;text-align: center;}";
    style = style + "img {width: 20%; height: 20%}";
    style =
        style +
        "div {display: flex; justify-content: center; margin-bottom: 15px;}";
    style = style + "</style>";

    // CRIA UM OBJETO WINDOW
    var win = window.open("", "", "height=700,width=700");
    win.document.write("<html><head>");
    win.document.write("<title></title>"); // <title> CABE�ALHO DO PDF.
    win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
    win.document.write("</head>");
    win.document.write("<body>");
    win.document.write(
        `<div><img src="${ImagemOmegaImpressao}"></img></div>`
    );
    win.document.write("<table>");
    win.document.write(minhaTabela);
    var removerColuna = win.document.getElementById("colunaFechamento");  //SELECIONA O HEADER DE COMPROVANTE
    removerColuna.parentNode.removeChild(removerColuna);  //REMOVE O HEADER DE COMPROVANTE
    const removerDadosColuna = win.document.querySelectorAll('.verComprovante'); //REMOVE OS BOTOES DE COMPROVANTE
    removerDadosColuna.forEach(verComprovante => {
        verComprovante.remove();
    });

    const TextoSelectData = win.document.querySelector('.select-data div button strong').textContent
    win.document.querySelector('.select-data').innerHTML = TextoSelectData
    // console.log(selectData)
    win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
    win.document.write("</body></html>");
    win.document.close(); // FECHA A JANELA
    setTimeout(function () {
        win.print(); // IMPRIME O CONTEUDO
    }, 500);
};

export async function handleHistoricoFechamento(
    dataInicial: Date,
    dataFinal: Date,
    setFechamentos: React.Dispatch<React.SetStateAction<Fechamentos[]>>,
    setError: React.Dispatch<boolean>,
    setLoading: React.Dispatch<boolean>,
    setDesconectar: React.Dispatch<boolean>,
    idFundoFinanceiro: string,
    token: string
) {
    setLoading(true);
    let dataInicialZerada = new Date(new Date(dataInicial.setHours(0, 1, 0))).toISOString()
    let dataFinalPesquisa = new Date(new Date(dataFinal.setHours(23, 59, 0))).toISOString()
    await obterHistoricoFechamento(dataInicialZerada, dataFinalPesquisa, true, token, idFundoFinanceiro).then((res) => {
        setFechamentos(res.data.resultado.fechamentos);
    }).catch(async (err) => {
        if (err.toString() === "Error: Request failed with status code 401") {
            const dadosUsuario = obterDadosUsuario2();
            renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
                .then((res) => {
                    guardarDadosUsuario2(
                        res.data.camposAdicionais.tokenAcesso,
                        res.data.camposAdicionais.tokenRenovacao,
                        dadosUsuario.email.toString(),
                        dadosUsuario.id.toString()
                    );
                    obterHistoricoFechamento(dataInicialZerada, dataFinalPesquisa, true, res.data.camposAdicionais.tokenAcesso, idFundoFinanceiro)
                        .then(res => {
                            setFechamentos(res.data.resultado.fechamentos);
                        })

                })
                .catch(() => setDesconectar(true));
        } else {
            setError(true);
        }
    }).finally(() => setLoading(false))
}

export const obterIdFundoFinanceiroAcessoria = (
    setErro: React.Dispatch<boolean>,
    setDesconectar: React.Dispatch<boolean>,
    dataInicial: Date,
    dataFinal: Date,
    setFechamentos: React.Dispatch<React.SetStateAction<Fechamentos[]>>,
    setLoading: React.Dispatch<boolean>,
) => {
    const { token } = obterDadosUsuario2()

    obterIdFundoAcessoria(token)
        .then(res => {
            handleHistoricoFechamento(dataInicial, dataFinal, setFechamentos, setErro, setLoading, setDesconectar, res.data.resultado.fundoFinanceiro.id, token);
        })
        .catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                const dadosUsuario = obterDadosUsuario2();
                renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
                    .then((res) => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            dadosUsuario.email.toString(),
                            dadosUsuario.id.toString()
                        );
                        obterIdFundoAcessoria(res.data.camposAdicionais.tokenAcesso,)
                            .then(res => {
                                handleHistoricoFechamento(dataInicial, dataFinal, setFechamentos, setErro, setLoading, setDesconectar, res.data.resultado.fundoFinanceiro.id, res.data.camposAdicionais.tokenAcesso);
                            })

                    })
                    .catch(() => setDesconectar(true))
            } else {
                setErro(true);
            }
        })
}

export const obterFundoFinaceiroPrivate = (
    idCredenciais: string,
    setErro: React.Dispatch<boolean>,
    setDesconectar: React.Dispatch<boolean>,
    dataInicial: Date,
    dataFinal: Date,
    setFechamentos: React.Dispatch<React.SetStateAction<Fechamentos[]>>,
    setLoading: React.Dispatch<boolean>,
) => {
    const { token } = obterDadosUsuario2()

    obterFundoFinanceiro(token, idCredenciais)
        .then(res => {
            const fundoPrivate = res.data.resultado.clientes.filter((fundo: { id: string, servicoFinanceiro: string }) => fundo.servicoFinanceiro === "Private")[0]
            handleHistoricoFechamento(dataInicial, dataFinal, setFechamentos, setErro, setLoading, setDesconectar, fundoPrivate.id, token);
        })
        .catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                const dadosUsuario = obterDadosUsuario2();
                renovacaoToken(dadosUsuario.token, dadosUsuario.tokenRenovacao)
                    .then((res) => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            dadosUsuario.email.toString(),
                            dadosUsuario.id.toString()
                        );
                        obterFundoFinanceiro(res.data.camposAdicionais.tokenAcesso, idCredenciais)
                            .then(res => {
                                const fundoPrivate = res.data.resultado.clientes.filter((fundo: { id: string, servicoFinanceiro: string }) => fundo.servicoFinanceiro === "Private")[0]
                                handleHistoricoFechamento(dataInicial, dataFinal, setFechamentos, setErro, setLoading, setDesconectar, fundoPrivate.id, res.data.camposAdicionais.tokenAcesso);
                            })

                    })
                    .catch(() => setDesconectar(true));
            } else {
                setErro(true);
            }
        })
}