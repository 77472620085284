import React, { useState } from 'react'

import './styles.css'

import Dropdown from "react-bootstrap/Dropdown";
import AnimateHeight from 'react-animate-height';
import { BsThreeDotsVertical } from 'react-icons/bs'

export interface CardFormaDePagamentoProps {
    nomeFavorecido?: string;
    tipoDocumento?: string;
    numeroDocumento?: string;
    tipoChavePix?: string;
    chavePix?: string;
    numeroConta?: string;
    agencia?: string;
    nomeBanco?: string;
    codigoBanco?: number;
    apelido?: string
}

export const CardFormaDePagamento = ({ agencia, chavePix, codigoBanco, nomeBanco, nomeFavorecido, numeroConta, numeroDocumento, tipoChavePix, tipoDocumento, apelido }: CardFormaDePagamentoProps) => {
    const [aberto, setAberto] = useState<boolean>(false)
    return (
        <div className='CardFormaDePagamento' >
            <div className='header-CardFormaDePagamento'>
                <div className='titulo-CardFormaDePagamento' onClick={() => setAberto(!aberto)}>
                    {chavePix ?
                        <>

                            {apelido ?
                                <p className='header-item-CardFormaDePagamento'>{apelido}</p> :
                                <>
                                    <p className='header-item-CardFormaDePagamento'>{tipoChavePix}</p>
                                    <p className='header-item-CardFormaDePagamento'>{chavePix}</p>
                                </>
                            }
                        </>
                        :
                        <>
                            {apelido ?
                                <p className='header-item-CardFormaDePagamento'>{apelido}</p>
                                :
                                <>
                                    <p className='header-item-CardFormaDePagamento'>{nomeBanco}</p>
                                    <p className='header-item-CardFormaDePagamento'>{numeroConta}</p>
                                    <p className='header-item-CardFormaDePagamento'>{agencia}</p>
                                    <p className='header-item-CardFormaDePagamento'>{codigoBanco}</p>
                                </>
                            }
                        </>
                    }
                </div>

                {/* <Dropdown>
                    <Dropdown.Toggle variant="tranparent" id="dropdown-basic">
                        <div className='toggle-dropdown-CardFormaDePagamento'>
                            <BsThreeDotsVertical />
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item>
                            Editar
                        </Dropdown.Item>
                        <Dropdown.Item>
                            Apagar
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown> */}
            </div>

            <AnimateHeight
                duration={500}
                height={aberto ? 'auto' : 0}
            >
                <div className='body-CardFormaDePagamento'>
                    {nomeBanco && <p>Banco: {nomeBanco}</p>}
                    {agencia && <p>Agencia: {agencia}</p>}
                    {numeroConta && <p>Conta: {numeroConta}</p>}
                    {nomeBanco && <p>Código do banco: {codigoBanco}</p>}
                    {tipoDocumento && <p>{tipoDocumento}: {numeroDocumento}</p>}
                    {chavePix && <p>Pix: {tipoChavePix} {chavePix}</p>}
                    {nomeFavorecido && <p>Favorecido: {nomeFavorecido}</p>}
                </div>
            </AnimateHeight>
        </div>
    )
}