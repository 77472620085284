import React from "react";
import LegalNavbar from "../../../components/LegalNavbar/LegalNavbar";
import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import "./TermsOfUse.css";

const TermsOfUse = () => {
    return (
        <>
            <LegalNavbar />
            <div className="container">

                <Header titulo="Terms Of Use" />
                <div className="caixa1">
                    <fieldset>
                        <h4>Terms of Use - Omega Digital Assets</h4>
                        <p>Definitions.</p>
                        <p>
                            <b>"Omega".</b> The legal entity under private law (OMEGA
                            CONSULTORIA E NEGOCIOS LTDA.), regularly enrolled with the
                            Corporate Taxpayer Registry No. 34.365.211/0001-40, headquartered
                            at Avenida José Silva de Azevedo Neto, no. 200, Block 6, Suite
                            122, Barra da Tijuca, Rio de Janeiro, RJ, Zip Code: 22775-056.
            </p>
                        <p>
                            <b>Public Agent.</b> Any individual, public servant or not, even
                            if on a temporary basis or without compensation, in public office,
                            employment or function in or for a Government Authority, including
                            Relevant Public Agent; any individual who works for a service
                            provider company contracted or agreed to perform an activity
                            typical of the Public Administration; or any political party
                            leader, its employees or other persons who act for or on behalf of
                            a political party or candidate for political office; or agent of
                            public international organizations.
            </p>
                        <p>
                            <b>Government Authority.</b> Every organ, department or entity of
                            the direct, indirect, or foundational administration of any of the
                            Powers of the Union, the States, the Federal District, the
                            Municipalities, of a Territory, legal entity incorporated to the
                            public patrimony or entity for the creation or funding of which
                            the public treasury has contributed or contributes with more than
                            fifty percent of the assets or annual revenue; Political parties;
                            bodies, state entities or diplomatic representations of a foreign
                            country, as well as legal entities controlled, directly or
                            indirectly, by the public power of a foreign country or public
                            international organizations, including sovereign funds or an
                            entity whose ownership is a sovereign fund.
            </p>
                        <p>
                            <b>Cryptocurrencies, Tokens, and Crypto-Assets.</b> For the
                            purpose of the Act and those modified by it, crypto-assets means:
                            I - Units of value encrypted through the combination of public and
                            private keys of signature by digital means, generated by a public
                            or private and decentralized registration system, digitally
                            transferable and that are not or represent legal tender in Brazil
                            or in any other country; II - Virtual units representing goods,
                            services or rights, encrypted by means of the combination of
                            public and private keys of signature by digital means, registered
                            in a public or private and decentralized registration system,
                            digitally transferable, and that are not or represent legal tender
                            in Brazil or in any other country; III - Virtual Tokens that grant
                            their holder access to the registration system that originated the
                            respective utility token in the scope of a certain platform,
                            project or service for the creation of new registrations in said
                            system and that do not fit in the concept of security set forth in
                            article 2 of Law no. 6385, of December 7th, 1976.
            </p>
                        <p>
                            <b>Securities and Exchange Commission (CVM).</b> The Securities
                            and Exchange Commission (CVM) was created on 12/07/1976 by Law
                            6385/76, with the objective of inspecting, regulating,
                            disciplining, and developing the securities market in Brazil. CVM
                            is an autonomous entity under a special regime, linked to the
                            Ministry of Economy, with its own legal personality and assets,
                            independent administrative authority, absence of hierarchical
                            subordination, fixed term and stability of its directors, and
                            financial and budgetary autonomy. For more details about the CVM's
                            Internal Rules, see CVM Resolution 24.
            </p>
                        <h4>Clause One - Acceptance of the terms and conditions of use.</h4>
                        <p>
                            a. These OMEGA Terms and Conditions of Use ("Terms") govern the
                            use of any of our free and paid services on the internet
                            ("Services"), whether on the website ("Site") or mobile
                            application ("App").
            </p>
                        <p>
                            b. The use of the Site and/or App is conditioned upon acceptance
                            of and compliance with the Terms. To make use of the Services it
                            is necessary: (i) carefully read the terms, rules and conditions
                            described below; (ii) fully agree to them; and (iii) register by
                            providing a valid email address to access the Services. By using
                            the Services, you ("User") agree that you have read, understood,
                            and accepted the Terms, rules and conditions set forth herein.
            </p>
                        <p>
                            c. OMEGA reserves the right to modify the presentation,
                            configuration and availability of the Site and App at any time.
                            The same applies to these Terms, which are established as a
                            fundamental condition for using the Services.
            </p>
                        <p>
                            d. The User acknowledges that these Terms must be observed and
                            faithfully complied with, under penalty of cancellation of the
                            Services and blocking the use of the Site and App, as well as the
                            adoption of other appropriate measures.
            </p>
                        <h4>Clause Two - Registration, Login, and Password</h4>
                        <p>
                            a. Persons who have the civil capacity to contract the Services
                            may register as Users. In case of legal entities, the registration
                            as a User can be done by any legal representative. Persons, who do
                            not enjoy full civil capacity, including minors, are barred from
                            registering.
            </p>
                        <p>
                            b. The information provided at the time of registration and in any
                            subsequent update must be true and Users are solely responsible
                            for the veracity of such information. In case of supervening
                            changes, Users must update their registration.
            </p>
                        <p>
                            c. Each person may only register as a User. User registration is
                            unique, personal, and nontransferable. The existence of untrue or
                            outdated personal data and/or the duplicity of registrations of
                            the same User may result in the exclusion of the registration(s)
                            and the impossibility of the individual to register again as a
                            User. OMEGA may refuse or cancel any registration that has been
                            made in disagreement with these Terms.
            </p>
                        <p>
                            d. In case of an individual customer, the following data will be
                            required: a) full name; b) individual taxpayer registration
                            number; c) identity card number and name of the issuing agency or,
                            if a foreigner, passport or Alien Identity Card data; d) full
                            address with an updated copy of the address proof; e) fitting into
                            any of the conditions foreseen in items I, II and III of Article 1
                            of the Financial Activities Control Council (COAF) Resolution no
                            15, of 03/28/2007; and f) fitting into the condition of
                            politically exposed person, in the terms of the Financial
                            Activities Control Council (COAF) Resolution no 16, of 03/28/2007;
                            or, in case of legal entity: a) corporate name and trade name; b)
                            Corporate Taxpayer Registration Number; c) full address; d)
                            identification of the members and others involved, as well as
                            their fitting into any of the conditions foreseen in items I, II,
                            and III of Article 1 of the Financial Activities Control Council
                            (COAF) Resolution no. 15, of 03/28/2007, or in the condition of
                            politically exposed person, pursuant to the Financial Activities
                            Control Council (COAF) Resolution no 16, of 03/28/2007; and e)
                            identification of the politically exposed person, pursuant to COAF
                            Resolution no 16, of March 28th, 2007; and) identification of the
                            final beneficiaries or the register of the measures adopted with
                            the purpose of identifying them, pursuant to Article 7, as well as
                            their fitting into any of the conditions foreseen in items I, II,
                            and III of Article 1 of the Financial Activities Control Council
                            (COAF) Resolution No. 15 of 03/28/2007 or in the condition of
                            politically exposed person, pursuant to the Financial Activities
                            Control Council (COAF) Resolution No. 16 of 03/28/2007. III -
                            register of the purpose and nature of the business relation; IV -
                            date of the registration and, when this is the case, of its
                            updates; and V - printed and electronic correspondences that
                            dispose of the realization of operations.
            </p>
                        <p>
                            e. The User account can only be accessed by means of the personal
                            and untransferable username and password created by the Users at
                            the time of registration. The responsibility for the safekeeping
                            and protection of the access password is exclusive to the Users,
                            who will be held responsible for any use that third parties may
                            make. In case of unauthorized use of their registration, Users
                            must immediately inform OMEGA. We recommend the User to choose a
                            strong password and eventually change it every 60 (sixty) days, or
                            a shorter period that the User considers appropriate, which can be
                            done by accessing the corresponding page on the website.
            </p>
                        <p>
                            f. When the User registers, he/she will have to create a login and
                            a password to access his/her account on the Platform.
            </p>
                        <p>
                            g. The login and password are personal and non-transferable and
                            must be kept confidential to preserve access and/or knowledge by
                            third parties. In this sense, the User must keep his/her
                            smartphone, tablet and/or computer properly protected with
                            antivirus tools, and he/she must not access his/her account from
                            public computers or from public Wi-Fi connections and/or
                            third-party devices and, in the latter case, if the user does so,
                            he/she must take every precaution to make sure that there are no
                            viruses or tools that allow or may allow the copy of the data
                            charged to said device, being fully and exclusively responsible
                            for the risks of such conduct and any losses and damages
                            eventually incurred by the User, Omega, and/or third parties.
            </p>
                        <p>
                            h. In the event the User becomes aware or suspects that a third
                            party has had access to his/her login and/or password or that any
                            unauthorized use or access has occurred to his/her account by a
                            third party, he/she must immediately access the Platform and, in
                            the "Help Center" channel, communicates the fact to Omega and, in
                            the "Settings" menu, select the "Change Password" option,
                            replacing it with one that bears no resemblance whatsoever to the
                            previous one. In this case, we also recommend changing the
                            password for accessing the e-mail address registered on the
                            Platform.
            </p>
                        <p>
                            i. Upon concluding the registration, the User will receive a
                            message from OMEGA in his/her registered e-mail in order to
                            confirm the respective registration.
            </p>
                        <p>
                            j. OMEGA reserves the right, at any time, at its free and
                            exclusive discretion and without prior notice: (i) check the data
                            informed by the User on the website and App registration and may
                            request additional data and/or documents; and (ii) cancel or
                            delete the registrations of Users who are delinquent in relation
                            to any installment of the subscription payment for any Service.
                            For such purpose, OMEGA will send, as soon as it verifies the
                            default, an e-mail to the User at the address he/she informed in
                            his/her registration and, if there is no response or effective
                            payment within ten (10) days from the sending of such e-mail,
                            OMEGA will proceed to the cancellation of the registration and
                            exclusion of the User from its database.
            </p>
                        <p>
                            k. OMEGA undertakes not to share, sell, or otherwise transmit
                            Users' personal data to third parties, except if the information
                            is required by law or if it is necessary for the operation of the
                            Website or App or the Services included in the respective
                            subscription.
            </p>
                        <h4>Clause Three - Responsibilities and Rules of Conduct</h4>
                        <p>
                            a. The User, in addition to other obligations and responsibilities
                            assumed by through the acceptance of these Terms of Use, is
                            legally responsible, where applicable, and is fully and
              exclusively responsible for{" "}
                        </p>
                        <p>
                            1) the operations carried out on the Platform through the use of
              his/her login and password;{" "}
                        </p>
                        <p>
                            2) supplying and keeping exact, correct, precise, true, current,
                            and complete information, exempting Omega from any liability in
                            this regard;
            </p>
                        <p>
                            3) keeping the confidentiality of the access data to the Platform;
            </p>
                        <p>
                            4) being fully aware of the operation of the Platform, as well as
                            the risks involved in its use, especially the risks of
                            cybersecurity and the market, given the high volatility of
                            crypto-assets;
            </p>
                        <p>5) adopting good practices when creating an access password;</p>
                        <p>
                            6) not sharing data with third parties, such as logins and
                            passwords;
            </p>
                        <p>7) using strong passwords;</p>
                        <p>
                            8) not using the password used on the platform on other sites or
                            services, changing it from time to time;
            </p>
                        <p>9) at the end of the platform's use, close the user's login;</p>
                        <p>
                            10) avoid using the platform on computers or networks with public
              access; and{" "}
                        </p>
                        <p>11) keeping the operating system and antivirus updated.</p>
                        <p>
                            12) preferably use the two-factor authentication (2FA) mode to
              improve security in validating transactions within the platform.{" "}
                        </p>
                        <p>
                            b. Do not promote or encourage the use of functionalities,
                            software and content that cause, due to their characteristics
                            (such as extensions and file formats) damage to the OMEGA system.
            </p>
                        <p>
                            c. The User agrees that by using the website and/or the App,
                            he/she will not:
            </p>
                        <p>1. Violate these Terms and any of its rules and conditions;</p>
                        <p>
                            2. Engage in misrepresentation, which includes misrepresentation
                            (i.e., willfully and intentionally disclosing information that the
                            User knows to be false or is known to be false) and fraudulent
                            misrepresentation;
            </p>
                        <p>
                            3. Post or transmit any abusive or offensive content in the
                            comments;
            </p>
                        <p>4. Replicate or store abusive content on OMEGA's servers;</p>
                        <p>
                            5. Do anything or perform any act contrary to good faith and the
                            uses and customs of the virtual communities and that may offend
                            any third party rights. By violation of the uses and customs of
                            virtual communities should also be understood the excessive use of
                            text in capital letters, use of offensive criticism (known as
                            "flamming"), the unnecessary and imprudent inclusion of previous
                            communications in any publication or transmission, as well as any
                            other act that is at odds with commonly accepted Internet
                            etiquette;
            </p>
                        <p>6. To commit fraud;</p>
                        <p>
                            7. Violate or infringe the intellectual property rights,
                            industrial property, fiduciary or contractual rights, privacy
                            rights or publicity rights of third parties;
            </p>
                        <p>
                            8. Propagate, distribute or transmit destructive code, whether or
                            not it has caused actual damage;
            </p>
                        <p>
                            9. Gather personal or business data, including (but not limited
                            to) e-mail addresses and/or names from any Internet resources,
                            whether managed by OMEGA or third parties, for commercial,
                            political, charitable or other purposes without the consent of the
                            owners of such data;
            </p>
                        <p>
                            10. To use robots, "spiders" or any other device, automatic or
                            manual, to monitor or copy any content from OMEGA's service;
            </p>
                        <p>
                            11. Transmit content that does not belong to the User or that the
                            User does not have the right to publish or distribute, whether
                            under law or contract;
            </p>
                        <p>
                            12. To use or transmit any content, information, text, image,
                            video, or logo published on the website with direct or indirect
                            advertising purposes, or even with any other commercial intention,
                            except if previously and expressly authorized by OMEGA, under
                            penalty of being liable for applicable losses and damages;
            </p>
                        <p>
                            13. To access the website without authorization, through hacking
                            practices, password mining or any other fraudulent means or that
                            represents violation to third parties' rights;
            </p>
                        <p>
                            14. Conduct or encourage illegal activities, including (but not
                            limited to) promoting or facilitating the access, use or sale of
                            dangerous substances or devices;
            </p>
                        <p>
                            15. Failure to comply with any applicable laws, standards, rules,
                            principles, and regulations; and
            </p>
                        <p>
                            16. Helping any third party to perform any of the actions
                            prohibited by these Terms.
            </p>
                        <h4>Clause Four - Platform and Cost of the Services</h4>
                        <p>
                            a. Access to Omega's crypto-assets trading platform can be
                            accessed through its website (www.omegainvestimentos.com.br)
                            and/or its own-, and third-party applications and/or APIs
                            (referred to as the "Platform"). Omega is responsible for
                            providing, via whitelabel, the User access to the Platform.
            </p>
                        <p>
                            b. Omega enables through the Platform the interaction between
                            users who wish to purchase or sell their own crypto-assets that
                            are made available and supported by Omega's Platform, as well as
                            the purchase and sale of digital assets and tokens
                            ("Crypto-assets"), as well as other services, by offering
                            functionalities to facilitate and provide security for the
                            transactions performed by the User on the Platform
                            ("Transactions").
            </p>
                        <p>
                            c. Omega only holds the crypto-assets, and ownership is exclusive
                            to the User.
            </p>
                        <p>
                            d. The Crypto-assets which are objects of each Operation are
                            linked to the virtual wallet, which, by means of public and
                            private keys, allows the user to send, receive and consult the
                            Crypto-asset linked to its account ("Wallet") and can be viewed
                            through the Platform.
            </p>
                        <p>
                            e. The availability of any free Service is the exclusive
                            prerogative of OMEGA, which may, at any time, exclude, include,
                            change, update and/or replace any content or functionality of such
                            Service, or even deactivate it, without characterizing a violation
                            of these Terms and without any notification in this sense.
            </p>
                        <p>
                            f. The values charged by the platform may be changed by OMEGA,
                            provided that the user is previously informed.
            </p>
                        <p>
                            g. OMEGA may, at its sole discretion, promote the Services,
                            offering discounts or other benefits. Such promotions will be
                            ruled by its own regulation.
            </p>
                        <p>
                            h. The Platform registration is free. In order to check the values
                            of the fees charged, terms and limits, access the tables of fees,
                            terms, and limits available in the Platform through the link Fees
                            and Limits and/or in the logged-on area of the Platform, when
                            available.
            </p>
                        <p>
                            i. Fees will be due for performing the following operations on the
                            Platform:
            </p>
                        <p>
                            <b> 1) Purchase/Sell Order Execution: </b>The publication of a
                            purchase and/or sell order for crypto assets on the Platform is
                            free of charge. However, in the event that this sell order is
                            accepted, in whole or in part, by another user, the User will pay
                            the order execution fee for this order, which consists of the cost
                            of purchasing and selling brokerage. Thus, the balance in the
                            platform, after the transaction is completed, will be the value
                            derived from the purchase/sale of the User’s Crypto assets, minus
                            the brokerage fees, according to the table.
            </p>
                        <p>
                            <b>2) Deposit and withdrawal of Crypto-assets:</b> the receiving
                            and sending of Crypto-assets is subject to fees charged by the
                            network of miners and/or validators (blockchain), which may vary
                            according to network conditions. The Crypto-asset withdrawal fee,
                            if applicable, will be shown at the time of the withdrawal
                            Transaction and will be automatically deducted from the total
                            amount of Crypto-asset to be sent externally.
            </p>
                        <p>
                            <b> 3) Withdrawal of amounts in BRL:</b> If this functionality is
                            available, when making a withdrawal from the platform to a current
                            account held by the User, in fiat currency (BRL), a commission may
                            be charged on the amount withdrawn.
            </p>
                        <p>
                            j. The fees, values and commissions charged may be changed by
                            Omega, which reserves the right to do so, by its free choice,
                            without any criteria and/or conditions for such, being this a
                            pricing policy to be defined solely and exclusively by the
                            Platform. However, should there be a change in the pricing policy,
                            Omega shall notify the customers through the Platform's
                            communication channels, as well as send a newsletter to the users'
                            e-mail.
            </p>
                        <p>
                            k. The User declares that he/she has read and perfectly understood
                            the terms and conditions proposed herein and agrees with the
                            charges listed in the items of this clause, as well as defined in
                            the pricing policy available on the platform.
            </p>
                        <h4>
                            Clause Five - Operations on the Platform, Conditions, Rights, and
                            Responsibilities
            </h4>
                        <p>
                            a. In order to be possible to perform any Operation, the user must
                            previously have its registration approved and the account release
                            effected by Omega, with the confirmation via e-mail, as well as
                            having accepted all the provisions of these Terms of Use and the
                            Privacy and Personal Data Protection Policy.
            </p>
                        <p>
                            b. In order to perform any Operation on the Platform, the User
                            must have sufficient funds in Brazilian Reais or the equivalent in
                            Crypto-Assets in its account. Omega may change the value of the
                            operational limits, and it is not possible to execute Transactions
                            not respecting such limits, which can be consulted on the link
                            Fees, Commissions and Limits and/or on the logged-on area of the
                            Platform, when available.
            </p>
                        <p>
                            c. The User, when deciding to purchase or sell Crypto assets,
                            (only those listed by Omega on its Platform), must post a purchase
                            or sell order on the Platform, as the case may be, by completing
                            the procedures available on the Platform. Before publishing the
                            purchase or sell order, the User must have, in its account, a
                            balance in Brazilian Reais (for the case of a purchase order) or
                            in Crypto-assets (for the case of a sell order) sufficient to
                            cover the offer it intends to make.
            </p>
                        <p>
                            d. The User declares to be aware that each purchase or sell order
                            posted on the Platform represents a firm purchase or sell offer,
                            as the case may be, in accordance with the provisions of the
                            applicable legislation, according to the characteristics of the
                            Crypto-asset and price referred to in the respective purchase or
                            sell order. The User understands and agrees that, upon acceptance
                            of his/her purchase or sell order by another user, or by the
                            Platform, the Transaction will be automatically executed by the
                            Platform and cannot be undone, modified, or reversed.
            </p>
                        <p>
                            e. When the User wishes to exchange his/her Crypto-assets for
                            other Crypto-assets and such exchange is supported by the
                            Platform, hereby represent that his/her exchange offer represents
                            two firm offers (a sale followed by an automatic purchase) and
                            agrees that the items in this clause are applicable to the
                            intended Crypto-assets exchange Transaction.
            </p>
                        <p>
                            f. If the User chooses to cancel a published purchase order or
                            sell order that has not yet been accepted by another user, he/she
                            may do so through the Platform itself, and the original balance,
                            either in the equivalent amount in Brazilian Reais or in Crypto
                            assets, as the case may be, will be restored to his/her account.
                            This possibility can only be exercised if the purchase or sell
                            order has not been accepted by another party, in case the
                            operation has already been consummated, item "d" of this clause
                            shall apply.
            </p>
                        <p>
                            g. Omega is merely a technology service provider that allows
                            connecting users and transacting the purchase and sale of
                            crypto-assets, so that the User declares to be aware that Omega is
                            not a party nor shall be considered a party to any Transaction,
                            nor does it become the owner of the Crypto-assets traded on the
                            Platform between users.
            </p>
                        <p>
                            h. The User acknowledges and accepts that, when executing
                            Transactions, it does so at its own risk. It is emphasized that
                            Omega is merely a technology service provider, as a service
                            provider for making the Platform available for trading
                            Transactions between users. User acknowledges Omega's exemption
                            from liability for the effective compliance with the obligations
              and risks assumed by User in its transactions on the Platform.{" "}
                        </p>
                        <p>
                            i. When carrying out Transactions, User must ensure that it has
                            correctly provided: (i) its bank details for the withdrawal of
                            amounts in Brazilian Reais (if this option is available on the
                            Platform) and (ii) the address of the Wallets for the receipt and
                            withdrawal of Crypto assets from the Platform.
            </p>
                        <p>
                            j. The User declares to be aware that, if the wrong address is
                            provided, or if the type of network for the transaction is
                            incorrect, the loss of crypto-assets may occur, whether by sending
                            an address different from the intended one or by incompatibility
                            of blockchains, and also acknowledges Omega's lack of liability
                            for the effective compliance with the obligations and risks
                            assumed by the User in its transactions on the Platform, including
                            as to the correct completion of wallet addresses and corresponding
                            networks for sending and receiving crypto-assets
            </p>
                        <p>
                            k. The User acknowledges and releases Omega from any and all
                            liability arising from operational risk: which is defined as the
                            possibility of losses resulting from failures, instability,
                            deficiency or inadequacy of systemic processes, internet, as well
                            as other external events linked to the computer and operational
                            system. The possibility of loss resulting from: (i) systemic
                            capacity of the mining network of bitcoins and cryptocurrencies in
                            general, when transacting the operations commanded by the
                            wallets/operators; (ii) the time interval between the
                            withdrawal/transfer command, and/or the conversion of the
                            cryptocurrency to fiat currency, since it is not possible to
                            guarantee the time of the transfer, and there may be market
                            variation in the systemic/operational lapse with consequent impact
              on the value of the currency. <b>Liquidity risk:</b> This type of
                            risk takes two forms; (i) market liquidity risk; (ii) liquidity
                            risk related to the funding of the exchanges, certain that because
                            there is no regulation, the exchanges that work as banks in the
                            operation of cryptocurrencies, are not subject to the control of
                            the Central Bank, the Securities Exchange Commission (CVM), among
                            others, and there is no provision in the law that requires
                            collateral, insurance, or any other type of guarantee as to their
                            liquidity and solvency. The liquidity risk of brokerage houses is
                            related to the operational, systemic and cash flow capacity of
                            these intermediaries to transact the operations commanded, that
                            is, the brokerage houses "exchanges" are the operational system
                            that performs the purchase/sell, deposit/withdraw commands of the
                            operator/portfolio, and in this case, Omega uses the entire
                            operational system, liquidity, order book, and consequently, the
                            funding provided by the broker Okx (https://www.okx.com/en-br),
                            being Omega and consequently, the User, to the terms and services
                            of the brokerage provider of the technology, security and
                            liquidity services, pursuant to
                            (https://www.okx.com/support/hc/en-us/articles/360021813691).
              <b>Cybersecurity Risk:</b> Due to the possibility of hacker
                            attacks on the protocols themselves (crypto-assets network), which
                            are subject to this type of attack, as well as the possibility of
                            such attacks on the exchanges that perform the custody, purchase,
                            and sale of digital assets.
            </p>
                        <p>
                            l. Omega reserves the right, at its sole discretion, without the
                            need for any prior notice and without any burden, fine, liability
                            or duty to indemnify, to implement limits to the use of the
                            Platform, such as, but not limited to, the quantity of
                            Transactions to be carried out by the User, limits on the global
                            value of the transactions, among others.
            </p>
                        <h4>Clause Five - Services Rendered</h4>
                        <p>
                            a. The services of crypto-assets purchase, sale, transfer, and
                            custody will work only for those crypto-assets supported by the
                            Platform, according to the list found in the trading area and/or
                            by means of the crypto-asset ticker search in the platform, being
                            certain that new assets may be listed, as well as delisted,
                            following the rules defined by Okx.
            </p>
                        <p>
                            b. If the listing and support for any Crypto asset on the Platform
                            is discontinued, Omega will inform users of the procedure to be
                            adopted.
            </p>
                        <p>
                            c. Omega securely stores the private keys for Crypto assets
                            deposited at each User's Wallet address by means of a combination
                            of security protocols defined by Omega. As a result of this
                            configuration, chosen for the safety of the users, there may be
                            delays in relation to the processing of the receipt of
                            Crypto-asset in the Wallet, account credit and/or sending of
                            Crypto-asset through the Platform, with Omega being exempt from
                            any and all liability for such delays for the safety of the
                            transactions and the Platform.
            </p>
                        <p>
                            d. The User is prohibited from using the Platform to receive,
                            send, store or interact with Crypto assets not supported by Omega.
                            The Platform is not set up to detect or ensure transactions
                            involving unsupported crypto-asset products and any attempt to
                            send, receive or store such crypto-asset products may result in
                            the complete, definitive and irreversible loss of such
                            crypto-asset products, it being understood that if User were to
                            carry out any transaction in this regard, Omega shall be exempt
                            from any liability in connection with such acts and any losses
                            arising from such situations, as this is expressly forbidden and
                            the risks are perfectly understood.
            </p>
                        <p>
                            e. User acknowledges that Omega is not the owner, holder,
                            controller, partner, or possesses any management bond over the
                            protocols and networks that operate and manage the Crypto asset
                            supported by the Platform, such as the blockchains that transact
                            and register transactions. Most crypto-asset products are open
                            source and accessible by anyone, allowing the alteration, use,
                            modification, distribution of the crypto-asset's codes and
                            programming. Thus, in spite of the existence of several mitigation
                            and governance mechanisms, there is no way to ensure that such
                            protocols will work uninterruptedly, nor in relation to
                            maintenance and development, there being no legal and operational
                            security in relation to crypto-assets, so that the decision to
                            purchase and sell must consider such risks, and the Platform has
                            no relationship and consequently no responsibility for such a User
                            decision, which exempts Omega from any responsibility in this
              regard.{" "}
                        </p>
                        <p>
                            f. In particular, as a consequence of the open and decentralized
                            nature, protocols are subject to radical changes in their
                            operating rules ("Forks or Protocol Changes"). As a rule, when a
                            Fork occurs, the original protocol splits into parallel networks,
                            namely (i) the version of the original protocol that did not
                            adhere to the proposed changes; and (ii) the version of the
                            protocol that changed its rules and follows a new dynamic of
                            operation. Such Forks or Protocol Changes may considerably affect
                            the value, function and/or names of the Crypto assets. Omega, at
                            its sole discretion, may decide to support or not one or all
                            possible splits resulting from Forks or Protocol Changes, always
                            following the listing and/or delisting of assets promoted by Okx.
            </p>
                        <p>
                            g. If a Crypto-asset is not supported as a result of Forks or
                            Protocol Changes, the User shall have no rights and no access to
                            the balance related to the Crypto-asset in question, if the
                            protocol is not supported by the platform. It should be noted that
                            the User's rights and accesses will remain valid in relation to
                            the protocol supported by the platform, even if there are changes
                            in relation to the original situation.
            </p>
                        <p>
                            h. In any case, it is the joint and several responsibilities of
                            the User the decision of how to deal with Forks or Protocol
                            Changes, and the User is aware and agrees that any loss of balance
                            due to Forks or Protocol Changes is a risk inherent to their
                            choice to operate in the crypto-assets market. In case of Forks or
                            Protocol Changes, Operations on the Platform may be suspended,
                            including without prior notice and/or estimated time for
                            normalization of the Operations.
            </p>
                        <p>
                            i. The User acknowledges and accepts the risks related to Forks or
                            Protocol Changes and declares his/her awareness and agreement that
                            Omega has no responsibility and/or duty to help him/her move
                            and/or sell the unsupported Crypto asset resulting from Forks or
                            Protocol Changes, which consists in a User's own duty of
                            diligence.
            </p>
                        <p>
                            j. The services provided by Omega are only those of brokerage in
                            the purchase and sale of crypto assets, so that the User fully
                            acknowledges that they do not constitute the recommendation,
                            advice, and indication of investments.
            </p>
                        <p>
                            k. Moreover, the user recognizes and agrees that the price of
                            assets is set by the global market itself, through supply and
                            demand for such assets, so that the platform has no relationship,
              management, or responsibility for the prices of assets.{" "}
                        </p>
                        <h4>Clause Five - Services Rendered</h4>
                        <p>
                            a. OMEGA respects the intellectual property rights of third
                            parties and requires Users to do the same.
            </p>
                        <p>
                            b. All copyrights over the material eventually submitted by the
                            User to OMEGA will remain the property of the User and the User's
                            name will be quoted whenever the material is used by OMEGA, as
                            informed by the User, except if the space or physical support in
                            which it is to appear does not allow such quote. The User
                            authorizes OMEGA to use, reproduce, publish, present and display
                            on the website and/or App the material submitted by the User,
                            without any remuneration or payment due to the User. OMEGA is
                            under no obligation to use material submitted by User and is
                            authorized to edit material submitted by User.
            </p>
                        <p>
                            c. User acknowledges and declares that in any contribution
                            submitted to the website and App, the corresponding material is of
                            User's exclusive creation and does not constitute an infringement
                            of copyrights, trademarks, secrets, personality rights, including
                            honor, intimacy, privacy and the image of persons, property rights
                            and any other third-party rights, and that User has the authority
                            to authorize OMEGA to use the material as provided herein.
            </p>
                        <p>
                            d. Provided that the source is cited (including the author's name,
                            when possible and applicable) and within the conditions and limits
                            provided by law, notably the Copyright Law (Law No. 9610/98), the
                            User may not reproduce, publish, present, rent, offer or expose
                            any copy of any content belonging to OMEGA without its consent or,
                            in the case of content created by third parties, without the
                            author's consent.
            </p>
                        <p>
                            e. The User agrees to comply with all national and international
                            laws regarding Intellectual Property Rights.
            </p>
                        <h4>Clause Six - Reports of Abuse and Violations</h4>
                        <p>
                            a. The User undertakes to report any abuse or violation of these
                            Terms or any third-party rights that he/she observes and/or is
                            victim to when using the website and/or App. The User must use the
                            channel provided on the website to communicate and report abuses
                            to OMEGA.
            </p>
                        <p>
                            b. All content that the User posts using any discussion forum is
                            information that, by its nature and characteristic, is public,
                            open, and non-confidential. By disclosing personal data such as
                            his/her name and e-mail address in comments, the User accepts and
                            understands that this information may be collected and used by
                            others to communicate with him or her, without any liability to
                            OMEGA. Therefore, OMEGA recommends the User to be careful when
                            deciding to share any personal data on any website.
            </p>
                        <h4>Clause Seven - Responsibilities</h4>
                        <p>
                            a. OMEGA is not responsible for any damages suffered by the User
                            or third parties that in any way originate from or are dependent
                            on the website and/or App.
            </p>
                        <p>
                            b. OMEGA does not own the products offered by any Merchants (or
                            Advertisers), does not hold their possession, does not make the
                            sales offers, nor intervenes in the delivery of the products,
                            whose negotiation is initiated on the website and/or App.
            </p>
                        <p>
                            c. User acknowledges and accepts that, when negotiating with
                            Merchants, User does so at User's own risk and exempts OMEGA from
                            any liability.
            </p>
                        <p>
                            d. The content about cryptocurrencies, tokens, and digital assets
                            in general is merely educational, represents only the opinion of
                            the Users and developers of such content, so that it is not an
                            investment recommendation, and, therefore, the information
                            contained in Omega's website and App does not constitute, nor
                            should it be interpreted as, advice, recommendation, offer and/or
                            solicitation to purchase or sell stocks, bonds, securities,
                            cryptocurrencies, tokens, and any other financial instruments.
            </p>
                        <p>
                            e. The profitability and success of investment theses obtained in
                            the past does not represent a guarantee of future profitability.
                            The User is formally recommended to carefully read the analyses,
                            information form, and essential information sheet in order to
                            assess the risks inherent in the transactions and to determine,
                            independently and by their own judgment, their ability to assume
                            them.
            </p>
                        <p>
                            f. The Securities Exchange Commission (CVM), and regulatory body
                            of the capital market, does not authorize direct investment in
                            cryptocurrencies by investment funds, fund managers, etc. In such
                            a way that, the User's decision, based on information, analyses,
                            reports, comments, or opinions found in the App and OMEGA's
                            Website reflect solely and exclusively the personal opinion of its
                            publishers, and so they are not regulated and/or authorized by the
                            Securities Exchange Commission (CVM), reinforcing that it is up to
                            the User to evaluate the risks inherent to the transactions and to
                            determine, independently and by his/her own judgment, his/her
                            capacity to assume them.
            </p>
                        <p>
                            g. User acknowledges its full responsibility and fully and
                            unconditionally releases Omega from any and all liability arising
                            from any losses, including loss of opportunity, material or
                            immaterial, direct or indirect damages, losses, loss of profits,
                            indemnities and/or reparations of any other nature that may be
                            incurred by User as a result of: (i) any Transaction carried out
                            or not on the Platform; (ii) for the financial results arising
                            from such Transactions, (iii) for any technical and/or operational
                            failure or unavailability occurring on the Platform, including as
                            a result of services provided by third parties and/or the
                            protocols and network structures governing Crypto-asset, which may
                            cause, for example, the non-performance of Transactions, eventual
                            alteration of Crypto-asset quotes, delay in the processing and
                            execution of purchase and/or sale orders executed by the User
                            and/or eventual mismatches between the execution and cancellation
                            of orders; (iv) for the results produced as a result of any type
                            of external influence or installation, in the equipment used by
                            the User, of malicious software, including, but not limited to
                            viruses, trojans, malware, worms, bots, backdoors, spyware,
                            rootkits or any other devices that may be created as a result of
                            the User's use of the platform (v) for the repair of any and all
                            losses and damages incurred by the Platform, another User or any
                            third party arising from the activity performed by the User on the
                            Platform, including, without limitation, those arising from
                            violation of any rights of other users or third parties, Omega's
                            intellectual property rights, or the provisions of these Terms of
                            Use and the Privacy and Personal Data Protection Policy.
            </p>
                        <p>
                            h. By events defined in the applicable legislation as fortuitous
                            case or force majeure.
            </p>
                        <h4>Clause Eight - Applicable Law</h4>
                        <p>
                            a. These Terms are governed and construed under the laws of the
                            Federative Republic of Brazil and all disputes, actions and other
                            related matters will be determined in accordance with that
              country's current legislation.{" "}
                        </p>
                        <p>
                            b. These Terms of Use are effective from the moment of acceptance
                            by the user on the platform and shall be in force for an
                            indefinite term.
            </p>
                        <p>
                            c. Security protocol updates or any other changes to improve
                            functionality and the User experience do not imply any
                            acknowledgement, express or implied, that the protocols previously
                            used were insufficient, but only that Omega is updating its
                            protocols to better serve Users.
            </p>
                        <p>
                            d. OMEGA observes and applies the legislation in force on
                            anti-corruption practices, and it is forbidden to promise, offer
                            or give, directly or indirectly, any Undue Advantage, whether
                            monetary or not, to a Public Agent or to a third person related to
                            him/her. It is also forbidden to make payments not foreseen in the
                            legislation, including, without limitation, payments aimed at
                            accelerating administrative or judicial procedures and influencing
                            decisions.
            </p>
                        <p>
                            e. Eventual tolerance by any of the parties in relation to the
                            requirement of regular compliance with the obligations of the
                            other party shall not constitute a waiver, alteration,
                            modification of any of the rights or obligations established by
                            means of these Terms of Use and/or the Privacy and Personal Data
                            Protection Policy
            </p>
                        <p>
                            f. The invalidity or partial unenforceability of these Terms of
                            Use shall not affect the User in the part not considered invalid
                            or unenforceable.
            </p>
                        <p>
                            g. The conditions and information presented in these Terms are in
                            accordance with the Brazilian legislation, meeting, including, the
                            terms of Law No. 13709/2018 (LGPD – Data Protection Law) and
                            related standards, according to OMEGA's Privacy Policy, which the
                            User declares to have read, understood, and accepted its terms,
                            rules, and conditions therein.
            </p>
                        <p>
                            h. The courts of the capital city and state of Rio de Janeiro are
                            hereby elected as the sole competent jurisdiction to settle any
                            doubts arising from these Terms, dismissing any other, not however
                            privileged.
            </p>
                    </fieldset>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TermsOfUse;
