import React from "react";
import { useNavigate } from "react-router-dom";

import "./styles.css";

export interface TabelaListarClientesAppProps {
  nome: string;
  email: string;
  userName: string;
}


export const TabelaListarClientesApp = ({ nome, email, userName }: TabelaListarClientesAppProps) => {

  return (
    <tr className="tabelaLinha" style={{ textAlign: 'center' }}>
      <th>{nome}</th>
      <th>{email}</th>
      <th>{userName}</th>
    </tr>

  )
}
