import ImagemOmegaImpressao from "../../../assets/img/logo.png";
import { ClienteApp } from ".";
import { getUsuarioTodosApp, postUsuarioLoginApp } from "../../../services/usuario"

interface FuncoesListarClientesAppProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setErro: React.Dispatch<React.SetStateAction<boolean>>;
    setListaClientesApp: React.Dispatch<React.SetStateAction<ClienteApp[]>>;
}

export const FuncoesListarClientesApp = ({ setErro, setListaClientesApp, setLoading }: FuncoesListarClientesAppProps) => {

    function obterTodosUsuarioApp() {
        setLoading(true);
        postUsuarioLoginApp("master@info4.com.br", "123456#Ab").then((res) => {

            getUsuarioTodosApp(res.data.camposAdicionais.tokenAcesso, 1, 2147483646).then((res) => {
                console.log(res.data.Resultado.Usuarios)
                setListaClientesApp(res.data.Resultado.Usuarios)

            }).catch(() => {
                setErro(true);
            })

        }).catch(() => {
            setErro(true);
        }).finally(() => {
            setLoading(false);
        })
    }

    function criaPDFUsersApp() {
        const minhaTabela = document.getElementById("tabelaUsersApp")!.innerHTML;

        var style = "<style>";
        style = style + "table {width: 100%;font: 12px Calibri;}";
        style =
            style +
            "table, th, td { border: solid 1px #DDD; border-collapse: collapse;";
        style = style + "padding: 2px 3px;text-align: center;}";
        style = style + "img {width: 20%; height: 20%}";
        style =
            style +
            "div {display: flex; justify-content: center; margin-bottom: 15px;}";
        style = style + "</style>";

        // CRIA UM OBJETO WINDOW
        var win = window.open("", "", "height=700,width=700");
        win.document.write("<html><head>");
        win.document.write("<title></title>"); // <title> CABE�ALHO DO PDF.
        win.document.write(style); // INCLUI UM ESTILO NA TAB HEAD
        win.document.write("</head>");
        win.document.write("<body>");
        win.document.write(
            `<div><img src="${ImagemOmegaImpressao}"></img></div>`
        );
        win.document.write("<table>");
        win.document.write(minhaTabela);

        win.document.write("</table>"); // O CONTEUDO DA TABELA DENTRO DA TAG BODY
        win.document.write("</body></html>");
        setTimeout(function () {
            win.print(); // IMPRIME O CONTEUDO
        }, 500);
        win.document.close(); // FECHA A JANELA
    };

    return ({
        obterTodosUsuarioApp,
        criaPDFUsersApp
    })
}