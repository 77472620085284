import React, { useEffect, useState } from "react";
import "./ConfirmacaoEmail.css";

import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import { Button } from "../../../components/Button";
import { confirmaEmailVerificandoSender, handleReenviar } from "./functions";

import { useNavigate } from "react-router-dom";

const ConfirmacaoEmail = () => {
  const [confirmado, setConfirmado] = useState<boolean>(false);
  const [erro, setErro] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [reenviar, setReenviar] = useState<boolean>(false);
  const [erroReenviar, setErroReenviar] = useState<boolean>(false);

  const navigate = useNavigate();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const email = urlParams.get("Email")!;
  const sender = urlParams.get("Sender");
  const token = urlParams.get("Token")!;
  const tipo = urlParams.get("Tipo")

  useEffect(() => {
    confirmaEmailVerificandoSender(sender, email, token, setConfirmado, setErro)
  }, [reload]);

  return (
    <>
      <Footer />
      <div className="container">
        <Header titulo="Confirmação de E-mail" />
        <div className="caixaConfirmacaoEmail linha1">
          <div className="caixaConfirmacaoEmail__fieldset_left">
            <fieldset className="caixaConfirmacaoEmail__fieldset__global">
              <div>
                <div className="conteudo">
                  {!confirmado ? (
                    <div className="conteudo__div">
                      <h5 className="conteudo__div__titulo">
                        "Confirmando seu email..."
                      </h5>
                    </div>
                  ) : erro ? (
                    <>
                      {!reenviar ? (
                        <div className="conteudo__div">
                          <h5 className="conteudo__div__titulo">
                            "Ocorreu um erro com sua confirmação!"
                          </h5>
                          <p className="conteudo__texto">
                            Clique abaixo para reenviar para seu e-mail a
                            confirmação.
                          </p>
                          {erroReenviar && (
                            <p>
                              Algo deu errado, aguarde alguns instantes e tente
                              novamente.
                            </p>
                          )}
                          <Button
                            onClick={() => handleReenviar(email, tipo, setReenviar, setErroReenviar)}
                            className="btn__ConfirmacaoEmail"
                            data-bs-toggle="modal"
                            data-bs-target="#acessar"
                            titleButton="RE-ENVIAR"
                          />
                        </div>
                      ) : (
                        <div className="conteudo__div">
                          <h5 className="conteudo__div__titulo">
                            E-mail reenviado com sucesso!
                          </h5>
                          <p className="conteudo__texto">
                            Verifique seu E-mail, verifique sua caixa de
                            entrada.
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    sender == "sys" ?

                    <div className="conteudo__div">
                      <h5 className="conteudo__div__titulo">
                        "Obrigado por confirmar seu email!"
                      </h5>
                      <p className="conteudo__texto">
                        Para retornar à tela de Login e acessar a plataforma,
                        clique abaixo:
                      </p>

                      <Button
                        onClick={() => navigate("/")}
                        className="btn__ConfirmacaoEmail"
                        data-bs-toggle="modal"
                        data-bs-target="#acessar"
                        titleButton="ACESSAR"
                      />
                    </div>
                    :
                    <div className="conteudo__div">
                      <h5 className="conteudo__div__titulo">
                        "Obrigado por confirmar seu email!"
                      </h5>
                      <p className="conteudo__texto">
                        Retorne à tela de Login do app e entre utilizando as credenciais cadastradas.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmacaoEmail;
