import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import { handleHistoricoFechamento, handlePosicaoConsolidada, exibirAtivosNaHome } from "./functions";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { LoadingButton } from "../../../components/loadingButtom";
import { ErroTabela } from "../../../components/Erros/ErroTabela";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";

import { AiOutlineReload } from "react-icons/ai";
import { mascaraBRL, mascaraUSD } from "../../../functions/MascarasMonetarias";
import { GraficoLinha } from "../../../components/Graficos/GraficoLinha";
import { ServicoFinanceiroClienteContext, usuarioServicoFinanceiroContextType } from "../../../context/servicoFinanceiroCliente";
import { obterServiçosFinanceiros } from "../../../services/cookies";
import useWindowDimensions from "../../../functions/UseWindowDimensions";
import { GraficoPizza } from "../../../components/Graficos/GraficoPizza";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, PluginOptionsByType } from 'chart.js';
import { _DeepPartialObject } from "chart.js/types/utils";

export interface DadosPosicao {
    "cotas": number,
    "valorCota": number,
    "saldoCliente": number,
    "performanceFundo": number,
    "rendimentoConta": number,
    'totalSaques': number,
    'totalAportes': number,
    'saldoAnterior': number,
    'valorCotaAnterior': number,
    'saldoClienteRentabilidade': number,
    'valorPrimeiroAporte': number,
}

function Home() {

    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);

    //posicaoConsolidadareceber requisição
    const [dadosPosicao, setDadosPosicao] = useState<DadosPosicao>()
    //gráfico tratamento de erro e loading
    const [loadingPosicao, setLoadingPosicao] = useState<boolean>(true);
    const [errorPosicao, setErrorPosicao] = useState<boolean>(false);

    //gráfico fazer requisição
    const [dataInicial, setDataInicial] = useState<string>(new Date(new Date().setMonth(new Date().getMonth() - 72)).toISOString());
    const [dataFinal, setDataFinal] = useState<string>(new Date().toISOString());
    //gráfico receber requisição
    const [rendimentosDia, setRendimentosDia] = useState<Array<number>>([]);
    const [valoresTotais, setValoresTotais] = useState<Array<number>>([]);
    const [valoresTotaisPrivate, setValoresTotaisPrivate] = useState<Array<number>>([]);
    const [valoresAcumulados, setValoresAcumulados] = useState<Array<number>>([]);
    const [labels, setLabels] = useState<Array<string>>([]);
    const [indexData, setIndexData] = useState<number>(0);
    const [indexDataPrivate, setIndexDataPrivate] = useState<number>(0);
    //parte do gráfico pizza
    const [colorArray, setColorArray] = useState<Array<string>>([]);
    const [porcentagem, setPorcentagem] = useState<Array<number>>([]);
    const [valorTotal, setValorTotal] = useState<Array<number>>([]);
    const [valorUnitario, setValorUnitario] = useState<Array<number>>([]);
    const [labelsMoeda, setLabelsMoeda] = useState<Array<string>>([]);

    //gráfico tratamento de erro e loading
    const [loadingGrafico, setLoadingGrafico] = useState<boolean>(true);
    const [errorGrafico, setErrorGrafico] = useState<boolean>(false);
    //tratar altura e largura para usar na view mobile dos graficos
    const { height, width } = useWindowDimensions();

    const serviçosFinanceiros = obterServiçosFinanceiros();
    const { reloadServicoFinanceiro, usuarioServicoFinanceiro } = useContext<usuarioServicoFinanceiroContextType>(ServicoFinanceiroClienteContext);


    console.log(dadosPosicao)

    useEffect(() => {
        setValoresAcumulados([]);
        setRendimentosDia([]);
        setValoresTotais([]);
        setLabels([]);
        handleHistoricoFechamento(
            setLoadingGrafico,
            setErrorGrafico,
            dataInicial,
            dataFinal,
            setModalDesconectar,
            setRendimentosDia,
            setValoresTotais,
            setLabels,
            setValoresAcumulados,
            usuarioServicoFinanceiro,
            setLoadingPosicao,
            setErrorPosicao,
            setDadosPosicao,
            setLabelsMoeda,
            setPorcentagem,
            setValorUnitario,
            setValorTotal,
            colorArray,
            setColorArray,
            setValoresTotaisPrivate
        );
    }, [dataInicial, dataFinal, reloadServicoFinanceiro]);


    ChartJS.register(ArcElement, Tooltip, Legend);

    const plugins: _DeepPartialObject<PluginOptionsByType<"pie">> = {
        legend: {
            display: true,
            position: height >= width ? 'top' : 'left'
        },
        tooltip: {
            enabled: true,
            mode: "point",
            callbacks: {
                title: (data) => {
                    return data.map((data2) => {
                        return data2.label
                    })
                },
                label: function (data) {
                    var multistringText = ["Porcentagem do ativo na carteira: " + porcentagem[data.dataIndex].toFixed(2) + "%"];
                    // multistringText.push("Valor unitário do ativo: $ " + valorUnitario[data.dataIndex].toFixed(2));
                    // multistringText.push("Valor total do ativo: $ " + valorTotal[data.dataIndex].toFixed(2));

                    return multistringText;
                }
            }
        },
    };

    return (
        <>
            <Navbar />
            <div id="container">
                <div className="caixaHome">
                    <fieldset>
                        <div className="headerDashboard">
                            <h2 className="textoNomeHeader">Home</h2>
                        </div>
                    </fieldset>
                </div>

                <div className="caixa1">
                    <fieldset>
                        <div>
                            <h5>Posição Consolidada</h5>
                        </div>
                        <div className="textCentralizar">

                            <table className="tabela">
                                {errorPosicao ? <ErroTabela
                                    handleClick={() =>
                                        handleHistoricoFechamento(
                                            setLoadingGrafico,
                                            setErrorGrafico,
                                            dataInicial,
                                            dataFinal,
                                            setModalDesconectar,
                                            setRendimentosDia,
                                            setValoresTotais,
                                            setLabels,
                                            setValoresAcumulados,
                                            usuarioServicoFinanceiro,
                                            setLoadingPosicao,
                                            setErrorPosicao,
                                            setDadosPosicao,
                                            setLabelsMoeda,
                                            setPorcentagem,
                                            setValorUnitario,
                                            setValorTotal,
                                            colorArray,
                                            setColorArray,
                                            setValoresTotaisPrivate
                                        )
                                    }
                                    className="formatacaoTabela"
                                    titleButton={<>
                                        <>
                                            <AiOutlineReload className="iconButtonDashboard" />
                                        </>
                                    </>
                                    }
                                /> : !loadingPosicao ?
                                    <thead>
                                        {usuarioServicoFinanceiro.idServicoFinanceiro !== 3 &&
                                            <>
                                                <tr>
                                                    <td className="table-secondary tableTitulo alignConteudo">
                                                        <strong>Cotas</strong>
                                                    </td>
                                                    <td className="table-secondary tableTitulo alignConteudo">
                                                        <strong>{dadosPosicao?.cotas.toString().match(/^-?\d+(?:\.\d{0,4})?/)}</strong>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table-secondary tableConteudo alignConteudo">
                                                        <strong>Valor Cota</strong>
                                                    </td>
                                                    <td className="table-secondary tableConteudo alignConteudo">
                                                        <strong>R$ {mascaraBRL(dadosPosicao?.valorCota.toFixed(2).toString())}</strong>
                                                    </td>
                                                </tr>
                                            </>
                                        }

                                        <>
                                            <tr>
                                                <td className="table-secondary tableTitulo alignConteudo">
                                                    <strong>Saldo Líquido</strong>
                                                </td>
                                                <td className="table-secondary tableTitulo alignConteudo">
                                                    <strong>{dadosPosicao ? (usuarioServicoFinanceiro.idServicoFinanceiro === 3 ? '$ ' + mascaraUSD(dadosPosicao.saldoCliente.toFixed(2)) : 'R$ ' + mascaraBRL(dadosPosicao.saldoCliente.toFixed(2))) : "0,00"}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-secondary tableConteudo alignConteudo">
                                                    <strong>Rentabilidade</strong>
                                                </td>
                                                <td className="table-secondary tableConteudo alignConteudo">
                                                    <strong>
                                                        {dadosPosicao ? mascaraBRL(((((dadosPosicao.saldoCliente + (dadosPosicao.totalSaques * - 1)) / dadosPosicao.totalAportes) - 1) * 100).toFixed(2)) + "%" : "0%"}
                                                    </strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-secondary tableTitulo alignConteudo">
                                                    <strong>Total de Aportes</strong>
                                                </td>
                                                <td className="table-secondary tableTitulo alignConteudo">
                                                    <strong>{dadosPosicao ? (usuarioServicoFinanceiro.idServicoFinanceiro === 3 ? '$ ' + mascaraUSD(dadosPosicao.totalAportes.toFixed(2)) : 'R$ ' + mascaraBRL(dadosPosicao.totalAportes.toFixed(2))) : "0,00"}</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="table-secondary tableConteudo alignConteudo">
                                                    <strong>Total de Saques</strong>
                                                </td>
                                                <td className="table-secondary tableConteudo alignConteudo">
                                                    <strong>{dadosPosicao ? (usuarioServicoFinanceiro.idServicoFinanceiro === 3 ? '$ ' + mascaraUSD(dadosPosicao.totalSaques.toFixed(2)) : 'R$ ' + mascaraBRL(dadosPosicao.totalSaques.toFixed(2))) : "0,00"}</strong>
                                                </td>
                                            </tr>
                                        </>

                                    </thead>
                                    :
                                    <tr className="formatacaoLoading">
                                        <td colSpan={4}>
                                            <LoadingButton />
                                        </td>
                                    </tr>
                                }
                            </table>
                        </div>
                    </fieldset>
                </div>
                {usuarioServicoFinanceiro.idServicoFinanceiro !== 3 &&
                    <div className="caixa1">
                        <fieldset>
                            <div>
                                <h5>Informações Gerais</h5>
                            </div>
                            <table className="tabela">
                                <thead>
                                    <tr>
                                        <td className="table-secondary tableTitulo alignConteudo">
                                            <strong>Cota de aplicação</strong>
                                        </td>
                                        <td className="table-secondary tableTitulo alignConteudo">
                                            <strong>D+1</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary tableConteudo alignConteudo">
                                            <strong>Cota de resgate</strong>
                                        </td>
                                        <td className="table-secondary tableConteudo alignConteudo">
                                            <strong>D+2</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary tableTitulo alignConteudo">
                                            <strong>Pagamento do resgate</strong>
                                        </td>
                                        <td className="table-secondary tableTitulo alignConteudo">
                                            <strong>D+5 (após cotização)</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="table-secondary tableConteudo alignConteudo">
                                            <strong>Aplicação inicial minima</strong>
                                        </td>
                                        <td className="table-secondary tableConteudo alignConteudo">
                                            <strong>R$ 25.000,00</strong>
                                        </td>
                                    </tr>
                                </thead>
                            </table>
                        </fieldset>
                    </div>
                }
                {/* {(usuarioServicoFinanceiro.idServicoFinanceiro !== 3 && usuarioServicoFinanceiro.idServicoFinanceiro !== 2) &&
                    <>
                        <div className="caixa1">
                            <fieldset className="fieldsetGrafico fieldsetGraficoHome" >
                                {errorGrafico ? <ErroTabela
                                    handleClick={() =>
                                        handleHistoricoFechamento(
                                            setLoadingGrafico,
                                            setErrorGrafico,
                                            dataInicial,
                                            dataFinal,
                                            setModalDesconectar,
                                            setRendimentosDia,
                                            setValoresTotais,
                                            setLabels,
                                            setValoresAcumulados,
                                            usuarioServicoFinanceiro,
                                            setLoadingPosicao,
                                            setErrorPosicao,
                                            setDadosPosicao,
                                            setLabelsMoeda,
                                            setPorcentagem,
                                            setValorUnitario,
                                            setValorTotal,
                                            colorArray,
                                            setColorArray,
                                            setValoresTotaisPrivate
                                        )
                                    }
                                    className="formatacaoGrafico"
                                    titleButton={<>
                                        <>
                                            <AiOutlineReload className="iconButtonDashboard" />
                                        </>
                                    </>
                                    }
                                /> : !loadingGrafico ? <>
                                    <div className="divGrafico">
                                        <GraficoLinha
                                            setIndexData={setIndexData}
                                            dadosGrafico={valoresTotais}
                                            labels={labels}
                                            alturaGrafico={height >= width ? '350px' : '90%'}
                                            nomeDado={"Evolução"}
                                            tituloGrafico='Performance Mensal da Assessoria Omega Capital'
                                            tituloTooltip={"Diferença percentual mensal"}
                                            dadoTooltip={'Valor percentual de diferença: ' + valoresTotais[indexData]?.toFixed(2) + "%"}
                                            graficoPorcentagem={true}
                                        />
                                    </div>
                                </> :
                                    <LoadingButton />
                                }
                            </fieldset>
                        </div>

                        <div className="caixa1">
                            <fieldset className="fieldsetGrafico fieldsetGraficoHome" >
                                {errorGrafico ? <ErroTabela
                                    handleClick={() =>
                                        handleHistoricoFechamento(
                                            setLoadingGrafico,
                                            setErrorGrafico,
                                            dataInicial,
                                            dataFinal,
                                            setModalDesconectar,
                                            setRendimentosDia,
                                            setValoresTotais,
                                            setLabels,
                                            setValoresAcumulados,
                                            usuarioServicoFinanceiro,
                                            setLoadingPosicao,
                                            setErrorPosicao,
                                            setDadosPosicao,
                                            setLabelsMoeda,
                                            setPorcentagem,
                                            setValorUnitario,
                                            setValorTotal,
                                            colorArray,
                                            setColorArray,
                                            setValoresTotaisPrivate
                                        )
                                    }
                                    className="formatacaoGrafico"
                                    titleButton={<>
                                        <>
                                            <AiOutlineReload className="iconButtonDashboard" />
                                        </>
                                    </>
                                    }
                                /> : !loadingGrafico ? <>
                                    <div className="divGrafico">
                                        <GraficoLinha
                                            setIndexData={setIndexDataPrivate}
                                            dadosGrafico={valoresAcumulados}
                                            labels={labels}
                                            alturaGrafico={height >= width ? '350px' : '90%'}
                                            nomeDado={"Evolução"}
                                            tituloGrafico='Rendimento Acumulado da Assessoria Omega'
                                            tituloTooltip={"Valor acumulado percentual"}
                                            dadoTooltip={'Valor acumulado: ' + valoresAcumulados[indexDataPrivate]?.toFixed(2) + "%"}
                                            graficoPorcentagem={true}
                                        />
                                    </div>
                                </> :
                                    <LoadingButton />
                                }
                            </fieldset>
                        </div>
                    </>
                }
                {(usuarioServicoFinanceiro.idServicoFinanceiro === 3 || usuarioServicoFinanceiro.idServicoFinanceiro === 2) &&
                    <>
                        <div className="caixa1">
                            <fieldset className="fieldsetGrafico fieldsetGraficoHome" >
                                {errorGrafico ? <ErroTabela
                                    handleClick={() =>
                                        handleHistoricoFechamento(
                                            setLoadingGrafico,
                                            setErrorGrafico,
                                            dataInicial,
                                            dataFinal,
                                            setModalDesconectar,
                                            setRendimentosDia,
                                            setValoresTotais,
                                            setLabels,
                                            setValoresAcumulados,
                                            usuarioServicoFinanceiro,
                                            setLoadingPosicao,
                                            setErrorPosicao,
                                            setDadosPosicao,
                                            setLabelsMoeda,
                                            setPorcentagem,
                                            setValorUnitario,
                                            setValorTotal,
                                            colorArray,
                                            setColorArray,
                                            setValoresTotaisPrivate,
                                        )
                                    }
                                    className="formatacaoGrafico"
                                    titleButton={<>
                                        <>
                                            <AiOutlineReload className="iconButtonDashboard" />
                                        </>
                                    </>
                                    }
                                /> : !loadingGrafico ? <>
                                    <div className="divGrafico">
                                        <GraficoLinha
                                            setIndexData={setIndexData}
                                            dadosGrafico={valoresTotaisPrivate}
                                            labels={labels}
                                            alturaGrafico={height >= width ? '350px' : '90%'}
                                            nomeDado={"Evolução"}
                                            tituloGrafico='Performance Mensal Private Omega Capital'
                                            tituloTooltip={"Diferença percentual mensal"}
                                            dadoTooltip={'Valor percentual de diferença: ' + valoresTotaisPrivate[indexData]?.toFixed(2) + "%"}
                                            graficoPorcentagem={true}
                                        />
                                    </div>
                                </> :
                                    <LoadingButton />
                                }
                            </fieldset>
                        </div>
                        <div className="caixa1">
                            <fieldset className="fieldsetGrafico fieldsetGraficoHome" >
                                {errorGrafico ? <ErroTabela
                                    handleClick={() =>
                                        handleHistoricoFechamento(
                                            setLoadingGrafico,
                                            setErrorGrafico,
                                            dataInicial,
                                            dataFinal,
                                            setModalDesconectar,
                                            setRendimentosDia,
                                            setValoresTotais,
                                            setLabels,
                                            setValoresAcumulados,
                                            usuarioServicoFinanceiro,
                                            setLoadingPosicao,
                                            setErrorPosicao,
                                            setDadosPosicao,
                                            setLabelsMoeda,
                                            setPorcentagem,
                                            setValorUnitario,
                                            setValorTotal,
                                            colorArray,
                                            setColorArray,
                                            setValoresTotaisPrivate
                                        )
                                    }
                                    className="formatacaoGrafico"
                                    titleButton={<>
                                        <>
                                            <AiOutlineReload className="iconButtonDashboard" />
                                        </>
                                    </>
                                    }
                                /> : !loadingGrafico ? <>
                                    <div className="divGrafico">
                                        <GraficoLinha
                                            setIndexData={setIndexDataPrivate}
                                            dadosGrafico={rendimentosDia ? rendimentosDia : [0]}
                                            labels={labels}
                                            alturaGrafico={height >= width ? '350px' : '90%'}
                                            nomeDado={"Evolução"}
                                            tituloGrafico='Percentual acumulado Private Omega Capital'
                                            tituloTooltip={"Percentual acumulado"}
                                            dadoTooltip={'Percentual acumulado: % ' + mascaraUSD(rendimentosDia[indexDataPrivate]?.toFixed(2))}
                                            graficoPorcentagem={true}
                                        />
                                    </div>
                                </> :
                                    <LoadingButton />
                                }
                            </fieldset>
                        </div>
                    </>
                } */}
                <div className="caixa1">
                    <fieldset className="fieldsetGrafico fieldsetGraficoHome" >
                        {errorGrafico ? <ErroTabela
                            handleClick={() =>
                                handleHistoricoFechamento(
                                    setLoadingGrafico,
                                    setErrorGrafico,
                                    dataInicial,
                                    dataFinal,
                                    setModalDesconectar,
                                    setRendimentosDia,
                                    setValoresTotais,
                                    setLabels,
                                    setValoresAcumulados,
                                    usuarioServicoFinanceiro,
                                    setLoadingPosicao,
                                    setErrorPosicao,
                                    setDadosPosicao,
                                    setLabelsMoeda,
                                    setPorcentagem,
                                    setValorUnitario,
                                    setValorTotal,
                                    colorArray,
                                    setColorArray,
                                    setValoresTotaisPrivate
                                )
                            }
                            className="formatacaoGrafico"
                            titleButton={<>
                                <>
                                    <AiOutlineReload className="iconButtonDashboard" />
                                </>
                            </>
                            }
                        /> : !loadingGrafico ? <>
                            <div className="divGrafico">
                                <GraficoPizza
                                    colorArray={colorArray}
                                    labels={labelsMoeda}
                                    porcentagens={porcentagem}
                                    plugins={plugins}
                                    tituloGrafico={"Composição da Carteira"}
                                    subTituloGrafico={"(Moedas com menos de 1% de participação foram ocultadas)"}
                                    mobile={height >= width ? true : false}
                                />
                            </div>
                        </> :
                            <LoadingButton />
                        }
                    </fieldset>
                </div>
            </div>
            <Footer />
            {modalDesconectar && <ModalDesconectar modal={modalDesconectar} />}
        </>

    );
};

export default Home;
