import React from "react";
import './ModalHistoricoFechamento.css';

import { Button } from "../../Button";
import { TabelaModalHistoricoFechamento } from "../../../components/Tabelas/TabelaModalHistoricoFechamento";
import { CardModalHistoricoFechamento } from "../../../components/Cards/CardModalHistoricoFechamento";

import Modal from "react-bootstrap/Modal";
import { AiFillPrinter } from "react-icons/ai";
import { criaPDF } from "./functions";
import { mascaraUSD, mascaraBRL } from "../../../functions/MascarasMonetarias";

interface modalHistoricoFechamentoProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    valorTotalReal: number;
    valorTotalDolar: number;
    valorCota: number;
    cotacoes: Cotacoes[];
}

export interface Cotacoes {
    nome: string;
    porcentagemDoAtivoNaCarteira?: number;
    quantidade: number;
    sigla: string;
    valorTotal: number;
    valorUnitario: number;
    adicionadoManualmente?: boolean;
    origem?: string;
}

export const ModalHistoricoFechamento = ({
    modal,
    setModal,
    cotacoes,
    valorTotalReal,
    valorTotalDolar,
    valorCota,
}: modalHistoricoFechamentoProps) => {

    console.log((valorTotalDolar));


    return (
        <Modal show={modal} onHide={() => setModal(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Fechamento do dia</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <fieldset>
                    <div id="fechamentoDesktop" className="desktopModalHistoricoFechamento">
                        <table id="tblHistoricoFechamento" className="tabelaFechamento">
                            <thead>
                                <tr className="tabelaFechamentoHeader">
                                    <th className="tableTituloFechamento">
                                        <h6>Ativo</h6>
                                    </th>
                                    <th className="tableTituloFechamento colunaQuantidade">
                                        <h6>Quantidade</h6>
                                    </th>

                                    <th className="tableTituloFechamento">
                                        <h6>Valor unitario do ativo</h6>
                                    </th>
                                    <th className="tableTituloFechamento colunaPorcentagemAtivo">
                                        <h6>Porcentagem na carteira</h6>
                                    </th>
                                    <th className="tableTituloFechamento">
                                        <h6>Total</h6>
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="tbodyPrincipal">
                                {cotacoes.map((cotacao, index) => {
                                    return (
                                        <TabelaModalHistoricoFechamento
                                            key={`${cotacao.nome}${cotacao.valorTotal}`}
                                            ativo={cotacao.nome}
                                            quantidade={cotacao.quantidade}
                                            valorUnitario={cotacao.valorUnitario}
                                            total={cotacao.valorTotal}
                                            fonteBold={false}
                                            manual={cotacao.adicionadoManualmente}
                                            index={index}
                                            porcentagemDoAtivoNaCarteira={cotacao.porcentagemDoAtivoNaCarteira}
                                            sigla={cotacao.sigla}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div id="fechamentoMobile" className="mobileModalHistoricoFechamento">
                        {cotacoes.map((cotacao, index) => (
                            <CardModalHistoricoFechamento
                                key={`${cotacao.nome}${cotacao.valorTotal}`}
                                ativo={cotacao.nome}
                                quantidade={cotacao.quantidade}
                                valorUnitario={cotacao.valorUnitario}
                                total={cotacao.valorTotal}
                                manual={cotacao.adicionadoManualmente}
                                index={index}
                                porcentagemDoAtivoNaCarteira={cotacao.porcentagemDoAtivoNaCarteira}
                                sigla={cotacao.sigla}
                            />
                        ))}
                    </div>

                    <table
                        id="tblHistoricoFechamentoFooter"
                        className="tabelaFechamento footerTabelaFechamento"
                    >
                        <tfoot>
                            <tr className="corDaLinhaDaTabelaFechamento">
                                <td className="textoValorDoFechamentoFechamento" colSpan={5}>
                                    Total do fechamento do dia (USD): &nbsp;
                                    <label className="fw-bold lblTotalFechamentoUSD">
                                        US$ {mascaraUSD(valorTotalDolar.toFixed(2))}
                                    </label>
                                </td>
                            </tr>
                            <tr className="corDaLinhaDaTabelaFechamento">
                                <td className="textoValorDoFechamentoFechamento" colSpan={5}>
                                    Total do fechamento do dia (BRL): &nbsp;
                                    <label className="fw-bold lblTotalFechamentoBRL">
                                        R$ {mascaraBRL(valorTotalReal.toFixed(2))}
                                    </label>
                                </td>
                            </tr>
                            <tr className="corDaLinhaDaTabelaFechamento">
                                <td className="textoValorDoFechamentoFechamento" colSpan={5}>
                                    Valor da cota do dia:  &nbsp;
                                    <label className="fw-bold lblTotalCotaFechamento">R$ {mascaraBRL(valorCota.toFixed(2))}</label>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </fieldset>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="btn__laranja"
                    onClick={() => criaPDF()}
                    titleButton={
                        <>
                            <AiFillPrinter className="iconButton" />
                            Imprimir
                        </>
                    }
                />
                <Button
                    classNameButton="btnCancelar"
                    onClick={() => setModal(false)}
                    titleButton="Fechar"
                />
            </Modal.Footer>
        </Modal>
    );
};
