import React, { useEffect, useState } from "react";
import "../../pages/Cliente/ConfirmacaoEmail/ConfirmacaoEmail.css";

import Footer from "../../components/Footer/Footer";
import { Header } from "../../components/Header";
import { Button } from "../../components/Button";

import { useNavigate } from "react-router-dom";

export const NotFound = () => {

    const navigate = useNavigate();

    return (
        <>
            <Footer />
            <div className="container">
                <Header titulo="Página não encontrada." />
                <div className="caixaConfirmacaoEmail linha1">
                    <div className="caixaConfirmacaoEmail__fieldset_left">
                        <fieldset className="caixaConfirmacaoEmail__fieldset__global">
                            <div>
                                <div className="conteudo">
                                    <div className="conteudo__div">
                                        <h5 className="conteudo__div__titulo">
                                            A página que você procura não foi encontrada.
                                        </h5>
                                        <p className="conteudo__texto">
                                            Para retornar à tela anterior,
                                            clique abaixo:
                                        </p>

                                        <Button
                                            onClick={() => navigate(-1)}
                                            className="btn__ConfirmacaoEmail"
                                            data-bs-toggle="modal"
                                            data-bs-target="#acessar"
                                            titleButton="ACESSAR"
                                        />
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </>
    );
};

