import React, { useRef, useState } from 'react'
import './styles.css'
import { handleFotoPerfilUpload, handleUserName } from './functions';

import { Input } from '../Input'
import { Erro } from '../../pages/Cliente/AlteracaoSenha/AlteracaoSenha';
import SegurancaDeSenha from '../SegurancaSenha/SegurancaSenha';

interface CadastroAcessoProps {
    userName: string,
    setUserName: React.Dispatch<React.SetStateAction<string>>,
    setEmail: React.Dispatch<React.SetStateAction<string>>,
    setPassword: React.Dispatch<React.SetStateAction<string>>,
    setImagePerfil: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>,
    setSenhaConfirmada: React.Dispatch<React.SetStateAction<string>>,
    senha: string,
    setError: React.Dispatch<React.SetStateAction<Erro>>,
}

export const CadastroAcesso = ({ userName, setUserName, setEmail, setPassword, setImagePerfil, setSenhaConfirmada, senha, setError }: CadastroAcessoProps) => {
    const filesElement = useRef(null);

    return (
        <div className="AreaLogin">
            <fieldset>
                <h3 className="TextoTitulo">ACESSO</h3>
                <div className="inputsCadastroAcesso">
                    <Input required={true} label="Username" placeholder=' ' value={userName} onChange={(e) => { handleUserName(e.target.value, setError, setUserName) }} />
                    <Input required={true} type="email" label="Email" placeholder=' ' onChange={(e) => { setEmail(e.target.value) }} />
                    <Input required={true} type="password" label="Senha" placeholder=' ' senha={true} onChange={(e) => {
                        setPassword(e.target.value)
                    }} />
                    <SegurancaDeSenha senha={senha} />
                    <Input
                        required={true}
                        type="password"
                        placeholder=' '
                        senha={true}
                        label="Confirme a Senha"
                        onChange={(e) => {
                            setSenhaConfirmada(e.target.value);
                        }} />
                </div>
                <div className="Area_DocumentosAnexos">
                    <div>
                        <label className="DocumentosAnexos">
                            Foto do perfil do usuário
                        </label>
                        <Input
                            className="DocumentosAnexos_Botao"
                            type="file"
                            ref={filesElement}
                            onChange={(e) => handleFotoPerfilUpload(e, setError, setImagePerfil)}
                            accept="image/*,.pdf"
                        />
                    </div>
                </div>
            </fieldset>
        </div >
    )
}
