import React from "react";
import "./ConfirmacaoUsuario.css";
import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import { Button } from "../../../components/Button";
import { Link } from "react-router-dom";

const ConfirmacaoUsuario = () => {
    return (
        <>
            <Footer />
            <div className="container">
                <Header titulo="Cadastrado com Sucesso!" />
                <div className="caixaConfirmacaoUsuario linha1">
                    <div className="caixaConfirmacaoUsuario__fieldset_left">
                        <fieldset className="caixaConfirmacaoUsuario__fieldset__global">
                            <div>
                                <div className="conteudo">
                                    <div className="conteudo__div">
                                        <h5 className="conteudo__div__titulo">Informações recebidas com sucesso!</h5>
                                        <p className="conteudo__texto">Entraremos em contato após análise de documentos</p>
                                        <Link
                                            style={{ textDecoration: 'none' }}
                                            to="/"
                                        >
                                            <Button
                                                data-bs-toggle="modal"
                                                data-bs-target="#acessar"
                                                titleButton="Voltar"
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmacaoUsuario;