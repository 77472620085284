import React, { useEffect, useState } from "react";
import "./Routes.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { PrivateRoute } from "./PrivateRoute";

import { obterPerfilUsuario } from '../../services/cookies'
import { UsuarioContextType, usuarioContext } from '../../context/tipoUsuario'

//Paginas do USUÁRIO
import Login from "../../pages/Login/Login";
import RedefinirSenha from "../../pages/RedefinirSenha/RedefinirSenha";
import ConfirmacaoEmail from "../../pages/Cliente/ConfirmacaoEmail/ConfirmacaoEmail";
import Cadastro from "../../pages/Cliente/Cadastro/Cadastro";
import AlteracaoSenha from "../../pages/Cliente/AlteracaoSenha/AlteracaoSenha";
import DadosCadastrais from "../../pages/Cliente/DadosCadastrais/DadosCadastrais";

//Paginas do CLIENTE
import AporteSaque from "../../pages/Cliente/AporteSaque/AporteSaque";
import { Ativos } from "../../pages/Cliente/Ativos/Ativos";
import ConfirmacaoUsuario from "../../pages/Cliente/ConfirmacaoUsuario/ConfirmacaoUsuario";
import Contato from "../../pages/Cliente/Contato/Contato";
import Home from "../../pages/Cliente/Home/Home";
import Faq from "../../pages/Cliente/Faq/Faq";
import Relatorios from "../../pages/Cliente/Relatorios/Relatorios";
import DashboardsCliente from "../../pages/Cliente/DashboardsCliente/DashboardsCliente";
import { Extrato } from "../../pages/Cliente/Extrato/Extrato";

//Paginas do GESTOR
import CadastroFuncionario from "../../pages/Gestor/CadastroFuncionario/CadastroFuncionario";
import TemplateEmail from "../../pages/Gestor/TemplateEmail/TemplateEmail";
import GerenciarCadastro from "../../pages/Gestor/GerenciarCadastro/GerenciarCadastro";
import GerenciarServicos from "../../pages/Gestor/GerenciarServicos/GerenciarServicos";
// import GerenciarAtivosFinanceiros from "../../pages/Gestor/GerenciarAtivosFinanceiros/GerenciarAtivosFinanceiros";
import HistoricoFechamento from "../../pages/Gestor/HistoricoFechamento/HistoricoFechamento";
import HomeGestor from "../../pages/Gestor/HomeGestor/HomeGestor";
import VisaoGeral from "../../pages/Gestor/VisaoGeral/VisaoGeral";
import SolicitacaoSaque from "../../pages/Gestor/SolicitacaoSaque/SolicitacaoSaque";
import Fechamento from "../../pages/Gestor/Fechamento/Fechamento";
import ListarUsuarios from "../../pages/Gestor/ListarUsuarios/ListarUsuarios";
import ListarFuncionarios from "../../pages/Gestor/ListarFuncionarios/ListarFuncionarios";
import DashboardsGestor from "../../pages/Gestor/DashboardsGestor/DashboardsGestor";
import { ValidacaoAporte } from "../../pages/Gestor/ValidacaoAporte/ValidacaoAporte";

//Paginas de Termos Legais
import PrivacyPolicy from "../../pages/Legal/PrivacyPolicy/PrivacyPolicy";
import Fees from "../../pages/Legal/Fees/Fees";
import TermsOfUse from "../../pages/Legal/TermsOfUse/TermsOfUse";
import TermosDeUsoSistema from "../../pages/Legal/TermosDeUsoSistema/TermosDeUsoSistema";
import { NotFound } from "../../pages/NotFound/NotFound";
import { Privacidade } from "../../pages/Privacidade/Privacy";
import { ListarClientesPrivate } from "../../pages/Gestor/ListarClientesPrivate";

import { DashboardsDoCliente } from "../../pages/Gestor/DashboardsCliente";
import { ListarClientesApp } from "../../pages/Gestor/ListarClientesApp";


const RoutesSidebar = () => {
  const [perfil, setPerfil] = useState<string>('')

  useEffect(() => {
    setPerfil(obterPerfilUsuario())
  }, [])


  return (
    <Router>
      <Routes>
        {(perfil === "Administrador" || perfil === "Funcionario") && (
          <>
            <Route
              path="usuarios/dashboards/:idCliente/:nome"
              element={
                <PrivateRoute>
                  <DashboardsDoCliente />
                </PrivateRoute>
              }
            />
            <Route

              path="/funcionario/cadastro"
              element={
                <PrivateRoute>
                  <CadastroFuncionario />
                </PrivateRoute>
              }
            />
            <Route

              path="/funcionario/clientes-private"
              element={
                <PrivateRoute>
                  <ListarClientesPrivate />
                </PrivateRoute>
              }
            />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <HomeGestor />
                </PrivateRoute>
              }
            />
            <Route
              path="/gerenciar/template-email"
              element={
                <PrivateRoute>
                  <TemplateEmail />
                </PrivateRoute>
              }
            />
            <Route
              path="/gerenciar/cadastro"
              element={
                <PrivateRoute>
                  <GerenciarCadastro />
                </PrivateRoute>
              }
            />
            <Route
              path="/gerenciar/dashboards"
              element={
                <PrivateRoute>
                  <DashboardsGestor />
                </PrivateRoute>
              }
            />
            <Route
              path="/gerenciar/servicos-financeiros"
              element={
                <PrivateRoute>
                  <GerenciarServicos />
                </PrivateRoute>
              }
            />
            <Route
              path="/gerenciar/historico-fechamento/:idCredenciais/:nomeCliente"
              element={
                <PrivateRoute>
                  <HistoricoFechamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/gerenciar/historico-fechamento"
              element={
                <PrivateRoute>
                  <HistoricoFechamento />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/visao-geral"
              element={
                <PrivateRoute>
                  <VisaoGeral />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/solicitacoes/saque"
              element={
                <PrivateRoute>
                  <SolicitacaoSaque />
                </PrivateRoute>
              }
            />
            <Route
              path="/validacao/aporte"
              element={
                <PrivateRoute>
                  <ValidacaoAporte />
                </PrivateRoute>
              }
            />
            <Route
              path="/fechamento"
              element={
                <PrivateRoute>
                  <Fechamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/fechamento/:idCredenciais"
              element={
                <PrivateRoute>
                  <Fechamento />
                </PrivateRoute>
              }
            />
            <Route
              path="/usuarios"
              element={
                <PrivateRoute>
                  <ListarUsuarios />
                </PrivateRoute>
              }
            />
            <Route
              path="/funcionarios"
              element={
                <PrivateRoute>
                  <ListarFuncionarios />
                </PrivateRoute>
              }
            />
            <Route
              path="/extrato"
              element={
                <PrivateRoute>
                  <Extrato />
                </PrivateRoute>
              }
            />
            {perfil === "Administrador" &&
              <Route
                path="/gerenciar/ClientesApp"
                element={
                  <PrivateRoute>
                    <ListarClientesApp />
                  </PrivateRoute>
                }
              />
            }
          </>
        )}

        {(perfil === "UsuarioAssessoria" || perfil === "UsuarioCustodia") && (
          <>
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/aport-e-saque"
              element={
                <PrivateRoute>
                  <AporteSaque />
                </PrivateRoute>
              }
            />
            <Route
              path="/ativos"
              element={
                <PrivateRoute>
                  <Ativos />
                </PrivateRoute>
              }
            />

            <Route
              path="/relatorios"
              element={
                <PrivateRoute>
                  <Relatorios />
                </PrivateRoute>
              }
            />
            <Route
              path="/faq"
              element={
                <PrivateRoute>
                  <Faq />
                </PrivateRoute>
              }
            />
            <Route
              path="/contato"
              element={
                <PrivateRoute>
                  <Contato />
                </PrivateRoute>
              }
            />
            <Route
              path="/extrato"
              element={
                <PrivateRoute>
                  <Extrato />
                </PrivateRoute>
              }
            />
            <Route
              path="/dashboards"
              element={
                <PrivateRoute>
                  <DashboardsCliente />
                </PrivateRoute>
              }
            />

          </>

        )}

        <Route
          path="/confirmacao-usuario"
          element={<ConfirmacaoUsuario />}
        />
        <Route path="/" element={<Login />} />
        <Route path="/redefinirSenha" element={<RedefinirSenha />} />
        <Route path="/cliente/cadastro" element={<Cadastro />} />
        <Route
          path="/cliente/alteracaoSenha"
          element={
            <PrivateRoute>
              <AlteracaoSenha />
            </PrivateRoute>
          }
        />
        <Route
          path="/dados-cadastrais"
          element={
            <PrivateRoute>
              <DadosCadastrais />
            </PrivateRoute>
          }
        />
        <Route
          path="/confirmacao-email"
          element={<ConfirmacaoEmail />}
        />
        <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/legal/fees" element={<Fees />} />
        <Route path="/legal/terms-of-use" element={<TermsOfUse />} />
        <Route path="/termos-de-uso" element={<TermosDeUsoSistema />} />
        <Route path="/app/privacy" element={<Privacidade />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default RoutesSidebar;
