import { Endereco, Usuario } from "../../pages/Cliente/Cadastro/Cadastro";
import { buscarDadosCep } from "../../services/api";


export const checkCEP = async (
    e: any,
    setCEP: (value: React.SetStateAction<string>) => void,
    setRua: (value: React.SetStateAction<string>) => void,
    setBairro: (value: React.SetStateAction<string>) => void,
    setEstado: (value: React.SetStateAction<string>) => void,
    setCidade: (value: React.SetStateAction<string>) => void,
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    rua: string,
    bairro: string,
    cidade: string,
    complemento: string,
    estado: string,
    CEP: string

) => {
    setCEP(e);
    await buscarDadosCep(e).then((res) => {
        setRua(res.data.logradouro);
        setBairro(res.data.bairro);
        setCidade(res.data.localidade);
        setEstado(res.data.uf);
    }).finally(() => {
        handleEndereco(setUsuarioPF, rua, bairro, cidade, complemento, estado, e);
    });
};

export function handleEndereco(
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    rua: string,
    bairro: string,
    cidade: string,
    complemento: string,
    estado: string,
    CEP: string
) {
    setUsuarioPF((usuario) => {
        usuario.endereco = { rua, bairro, cep: CEP, cidade, complemento, estado }
        return usuario
    });
}

export function handleDataDeNascimento(
    e: string,
    setMaskDataDeNascimento: (value: React.SetStateAction<string>) => void,
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
) {

    e = e.replace(/\D/g, "");
    e = e.replace(/^(\d\d)(\d)/g, "$1/$2");
    e = e.replace(/(\d{2})(\d)/, "$1/$2");
    setMaskDataDeNascimento(e);
    let dateSplit = e.split("/");
    let date = new Date(dateSplit[2] + "/" + dateSplit[1] + "/" + dateSplit[0]);

    date.toISOString() && setUsuarioPF((usuario) => {
        usuario.dataNascimento = date.toISOString();
        return usuario;
    });
};

export function handleMaskRG(
    e: string,
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    setMaskRG: (value: React.SetStateAction<string>) => void,
) {
    e = e.replace(/[^-.0-9a-z]/g, "");
    setUsuarioPF((usuario) => {
        usuario.rg = e
        return usuario
    });
    setMaskRG(e);
}

export function handleMaskCPF(
    e: string,
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    setMaskCPF: (value: React.SetStateAction<string>) => void,
) {
    e = e.replace(/\D/g, "");
    setUsuarioPF((usuario) => {
        usuario.cpf = e
        return usuario
    });
    e = e.replace(/^(\d{3})(\d)/g, "$1.$2");
    e = e.replace(/(\d{3})(\d)/, "$1.$2");
    e = e.replace(/(\d{3})(\d)/, "$1-$2");
    setMaskCPF(e);
}

export function handleMaskCEP(
    e: any,
    setCEP: (value: React.SetStateAction<string>) => void,
    setRua: (value: React.SetStateAction<string>) => void,
    setBairro: (value: React.SetStateAction<string>) => void,
    setEstado: (value: React.SetStateAction<string>) => void,
    setCidade: (value: React.SetStateAction<string>) => void,
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    setMaskCEP: (value: React.SetStateAction<string>) => void,
    rua: string,
    bairro: string,
    cidade: string,
    complemento: string,
    estado: string,
    CEP: string
) {
    e = e.replace(/\D/g, "");
    checkCEP(e, setCEP, setRua, setBairro, setEstado, setCidade, setUsuarioPF, rua, bairro, cidade, complemento, estado, CEP);
    e = e.replace(/^(\d{2})(\d)/g, "$1.$2");
    e = e.replace(/(\d{3})(\d)/, "$1-$2");
    setMaskCEP(e);
}

export function handleMaskTelefone(
    e: string,
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    setMaskTelefone: (value: React.SetStateAction<string>) => void,
) {
    e = e.replace(/\D/g, "");
    setUsuarioPF((usuario) => {
        usuario.telefone = e;
        return usuario;
    });
    e = e.replace(/^(\d{2})(\d)/g, "($1) $2");
    e = e.replace(/(\d{5})(\d)/, "$1-$2");
    setMaskTelefone(e);
}

export function handleMaskRenda(
    e: string,
    setUsuarioPF: React.Dispatch<React.SetStateAction<Usuario>>,
    setRendaMask: (value: React.SetStateAction<string>) => void
) {
    e = e.replace(/[^,.0-9]/g, "");
    e = e.replace(",", ".");
    var count = 0;
    for (let i = 0; i <= e.length; i++) {
        if (e[i] === ".") {
            count = count + 1;
            if (count >= 2) {
                e = e.replace(/[.]/, "");
            }
        }
    }

    setUsuarioPF((usuario) => {
        usuario.renda = Number(e);
        return usuario;
    });
    e = "R$ " + e;
    setRendaMask(e);
}