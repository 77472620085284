import React from "react";
import "./styles.css";

import { Button } from "../../Button";


interface CardHomeGestorProps {
    link: string;
    titulo: string;
    subTitulo: string;
}
export const CardHomeGestor = ({
    link,
    titulo,
    subTitulo,
}: CardHomeGestorProps) => {

    return (
        <fieldset className="fieldset_CardHomeGestor">
            <h5 className="">
                {titulo}
            </h5>
            <p className="">{subTitulo}</p>
            {link !== '/fechamento' ?
                <Button
                    onClick={() => window.location.href = (link)}
                    // className=""
                    data-bs-toggle="modal"
                    data-bs-target="#acessar"
                    titleButton="ACESSAR"
                />
                :
                <>
                    <div className="botoesCardHomeGestor">
                        <Button
                            onClick={() => window.location.href = ('/funcionario/clientes-private')}
                            data-bs-toggle="modal"
                            data-bs-target="#acessar"
                            titleButton="PRIVATE"
                        />
                        <Button
                            onClick={() => window.location.href = ('/fechamento')}
                            data-bs-toggle="modal"
                            data-bs-target="#acessar"
                            titleButton="ASSESSORIA"
                        />
                    </div>
                    </>
                }
        </fieldset>
    );
};
