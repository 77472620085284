import { contato, } from "../../../services/cliente"
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies"
import { renovacaoToken } from "../../../services/usuario"

export function enviarContato(
    email: string,
    nome: string,
    telefone: string,
    motivoContato: string,
    mensagem: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErro: React.Dispatch<React.SetStateAction<boolean>>,
    setSucesso: React.Dispatch<React.SetStateAction<boolean>>,
    setDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setErroPreenchimento: React.Dispatch<React.SetStateAction<boolean>>,
    setErroEmail: React.Dispatch<React.SetStateAction<boolean>>,
) {
    if (verificaPreenchimento(email, mensagem, setErroPreenchimento, setErroEmail)) {
        setErroPreenchimento(false)
        setLoading(true);
        contato(obterDadosUsuario2().token, email, nome, telefone, motivoContato, mensagem).then(() => {
            setSucesso(true);
        }).catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(obterDadosUsuario2().token, obterDadosUsuario2().tokenRenovacao).then(res => {
                    guardarDadosUsuario2(
                        res.data.camposAdicionais.tokenAcesso,
                        res.data.camposAdicionais.tokenRenovacao,
                        obterDadosUsuario2().email.toString(),
                        obterDadosUsuario2().id.toString()
                    );
                    contato(res.data.camposAdicionais.tokenAcesso, email, nome, telefone, motivoContato, mensagem).then(res => {
                        setSucesso(true);
                    })
                }).catch(() => {
                    setDesconectar(true)
                })
            } else {
                setErro(true)
            }
        }).finally(() => setLoading(false))
    }

}

export function handleMaskTelefone(
    e: string,
    setTelefone: React.Dispatch<React.SetStateAction<string>>,
    setMaskTelefone: (value: React.SetStateAction<string>) => void,
) {
    e = e.replace(/\D/g, "");
    setTelefone(e);
    e = e.replace(/^(\d{2})(\d)/g, "($1) $2");
    e = e.replace(/(\d{5})(\d)/, "$1-$2");
    setMaskTelefone(e);
}

function verificaPreenchimento(
    email: string,
    mensagem: string,
    setErroPreenchimento: React.Dispatch<React.SetStateAction<boolean>>,
    setErroEmail: React.Dispatch<React.SetStateAction<boolean>>,
) {
    if (!verificarSeEmailEValido(email)) {
        setErroEmail(true);
        return false;
    }
    if (mensagem === "") {
        setErroPreenchimento(true);
        return false;
    }

    return true
}

export const verificarSeEmailEValido = (email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(email) === false) {
        return false;
    } else {
        return true;
    }
};