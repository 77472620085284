import React, { useState } from "react";
import "./Contato.css";
import { enviarContato, handleMaskTelefone } from "./functions";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";
import { SelectMotivoContato } from "../../../components/select/SelectMotivoContato";
import { LoadingButton } from "../../../components/loadingButtom";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { MensagemDeSucesso } from "../../../components/RespostasDosFormularios/Sucesso";


const Contato = () => {
    const [email, setEmail] = useState<string>('');
    const [nome, setNome] = useState<string>('');
    const [telefone, setTelefone] = useState<string>('');
    const [telefoneMask, setTelefoneMask] = useState<string>('');
    const [motivoContato, setMotivoContato] = useState<string>('Dúvida');
    const [mensagem, setMensagem] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [erro, setErro] = useState<boolean>(false);
    const [erroPreenchimento, setErroPreenchimento] = useState<boolean>(false);
    const [erroEmail, setErroEmail] = useState<boolean>(false);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [desconectar, setDesconectar] = useState<boolean>(false);

    return (
        <>
            <Navbar />
            <div className="container align-self-center area__contato">
                <Header titulo="Contato" />
                <div className="row espacamentoContato">
                    <div className="caixaContato">
                        <fieldset>
                            <div className="col-xs-12 col-md-9 p-3 m-auto">
                                <Input
                                    onChange={(e) => setEmail(e.target.value)}
                                    type="email"
                                    id="email"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                    label="Email:"
                                    required
                                />
                                <Input
                                    onChange={(e) => setNome(e.target.value)}
                                    id="nome"
                                    type="text"
                                    label="Nome:"
                                    required
                                />
                                <Input
                                    onChange={(e) => handleMaskTelefone(e.target.value, setTelefone, setTelefoneMask)}
                                    maxLength={15}
                                    value={telefoneMask}
                                    label="Telefone:"
                                    required
                                />
                                <label
                                    htmlFor="motivo"
                                    className="textoLabelContato"
                                >
                                    Motivo do contato:
                                </label>
                                <br />
                                <SelectMotivoContato
                                    setMotivo={setMotivoContato}
                                />
                                <label
                                    className="textoLabelContato"
                                >
                                    Mensagem:
                                </label>
                                <textarea
                                    onChange={(e) => setMensagem(e.target.value)}
                                    className="w-100 mb-3 p-2 rounded form-control mt-0 corTexto"
                                    name="message"
                                    rows={10}
                                    cols={10}
                                    placeholder="Escreva sua mensagem aqui."
                                    id="mensagem"
                                    required
                                />
                                {erroEmail && <p>Por favor, insira um email válido.</p>}
                                {erroPreenchimento && <p>Por favor, preencha a mensagem.</p>}
                                {erro && <p>Ocorreu um erro inesperado. Por favor tente novamente.</p>}
                                {loading ?
                                    <LoadingButton /> :
                                    sucesso ? <MensagemDeSucesso
                                        mensagem="Sua mensagem foi enviada, responderemos assim que possível."
                                    /> : <Button
                                        onClick={() =>
                                            enviarContato(email, nome, telefone, motivoContato, mensagem, setLoading, setErro, setSucesso, setDesconectar, setErroPreenchimento, setErroEmail)
                                        }
                                        titleButton="Enviar"
                                    />
                                }
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            {desconectar &&
                <ModalDesconectar
                    modal={desconectar}
                />}
            <Footer />
        </>
    );
};

export default Contato;