import React from 'react'


interface SelectEstadoProps {
    setUf?: React.Dispatch<React.SetStateAction<string>>,
    UF?: string,
    onChange?: React.ChangeEventHandler<HTMLSelectElement>,
}
export const SelectEstado = ({ setUf, UF, onChange }: SelectEstadoProps) => {
    return (
        <select className="selectMenor" placeholder="Estado" onChange={(e) => onChange ? onChange(e) : setUf(e.target.value)}  >
            {UF ?
                <option className="Estado" value={`${UF}`} defaultChecked>{UF}</option>
                :
                <option className="Estado" value="" defaultChecked>UF</option>
            }
            <option className="Estado" value="AC">AC</option>
            <option className="Estado" value="AL">AL</option>
            <option className="Estado" value="AP">AP</option>
            <option className="Estado" value="AM">AM</option>
            <option className="Estado" value="BA">BA</option>
            <option className="Estado" value="CE">CE</option>
            <option className="Estado" value="DF">DE</option>
            <option className="Estado" value="ES">ES</option>
            <option className="Estado" value="GO">GO</option>
            <option className="Estado" value="MA">MA</option>
            <option className="Estado" value="MT">MT</option>
            <option className="Estado" value="MS">MS</option>
            <option className="Estado" value="MG">MG</option>
            <option className="Estado" value="PA">PA</option>
            <option className="Estado" value="PB">PB</option>
            <option className="Estado" value="PR">PR</option>
            <option className="Estado" value="PE">PE</option>
            <option className="Estado" value="PI">PI</option>
            <option className="Estado" value="RJ">RJ</option>
            <option className="Estado" value="RN">RN</option>
            <option className="Estado" value="RS">RS</option>
            <option className="Estado" value="RO">RO</option>
            <option className="Estado" value="RR">RR</option>
            <option className="Estado" value="SC">SE</option>
            <option className="Estado" value="SP">SP</option>
            <option className="Estado" value="SE">SE</option>
            <option className="Estado" value="TO">TO</option>
        </select>
    )
}