import React, { useState, useEffect } from "react";
import "../GerenciarCadastro/GerenciarCadastro.css";
import { retornarUsuariosEmEspera } from "./functions";

import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import { TabelaGerenciarCadastro } from "../../../components/Tabelas/TabelaGerenciarCadastro";
import { CardGerenciarCadastro } from "../../../components/Cards/CardGerenciarCadastro";
import { LoadingButton } from "../../../components/loadingButtom";
import { ModalDadosPessoaisCliente } from "../../../components/modais/ModalDadosPessoaisCliente";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";

import { AiOutlineReload, AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Button } from "../../../components/Button";
import { voltarSlide, proximoSlide } from "../../Cliente/Extrato/functions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    variableWidth: true,
    respondTo: 'slider',
    rows: 2,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlide" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlide" style={{ display: 'none !important' }}></div>
};

export interface Cliente {
    idCredenciais: string,
    nome: string,
    idsServicosContratados: string[]
}

const GerenciarCadastro = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [modalDadosPessoais, setModalDadosPessoais] = useState<boolean>(false);
    // const [acao, setAcao] = useState<boolean>(false);
    const [erro, setErro] = useState<boolean>(false);
    const [desconectar, setDesconectar] = useState<boolean>(false);
    const [idCliente, setIdCliente] = useState<string>("");
    const [usuariosEmEspera, setUsuariosEmEspera] = useState<Array<Cliente>>([]);

    useEffect(() => {
        setLoading(false);
        retornarUsuariosEmEspera(setLoading, setErro, setDesconectar, setUsuariosEmEspera);
    }, [reload])

    return (
        <>
            <Navbar />
            <div id="container">
                <Header titulo="Gerenciar Cadastros" />
                <div className="desktopGerenciarCadastro">
                    <div className="caixa1">
                        <fieldset>
                            {loading ?
                                <table className="tabela">
                                    <thead>
                                        <tr className="gerenciarCadastroTabela">
                                            <th className="table-secondary tableTitulo tableData colunas">
                                                <strong>Nome</strong>
                                            </th>
                                            <th className="table-secondary tableTitulo tableCotas colunas">
                                                <strong>Tipo</strong>
                                            </th>
                                            <th className="table-secondary tableTitulo tableOperacao colunas">
                                                <strong>Status</strong>
                                            </th>
                                            <th className="table-secondary tableTitulo tableOperacao colunas">
                                                <strong>Informações</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {erro ? (
                                            <tr>
                                                <td colSpan={5} style={{ textAlign: "center" }}>
                                                    <p>Ocorreu um erro. Por favor tente novamente.</p>
                                                    <AiOutlineReload
                                                        onClick={() => setReload(!reload)}
                                                    />
                                                </td>
                                            </tr>
                                        ) : usuariosEmEspera.length !== 0 ? (
                                                usuariosEmEspera.map((usuario) =>
                                                    <TabelaGerenciarCadastro key={usuario.idCredenciais} usuario={usuario} setModal={setModalDadosPessoais} setIdCliente={setIdCliente} setReload={setReload} reload={reload} />
                                                )
                                        ) : (
                                                    <tr>
                                                        <td colSpan={5} style={{ textAlign: "center", }}>
                                                            <p>Nenhum resultado encontrado no momento.</p>
                                                        </td>
                                                    </tr>
                                                )
                                        }
                                    </tbody>
                                </table>
                                : (
                                    <div className="loading-container-GerenciarCadastro">
                                        <LoadingButton />
                                    </div>
                                )
                            }
                        </fieldset>
                    </div>
                </div>
                <div className="mobileGerenciarCadastro">
                    <div className="caixa1">
                        <fieldset>
                            <div className="divBotoesExtratoMobile">
                                <Button
                                    className="btn__cinza btnMargin"
                                    onClick={() => voltarSlide()}
                                    titleButton={
                                        <>
                                            <AiOutlineArrowLeft className="iconButtonExtrato" />
                                        </>
                                    }
                                />
                                <Button
                                    className="btn__cinza btnMargin"
                                    onClick={() => proximoSlide()}
                                    titleButton={
                                        <>
                                            <AiOutlineArrowRight className="iconButtonExtrato" />
                                        </>
                                    }
                                />
                            </div>
                            <div className="boxSlideExtrato">
                                {erro ? (
                                    <div>
                                        <span className="formatacaoLoadingExtrato">
                                            Ocorreu um erro. Clique no botão para tentar novamente.
                                        </span>
                                        <Button
                                            className="btn__cinza btnMargin"
                                            onClick={() => setReload(!reload)}
                                            titleButton={
                                                <>
                                                    <AiOutlineReload
                                                        className="iconButtonExtrato"
                                                        onClick={() => setReload(!reload)} />
                                                </>
                                            }
                                        />
                                    </div>
                                ) : !loading ? (
                                    <span className="formatacaoLoadingExtrato">
                                        <LoadingButton />
                                    </span>
                                ) : (
                                    <Slider {...settings}>
                                        {usuariosEmEspera.length !== 0 ? (
                                            usuariosEmEspera.map((usuario) => (
                                                <CardGerenciarCadastro key={usuario.idCredenciais} usuario={usuario} setModal={setModalDadosPessoais} setIdCliente={setIdCliente} setReload={setReload} reload={reload} />
                                            ))
                                        ) : <span>Nenhum registro encontrado no momento.</span>
                                        }
                                    </Slider>
                                )}
                            </div>
                        </fieldset>
                    </div>
                </div>

            </div>
            {modalDadosPessoais && <ModalDadosPessoaisCliente
                modal={modalDadosPessoais}
                setModal={() => setModalDadosPessoais(false)}
                idCliente={idCliente}
            />}
            <Footer />
            {desconectar && <ModalDesconectar modal={desconectar} />}
        </>
    );
}

export default GerenciarCadastro;