import React, { useState } from "react";

import { Button } from "../../Button";

import { AiOutlineCheckCircle, AiOutlineMinusCircle } from "react-icons/ai";

interface CardListarFuncionariosProps {
    nome: string,
    status: boolean,
    ativar: () => void,
    desativar: () => void,
}

export const CardListarFuncionarios = ({
    nome,
    status,
    ativar,
    desativar
}: CardListarFuncionariosProps) => {
    return (
        <>
            <div className="caixa1 caixaListarUsuarios">
                <fieldset>
                    <div className="FieldsetDivTexto">
                        <h5>{nome}</h5>
                        <h6>{status ? "Ativo" : "Inativo"}</h6>
                    </div>
                    <div className="divButton">
                        {!status ? (
                            <>
                                <Button
                                    onClick={ativar}
                                    className="btn__verde botaoCircular cardBtn"
                                    titleButton={
                                        <>
                                            <AiOutlineCheckCircle className="iconButtonExtrato" />
                                            <p className="textoBotao">Ativar</p>
                                        </>
                                    }
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    onClick={desativar}
                                    className="btn__vermelho botaoCircular cardBtn"
                                    titleButton={
                                        <>
                                            <AiOutlineMinusCircle className="iconButtonExtrato" />
                                            <p className="textoBotao">Desativar</p>
                                        </>
                                    }
                                />
                            </>
                        )}
                    </div>
                </fieldset>
            </div>
        </>
    );
};
