import React, { useEffect, useState } from "react";

import "../ValidacaoAporte/ValidacaoAporte.css";
import { obterOperacaoFinanceira } from "./functions";

import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";

import { Loading } from "../../../components/Loading";
import { TabelaValidacaoAporte } from "../../../components/Tabelas/ValidacaoAporte";
import { CardValidarAporte } from "../../../components/Cards/CardValidarAporte";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { SelectServicoFinanceiro } from "../../../components/select/SelectServicoFinanceiro";

import { AiOutlineReload, AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Button } from "../../../components/Button";
import { LoadingButton } from "../../../components/loadingButtom";
import { voltarSlide, proximoSlide } from "../../Cliente/Extrato/functions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  swipeToSlide: false,
  slidesToShow: 1,
  draggable: false,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0px',
  respondTo: 'slider',
  rows: 3,
  slidesPerRow: 1,
  nextArrow: <div id="nextArrowSlide" style={{ display: 'none !important' }}></div>,
  prevArrow: <div id="prevArrowSlide" style={{ display: 'none !important' }}></div>
};

export interface OperacoesFinanceiras {
  dataSolicitacao: Date;
  valor: number;
  tipo: "Aporte" | "Saque";
  status: "EmEspera" | "Efetuado" | "Rejeitado";
}

export interface Conta {
  servicoFinanceiroConta: string;
  operacoesFinanceiras: Array<OperacoesFinanceiras>;
}

export interface RespostaOperacaoFinanceira {
  idCredenciais: string;
  nome: string;
  conta: Conta;
}

export const ValidacaoAporte = () => {
  const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
  const [data, setData] = useState<Array<RespostaOperacaoFinanceira>>([]);
  const [IdServicoFinanceiro, setIdServicoFinanceiro] = useState<number>(1);
  const [OperacaoFinanceira] =
    useState<string>("Aporte");
  const [StatusOperacaoFinanceira] =
    useState<string>("EmEspera");

  const [loadingPage, setLoadingPage] = useState<boolean>(false);

  const [erroPage, setErroPage] = useState<boolean>(false);

  const [reloadPage, setReloadPage] = useState<boolean>(false);

  useEffect(() => {
    setLoadingPage(true);

    obterOperacaoFinanceira(IdServicoFinanceiro, OperacaoFinanceira, StatusOperacaoFinanceira, setData, setLoadingPage, setErroPage, setModalDesconectar)
  }, [reloadPage, IdServicoFinanceiro]);

  return (
    <>
      {loadingPage && <Loading />}
      <Navbar />
      <div id="container">
        <Header titulo={"Validação de " + OperacaoFinanceira} >
          <div className="container-select-ValidacaoAporte">
            <SelectServicoFinanceiro setServico={setIdServicoFinanceiro} />
          </div>
        </Header>
        <div id="desktop" className="caixa1">
          <fieldset>
            <div>
              <h5>Aguardando Validação</h5>
            </div>
            <table id="tblTransacoes" className="tabela">
              <thead>
                <tr className="tabelaValidacaoAporte">
                  <th className="table-secondary tableTitulo tableData ">
                    <strong>Ver Comprovante</strong>
                  </th>
                  <th className="table-secondary tableTitulo tableTipo ">
                    <strong>Nome</strong>
                  </th>
                  <th className="table-secondary tableTitulo tableOperacao ">
                    <strong>Valor</strong>
                  </th>
                  <th className="table-secondary tableTitulo tableOperacao ">
                    <strong>Status</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {erroPage ? (
                  <tr>
                    <td colSpan={5} style={{ textAlign: "center" }}>
                      <p>Ocorreu um erro. Por favor tente novamente.</p>
                      <AiOutlineReload
                        onClick={() => setReloadPage(!reloadPage)}
                      />
                    </td>
                  </tr>
                ) : data.length !== 0 ? (
                  data.map((value) => {
                    return value.conta.operacoesFinanceiras.map(
                      (value2) => (
                        <TabelaValidacaoAporte
                          IdServicoFinanceiro={IdServicoFinanceiro}
                          dados={value}
                          key={String(value2.dataSolicitacao)}
                          dadosMap={value2}
                          setModal={setModalDesconectar}
                          setReload={setReloadPage}
                        />
                      )
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={5} style={{ textAlign: "center", }}>
                      <p>Nenhum resultado encontrado no momento.</p>
                    </td>
                  </tr>
                )
                }
              </tbody>
            </table>
          </fieldset>
        </div>
      </div>
      <div id="mobile">
        <div className="caixa1">
          <fieldset>
            <div className="divBotoesExtratoMobile">
              <Button
                className="btn__cinza btnMargin"
                onClick={() => voltarSlide()}
                titleButton={
                  <>
                    <AiOutlineArrowLeft className="iconButtonExtrato" />
                  </>
                }
              />
              <Button
                className="btn__cinza btnMargin"
                onClick={() => proximoSlide()}
                titleButton={
                  <>
                    <AiOutlineArrowRight className="iconButtonExtrato" />
                  </>
                }
              />
            </div>
            <div className="boxSlideExtrato">
              {erroPage ? (
                <div>
                  <span className="formatacaoLoadingExtrato">
                    Ocorreu um erro. Clique no botão para tentar novamente.
                  </span>
                  <Button
                    className="btn__cinza btnMargin"
                    onClick={() => setReloadPage(!reloadPage)}
                    titleButton={
                      <>
                        <AiOutlineReload
                          className="iconButtonExtrato"
                          onClick={() => setReloadPage(!reloadPage)} />
                      </>
                    }
                  />
                </div>
              ) : loadingPage ? (
                <span className="formatacaoLoadingExtrato">
                  <LoadingButton />
                </span>
              ) : (
                <Slider {...settings}>
                  {data.length !== 0 ? (
                    data.map((value) => {
                      return value.conta.operacoesFinanceiras.map((value2) => (
                        <CardValidarAporte
                          IdServicoFinanceiro={IdServicoFinanceiro}
                          dados={value}
                          key={String(value2.dataSolicitacao)}
                          dadosMap={value2}
                          setModal={setModalDesconectar}
                          setReload={setReloadPage}
                        />
                      ));
                    })
                  ) : <span>Nenhum registro encontrado no momento.</span>
                  }
                </Slider>
              )}
            </div>
          </fieldset>
        </div>
      </div>
      {modalDesconectar && <ModalDesconectar modal={modalDesconectar} />}
      <Footer />
    </>
  );
};
