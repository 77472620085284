import React, { useContext, useRef, useState } from "react";
import "./styles.css";
import { enviarSolicitacaoDeAporte, handleDocumentoUpload, handleHide } from "./functions";

import { Dados } from "../../../pages/Cliente/AporteSaque/AporteSaque";
import { Button } from "../../Button";
import { Input } from "../../Input";
import { LoadingButton } from "../../loadingButtom";
import { MensagemDeErro } from "../../RespostasDosFormularios/MensagemDeErro";
import { MensagemDeSucesso } from "../../RespostasDosFormularios/Sucesso";
import { ModalDesconectar } from "../ModalDesconectar";

import Modal from "react-bootstrap/Modal";
import { transformarValorParaBRLEmInput, transformarValorParaUSDEmInput } from "../../../functions/MascarasMonetarias";
import { ServicoFinanceiroClienteContext, usuarioServicoFinanceiroContextType } from "../../../context/servicoFinanceiroCliente";

interface modalProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
  dadosUsuario: Dados;
}

interface ImagensDocumentacao {
  tipoDocumento: string;
  conteudo: string | ArrayBuffer | null;
  nomeArquivo: string;
  tamanho: string;
  dataUpload: string;
}

export const ModalAporte = ({ modal, setModal, dadosUsuario }: modalProps) => {
  const filesElement = useRef(null);

  const [valorAporte, setValorAporte] = useState<number>(0);
  const [imagensDocumentacao, setImagensDocumentacao] = useState<
    string | ArrayBuffer | null
  >();
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [falha, setFalha] = useState<boolean>(false);
  const [mensagem, setMensagem] = useState<string>("");
  const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);

  const [valorAporteMask, setValorAporteMask] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const { usuarioServicoFinanceiro } = useContext<usuarioServicoFinanceiroContextType>(ServicoFinanceiroClienteContext);

  return !modalDesconectar ? (
    <Modal show={modal} onHide={() => handleHide(setModal, setSucesso, setFalha, setValorAporte, setValorAporteMask, setImagensDocumentacao)}>
      <form onSubmit={(e) => enviarSolicitacaoDeAporte(e, dadosUsuario, setLoading, valorAporte, imagensDocumentacao, setMensagem, setSucesso, setFalha, setValorAporte, setModalDesconectar, usuarioServicoFinanceiro)}>
        <Modal.Header closeButton>
          <Modal.Title>Aporte</Modal.Title>
        </Modal.Header>
        {falha && <MensagemDeErro mensagem={mensagem} />}
        {sucesso ? (
          <MensagemDeSucesso mensagem={mensagem} />
        ) : (
          <>
            <Modal.Body>
              <div className="modal-body">
                <Input
                  label="Valor do aporte:"
                  maxLength={13}
                  autoComplete="off"
                  placeholder=" "
                  value={usuarioServicoFinanceiro.idServicoFinanceiro === 3 ? "$ " + valorAporteMask : "R$ " + valorAporteMask}
                  onChange={(e) => {
                    usuarioServicoFinanceiro.idServicoFinanceiro === 3 ? setValorAporteMask(transformarValorParaBRLEmInput(e.target.value, setValorAporteMask)) : setValorAporteMask(transformarValorParaUSDEmInput(e.target.value, setValorAporteMask))
                    setValorAporte(Number(transformarValorParaBRLEmInput(e.target.value, setValorAporteMask).replace(/\./g, '').replace(/,/g, '.')));
                  }}
                />
                <br />
                <h6 style={{ fontFamily: "Poppins", color: "#808080" }}>
                  {" "}
                  Dados bancários:
                </h6>
                <br />
                <a>Favorecido: Omega Consultoria e Negócios Ltda</a>
                <br />
                <a>Banco: 208 – BTG Pactual S.A.</a>
                <br />
                <a>Agência: 0050</a>
                <br />
                <a>Conta Corrente: 371212-4</a>
                <br />
                <a>CNPJ: 34.365.211/0001-40</a>
                <br />
                <a>Chave PIX: 34.365.211/0001-40</a>
                <br />
                <br />
                <h6 style={{ fontFamily: "Poppins", color: "#808080" }}>
                  Comprovante de depósito:
                </h6>
                <div className="Area_DocumentosAnexos">
                  <Input
                    type="file"
                    multiple
                    ref={filesElement}
                    className="DocumentosAnexos_Botao"
                    accept="image/*,.pdf"
                    onChange={(e) => {
                      let tipoDocumento: string = "Comprovante ";
                      handleDocumentoUpload(e, setImagensDocumentacao);
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  className="btnAlterar"
                  onClick={(e) => enviarSolicitacaoDeAporte(e, dadosUsuario, setLoading, valorAporte, imagensDocumentacao, setMensagem, setSucesso, setFalha, setValorAporte, setModalDesconectar, usuarioServicoFinanceiro)}
                  titleButton="Confirmar"
                  disabled={sucesso ? true : false}
                  type="submit"
                />
              )}
              <Button
                className="btnCancelar"
                onClick={() => handleHide(setModal, setSucesso, setFalha, setValorAporte, setValorAporteMask, setImagensDocumentacao)}
                titleButton="Cancelar"
              />
            </Modal.Footer>
          </>
        )}
      </form>
    </Modal>
  ) : (
    <ModalDesconectar modal={modalDesconectar} />
  );
};
