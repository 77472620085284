import React, { useState } from "react";
import { handleStatus, handleStatusAprovarAportePrivate } from "./functions";
import "./style.css";

import { AiOutlineFileSearch, AiOutlineFileExcel, AiOutlineMinusCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { Button } from "../../Button";
import { LoadingButton } from "../../loadingButtom";
import { ModalAprovar } from "../../modais/ModalAprovar";
import { ModalComprovante } from "../../modais/ModalComprovante";
import { mascaraBRL, mascaraUSD } from "../../../functions/MascarasMonetarias";

interface TabelaValidacaoAporteProps {
    dados: RespostaOperacaoFinanceira;
    dadosMap: OperacoesFinanceiras;
    IdServicoFinanceiro: number;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OperacoesFinanceiras {
    dataSolicitacao: Date;
    valor: number;
    tipo: "Aporte" | "Saque";
    status: "EmEspera" | "Efetuado" | "Rejeitado";
    urlImagemComprovante?: any;
}

interface Conta {
    servicoFinanceiroConta: string;
    operacoesFinanceiras: Array<OperacoesFinanceiras>;
}

interface RespostaOperacaoFinanceira {
    idCredenciais: string;
    nome: string;
    conta: Conta;
}

export const TabelaValidacaoAporte = ({
    dados,
    dadosMap,
    IdServicoFinanceiro,
    setModal,
    setReload,
}: TabelaValidacaoAporteProps) => {

    const [loadingAprovar, setLoadingAprovar] = useState<boolean>(false);
    const [modalAprovar, setModalAprovar] = useState<boolean>(false);
    const [erroAprovar, setErroAprovar] = useState<boolean>(false);
    const [modalRejeitar, setModalRejeitar] = useState<boolean>(false);
    const [shakeButton, setShakeButton] = useState<boolean>(false);
    const [comprovanteModal, setComprovanteModal] = useState<boolean>(false);

    const exibirPDF = () => {
        setComprovanteModal(true);
    };

    return (
        <>
            <tr id="trAvisoSemRegistros" className="hoverTabelaValidacaoAporte">
                <th className="table-secondary tableConteudo tableData ">
                    {dadosMap.urlImagemComprovante ? (
                        <Button
                            className="btn__cinza buttonComprovante bgTransparente"
                            onClick={exibirPDF}
                            titleButton={
                                <>
                                    <AiOutlineFileSearch className="comprovanteExistente" />
                                </>
                            }
                        />
                    ) : <Button
                            className={shakeButton ? 'btn__cinza buttonComprovante bgTransparente shake' : 'btn__cinza buttonComprovante bgTransparente'}
                        onClick={() => setShakeButton(!shakeButton)}
                        onMouseLeave={() => setShakeButton(false)}
                        titleButton={
                            <>
                                <AiOutlineFileExcel className="comprovanteVazio" />
                            </>
                        }
                    />
                    }
                </th>
                <th className="table-secondary tableConteudo tableTipo ">
                    <strong>{dados.nome}</strong>
                </th>
                <th className="table-secondary tableConteudo tableOperacao ">
                    <strong>
                        {IdServicoFinanceiro != 3 ?
                        "R$" + mascaraBRL(dadosMap.valor.toFixed(2))
                        :
                        "$" + mascaraUSD(dadosMap.valor.toFixed(2))}
                    </strong>
                </th>

                <th className="colunaBotoes colunaCadastro">
                    {loadingAprovar ? (
                        <LoadingButton />
                    ) : (<>
                        <Button
                                onClick={() =>
                                    IdServicoFinanceiro != 3 ? setModalAprovar(true)
                                        :
                                        handleStatusAprovarAportePrivate(
                                            setLoadingAprovar,
                                            dados.idCredenciais,
                                            IdServicoFinanceiro,
                                            dadosMap.dataSolicitacao,
                                            "Efetuado",
                                            dadosMap.valor,
                                            dadosMap.tipo,
                                            0,
                                            "",
                                            setReload,
                                            setErroAprovar)}
                            className="btn__verde botaoCircular botaoEsq"
                            titleButton={
                                <>
                                    <AiOutlineCheckCircle className="iconButtonExtrato" />
                                    <p className="textoBotao">Aprovar</p>
                                </>
                            }
                        />
                        <Button
                            onClick={() => handleStatus(
                                dados.idCredenciais,
                                dadosMap.dataSolicitacao,
                                "Rejeitado",
                                dadosMap.valor,
                                dadosMap.tipo,
                                IdServicoFinanceiro,
                                setLoadingAprovar,
                                setErroAprovar,
                                setReload,
                                setModal
                            )}
                            className="btn__vermelho botaoCircular botaoDir"
                            titleButton={
                                <>
                                    <p className="textoBotao">Reprovar</p>
                                    <AiOutlineMinusCircle className="iconButtonExtrato" />
                                </>
                            }
                        />
                    </>)}
                    {erroAprovar && (
                        <strong style={{ fontSize: 10, color: "red" }}>
                            Ocorreu um erro não previsto, por favor tente novamente.
                        </strong>
                    )}
                </th>
            </tr>
            {modalAprovar && (
                <ModalAprovar
                    modal={modalAprovar}
                    setModal={setModalAprovar}
                    setModalDesconectar={setModal}
                    IdServicoFinanceiro={IdServicoFinanceiro}
                    setReload={setReload}
                    setErroAprovar={setErroAprovar}
                    dataSolicitacao={dadosMap.dataSolicitacao}
                    idCredenciaisCliente={dados.idCredenciais}
                    status={"Efetuado"}
                    tipo={dadosMap.tipo}
                    valor={dadosMap.valor}
                />
            )}
            {comprovanteModal && (
                <ModalComprovante
                    modal={comprovanteModal}
                    setModal={setComprovanteModal}
                    comprovanteBase64={dadosMap.urlImagemComprovante}
                />
            )}
            {/* {modalRejeitar &&
                <ModalRejeitar
                    modal={modalRejeitar}
                    IdServicoFinanceiro={IdServicoFinanceiro}
                    setModal={setModalRejeitar}
                    setReload={setReload}
                    setErroAprovar={setErroAprovar}
                    dataSolicitacao={dadosMap.DataSolicitacao}
                    idCredenciaisCliente={dados.IdCredenciais}
                    status={"Rejeitado"}
                    tipo={dadosMap.Tipo}
                    valor={dadosMap.Valor}
                />} */}
        </>
    );
};
