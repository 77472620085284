import React, { useEffect, useState } from "react";
import "./TabelaFechamento.css";
import { adicionarPorcentagemDoAtivoNaCarteiraNoAtivo } from "./functions";

import { Cotacoes } from "../../../pages/Gestor/Fechamento/Fechamento";
import { mascaraUSD } from "../../../functions/MascarasMonetarias";

interface TabelaFechamentoProps {
  sigla: string;
  ativo: string;
  quantidade: number;
  valorUnitario: number;
  valorTotal: number;
  total: number;
  manual?: boolean;
  removerNovoAtivoOffline: (index: number) => void;
  index: number;
  listaCotacoes?: Cotacoes[];
  setListaCotacoes?: (cotacao: Cotacoes[]) => void;
}

export const TabelaFechamento = ({
  ativo,
  quantidade,
  valorUnitario,
  valorTotal,
  total,
  manual,
  removerNovoAtivoOffline,
  index,
  sigla,
  listaCotacoes,
  setListaCotacoes,
}: TabelaFechamentoProps) => {


  useEffect(() => {
    adicionarPorcentagemDoAtivoNaCarteiraNoAtivo(listaCotacoes, index, ativo, ((100 * total) / valorTotal), quantidade, sigla, valorUnitario, manual, setListaCotacoes)
  }, [valorTotal]);

  const handleDelete = () => {
    removerNovoAtivoOffline(index);
  };

  return (
    <tr id="trTabelaComDados" className="tabelaLinha">
      <th className="table-secondary tableConteudo">
        <strong>{ativo}</strong>
      </th>
      <th className="table-secondary tableConteudo">
        <strong>{quantidade.toFixed(6)}</strong>
      </th>
      <th className="table-secondary tableConteudo">
        <strong> US$ {mascaraUSD(valorUnitario.toFixed(2))}</strong>
      </th>
      <th className="table-secondary tableConteudo">
        <strong>
          {((100 * total) / valorTotal) < 0.000999
            ? ((100 * total) / valorTotal).toFixed(3)
            : ((100 * total) / valorTotal).toString().match(/^-?\d+(?:\.\d{0,3})?/)[0]}
          %
        </strong>
      </th>
      <th className="table-secondary tableConteudo">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: 10,
          }}
        >
          <strong> US$ {mascaraUSD(total.toFixed(2))}</strong>
          {manual && (
            <span className="delete-button" onClick={handleDelete}>
              X
            </span>
          )}
        </div>
      </th>
    </tr>
  );
};
