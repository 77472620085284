import { Erro } from "../../pages/Cliente/AlteracaoSenha/AlteracaoSenha";

import imageCompression from 'browser-image-compression';

export const handleFotoPerfilUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: React.Dispatch<React.SetStateAction<Erro>>,
    setImagePerfil: (value: React.SetStateAction<string | ArrayBuffer>) => void
) => {
    let imagemOriginal = e.target.files[0];

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1300,
        useWebWorker: true
    }
    try {
        const imagemComprimida = await imageCompression(imagemOriginal, options);

        var reader = new FileReader();
        reader.readAsDataURL(imagemComprimida);
        reader.onload = function () {
            setImagePerfil(reader.result);
        };
    } catch (error) {
        setError({
            erro: true,
            mensagem: 'Ocorreu um erro na sua solicitação. Por favor tente novamente.'
        })
    }
}

export function handleUserName(
    value: string,
    setError: React.Dispatch<React.SetStateAction<Erro>>,
    setUserName: React.Dispatch<React.SetStateAction<string>>,
) {
    let valueAntigo = value

    value = value.replace(/[^-.0-9a-zA-Z]/g, "")
    if (valueAntigo != value) {
        setError({
            erro: true,
            mensagem: 'Username só pode ter números, letras, "." e "-".'
        })
    }
    setUserName(value)
}