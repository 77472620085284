import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { guardarDadosUsuario, guardarDadosUsuario2 } from "../../../services/cookies";
import { operacaoFinanceiraAporteSaque } from "../../../services/operacaoFinanceira";
import { renovacaoToken } from "../../../services/usuario";
import { DadosUsuario } from "../ModalNovoServicoFinanceiro";

export const enviarsolicitacaoSaque = async (
    e: React.FormEvent,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    dadosUsuario: DadosUsuario,
    valorSaque: number,
    setMensagem: (value: React.SetStateAction<string>) => void,
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setFalha: (value: React.SetStateAction<boolean>) => void,
    setValorSaque: (value: React.SetStateAction<number>) => void,
    setModalDesconectar: (value: React.SetStateAction<boolean>) => void,
    setValorSaqueMask: (value: React.SetStateAction<string>) => void,
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo
) => {
    e.preventDefault();

    setLoading(true);

    await operacaoFinanceiraAporteSaque(
        dadosUsuario.id,
        usuarioServicoFinanceiro.idServicoFinanceiro,
        valorSaque,
        "SAQUE",
        dadosUsuario.token
    )
        .then((res) => {
            setMensagem(
                "Seu saque foi solicitado com sucesso. Aguarde a análise de nossa equipe."
            );
            setLoading(false);
            setSucesso(true);
            setFalha(false);
            setValorSaque(0);
        })
        .catch(async (error) => {
            switch (error.response.status) {
                case 400:
                    if (error.response.data.message === "O valor é obrigatório.") {
                        setMensagem(
                            "Verifique o preenchimento do campo de saque e tente novamente."
                        );
                    }
                    if (error.response.data.message === "O valor do saque excede o saldo em conta.") {
                        setMensagem(
                            "O valor do saque excede o saldo em conta."
                        );
                    }
                    setFalha(true);
                    break;
                case (error.response.status === 401):
                    await renovacaoToken(
                        dadosUsuario.token,
                        dadosUsuario.tokenRenovacao
                    )
                        .then(async (res2) => {
                            guardarDadosUsuario(
                                res2.data.camposAdicionais.tokenAcesso,
                                res2.data.camposAdicionais.tokenRenovacao,
                                dadosUsuario.email.toString(),
                                dadosUsuario.id.toString()
                            );
                            guardarDadosUsuario2(
                                res2.data.camposAdicionais.tokenAcesso,
                                res2.data.camposAdicionais.tokenRenovacao,
                                dadosUsuario.email.toString(),
                                dadosUsuario.id.toString()
                            );
                            await operacaoFinanceiraAporteSaque(
                                dadosUsuario.id,
                                usuarioServicoFinanceiro.idServicoFinanceiro,
                                valorSaque,
                                "SAQUE",
                                res2.data.camposAdicionais.tokenAcesso
                            )
                                .then((res2) => {
                                    setMensagem(
                                        "Seu saque foi solicitado com sucesso. Aguarde a análise de nossa equipe."
                                    );
                                    setLoading(false);
                                    setSucesso(true);
                                    setFalha(false);
                                })
                                .catch((e2) => {
                                    setLoading(false);
                                    setSucesso(false);
                                    setFalha(true);
                                    switch (e2.response.status) {
                                        case 400:
                                            if (
                                                valorSaque === 0 ||
                                                valorSaque === null ||
                                                valorSaque === undefined
                                            )
                                                setMensagem(
                                                    "Verifique o preenchimento do campo de saque e tente novamente."
                                                );
                                            break;
                                        case 401:
                                            setModalDesconectar(true);
                                            setMensagem(
                                                "Você foi desconectado. Faça o login e tente novamente!"
                                            );
                                            break;
                                        case 404:
                                            setMensagem(
                                                "Usuário não credenciado à operação solicitada."
                                            );
                                            break;
                                        case 500:
                                            setMensagem(
                                                "Algo deu errado, tente novamente mais tarde."
                                            );
                                            break;
                                    }
                                });
                        })
                        .catch((err3) => {
                            setModalDesconectar(true);
                        });
                    // setMensagem('Você foi desconectado. Faça o login e tente novamente!')
                    break;
                case 404:
                    setMensagem("Usuário não credenciado à operação solicitada.");
                    setFalha(true);
                    break;
                case 500:
                    setMensagem("Algo deu errado, tente novamente mais tarde.");
                    setFalha(true);
                    break;
            }
            setLoading(false);
            setValorSaque(0);
            setValorSaqueMask("");
        }).finally(() => setLoading(false));
};

export const handleHide = (
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setFalha: (value: React.SetStateAction<boolean>) => void,
    setValorSaque: (value: React.SetStateAction<number>) => void,
    setModal: (modal: boolean) => void,
    setValorSaqueMask: (value: React.SetStateAction<string>) => void,
) => {
    setModal(false);
    setSucesso(false);
    setFalha(false);
    setValorSaque(0);
    setValorSaqueMask("");
};