import React, { useState } from "react";
import { handleStatus } from "./functions";

import { AiFillEye, AiFillEyeInvisible, AiOutlineMinusCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { Button } from "../../Button";
import { LoadingButton } from "../../loadingButtom";
import { ModalAprovar } from "../../modais/ModalAprovar";
import { ModalComprovante } from "../../modais/ModalComprovante";
import { mascaraBRL, mascaraUSD } from "../../../functions/MascarasMonetarias";

interface TabelaSolicitacaoSaqueProps {
    dados: RespostaOperacaoFinanceira;
    dadosMap: OperacoesFinanceiras;
    IdServicoFinanceiro: number;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

interface OperacoesFinanceiras {
    dataSolicitacao: Date;
    valor: number;
    tipo: "Aporte" | "Saque";
    status: "EmEspera" | "Efetuado" | "Rejeitado";
    urlImagemComprovante: string;
}

interface Conta {
    servicoFinanceiroConta: string;
    operacoesFinanceiras: Array<OperacoesFinanceiras>;
}

interface RespostaOperacaoFinanceira {
    idCredenciais: string;
    nome: string;
    conta: Conta;
}

export const TabelaSolicitacaoSaque = ({
    dados,
    dadosMap,
    IdServicoFinanceiro,
    setModal,
    setReload,
}: TabelaSolicitacaoSaqueProps) => {

    const [loadingAprovar, setLoadingAprovar] = useState<boolean>(false);
    const [modalAprovar, setModalAprovar] = useState<boolean>(false);
    const [erroAprovar, setErroAprovar] = useState<boolean>(false);
    const [modalRejeitar, setModalRejeitar] = useState<boolean>(false);
    const [shakeButton, setShakeButton] = useState<boolean>(false);
    const [comprovanteModal, setComprovanteModal] = useState<boolean>(false);

    const exibirPDF = () => {
        setComprovanteModal(true);
    };

    return (
        <>
            <tr id="trAvisoSemRegistros" className="hoverTabelaValidacaoAporte">
                <th className="table-secondary tableConteudo tableTipo ">
                    <strong>{dados.nome}</strong>
                </th>
                <th className="table-secondary tableConteudo tableOperacao ">
                    <strong>
                        {IdServicoFinanceiro != 3 ?
                            "R$" + mascaraBRL(dadosMap.valor.toFixed(2))
                            :
                            "$" + mascaraUSD(dadosMap.valor.toFixed(2))}
                    </strong>
                </th>

                <th className="colunaBotoes colunaCadastro">
                    {loadingAprovar ? (
                        <LoadingButton />
                    ) : (<>
                        <Button
                            onClick={() => setModalAprovar(true)}
                            className="btn__verde botaoCircular botaoEsq"
                            titleButton={
                                <>
                                    <AiOutlineCheckCircle className="iconButtonExtrato" />
                                    <p className="textoBotao">Aprovar</p>
                                </>
                            }
                        />
                        <Button
                            onClick={() => handleStatus(
                                dados.idCredenciais,
                                dadosMap.dataSolicitacao,
                                "Rejeitado",
                                dadosMap.valor,
                                dadosMap.tipo,
                                IdServicoFinanceiro,
                                setLoadingAprovar,
                                setErroAprovar,
                                setReload,
                                setModal
                            )}
                            className="btn__vermelho botaoCircular botaoDir"
                            titleButton={
                                <>
                                    <p className="textoBotao">Reprovar</p>
                                    <AiOutlineMinusCircle className="iconButtonExtrato" />
                                </>
                            }
                        />
                    </>)}
                    {erroAprovar && (
                        <strong style={{ fontSize: 10, color: "red" }}>
                            Ocorreu um erro não previsto, por favor tente novamente.
                        </strong>
                    )}
                </th>
            </tr>
            {modalAprovar && (
                <ModalAprovar
                    modal={modalAprovar}
                    IdServicoFinanceiro={IdServicoFinanceiro}
                    setModal={setModalAprovar}
                    setReload={setReload}
                    setErroAprovar={setErroAprovar}
                    dataSolicitacao={dadosMap.dataSolicitacao}
                    idCredenciaisCliente={dados.idCredenciais}
                    status={"Efetuado"}
                    tipo={dadosMap.tipo}
                    valor={dadosMap.valor}
                    setModalDesconectar={setModal}
                />
            )}
            {comprovanteModal && (
                <ModalComprovante
                    modal={comprovanteModal}
                    setModal={setComprovanteModal}
                    comprovanteBase64={dadosMap.urlImagemComprovante}
                />
            )}
            {/* {modalRejeitar &&
                <ModalRejeitar
                    modal={modalRejeitar}
                    IdServicoFinanceiro={IdServicoFinanceiro}
                    setModal={setModalRejeitar}
                    setReload={setReload}
                    setErroAprovar={setErroAprovar}
                    dataSolicitacao={dadosMap.DataSolicitacao}
                    idCredenciaisCliente={dados.IdCredenciais}
                    status={"Rejeitado"}
                    tipo={dadosMap.Tipo}
                    valor={dadosMap.Valor}
                />} */}
        </>
    );
};
