import React from 'react'

interface SelectMotivoContatoProps {
    setMotivo: React.Dispatch<React.SetStateAction<string>>
}

export const SelectMotivoContato = ({ setMotivo }: SelectMotivoContatoProps) => {
    return (
        <select onChange={(e) => setMotivo(e.target.value)} id="selectMotivo">
            <option value="Duvida">Dúvida</option>
            <option value="Sugestao">Sugestão</option>
            <option value="Reclamacao">Reclamação</option>
        </select>
    )
}