import React, { useEffect, useState } from 'react'
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";

import "./DashboardsCliente.css";
import { GraficoLinha } from '../../../components/Graficos/GraficoLinha';
import { gerarNumerosAleatorios } from '../../Gestor/DashboardsGestor/functions';

const DashboardsCliente = () => {
    const [labelsMeses, setLabelsMeses] = useState<Array<string>>(['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']);

    return (
        <>
            <Navbar />
            <Header titulo='Dashboards' />
            
            <div className="caixa1">
                <fieldset>
                    <GraficoLinha dadosGrafico={gerarNumerosAleatorios(1, 4500, labelsMeses.length)} labels={labelsMeses} nomeDado={"Cotas"} tituloGrafico={"Posição em cotas"} />
                    <GraficoLinha dadosGrafico={gerarNumerosAleatorios(1, 4500, labelsMeses.length)} labels={labelsMeses} nomeDado={"Variação em Real"} tituloGrafico={"Posição em R$"} />
                    <GraficoLinha dadosGrafico={gerarNumerosAleatorios(1, 4500, labelsMeses.length)} labels={labelsMeses} nomeDado={"Periodo"} tituloGrafico={"Rentabilidade por período"} />
                </fieldset>
            </div>
        </>
    )
}

export default DashboardsCliente;