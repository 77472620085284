import { obterServicoFinanceiroPorStatus } from "../../services/usuario";

export const returnServicosFinanceiros = async (
    setLoadingServicosFinanceiros: (value: React.SetStateAction<boolean>) => void,
    setServicos: (value: React.SetStateAction<any[]>) => void,
    setErroServicos: (value: React.SetStateAction<boolean>) => void,

) => {
    setLoadingServicosFinanceiros(true);
    obterServicoFinanceiroPorStatus()
        .then((res: any) => {
            setServicos(res.data.resultado.usuario);
            setLoadingServicosFinanceiros(false);
            setErroServicos(false)
        })
        .catch((error: any) => {
            setLoadingServicosFinanceiros(false);
            setErroServicos(true)
        });
};