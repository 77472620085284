import React from "react";

import './styles.css'

import { BsFillPatchExclamationFill } from "react-icons/bs";
import { Button } from "../../Button";

interface ErroProps {
    handleTentarNovamente: () => void
}

export const Erro = ({ handleTentarNovamente }: ErroProps) => {
    return <>
        <div className="container-sucesso-ModalNovaFormaDePagamento">
            <BsFillPatchExclamationFill className="icon-ModalNovaFormaDePagamento" />
            <p className="title-ModalNovaFormaDePagamento" >Algo deu errado, aguarde alguns instantes e tente novamente mais tarde.</p>
        </div>
        <div>
            <Button titleButton={"Tentar novamente"} onClick={handleTentarNovamente} />
        </div>
    </>
}