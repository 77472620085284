import React from "react";
import LegalNavbar from "../../../components/LegalNavbar/LegalNavbar";
import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import "./TermosDeUsoSistema.css";
import { Button } from "../../../components/Button";
import { AiOutlineCloseCircle } from "react-icons/ai";

const TermosDeUsoSistema = () => {
    return (
        <>
            <div className="container">
                <Header titulo="Termos de Uso – Omega Digital Assets" />
                <div className="caixa1">
                    <fieldset>
                        <h4 className="tituloSecao">Definições</h4>
                        <p>
                            <b>Agente Público.</b> Qualquer pessoa física, servidor ou não, ainda
                            que transitoriamente ou sem remuneração, exercendo cargo, emprego ou
                            função pública em ou para Autoridade Governamental, incluindo Agente
                            Público Relevante; qualquer pessoa física que trabalhe para empresa
                            prestadora de serviço contratada ou conveniada para a execução de atividade
                            típica da Administração Pública; ou qualquer dirigente de partido político,
                            seus empregados ou outras pessoas que atuem para ou em nome de um partido
                            político ou candidato a cargo político; ou agente de organizações públicas
                            internacionais.
                            </p>
                        <p>
                            <b>Autoridade Governamental.</b> Todo órgão, departamento ou entidade da administração
                            direta, indireta ou fundacional de qualquer dos Poderes da União, dos Estados,
                            do Distrito Federal, dos Municípios, de Território, pessoa jurídica incorporada
                            ao patrimônio público ou entidade para cuja criação ou custeio ao erário haja
                            concorrido ou concorra com mais de cinquenta por cento do patrimônio ou da
                            receita anual; Partidos políticos; Órgãos, entidades estatais ou representações
                            diplomáticas de país estrangeiro, assim como pessoas jurídicas controladas,
                            direta ou indiretamente, pelo poder público de país estrangeiro ou organizações
                            públicas internacionais, inclusive fundos soberanos ou uma entidade cuja propriedade
                            é um fundo soberano.
                            </p>
                        <p>
                            <b>Criptomoeda </b>(s), <b>Tokens, e Criptoativos.</b> Para a finalidade da lei e daquelas por
                            ela modificadas, entende-se por criptoativos: I – Unidades de valor criptografadas
                            mediante a combinação de chaves públicas e privadas de assinatura por meio digital,
                            geradas por um sistema público ou privado e descentralizado de registro, digitalmente
                            transferíveis e que não sejam ou representem moeda de curso legal no Brasil ou em
                            qualquer outro país; II – Unidades virtuais representativas de bens, serviços ou direitos,
                            criptografados mediante a combinação de chaves públicas e privadas de assinatura por meio
                            digital, registrados em sistema público ou privado e descentralizado de registro, digitalmente
                            transferíveis, que não seja ou representem moeda de curso legal no Brasil ou em qualquer
                            outro país; III – Tokens Virtuais que conferem ao seu titular acesso ao sistema de registro
                            que originou o respectivo token de utilidade no âmbito de uma determinada plataforma, projeto
                            ou serviço para a criação de novos registros em referido sistema e que não se enquadram no
                            conceito de valor mobiliário disposto no art. 2° da Lei nº 6.385, de 7 de dezembro de 1976.
                            </p>
                        <p>
                            <b>Comissão de Valores Mobiliários</b> (CVM). A Comissão de Valores Mobiliários (CVM) foi criada em
                            07/12/1976 pela Lei 6.385/76, com o objetivo de fiscalizar, normatizar, disciplinar e desenvolver
                            o mercado de valores mobiliários no Brasil. A CVM é uma entidade autárquica em regime especial,
                            vinculada ao Ministério da Economia, com personalidade jurídica e patrimônio próprios, dotada de
                            autoridade administrativa independente, ausência de subordinação hierárquica, mandato fixo e
                            estabilidade de seus dirigentes, e autonomia financeira e orçamentária. Obtenha mais detalhes
                            sobre o Regimento Interno da CVM, acesse a Resolução CVM 24.
                            </p>
                        <p>
                            <b>OMEGA.</b> OMEGA CONSULTORIA E NEGOCIOS LTDA., pessoa jurídica de direito privado,
                            regularmente inscrita no CNPJ sob o nº 34.365.211/0001-40, com sede na Rua Gildásio
                            Amado, nº 55, Sala 506, CEP: 22.631-020.
                            </p>

                        <h4 className="tituloSecao">Cláusula Primeira - Aceitação dos termos e condições de uso.</h4>
                        <ul>
                            <li>
                                <b>a.</b> Estes Termos e Condições de Uso ("Termos") da OMEGA regulamentam
                                a utilização de quaisquer dos nossos serviços, gratuitos e pagos, na
                                internet ("Serviços"), seja no website ("Site") ou no aplicativo para celular
                                ("App").
                                </li>
                            <li>
                                <b>b.</b> O uso do Site e/ou App está condicionado à aceitação e ao cumprimento
                                dos Termos. Para fazer uso dos Serviços é necessário: (I) ler atentamente os termos,
                                regras e condições descritos abaixo; (II) concordar integralmente com eles; e (III)
                                cadastrar-se fornecendo um endereço de e-mail válido para ter acesso aos Serviços.
                                Ao fazer uso dos Serviços, você ("Usuário") concorda que leu, entendeu e aceitou os
                                Termos, regras e condições aqui dispostos.
                                </li>
                            <li>
                                <b>c.</b> A OMEGA se reserva o direito de modificar a qualquer momento a apresentação,
                                configuração e disponibilização do Site e App. O mesmo se aplica a estes Termos,
                                estabelecidos como condição fundamental para a utilização dos Serviços.
                                </li>
                            <li>
                                <b>d.</b> O Usuário reconhece que estes Termos devem ser observados e fielmente cumpridos,
                                sob pena de cancelamento dos Serviços e bloqueio de utilização do Site e App, bem como com
                                a adoção das demais medidas cabíveis.
                                </li>
                        </ul>

                        <h4 className="tituloSecao">Cláusula Segunda - Cadastro</h4>
                        <ul>
                            <li>
                                <b>a.</b> Podem se cadastrar como Usuários as pessoas que tenham capacidade civil para
                                contratar os Serviços. No caso de pessoas jurídicas, o cadastro como Usuário poderá ser
                                realizado por qualquer representante legal. Ficam impedidas de se cadastrar as pessoas
                                que não gozem de capacidade civil plena, inclusive menores de idade.
                                </li>
                            <li>
                                <b>b.</b> As informações fornecidas no momento do cadastro e em qualquer atualização
                                posterior devem ser verdadeiras e os Usuários são exclusivamente responsáveis pela
                                veracidade de tais informações. Havendo modificações supervenientes, os Usuários deverão
                                atualizar o seu cadastro.
                                </li>
                            <li>
                                <b>c.</b> Cada pessoa poderá fazer apenas um cadastro de Usuário. O cadastro de Usuário é
                                único, pessoal e intransferível. A existência de dados pessoais inverídicos ou desatualizados
                                e/ou a duplicidade de cadastros de um mesmo Usuário poderão acarretar a exclusão do(s) cadastro(s)
                                e a impossibilidade de o indivíduo voltar a se cadastrar como Usuário. A OMEGA poderá recusar ou
                                cancelar qualquer cadastro que tenha sido realizado em desacordo com estes Termos.
                                </li>
                            <li>
                                <b>d.</b> O acesso à conta de Usuário só poderá ser feito por meio do nome de usuário e da senha
                                pessoais e intransferíveis criados pelos Usuários no momento do cadastro. A responsabilidade pela
                                guarda e proteção da senha de acesso é exclusiva dos Usuários, que serão responsabilizados por qualquer
                                uso que terceiros venham a fazer. Havendo uso não autorizado de seu cadastro, os Usuários deverão
                                comunicar imediatamente à OMEGA. É recomendável ao Usuário a escolha de uma senha forte, e eventualmente
                                a sua modificação, o que pode ser feito acessando-se a página correspondente no Site.
                                </li>
                            <li>
                                <b>e.</b> Ao concluir o cadastro, o Usuário receberá uma mensagem da OMEGA em seu e-mail cadastrado,
                                visando à confirmação do respectivo cadastro.
                                </li>
                            <li>
                                <b>f.</b> A OMEGA se reserva o direito de, a qualquer tempo, ao seu livre e exclusivo critério e sem aviso prévio:
                                <ul>
                                    <li>
                                        1. conferir os dados informados pelo Usuário no cadastro do Site e App, podendo solicitar dados e/ou
                                        documentos adicionais; e
                                    </li>
                                    <li>
                                        2. cancelar ou excluir os cadastros dos Usuários que estejam inadimplentes em relação a qualquer
                                        parcela do pagamento de assinatura de qualquer Serviço. Para tanto, a OMEGA encaminhará, assim
                                        que constatada a inadimplência, um e-mail ao Usuário no endereço que este informou em seu cadastro e,
                                        caso não haja nenhuma resposta ou efetivo pagamento em até 10 (dez) dias contados do envio de tal e-mail,
                                        a OMEGA procederá ao cancelamento do cadastro e exclusão do Usuário do seu banco de dados.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <b>g.</b> A OMEGA se compromete a não compartilhar, vender ou de qualquer outra forma transmitir os dados pessoais
                                dos Usuários a terceiros, exceto se as informações forem exigidas judicialmente ou se forem necessárias ao
                                funcionamento do Site ou App ou dos Serviços incluídos na respectiva assinatura.
                                </li>
                        </ul>

                        <h4 className="tituloSecao">Cláusula Terceira - Site e APP - Acessos gratuitos e pagos</h4>
                        <ul>
                            <li>
                                <b>a.</b> A OMEGA cobrará pelo acesso superior a 10 (dez) conteúdos mensais, bem como pelo uso do App.
                                Assinantes pagantes do serviço "Omega PRO” têm acesso a diferentes conteúdos restritos deste serviço,
                                de acordo com o plano ou pacote contratado. Caso não seja assinante, basta clicar em "Assine" <a className="textoMarcado">no alto
                                da capa do site e realizar o cadastro e contratação.</a>
                            </li>
                            <li>
                                <b>b.</b> Visitantes do Site podem acessar e ler gratuitamente até 10 (dez) textos não restritos a cada
                                período de 30 dias consecutivos, desde que realizem o cadastro digital gratuito, bastando, para isso,
                                clicar em "Cadastre-se" no alto da capa do site ou no App.
                            </li>
                            <li>
                                <b>c.</b> A disponibilização de qualquer Serviço gratuito é prerrogativa exclusiva da OMEGA, a qual pode,
                                a qualquer tempo, excluir, incluir, alterar, atualizar e/ou substituir qualquer conteúdo ou funcionalidade
                                desse Serviço, ou mesmo desativá-lo, sem que isso caracterize violação a estes Termos e sem que seja
                                necessária qualquer notificação nesse sentido.
                            </li>
                            <li>
                                <b>d.</b> A Assinatura para acesso a conteúdo restrito do App ("Assinatura Omega PRO") será regulada de
                                acordo com as seguintes condições:
                            </li>
                            <li>
                                <b>e.</b> A contratação <a className="textoMarcado">será feita pelo Site ou App e os valores e a descrição detalhada dos Serviços
                                poderão ser visualizados em</a> https://www e em páginas adicionais durante a contratação;
                            </li>
                            <li>
                                <b>f.</b> <a className="textoMarcado">A renovação da Assinatura será sempre automática, no valor vigente da assinatura, detalhado
                                no endereço https://www.</a> ;
                            </li>
                            <li>
                                <b>g.</b> O Usuário poderá pedir o cancelamento da Assinatura a qualquer momento, porém a sua vigência
                                perdurará até o término do prazo originalmente contratado (mensal ou anual), ficando o Usuário ciente
                                de que não terá direito ao reembolso de quaisquer valores pagos. No caso da renovação anual automática,
                                o Usuário poderá pedir o cancelamento da Assinatura dentro de 7 (sete) dias após a renovação, com reembolso
                                integral do valor debitado; e
                            </li>
                            <li>
                                <b>h.</b> Os valores cobrados pela Assinatura poderão ser alterados pela OMEGA, desde que o Usuário seja previamente informado.
                            </li>
                            <li>
                                <b>i.</b> A OMEGA poderá, a seu exclusivo critério, realizar promoções de Serviços, oferecendo descontos ou outros benefícios.
                                Tais promoções serão regidas por regulamento próprio.
                            </li>
                        </ul>

                        <h4 className="tituloSecao">Cláusula Quarta - Regras de conduta do usuário</h4>
                        <ul>
                            <li>
                                <b>a.</b> O Usuário se compromete a não utilizar o Site e/ou o App para a publicação, criação, armazenamento e/ou
                                divulgação de: Conteúdo abusivo, como textos, fotos e/ou vídeos que tenham caráter difamatório, discriminatório,
                                obsceno, ofensivo, ameaçador, abusivo, vexatório, prejudicial, que contenha expressões de ódio contra pessoas ou
                                grupos, ou que contenha pornografia infantil, pornografia explícita ou violência, conteúdo que possa ser danoso a
                                menores, que contenha insultos ou ameaças religiosas ou raciais, ou que incentive danos morais (incluindo os corporais)
                                e patrimoniais, ou que possa violar qualquer direito de terceiro, notadamente os direitos humanos;
                            </li>
                            <li>
                                <b>b.</b> Banners publicitários e/ou qualquer tipo de comércio eletrônico que seja considerado ilícito, assim entendidos
                                os que sejam contrários à legislação ou ofendam direitos de terceiros;
                            </li>
                            <li>
                                <b>c.</b> Qualquer tipo de material (textos, fotos e/ou vídeos) protegido por direitos autorais, copyright ou que,
                                por qualquer razão, violem direitos de terceiros;
                            </li>
                            <li>
                                <b>d.</b> Informações difamatórias e caluniosas ou que sejam contrárias à honra, à intimidade pessoal e familiar ou à
                                imagem das pessoas (inclusive de pessoa jurídicas, entidades e organizações e ela equiparadas);
                            </li>
                            <li>
                                <b>e.</b> Material que incite à violência e à criminalidade, bem como à pirataria de produtos; e
                            </li>
                            <li>
                                <b>f.</b> Conteúdo que provoque, por suas características (como extensões e formatos de arquivos) danos ao sistema da OMEGA.
                            </li>
                            <li>
                                <b>g.</b> O Usuário concorda que, ao usar o Site e/ou o App, não irá:
                                <ul>
                                    <li>
                                        1. Violar estes Termos e quaisquer de suas regras e condições;
                                    </li>
                                    <li>
                                        2. Praticar falsidade, assim entendidas a falsidade de informações
                                        (i.e: divulgação proposital e voluntária de informações que o Usuário saiba ser falsa ou que sejam notoriamente falsas)
                                        e a falsidade ideológica;
                                    </li>
                                    <li>
                                        3. Publicar ou transmitir qualquer conteúdo abusivo ou ofensivo nos comentários;
                                    </li>
                                    <li>
                                        4. Replicar ou armazenar conteúdo abusivo nos servidores da OMEGA;
                                    </li>
                                    <li>
                                        5. Fazer qualquer coisa ou praticar qualquer ato contrário à boa-fé e aos usos e costumes das comunidades virtuais e
                                        que possam ofender qualquer direito de terceiros. Por violação aos usos e costumes de comunidades virtuais também deve
                                        ser entendido o uso excessivo de texto em letras maiúsculas, uso de críticas ofensivas (conhecidas como "flamming"), a
                                        inclusão desnecessária e imprudente de comunicações anteriores em qualquer publicação ou transmissão, bem como qualquer
                                        outro ato que esteja em desacordo com a etiqueta da Internet comumente aceita;
                                    </li>
                                    <li>
                                        6. Cometer fraude;
                                    </li>
                                    <li>
                                        7. Violar ou infringir direitos de propriedade intelectual, propriedade industrial, direitos fiduciários ou contratuais,
                                        direitos de privacidade ou publicidade de outros;
                                    </li>
                                    <li>
                                        8. Propagar, distribuir ou transmitir códigos destrutivos, quer tenham ou não causado danos reais;
                                    </li>
                                    <li>
                                        9. Reunir dados pessoais ou comerciais, incluindo (mas não se limitando a) endereços de e-mail e/ou nomes de qualquer recurso
                                        da Internet, seja gerenciado pela OMEGA ou por terceiros, para fins comerciais, políticos, de benemerência ou outros, sem o
                                        consentimento dos proprietários desses dados;
                                    </li>
                                    <li>
                                        10. Usar robôs, "spiders" ou qualquer outro dispositivo, automático ou manual, para monitorar ou copiar qualquer conteúdo do
                                        serviço da OMEGA;
                                    </li>
                                    <li>
                                        11. Transmitir conteúdo que não pertence ao Usuário ou que ele não tenha direito de publicar ou distribuir, seja sob lei ou contrato;
                                    </li>
                                    <li>
                                        12. Utilizar ou transmitir qualquer conteúdo, informação, texto, imagem, vídeo ou logomarca publicados no Site com finalidade
                                        direta ou indiretamente publicitária, ou mesmo com qualquer outro intuito comercial, exceto se prévia e expressamente autorizado
                                        pela OMEGA, sob pena de responder por perdas e danos cabíveis;
                                    </li>
                                    <li>
                                        13. Acessar o Site sem autorização, por meio de práticas de "hacking", "password mining" ou qualquer outro meio fraudulento ou que
                                        represente violação a direito de terceiros;
                                    </li>
                                    <li>
                                        14. Realizar ou incentivar atividades ilegais, incluindo (mas não se limitando a) promover ou facilitar o acesso, uso ou venda de
                                        substâncias ou dispositivos perigosos;
                                    </li>
                                    <li>
                                        15. Deixar de cumprir com quaisquer leis, normas, regras, princípios e regulamentações aplicáveis; e
                                    </li>
                                    <li>
                                        16. Ajudar qualquer terceiro a realizar qualquer uma das ações vedadas por estes Termos.
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <h4 className="tituloSecao">Cláusula Quinta - Direitos de Propriedade Intelectual</h4>
                        <ul>
                            <li>
                                <b>a.</b> A OMEGA respeita os direitos de propriedade intelectual de terceiros e impõe que os Usuários façam o mesmo.
                            </li>
                            <li>
                                <b>b.</b> Todos os direitos autorais sobre o material eventualmente submetido pelo Usuário à OMEGA continuarão sendo de
                                sua propriedade e seu nome será citado sempre que o material for utilizado pela OMEGA, conforme informado pelo Usuário,
                                exceto se o espaço ou suporte físico em que for figurar não possibilitar essa citação. O Usuário autoriza a OMEGA a usar,
                                reproduzir, publicar, apresentar e expor no Site e/ou no App o material submetido pelo Usuário, sem que qualquer remuneração
                                ou pagamento sejam devidos ao Usuário. A OMEGA não está obrigada a utilizar os materiais enviados pelo Usuário e está autorizado
                                a editar o material enviado pelo Usuário.
                            </li>
                            <li>
                                <b>c.</b> O Usuário reconhece e declara que em qualquer contribuição submetida para o Site e App, o material correspondente é de
                                sua exclusiva criação, não constituindo violação de direitos autorais, marcas, segredos, direitos de personalidade, incluindo honra,
                                intimidade, vida privada e a imagem das pessoas, direitos patrimoniais e quaisquer outros direitos de terceiros e que possui poderes
                                para autorizar o uso do material pela OMEGA conforme ora pactuado.
                            </li>
                            <li>
                                <b>d.</b> Desde que citada a fonte (inclusive o nome do autor, quando possível e aplicável) e dentro das condições e limites previstos
                                em lei, notadamente a Lei de Direitos Autorais (Lei n.º 9.610/98), o Usuário não pode reproduzir, publicar, apresentar, alugar, oferecer
                                ou expor qualquer cópia de qualquer conteúdo pertencente à OMEGA sem o consentimento desta ou, no caso de conteúdo de autoria de terceiros,
                                sem o consentimento do autor.
                            </li>
                            <li>
                                <b>e.</b> O Usuário se compromete a cumprir todas as leis nacionais e internacionais referentes aos Direitos de Propriedade Intelectual.
                            </li>
                        </ul>

                        <h4 className="tituloSecao">Cláusula Sexta - Denúncias de Abusos e Violações</h4>
                        <ul>
                            <li>
                                <b>a.</b> O Usuário se compromete a denunciar quaisquer abusos ou violação destes Termos ou de quaisquer direitos de terceiros
                                que observar e/ou for vítima quando da utilização do Site e/ou App. O Usuário deve utilizar o canal disponibilizado no Site para
                                comunicação e denunciação de abusos à OMEGA.
                            </li>
                            <li>
                                <b>b.</b> Todo conteúdo que o Usuário publica utilizando qualquer fórum de discussão é uma informação que, por sua natureza e
                                característica, é pública, aberta e não confidencial. Ao revelar dados pessoais, tais como seu nome e endereço de e-mail nos
                                comentários, o Usuário aceita e compreende que essa informação pode ser coletada e usada por outras pessoas para se comunicarem
                                com ele, sem que seja imputável qualquer responsabilidade à OMEGA. Desta forma, a OMEGA recomenda que o Usuário seja cuidadoso ao
                                decidir compartilhar qualquer dado pessoal em qualquer website.
                            </li>
                        </ul>

                        <h4 className="tituloSecao">Cláusula Sétima - Responsabilidades</h4>
                        <ul>
                            <li>
                                <b>a.</b> A OMEGA não se responsabiliza por quaisquer danos sofridos pelo Usuário ou terceiros que, de qualquer modo,
                                tenham origem ou dependência relacionada ao Site e/ou App.
                            </li>
                            <li>
                                <b>b.</b> A OMEGA não é proprietária dos produtos oferecidos por eventuais Comerciantes (ou Anunciantes), não detém a
                                posse deles, não realiza as ofertas de venda, tampouco, intervém na entrega dos produtos, cuja negociação se inicie no
                                Site e/ou App.
                            </li>
                            <li>
                                <b>c.</b> O Usuário reconhece e aceita que, ao realizar negociações com os Comerciantes, o faz por sua conta e risco,
                                isentando a OMEGA de qualquer responsabilidade.
                            </li>
                            <li>
                                <b>d.</b> O conteúdo sobre análises de criptomoedas, tokens, e ativos digitais de uma maneira geral é meramente educativo,
                                representa tão somente a opinião dos Usuários e desenvolvedores de tais conteúdos, de modo que não são recomendação de
                                investimentos, e, portanto, as informações contidas no website e App da Omega não constituem, nem tampouco devem ser
                                interpretadas como um conselho, recomendação, oferta e/ou solicitação para compra ou venda de ações, títulos, valores
                                mobiliários, criptomoedas, tokens, e quaisquer outros instrumentos financeiros.
                            </li>
                            <li>
                                <b>e.</b> A rentabilidade e sucesso de teses de investimento obtidas no passado não representa garantia de rentabilidade
                                futura. Ao Usuário, é recomendado formalmente a leitura cuidadosa das análises, formulário de informações, lâmina de informações
                                essenciais, de modo a avaliar os riscos inerentes às transações e a determinar, de forma independente e por seu próprio julgamento,
                                sua capacidade de assumi-los.
                            </li>
                            <li>
                                <b>f.</b> A CVM (comissão de valores mobiliários), e órgão regulador do mercado de capitais, não autoriza o investimento direto
                                em criptomoedas por fundos de investimentos, gestores de fundos, etc. De tal forma que, a decisão do Usuário, a partir de informação,
                                análises, relatórios, comentários, ou opiniões encontradas no App e Website da OMEGA refletem única e exclusivamente a opinião
                                pessoal dos seus divulgadores, e modo que não possuem regulamentação, e/ou autorização da CVM, reforçando que caberá ao Usuário,
                                avaliar os riscos inerentes às transações e a determinar, de forma independente e por seu próprio julgamento, sua capacidade de assumi-los.
                            </li>
                        </ul>

                        <h4 className="tituloSecao">Cláusula Oitava - Legislação Aplicável</h4>
                        <ul>
                            <li>
                                <b>a.</b> Estes Termos são governados e interpretados segundo as leis da República Federativa do Brasil e todas as disputas,
                                ações e outros assuntos relacionados serão determinados de acordo com essa legislação pátria vigente.
                            </li>
                            <li>
                                <b>b.</b> A OMEGA observa e aplica a legislação vigente sobre práticas anticorrupção, sendo vedada a promessa, oferta,
                                entrega, direta ou indireta, de qualquer Vantagem Indevida, monetária ou não, a Agente Público ou a terceira pessoa a ele
                                relacionada, sendo igualmente proibida a realização de pagamentos não previstos na legislação, incluindo, sem limitação,
                                pagamentos que se voltem a acelerar procedimentos administrativos ou judiciais e influenciar decisões.
                            </li>
                            <li>
                                <b>c.</b> As condições e informações apresentadas nestes Termos estão de acordo com a legislação brasileira, atendendo,
                                inclusive, aos termos da Lei nº 13.709/2018 ("LGPD – Lei Geral de Proteção de Dados") e normas relacionadas, consoante
                                Política de Privacidade da OMEGA, a qual o Usuário declara ter lido, entendido e aceitado os seus termos, regras e
                                condições nela dispostos.
                                </li>
                            <li>
                                <b>d.</b> Fica eleito o foro da capital da cidade e Estado do Rio de Janeiro como único competente para dirimir quaisquer
                                dúvidas havidas destes Termos, com exclusão de qualquer outro por mais privilegiado que seja ou possa vir a ser.
                            </li>
                        </ul>
                        <Button
                            className="btn__laranja"
                            onClick={() => {
                                window.close();
                            }}
                            titleButton={
                                <>
                                    <AiOutlineCloseCircle className="iconeFecharGuia" />
                                    Fechar Guia
                                </>
                            }
                            />
                    </fieldset>
                </div>
            </div>
                    <Footer />
        </>
                );
            };
            
            export default TermosDeUsoSistema;
