import { api } from './api'
export interface ativosFinanceirosProps {
  nome: string;
  sigla: string;
  origem: string;
  quantidade: number;
  valorUnitario: number;
  porcentagemDoAtivoNaCarteira: number;
  valorTotal: number;
}

export interface salvarFechamentoProps {
  data: Date;
  valorTotalDolar: number;
  valorTotalReal: number;
  valorCota: number;
  rendimentoDiario: number;
  ativosFinanceiros: ativosFinanceirosProps[];
  idResponsavel: string;
  cobrarTaxas: boolean;
  taxaAssessoria: number;
}

export const obterCotacoesDiarias = (token: string, idCredenciais?: string) => {
  const url = `/Cotacoes/Diaria?IdCredenciais=${idCredenciais ? idCredenciais : ""}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const salvarFechamento = (fechamento: salvarFechamentoProps, token: string, idFundoFinanceiro: string, idServicoFinanceiro: number) => {
  const url = "/Fechamento";

  return api.post(url,
    { idFundoFinanceiro, idServicoFinanceiro, fechamento },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const obterHistoricoFechamento = (dataInicial: string, dataFinal: string, OrdenacaoPorDataDesc: boolean, token: string, idFundoFinanceiro: string) => {
  const url = `/Fechamento?DataInicial=${dataInicial}&DataFinal=${dataFinal}&OrdenacaoPorDataDesc=${OrdenacaoPorDataDesc}&IdFundoFinanceiro=${idFundoFinanceiro}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const obterHistoricoFechamentoMensal = (dataInicial: string, dataFinal: string, token: string, idFundoFinanceiro: string) => {
  const url = `/Fechamento/TotaisMensais?DataInicial=${dataInicial}&DataFinal=${dataFinal}&IdFundo=${idFundoFinanceiro}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const obterUltimoFechamento = (token: string, IdServicoFinanceiro: number, IdCliente?: string) => {
  const url = `/Fechamento/Ultimo?IdServicoFinanceiro=${IdServicoFinanceiro}${IdCliente ? `&IdCliente=${IdCliente}` : ""}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const salvarFechamentoPrivate = (token: string, fechamento: salvarFechamentoProps, idFundoFinanceiro: string, idServicoFinanceiro: number) => {
  const url = "/Fechamento/Private";

  return api.post(url, { fechamento, idFundoFinanceiro, idServicoFinanceiro }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}
