import React from 'react'
import { Button } from '../../Button'
import "../CardListarUsuarios/CardListarUsuarios.css";
import { useNavigate } from 'react-router';

interface CardListarUsuariosAppProps {
    nome: string,
    email: string,
    userName: string
}

export const CardListarUsuariosApp = ({ nome, email, userName }: CardListarUsuariosAppProps) => {

    const navigate = useNavigate()

    return (
        <>
            <div className="caixa1 caixaListarUsuarios">
                <fieldset>
                    <div className="FieldsetDivTexto fieldsetListarUsuarios">
                        <h5>{nome}</h5>
                        <h6>Email: {email}</h6>
                        <h6>{userName}</h6>
                        <br />
                    </div>
                </fieldset>
            </div>
        </>
    )
}