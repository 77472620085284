import { buscarDadosCep } from "../../services/api";
import { UsuarioPJ, Socios } from "../../pages/Cliente/Cadastro/Cadastro";

export const mascaraCep = (
  e: any,
  cep: string,
  setCep: React.Dispatch<React.SetStateAction<string>>
) => {
  setCep(e);
  setCep(e.replace(/\D/g, "[0-9]{2}[.]?[0-9]{3}[-][0-9]{3}[-]"));
};

export const checkCEP = async (
  e: any,
  setCEP: (value: React.SetStateAction<string>) => void,
  setRua: (value: React.SetStateAction<string>) => void,
  setBairro: (value: React.SetStateAction<string>) => void,
  setEstado: (value: React.SetStateAction<string>) => void,
  setCidade: (value: React.SetStateAction<string>) => void,
  setUsuarioPJ: React.Dispatch<React.SetStateAction<UsuarioPJ>>,
  rua: string,
  bairro: string,
  cidade: string,
  complemento: string,
  estado: string,
  CEP: string
) => {
  setCEP(e);
  await buscarDadosCep(e)
    .then((res) => {
      setRua(res.data.logradouro);
      setBairro(res.data.bairro);
      setCidade(res.data.localidade);
      setEstado(res.data.uf);
    })
    .finally(() => {
      handleEnderecoPJ(
        setUsuarioPJ,
        rua,
        bairro,
        cidade,
        complemento,
        estado,
        e
      );
    });
};

export const checkCEPSocio = async (
  e: any,
  setCEPSocio: (value: React.SetStateAction<string>) => void,
  setRuaSocio: (value: React.SetStateAction<string>) => void,
  setBairroSocio: (value: React.SetStateAction<string>) => void,
  setEstadoSocio: (value: React.SetStateAction<string>) => void,
  setCidadeSocio: (value: React.SetStateAction<string>) => void,
  setSocioUnico: React.Dispatch<React.SetStateAction<Socios>>,
  ruaSocio: string,
  bairroSocio: string,
  cidadeSocio: string,
  complementoSocio: string,
  estadoSocio: string,
  CEPSocio: string
) => {
  setCEPSocio(e);
  await buscarDadosCep(e)
    .then((res) => {
      setRuaSocio(res.data.logradouro);
      setBairroSocio(res.data.bairro);
      setCidadeSocio(res.data.localidade);
      setEstadoSocio(res.data.uf);
    })
    .finally(() => {
      handleEnderecoSocio(
        setSocioUnico,
        ruaSocio,
        bairroSocio,
        cidadeSocio,
        complementoSocio,
        estadoSocio,
        e
      );
    });
};

export function handleMaskCNPJ(
  e: string,
  setUsuarioPJ: React.Dispatch<React.SetStateAction<UsuarioPJ>>,
  setMaskCNPJ: (value: React.SetStateAction<string>) => void
) {
  e = e.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  setUsuarioPJ((usuario) => {
    usuario.cnpj = e;
    return usuario;
  });
  setMaskCNPJ(e);
}

export function handleEnderecoPJ(
  setUsuarioPJ: React.Dispatch<React.SetStateAction<UsuarioPJ>>,
  rua: string,
  bairro: string,
  cidade: string,
  complemento: string,
  estado: string,
  CEP: string
) {
  setUsuarioPJ((usuario) => {
    usuario.endereco = { rua, bairro, cep: CEP, cidade, complemento, estado };
    return usuario;
  });
}

export function handleEnderecoSocio(
  setSocios: React.Dispatch<React.SetStateAction<Socios>>,
  rua: string,
  bairro: string,
  cidade: string,
  complemento: string,
  estado: string,
  CEP: string
) {
  setSocios((socio) => {
    socio.endereco = { rua, bairro, cep: CEP, cidade, complemento, estado };
    return socio;
  });
}

export function handleMaskTelefonePJ(
  e: string,
  setUsuarioPJ: React.Dispatch<React.SetStateAction<UsuarioPJ>>,
  setMaskTelefone: (value: React.SetStateAction<string>) => void
) {
  e = e.replace(/\D/g, "");
  setUsuarioPJ((usuario) => {
    usuario.telefone = e;
    return usuario;
  });
  e = e.replace(/^(\d{2})(\d)/g, "($1) $2");
  e = e.replace(/(\d{5})(\d)/, "$1-$2");
  setMaskTelefone(e);
}

export function handleMaskCEPPJ(
  e: any,
  setCEP: (value: React.SetStateAction<string>) => void,
  setRua: (value: React.SetStateAction<string>) => void,
  setBairro: (value: React.SetStateAction<string>) => void,
  setEstado: (value: React.SetStateAction<string>) => void,
  setCidade: (value: React.SetStateAction<string>) => void,
  setUsuarioPJ: React.Dispatch<React.SetStateAction<UsuarioPJ>>,
  setMaskCEP: (value: React.SetStateAction<string>) => void,
  rua: string,
  bairro: string,
  cidade: string,
  complemento: string,
  estado: string,
  CEP: string
) {
  e = e.replace(/\D/g, "");
  checkCEP(
    e,
    setCEP,
    setRua,
    setBairro,
    setEstado,
    setCidade,
    setUsuarioPJ,
    rua,
    bairro,
    cidade,
    complemento,
    estado,
    CEP
  );
  e = e.replace(/^(\d{2})(\d)/g, "$1.$2");
  e = e.replace(/(\d{3})(\d)/, "$1-$2");
  setMaskCEP(e);
}

export function handleMaskCEPSocio(
  e: any,
  setCEPSocio: (value: React.SetStateAction<string>) => void,
  setRuaSocio: (value: React.SetStateAction<string>) => void,
  setBairroSocio: (value: React.SetStateAction<string>) => void,
  setEstadoSocio: (value: React.SetStateAction<string>) => void,
  setCidadeSocio: (value: React.SetStateAction<string>) => void,
  setSocioUnico: React.Dispatch<React.SetStateAction<Socios>>,
  setMaskCEPSocio: (value: React.SetStateAction<string>) => void,
  ruaSocio: string,
  bairroSocio: string,
  cidadeSocio: string,
  complementoSocio: string,
  estadoSocio: string,
  CEPSocio: string
) {
  e = e.replace(/\D/g, "");
  checkCEPSocio(
    e,
    setCEPSocio,
    setRuaSocio,
    setBairroSocio,
    setEstadoSocio,
    setCidadeSocio,
    setSocioUnico,
    ruaSocio,
    bairroSocio,
    cidadeSocio,
    complementoSocio,
    estadoSocio,
    CEPSocio
  );
  e = e.replace(/^(\d{2})(\d)/g, "$1.$2");
  e = e.replace(/(\d{3})(\d)/, "$1-$2");
  setMaskCEPSocio(e);
}
