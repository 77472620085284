import { setLabels } from "react-chartjs-2/dist/utils";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { DateFormatter, DateFormatterMonthName } from "../../../functions/DateFormatter";
import { guardarDadosUsuario, guardarDadosUsuario2, obterDadosUsuario2, obterServiçosFinanceiros } from "../../../services/cookies";
import { obterPosicaoConsolidade } from "../../../services/dashboard";
import { obterHistoricoFechamento, obterHistoricoFechamentoMensal, obterUltimoFechamento } from "../../../services/fechamento";
import { renovacaoToken } from "../../../services/usuario";
import { getClienteServicosFinanceiros } from "../../../services/cliente";
import { Fechamentos } from "../../Gestor/HistoricoFechamento/HistoricoFechamento";
import { DadosPosicao } from "./Home";
import React from "react";
import { ativoFinanceiro } from "../Ativos/Ativos";
import { randomColor } from "../Ativos/functions";

export async function handlePosicaoConsolidada(
    setLoadingPosicao: (value: React.SetStateAction<boolean>) => void,
    setErrorPosicao: (value: React.SetStateAction<boolean>) => void,
    setDadosPosicao: (value: React.SetStateAction<DadosPosicao>) => void,
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo,
    token: string,
    idCliente?: string,
) {
    setLoadingPosicao(true);
    let idUsuario: string = obterDadosUsuario2().id;
    setErrorPosicao(false);
    await obterPosicaoConsolidade(idCliente ? idCliente : idUsuario, usuarioServicoFinanceiro.idServicoFinanceiro, token).then((res) => {
        setDadosPosicao(res.data.resultado.dadosHomeCliente);
        setLoadingPosicao(false)
    }).catch(async (err) => {
        setErrorPosicao(true);
        setLoadingPosicao(false)
    }).finally(() => setLoadingPosicao(false));

    setLoadingPosicao(false);
}

export async function handleHistoricoFechamento(
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void,
    setErrorGrafico: (value: React.SetStateAction<boolean>) => void,
    dataInicial: string,
    dataFinal: string,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setRendimentosDia: (value: React.SetStateAction<number[]>) => void,
    setValoresTotais: (value: React.SetStateAction<number[]>) => void,
    setLabels: (value: React.SetStateAction<string[]>) => void,
    setValoresAcumulados: (value: React.SetStateAction<number[]>) => void,
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo,
    setLoadingPosicao: (value: React.SetStateAction<boolean>) => void,
    setErrorPosicao: (value: React.SetStateAction<boolean>) => void,
    setDadosPosicao: (value: React.SetStateAction<DadosPosicao>) => void,
    setLabelsMoeda: React.Dispatch<React.SetStateAction<string[]>>,
    setPorcentagem: React.Dispatch<React.SetStateAction<number[]>>,
    setValorUnitario: React.Dispatch<React.SetStateAction<number[]>>,
    setValorTotal: React.Dispatch<React.SetStateAction<number[]>>,
    colorArray: string[],
    setColorArray: React.Dispatch<React.SetStateAction<string[]>>,
    setValoresTotaisPrivate: (value: React.SetStateAction<number[]>) => void,
    idCliente?: string,
    token?: string,
) {
    setLoadingGrafico(true);
    setErrorGrafico(false);
    const serviçosFinanceiros = obterServiçosFinanceiros();
    let dataInicialZerada = new Date(new Date(dataInicial).setHours(0)).toISOString()


    await obterHistoricoFechamentoMensal(dataInicialZerada, dataFinal, token ? token : obterDadosUsuario2().token, usuarioServicoFinanceiro.id).then((res) => {
        pegaDadosGrafico(res.data.resultado.fechamentos, setRendimentosDia, setValoresTotais, setLabels, setValoresAcumulados, setValoresTotaisPrivate)
        exibirAtivosNaHome(
            setLabelsMoeda,
            setPorcentagem,
            setValorUnitario,
            setValorTotal,
            setLoadingGrafico,
            setLoadingGrafico,
            colorArray,
            setColorArray,
            usuarioServicoFinanceiro,
            obterDadosUsuario2().token,
            idCliente
        )
        handlePosicaoConsolidada(
            setLoadingPosicao,
            setErrorPosicao,
            setDadosPosicao,
            usuarioServicoFinanceiro.idServicoFinanceiro === 0 ? serviçosFinanceiros[0] : usuarioServicoFinanceiro,
            obterDadosUsuario2().token,
            idCliente
        );
    }).catch(async (err) => {
        if (err.toString() === "Error: Request failed with status code 401") {
            await renovacaoToken(obterDadosUsuario2().token, obterDadosUsuario2().tokenRenovacao).then(async (res2) => {
                guardarDadosUsuario(
                    res2.data.camposAdicionais.tokenAcesso,
                    res2.data.camposAdicionais.tokenRenovacao,
                    obterDadosUsuario2().email.toString(),
                    obterDadosUsuario2().id.toString()
                );
                guardarDadosUsuario2(
                    res2.data.camposAdicionais.tokenAcesso,
                    res2.data.camposAdicionais.tokenRenovacao,
                    obterDadosUsuario2().email.toString(),
                    obterDadosUsuario2().id.toString()
                );
                await obterHistoricoFechamentoMensal(dataInicial, dataFinal, res2.data.camposAdicionais.tokenAcesso, usuarioServicoFinanceiro.id).then((res3) => {
                    pegaDadosGrafico(res3.data.resultado.fechamentos, setRendimentosDia, setValoresTotais, setLabels, setValoresAcumulados, setValoresTotaisPrivate)
                    exibirAtivosNaHome(
                        setLabelsMoeda,
                        setPorcentagem,
                        setValorUnitario,
                        setValorTotal,
                        setLoadingGrafico,
                        setLoadingGrafico,
                        colorArray,
                        setColorArray,
                        usuarioServicoFinanceiro,
                        res2.data.camposAdicionais.tokenAcesso,
                        idCliente
                    )
                    handlePosicaoConsolidada(
                        setLoadingPosicao,
                        setErrorPosicao,
                        setDadosPosicao,
                        usuarioServicoFinanceiro.idServicoFinanceiro === 0 ? serviçosFinanceiros[0] : usuarioServicoFinanceiro,
                        res2.data.camposAdicionais.tokenAcesso,
                        idCliente
                    );
                }).catch((err) => {
                    setErrorGrafico(true);
                })
            }).catch((err) => {
                setModalDesconectar(true);
            })
        }
        else {
            setErrorGrafico(true);
        }
    }).finally(() => setLoadingGrafico(false))
}

export const pegaDadosGrafico = (
    arrayFechamentos: Fechamentos[],
    setRendimentosDia: (value: React.SetStateAction<number[]>) => void,
    setValoresTotais: (value: React.SetStateAction<number[]>) => void,
    setLabels: (value: React.SetStateAction<string[]>) => void,
    setValoresAcumulados: (value: React.SetStateAction<number[]>) => void,
    setValoresTotaisPrivate: (value: React.SetStateAction<number[]>) => void,

) => {
    let rendimentoParciais: number[] = []
    let valoresParciais: number[] = []
    let valoresParciaisPrivate: number[] = []
    let valoresAcumuladosParciais: number[] = []
    let labelsParciais: string[] = []

    rendimentoParciais = []
    valoresParciais = []
    valoresParciaisPrivate = []
    valoresAcumuladosParciais = []
    labelsParciais = []

    arrayFechamentos.map((fechamentoDiario, index, array) => {
        rendimentoParciais = [...rendimentoParciais, ((fechamentoDiario.valorTotalDolar / array[0].valorTotalDolar) - 1) * 100]
        labelsParciais = [...labelsParciais, DateFormatterMonthName(fechamentoDiario.data)]
        index === 0 ? valoresParciais = [10.11] : valoresParciais = [...valoresParciais, (((fechamentoDiario.valorCota / array[index - 1].valorCota) - 1) * 100)]
        index === 0 ? valoresParciaisPrivate = [0] : valoresParciaisPrivate = [...valoresParciaisPrivate, (((fechamentoDiario.valorTotalDolar / array[index - 1].valorTotalDolar) - 1) * 100)]

        valoresAcumuladosParciais = [...valoresAcumuladosParciais, ((fechamentoDiario.valorCota / 1101.10) - 1) * 100]
    })

    setValoresTotaisPrivate(valoresParciaisPrivate);
    setRendimentosDia(rendimentoParciais);
    setValoresTotais(valoresParciais);
    setValoresAcumulados(valoresAcumuladosParciais);
    setLabels(labelsParciais);
}

export interface servicoFinanceiro {
    id: string,
    idServicoFinanceiro: number,
    servicoFinanceiro: string,
}

export const obterServicosFinanceirosCliente = async (
    idCredenciais: string,
    setListaServicosFinanceiros: React.Dispatch<servicoFinanceiro[]>,
    setErro: React.Dispatch<boolean>,
    setDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setServicoFinanceiroCliente: React.Dispatch<usuarioServicoFinanceiroTipo>,
    setIdServicoFinanceiroCliente: React.Dispatch<number>,
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void,
    setErrorGrafico: (value: React.SetStateAction<boolean>) => void,
    dataInicial: string,
    dataFinal: string,

    setRendimentosDia: (value: React.SetStateAction<number[]>) => void,
    setValoresTotais: (value: React.SetStateAction<number[]>) => void,
    setLabels: (value: React.SetStateAction<string[]>) => void,
    setValoresAcumulados: (value: React.SetStateAction<number[]>) => void,
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo,
    setLoadingPosicao: (value: React.SetStateAction<boolean>) => void,
    setErrorPosicao: (value: React.SetStateAction<boolean>) => void,
    setDadosPosicao: (value: React.SetStateAction<DadosPosicao>) => void,
    setLabelsMoeda: React.Dispatch<React.SetStateAction<string[]>>,
    setPorcentagem: React.Dispatch<React.SetStateAction<number[]>>,
    setValorUnitario: React.Dispatch<React.SetStateAction<number[]>>,
    setValorTotal: React.Dispatch<React.SetStateAction<number[]>>,
    colorArray: string[],
    setColorArray: React.Dispatch<React.SetStateAction<string[]>>,
    setValoresTotaisPrivate: React.Dispatch<React.SetStateAction<number[]>>,
    idCliente?: string,
) => {
    const { token, tokenRenovacao } = obterDadosUsuario2()

    await getClienteServicosFinanceiros(token, idCredenciais)
        .then(res => {
            setListaServicosFinanceiros(res.data.resultado.clientes)
            setServicoFinanceiroCliente(res.data.resultado.clientes[0])
            setIdServicoFinanceiroCliente(res.data.resultado.clientes[0].idServicoFinanceiro)
            handleHistoricoFechamento(
                setLoadingGrafico,
                setErrorGrafico,
                dataInicial,
                dataFinal,
                setDesconectar,
                setRendimentosDia,
                setValoresTotais,
                setLabels,
                setValoresAcumulados,
                res.data.resultado.clientes[0],
                setLoadingPosicao,
                setErrorPosicao,
                setDadosPosicao,
                setLabelsMoeda,
                setPorcentagem,
                setValorUnitario,
                setValorTotal,
                colorArray,
                setColorArray,
                setValoresTotaisPrivate,
                idCliente,
                token,
            );
        })
        .catch(async err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao).then(async (res2) => {
                    guardarDadosUsuario(
                        res2.data.camposAdicionais.tokenAcesso,
                        res2.data.camposAdicionais.tokenRenovacao,
                        obterDadosUsuario2().email.toString(),
                        obterDadosUsuario2().id.toString()
                    );
                    guardarDadosUsuario2(
                        res2.data.camposAdicionais.tokenAcesso,
                        res2.data.camposAdicionais.tokenRenovacao,
                        obterDadosUsuario2().email.toString(),
                        obterDadosUsuario2().id.toString()
                    );

                    await getClienteServicosFinanceiros(res2.data.camposAdicionais.tokenAcesso, idCredenciais)
                        .then(res => {
                            setListaServicosFinanceiros(res.data.resultado.clientes)
                            setServicoFinanceiroCliente(res.data.resultado.clientes[0])
                            setIdServicoFinanceiroCliente(res.data.resultado.clientes[0].idServicoFinanceiro)
                            handleHistoricoFechamento(
                                setLoadingGrafico,
                                setErrorGrafico,
                                dataInicial,
                                dataFinal,
                                setDesconectar,
                                setRendimentosDia,
                                setValoresTotais,
                                setLabels,
                                setValoresAcumulados,
                                res.data.resultado.clientes[0],
                                setLoadingPosicao,
                                setErrorPosicao,
                                setDadosPosicao,
                                setLabelsMoeda,
                                setPorcentagem,
                                setValorUnitario,
                                setValorTotal,
                                colorArray,
                                setColorArray,
                                setValoresTotaisPrivate,
                                idCliente,
                                res2.data.camposAdicionais.tokenAcesso
                            );
                        })
                        .catch(() => {
                            setErro(true)
                        })

                }).catch(() => {
                    setDesconectar(true);
                })
            }
            else {
                setErro(true);
            }
        })
}

export const exibirAtivosNaHome = async (
    setLabelsMoeda: React.Dispatch<React.SetStateAction<string[]>>,
    setPorcentagem: React.Dispatch<React.SetStateAction<number[]>>,
    setValorUnitario: React.Dispatch<React.SetStateAction<number[]>>,
    setValorTotal: React.Dispatch<React.SetStateAction<number[]>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setErroPage: React.Dispatch<React.SetStateAction<boolean>>,
    colorArray: string[],
    setColorArray: React.Dispatch<React.SetStateAction<string[]>>,
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo,
    token: string,
    idCliente?: string,
) => {
    await obterUltimoFechamento(
        token,
        usuarioServicoFinanceiro.idServicoFinanceiro,
        idCliente ? idCliente : obterDadosUsuario2().id
    )
        .then((res) => {
            handleDataAndLabels(res.data.resultado.fechamento.ativosFinanceiros, setLabelsMoeda, setPorcentagem, setValorUnitario, setValorTotal, colorArray, setColorArray);
            setErroPage(false);
        })
        .catch(async (e) => {
            setErroPage(true);
        }).finally(() => setLoading(false));
};

function handleDataAndLabels(
    ativosFinanceiros: ativoFinanceiro[],
    setLabels: React.Dispatch<React.SetStateAction<string[]>>,
    setPorcentagem: React.Dispatch<React.SetStateAction<number[]>>,
    setValorUnitario: React.Dispatch<React.SetStateAction<number[]>>,
    setValorTotal: React.Dispatch<React.SetStateAction<number[]>>,
    colorArray: string[],
    setColorArray: React.Dispatch<React.SetStateAction<string[]>>
) {
    let labels: string[] = [];
    let porcentagem: number[] = [];
    let valoresTotais: number[] = [];
    let valoresUnitarios: number[] = [];

    ativosFinanceiros
        .filter((item) => {
            return item.porcentagemDoAtivoNaCarteira >= 1;
        }).map(ativoFinanceiro => {
            labels.push(ativoFinanceiro.sigla + ": " + ativoFinanceiro.porcentagemDoAtivoNaCarteira.toFixed(2) + "%");
            porcentagem.push(ativoFinanceiro.porcentagemDoAtivoNaCarteira)
            valoresTotais.push(ativoFinanceiro.valorTotal);
            valoresUnitarios.push(ativoFinanceiro.valorUnitario);
        }
        )

    setLabels(labels);
    setPorcentagem(porcentagem);
    setValorUnitario(valoresUnitarios);
    setValorTotal(valoresTotais);
    randomColor(porcentagem, colorArray, setColorArray);
}