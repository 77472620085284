import { api, apiApp } from './api'

export const fazerLogin = (dadosLogin: any) => {
  const url = "/Usuario/Login";

  return api.post(url, { dadosLogin });
};

export const confirmacaoDeEmail = (email: string, token: string) => {
  const url = `/Usuario/Email/Confirmacao?Email=${email}&Token=${encodeURIComponent(
    token
  )}`;
  return api.get(url);
};

export const confirmacaoDeEmailApp = (email: string, token: string) => {
  const url = `/Usuario/Email/Confirmacao?Email=${email}&Token=${encodeURIComponent(
    token
  )}`;
  return apiApp.get(url);
};

export const confirmacaoDeEmailReenviar = (email: string, tipo: string) => {
  const url = `/Usuario/Email/Confirmacao/Reenviar?Email=${email}&TipoConfirmacao=${tipo}`;
  return api.get(url);
};

export const verificarDisponibilidadeUserName = (userName: string) => {
  const url = `/Usuario/Username/VerificarDisponibilidade?Username=${userName}`;
  return api.get(url);
};

export const verificarDisponibilidadeEmail = (email: string) => {
  const url = `/Usuario/Email/VerificarDisponibilidade?Email=${email}`;
  return api.get(url);
};

export const solicitarRedefinicaoDeSenha = (email: string, sender?: string) => {
  const url = `/Usuario/Senha/SolicitarRedefinicao?Email=${email}`;

  return sender === "app" ? apiApp.get(url) : api.get(url);
};

export const redefinirSenha = (
  sender: string,
  email: string,
  token: string,
  novaSenha: string
) => {

  const url = "/Usuario/Senha/Redefinir";

  return sender == "sys" ? api.post(url, { email, token, novaSenha }) : apiApp.post(url, { email, token, novaSenha });
};

export const renovacaoToken = (
  tokenAcesso: string | boolean,
  tokenRenovacao: string | boolean
) => {
  const url = `/Usuario/RenovarTokens?TokenAcesso=${encodeURIComponent(
    tokenAcesso
  )}&TokenRenovacao=${encodeURIComponent(tokenRenovacao)}`;
  return api.get(url);
};

export const obterServicoFinanceiroPorStatus = (status: boolean = true) => {
  const url = `/ServicoFinanceiro/PorStatus?Status=${status}`;

  return api.get(url);
};

export const obterCredenciais = (email: string, token: string) => {
  const url = `/Usuario/Credenciais?Email=${email}`;

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const obterFotoDePerfil = (email: string) => {
  const url = `/Usuario/Credenciais?Email=${email}`;

  return api.get(url);
};

export const obterContasBancariasPorCliente = (idCliente: string) => {
  const url = `/Cliente/ContaParaRecebimento?IdCredenciaisCliente=${idCliente}`;

  return api.get(url);
};

export const alterarSenha = (idCredenciais: string, token: string, senhaAtual: string, novaSenha: string) => {
  const url = "/Usuario/Senha/Alterar";

  const dados = {
    idCredenciais,
    senhaAtual,
    novaSenha
  }

  return api.put(url, dados, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const desativarUsuario = (token: string, idCredenciais: string) => {
  const url = "/Usuario/Desativar"

  return api.post(url, { idCredenciais }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const ativarUsuario = (token: string, idCredenciais: string) => {
  const url = "/Usuario/Ativar"

  return api.post(url, { idCredenciais }, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const obterFundoFinanceiro = (token: string, IdCredenciais: string) => {
  const url = `/Cliente/FundosFinanceiros?IdCredenciais=${IdCredenciais}`

  return api.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const getUsuarioTodosApp = (token: string, NumeroPagina: number, TamanhoPagina: number) => {
  const url = `/Usuario/Todos?NumeroPagina=${NumeroPagina}&TamanhoPagina=${TamanhoPagina}`

  return apiApp.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  })
}

export const postUsuarioLoginApp = (email: string, senha: string) => {
  const url = `/Usuario/Login`

  let dadosLogin = {
    email,
    senha
  }

  return apiApp.post(url, { dadosLogin })
}