import React, { Fragment, useState } from "react";
import { DateFormatter } from "../../../functions/DateFormatter";
import { AiOutlineFileSearch, AiOutlineFileExcel } from "react-icons/ai";
import "./style.css";
import { Button } from "../../../components/Button";
import { ModalComprovante } from "../../modais/ModalComprovante";
import { mascaraBRL, mascaraUSD } from "../../../functions/MascarasMonetarias";

interface TabelaExtratoProps {
    dados: {
        dataExecucao: Date;
        dataSolicitacao: Date;
        quantidadeCotas: number;
        tipo: "Aporte" | "Saque" | "TaxaPerformance" | "TaxaAdministracao";
        status: "EmEspera" | "Efetuado" | "Rejeitado";
        valor: number;
        urlImagemComprovante: string;
    };
    dataExecucao: boolean
    fonteBold: boolean;
    servicoFinanceiro?: number;
}
export const TabelaExtrato = ({ dados, dataExecucao, fonteBold, servicoFinanceiro = 1 }: TabelaExtratoProps) => {

    console.log(servicoFinanceiro);


    const status: string =
        dados.status === "EmEspera" ? "Em Espera" : dados.status;

    var cor: string;
    switch (dados.status) {
        case "Efetuado":
            cor = "#4ed809";
            break;
        case "Rejeitado":
            cor = "#c50505";
            break;
        default:
            cor = "#545557";
    }

    const [comprovanteModal, setComprovanteModal] = useState<boolean>(false);
    const [shakeButton, setShakeButton] = useState<boolean>(false);


    const exibirPDF = () => {
        setComprovanteModal(true);
    };

    return (
        <>
            <tr
                id="trTabelaComDados"
                className="hoverTabela tabelaLinha"
                style={{ textAlign: "center" }}
            >
                <th
                    className="tableConteudoStatus table-secondary tableOperacao"
                    style={{ color: cor }}
                >
                    {fonteBold ?
                        <strong>{status}</strong>
                        :
                        <h6>{status}</h6>
                    }
                </th>
                <th className="table-secondary tableConteudo tableData ">
                    {fonteBold ?
                        <strong>
                            {dataExecucao
                                ? DateFormatter(dados.dataExecucao).toString() === "01/01/0001" ? "Em espera." : DateFormatter(dados.dataExecucao)
                                : DateFormatter(dados.dataSolicitacao)}
                        </strong>
                        :
                        <h6>{dataExecucao
                            ? DateFormatter(dados.dataExecucao).toString() === "01/01/0001" ? "Em espera." : DateFormatter(dados.dataExecucao)
                            : DateFormatter(dados.dataSolicitacao)}
                        </h6>
                    }

                </th>
                <th className="table-secondary tableConteudo tableTipo ">
                    {fonteBold ?
                        <strong>{dados.tipo === "TaxaPerformance" ? "Taxa de Performance" : dados.tipo === "TaxaAdministracao" ? "Taxa de Administração" : dados.tipo}</strong>
                        :
                        <h6>{dados.tipo === "TaxaPerformance" ? "Taxa de Performance" : dados.tipo === "TaxaAdministracao" ? "Taxa de Administração" : dados.tipo}</h6>
                    }
                </th>
                {servicoFinanceiro !== 3 && <th className="table-secondary tableConteudo tableCotas ">
                    {fonteBold ?
                        <strong>{dados.quantidadeCotas.toString().match(/^-?\d+(?:\.\d{0,6})?/)}</strong>
                        :
                        <h6>{dados.quantidadeCotas.toString().match(/^-?\d+(?:\.\d{0,6})?/)}</h6>
                    }
                </th>
                }
                <th className="table-secondary tableConteudo tableOperacao ">
                    {fonteBold ?
                        <strong>{servicoFinanceiro !== 3 ? "R$ " + mascaraBRL(dados.valor.toFixed(2)) : "$ " + mascaraUSD(dados.valor.toFixed(2))}</strong>
                        :
                        <h6>{servicoFinanceiro !== 3 ? "R$ " + mascaraBRL(dados.valor.toFixed(2)) : "$ " + mascaraUSD(dados.valor.toFixed(2))}</h6>
                    }
                </th>
                <th className="table-secondary tableConteudo tableOperacao verComprovante">
                    {dados.urlImagemComprovante !== null ? (
                        <Button
                            className="btn__cinza buttonComprovante bgTransparente"
                            onClick={exibirPDF}
                            titleButton={
                                <>
                                    <AiOutlineFileSearch className="comprovanteExistente" />
                                </>
                            }
                        />
                    ) : <Button
                        className={shakeButton ? 'btn__cinza buttonComprovante bgTransparente shake' : 'btn__cinza buttonComprovante bgTransparente'}
                        onClick={() => setShakeButton(!shakeButton)}
                        onMouseLeave={() => setShakeButton(false)}
                        titleButton={
                            <>
                                <AiOutlineFileExcel className="comprovanteVazio" />
                            </>
                        }
                    />
                    }
                </th>
            </tr>
            {comprovanteModal && (
                <ModalComprovante
                    modal={comprovanteModal}
                    setModal={setComprovanteModal}
                    comprovanteBase64={dados.urlImagemComprovante}
                />
            )}
        </>
    );
};
