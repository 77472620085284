import React, { useState } from 'react'
import './TabelaGerenciarCadastro.css';

import { Button } from '../../Button'
import { alterarStatusDaConta } from "../../../services/cliente";

import { AiOutlineCheckCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { obterDadosUsuario2 } from '../../../services/cookies';
import { Cliente } from '../../../pages/Gestor/GerenciarCadastro/GerenciarCadastro';
import { handleStatus } from './functions';
import { ModalChavePrivateAPI } from '../../modais/ModalChavePrivateAPI';
import { Dados } from '../../../pages/Cliente/AporteSaque/AporteSaque';

interface TabelaGerenciarCadastroProps {
    usuario: Cliente,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    setIdCliente: React.Dispatch<React.SetStateAction<string>>,
    setReload: React.Dispatch<React.SetStateAction<boolean>>,
    reload: boolean
}

export const TabelaGerenciarCadastro = ({ usuario, setModal, setIdCliente, setReload, reload }: TabelaGerenciarCadastroProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [modalChaveAPI, setModalChaveAPI] = useState<boolean>(false);

    let dadosUsuario: Dados = obterDadosUsuario2();

    return (
        <>
            <tr className="tabelaLinha" style={{ textAlign: 'center' }}>
                <th>{usuario.nome}</th>
                <th>Fisica</th>
                {!loading && <th className="colunaBotoes colunaCadastro">
                    <Button
                        onClick={() => usuario.idsServicosContratados.includes("3") ? setModalChaveAPI(true) : handleStatus(usuario.idCredenciais, 1, setLoading, reload, setReload) }
                        className="btn__verde botaoCircular botaoEsq"
                        titleButton={
                            <>
                                <AiOutlineCheckCircle className="iconButtonExtrato" />
                                <p className="textoBotao">Aprovar</p>
                            </>
                        }
                    />
                    <Button
                        onClick={() => handleStatus(usuario.idCredenciais, 0, setLoading, reload, setReload)}
                        className="btn__vermelho botaoCircular botaoDir"
                        titleButton={
                            <>
                                <p className="textoBotao">Reprovar</p>
                                <AiOutlineMinusCircle className="iconButtonExtrato" />
                            </>
                        }
                    />
                </th>
                }
                <th>
                    <Button
                        onClick={() => {
                            setIdCliente(usuario.idCredenciais)
                            setModal(true)
                        }}
                        className="btn__cinza fonteBotoes"
                        titleButton="Dados Pessoais"
                    />
                </th>
            </tr>

            <ModalChavePrivateAPI modal={modalChaveAPI} setModal={() => setModalChaveAPI(false)} dadosUsuario={dadosUsuario} idCredenciais={usuario.idCredenciais} reload={reload} setReload={setReload} />
        </>
    )
}