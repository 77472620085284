import React, { useState } from "react";

import "./styles.css";

import { handleClose, handleSalvarConta } from "./functions";

import Modal from "react-bootstrap/Modal";
import AnimateHeight from 'react-animate-height';
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { Pix } from "../../FormasDePagamento/Pix";
import { Button } from "../../Button";
import { DadosBancarios } from "../../FormasDePagamento/DadosBancarios";
import { Sucesso } from './Sucesso'
import { Erro } from './Erro'
import { ModalDesconectar } from '../ModalDesconectar'
import { LoadingButton } from '../../loadingButtom'

interface modalProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
  handleReload: () => void
}

export const ModalNovaFormaDePagamento = ({ modal, setModal, handleReload }: modalProps) => {
  const [pixAberto, setPixAberto] = useState<boolean>(true)
  const [dadosBancariosAberto, setDadosBancariosAbero] = useState<boolean>(false)

  const [apelidoFormaPagamento, setApelidoFormaPagamento] = useState<string>('')
  const [chavePix, setChavePix] = useState<string>('');
  const [tipoChavePix, setTipoChavePix] = useState<string>('');
  const [nomeFavorecido, setNomeFavorecido] = useState<string>('')
  const [tipoDocumento, setTipoDocumento] = useState<string>('')
  const [numeroDocumento, setNumeroDocumento] = useState<string>('')
  const [numeroConta, setNumeroConta] = useState<string>('')
  const [agencia, setAgencia] = useState<string>('')
  const [nomeBanco, setNomeBanco] = useState<string>('')
  const [codigoBanco, setCodigoBanco] = useState<number>(0)
  const [sucesso, setSucesso] = useState<boolean>(false)
  const [erro, setErro] = useState<boolean>(false)
  const [erroPix, setErroPix] = useState<boolean>(false)
  const [erroDadosBancarios, setErroDadosBancario] = useState<boolean>(false)
  const [desconectar, setDesconectar] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <>
      <Modal show={modal} onHide={() => {
        handleClose(setModal, setSucesso, setErro, setErroPix, setErroDadosBancario, setNomeFavorecido, setTipoDocumento, setNumeroDocumento, setNumeroConta, setAgencia, setNomeBanco, setCodigoBanco, setChavePix, setTipoChavePix)
      }}>
        <form onSubmit={(e) => {
          e.preventDefault()
          handleSalvarConta(setSucesso, setErro, setErroPix, setErroDadosBancario, setDesconectar, setLoading, handleReload, { chavePix, tipoChavePix, agencia, codigoBanco, nomeBanco, nomeFavorecido, numeroConta, numeroDocumento, tipoDocumento, apelido: apelidoFormaPagamento })
        }}>
          <Modal.Header closeButton>
            <Modal.Title>Adicionar dados bancários</Modal.Title>
          </Modal.Header>
          {erro ? <Erro handleTentarNovamente={() => setErro(false)} /> :
            <>
              {sucesso ? <Sucesso handleClose={() => {
                handleClose(setModal, setSucesso, setErro, setErroPix, setErroDadosBancario, setNomeFavorecido, setTipoDocumento, setNumeroDocumento, setNumeroConta, setAgencia, setNomeBanco, setCodigoBanco, setChavePix, setTipoChavePix)
              }} /> :
                <>
                  <Modal.Body onSubmit={() => { }}>
                    <div className="section-title-ModalNovaFormaDePagamento" onClick={() => {
                      setDadosBancariosAbero(!dadosBancariosAberto)
                      setPixAberto(!pixAberto)
                    }}>
                      <h6>Pix</h6>
                      {pixAberto ? (
                        <BsCaretDownFill className="arrowDown" />
                      ) : (
                        <BsCaretUpFill className="arrowUp" />
                      )}
                    </div>
                    <AnimateHeight
                      duration={500}
                      height={pixAberto ? 'auto' : 0}
                    >
                      <Pix apelido={apelidoFormaPagamento} setApledo={setApelidoFormaPagamento} tipoChave={tipoChavePix} chavePix={chavePix} setChavePix={setChavePix} setTipoChave={setTipoChavePix} />
                    </AnimateHeight>

                    <div className="section-title-ModalNovaFormaDePagamento" onClick={() => {
                      setPixAberto(!pixAberto)
                      setDadosBancariosAbero(!dadosBancariosAberto)
                      setChavePix('')
                      setTipoChavePix('')

                    }}>
                      <h6>Dados Bancários</h6>
                      {dadosBancariosAberto ? (
                        <BsCaretDownFill className="arrowDown" />
                      ) : (
                        <BsCaretUpFill className="arrowUp" />
                      )}
                    </div>
                    <AnimateHeight
                      duration={500}
                      height={dadosBancariosAberto ? 'auto' : 0}
                    >
                      <DadosBancarios apelido={apelidoFormaPagamento} setApledo={setApelidoFormaPagamento} agencia={agencia} codigoBanco={codigoBanco} nomeBanco={nomeBanco} nomeFavorecido={nomeFavorecido} numeroConta={numeroConta} numeroDocumento={numeroDocumento} setAgencia={setAgencia} setCodigoBanco={setCodigoBanco} setNomeBanco={setNomeBanco} setNomeFavorecido={setNomeFavorecido} setNumeroConta={setNumeroConta} setNumeroDocumento={setNumeroDocumento} setTipoDocumento={setTipoDocumento} tipoDocumento={tipoDocumento} />
                    </AnimateHeight>
                  </Modal.Body>
                  {erroPix && <p className="mensagem-erro-preenchimento-ModalNovaFormaDePagamento">Todos os campos do Pix devem ser preenchidos</p>}
                  {erroDadosBancarios && <p className="mensagem-erro-preenchimento-ModalNovaFormaDePagamento">Todos os campos dos Dados bancários devem ser preenchidos</p>}

                  <Modal.Footer >
                    {loading ?
                      <div className="loading-ModalNovaFormaDePagamento">
                        <LoadingButton />
                      </div>
                      :
                      <>
                        <div className="footer-ModalNovaFormaDePagamento">

                          <Button titleButton="Salvar" type="submit" onClick={(e) => {
                            e.preventDefault()
                            handleSalvarConta(setSucesso, setErro, setErroPix, setErroDadosBancario, setDesconectar, setLoading, handleReload, { chavePix, tipoChavePix, agencia, codigoBanco, nomeBanco, nomeFavorecido, numeroConta, numeroDocumento, tipoDocumento, apelido: apelidoFormaPagamento })

                          }} />
                          <Button titleButton="Cancelar" onClick={() => {
                            handleClose(setModal, setSucesso, setErro, setErroPix, setErroDadosBancario, setNomeFavorecido, setTipoDocumento, setNumeroDocumento, setNumeroConta, setAgencia, setNomeBanco, setCodigoBanco, setChavePix, setTipoChavePix)
                          }} />
                        </div>
                      </>}
                  </Modal.Footer>
                </>
              }
            </>
          }
        </form>

      </Modal>
      {desconectar && <ModalDesconectar modal={desconectar} />}
    </>
  );
};
