import React from "react";
import LegalNavbar from "../../../components/LegalNavbar/LegalNavbar";
import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    return (
        <>
            <LegalNavbar />
            <div className="container">
                <Header titulo="Privacy Policy" />
                <div className="caixa1">
                    <fieldset>
                        <p>
                            We at OMEGA CONSULTORIA E NEGOCIOS LTDA. ("Omega") have a strong
                            commitment to the protection of privacy and personal data.
                            Therefore, we present the company's Privacy Policy, which has all
                            the information regarding the use, by Omega, of personal data
                            collected through our website and application, or even in the
                            performance of our activities. It is worth pointing out that, for
                            the purposes of this Policy, Personal Data is data related to the
                            identified or identifiable natural person - that is, it does not
                            apply to legal entity data
            </p>
                        <h4>1. Which data is collected? How is it used?</h4>
                        <p>
                            When you access our site, the server automatically stores your IP
                            address, Browser, Operating System, the language, the date, and
                            the URL used for browsing (This is a standard procedure and all
                            blogs and websites do it automatically).
            </p>
                        <p>
                            When you register/login to the site, as part of the inquiry
                            process, we collect the personal information that you give us such
                            as: name, e-mail, identity document, proof of residence,
                            individual taxpayer registry, etc.
            </p>
                        <p>
                            E-mail marketing will be sent only if you allow it. In these
                            e-mails you can receive news about our services, products in
                            general, new campaigns and other updates. In all e-mails, you will
                            have the opportunity to remove your address from the registry.
            </p>
                        <p>
                            The data we automatically collect is used for internal statistical
                            purposes and at no time is disclosed to the public.
            </p>
                        <p>
                            <b>Data arising from ordinary use of the site:</b> We may also use
                            cookies and other similar technologies to store information such
                            as your personal preferences when you visit our website. Data
                            collected in this way may involve information regarding your
                            device, geolocation data, internet application access records
                            (such as IP, date, and time), platform usage time, access
                            duration, clicks and search terms.
            </p>
                        <p>
                            The cookies that are used on this site may be installed by the
                            site itself, which originate from the different servers operated
                            by it, or from the servers of third parties that provide services
                            and install cookies and/or web beacons (for example, cookies that
                            are used to provide advertising services or certain content
                            through which the user sees the advertising or content at a
                            predetermined time). You can search your computer's hard drive as
                            instructed by the browser itself.
            </p>
                        <p>
                            You can turn off your cookies in your browser options, or by
                            making changes in the tools of Anti-virus programs. However, this
                            may change the way you interact with this site or other sites.
                            This may affect, or not allow you to log into programs, sites, or
                            forums on our and other networks.
            </p>
                        <h4>2. Why does Omega collect your personal information?</h4>
                        <p>
                            Your information will be collected for the following purposes:
            </p>
                        <b>To comply with a legal obligation.</b>
                        <p>
                            There are a variety of legal obligations arising from the
                            applicable laws to which we are subject (e.g., financial services
                            legislation, tax laws, etc.). There are also various supervisory
                            authorities to whose laws and regulations we are subject. These
                            obligations and regulations require us to perform personal data
                            processing activities for identity verifications, compliance with
                            court orders, tax laws and other obligations.
            </p>
                        <b>To send you communications and satisfaction surveys.</b>
                        <p>
                            From time to time, we may send you communications and satisfaction
                            surveys as part of our customer feedback process. It is in our
                            legitimate interest to obtain such feedback to ensure that our
                            services/products are being delivered at the highest level.
            </p>
                        <b>For behavior analysis on our Sites and Applications.</b>
                        <p>
                            Our Sites and Applications may contain data analysis tools that
                            allow us to analyze behavior. Information collected for this
                            purpose is completely anonymized.
            </p>
                        <b>For advertising.</b>
                        <p>
                            We may use your personal data to send you marketing communications
                            by email, telephone, or other pre-agreed upon ways (including
                            social media campaigns) to ensure that you are always up-to-date
                            about our products and services. When we send marketing
                            communications it will be based on your consent.
            </p>
                        <b>To ensure your property security and physical integrity.</b>
                        <p>
                            If you visit our premises, we may record your image through our
                            monitoring system for security reasons. Personal information will
                            also be collected to provide your access credentials in order to
                            maintain a secure working environment.
            </p>
                        <b>To safeguard legitimate interests.</b>
                        <p>
                            We process personal data to safeguard our legitimate interests.
                            Some examples of such activities include:
            </p>

                        <ul>
                            <li>
                                ● Commence claims and prepare our defense in cases of dispute;
              </li>
                            <li>
                                ● Established means and processes to provide technology
                                security, preventing against potential crimes, asset security,
                                and anti-intrusion measures;
              </li>
                            <li>● Analyze and prevent against fraud;</li>
                            <li>
                                ● Establishing systems to monitor our facilities for security
                                reasons;
              </li>
                            <li>
                                ● Business management measures, platform performance
                                optimization, and product and service development;
              </li>
                            <li>
                                ● Adherence to legal standards, notably those relating to money
                                laundering, concealment of assets and values, politically
                                exposed persons, etc.
              </li>
                        </ul>
                        <b>Obtaining your Consent.</b>
                        <p>
                            If the purpose of the collection for any processing of personal
                            data requires your consent, it will be provided through our Sites
                            and Applications.
            </p>
                        <p>
                            If you wish to withdraw your consent for us to no longer send you
                            marketing communications and emails, simply use the unsubscribe
                            option in the emails.
            </p>
                        <h4>3. With whom do we share the collected data?</h4>
                        <p>
                            Subject to the criteria set forth in this Policy, Omega will not
                            disclose its customers' personal data to a third party, except
            </p>
                        <ul>
                            <li>
                                (a) to fulfill the necessary measure within the terms of
                                applicable laws, rules or regulations;
              </li>
                            <li>
                                b) in compliance with a court order or order from a competent
                                government body;
              </li>
                            <li>c) due to the existence of an obligation of disclosure;</li>
                            <li>d) by legitimate interest that requires disclosure; or</li>
                            <li>e) at the client's request, with his/her consent.</li>
                        </ul>
                        <p>
                            Third parties to whom personal information is shared in order to
                            perform the contracted services must perform the processing in a
                            manner that ensures compliance with legal obligations. Even so, we
                            require that third party organizations that handle or obtain this
                            personal data, recognize the criticality of this information,
                            undertake to respect all individual privacy rights, and comply
                            with all relevant data protection laws.
            </p>
                        <p>
                            As part of using your data for the above purposes, we may
                            eventually disclose your personal information to:
            </p>
                        <ul>
                            <li>
                                (a) third party entities such as commercial service providers
                                and specialist consultants engaged for administrative,
                                financial, legal, security, research and other services;
              </li>
                            <li>b) Third parties who are performing Audit work;</li>
                            <li>c) Courts, controlling bodies and regulatory authorities;</li>
                            <li>
                                d) Any person or company provided that we have their consent;
              </li>
                            <li>
                                e) Customers accept and consent that we may from time to time
                                analyze data collected when they visit our Sites and
                                Applications or by other means, such as surveys, for statistical
                                purposes in order to improve and enhance our services.
              </li>
                        </ul>
                        <h4>4. Information Security Measures.</h4>
                        <p>
                            Omega implements reasonable protective measures against physical,
                            administrative, and technical threats to protect your personal
                            information from unauthorized access, use, and disclosure.
            </p>
                        <p>
                            Your information is stored on a local server and in the cloud,
                            both certified with the Privacy Shield certificate, which attests
                            to the highest quality in information storage and security.
            </p>
                        <p>
                            We strive to implement security measures that protect our systems
                            and databases with the best technologies available in the market.
                            Among the security measures we have adopted are data encryption,
                            information access control, the use of firewalls and the
                            implementation of internal information security policy, software
                            solution architecture with intrusion prevention and the use of
                            HTTPS.
            </p>
                        <p>
                            However, despite our efforts, considering the nature and
                            architecture of the internet, which includes elements that are not
                            under our control, it is impossible to guarantee that malicious
                            agents will not gain access to or make improper use of personal
                            data, as this is a risk inherent to the use of computerized
                            systems.
            </p>
                        <h4>5. How long will we keep your data in our possession?</h4>
                        <p>
                            Omega shall keep your personal information for as long as
                            necessary to fulfill the purposes for which the information is
                            processed or for other valid reasons for retaining your personal
                            information, such as complying with legal obligations. If you wish
                            Omega to no longer use your personal information, please contact
                            us at the telephone numbers and channels listed on the website.
            </p>
                        <h4>LEGAL NOTICE</h4>
                        <p>
                            Omega is not responsible for the misuse or loss of personal data
                            that it does not have access to or control. We are also exempt
                            from liability for unlawful and unauthorized use of such
                            information as a consequence of misuse or misuse of your access
                            credentials, negligent conduct or bad faith as a consequence of
                            acts or omissions by you or someone authorized on your behalf.
            </p>
                        <p>
                            We reserve the right to modify this privacy policy at any time, it
                            is recommended to review it frequently by the client, who in any
                            case will be notified through registrations and e-mails regarding
                            such modifications. It is also true that the changes and
              clarifications, once published, will take effect immediately.{" "}
                        </p>
                    </fieldset>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
