import React, { useEffect, useState } from "react";

import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import { LoadingButton } from "../../../components/loadingButtom";
import { ErroTabela } from "../../../components/Erros/ErroTabela";
import { Cliente } from "../ListarUsuarios/functions";
import { TabelaListarClientesPrivate } from "../../../components/Tabelas/TabelaClientesPrivate";
import { FunctionObterClientesPrivate } from "./functions";
import { Button } from "../../../components/Button";
import { voltarSlide, proximoSlide } from "../../Cliente/Extrato/functions";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Slider from "react-slick";
import { CardListarUsuariosPrivate } from "../../../components/Cards/CardListarUsuariosPrivate";

const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    respondTo: 'slider',
    rows: 3,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>
};

export const ListarClientesPrivate = () => {

    const [ListaClientesPrivate, setListaClientesPrivate] = useState<Cliente[]>([]);
    const [idServicoFinanceiro, setIdServicoFinanceiro] = useState<number>(3);
    const [idCliente, setIdCliente] = useState<string>('');
    const [numeroPagina, setNumeroPagina] = useState<number>(1);
    const [tamanhoPagina, setTamanhoPagina] = useState<number>(1000);
    const [loading, setLoading] = useState<boolean>(false);
    const [erro, setErro] = useState<boolean>(false);
    const [desconectar, setDesconectar] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false)

    const { obterTodosOsClientesPrivate } = FunctionObterClientesPrivate({
        idServicoFinanceiro,
        numeroPagina,
        tamanhoPagina,
        setLoading,
        setErro,
        setListaClientesPrivate,
        setDesconectar
    })

    useEffect(() => {
        obterTodosOsClientesPrivate()
    }, [reload])

    return (
        <>
            <Navbar />

            <div id="container">
                <Header titulo="Usuários Private" />
                <div className="desktopListarUsuarios">
                    <div className="caixa1">
                        <fieldset>
                            {loading ?
                                <div className="caixa1">
                                    <LoadingButton />
                                </div>
                                :
                                <>
                                    {erro ? <ErroTabela className="formatacaoTabela" handleClick={() => setReload(!reload)} /> :
                                        <table className="tabela">
                                            <thead>
                                                <tr className="gerenciarCadastroTabela">
                                                    <th className="table-secondary tableTitulo tableData colunas">
                                                        <strong>Nome</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableCotas colunas">
                                                        <strong>Email</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableOperacao colunas">
                                                        <strong>Fechamento</strong>
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ListaClientesPrivate.length !== 0 ? (
                                                        ListaClientesPrivate.map(({ idCredenciais, nome, email, }) =>
                                                            <TabelaListarClientesPrivate key={idCredenciais} nome={nome} idCliente={idCredenciais} email={email} />
                                                        )) : (
                                                            <tr>
                                                                <td colSpan={5} style={{ textAlign: "center", }}>
                                                                    <p>Nenhum resultado encontrado no momento.</p>
                                                                </td>
                                                            </tr>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                    }
                                </>
                            }
                        </fieldset>
                    </div>
                </div>
                <div className="mobileListarUsuarios">
                    <div className="container-mobile-ListarUsuarios">

                        {erro ?
                            <div className="caixa1">
                                <ErroTabela className="formatacaoGrafico" handleClick={() => setReload(!reload)} />
                            </div>
                            :
                            <>
                                {loading ?
                                    <div className="caixa1">
                                        <LoadingButton />
                                    </div>
                                    :
                                    <div className="caixa1">
                                        <fieldset  >

                                            <div className="divBotoesExtratoMobile">
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => voltarSlide("prevArrowSlideListaUsuarios")}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowLeft className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => proximoSlide('nextArrowSlideListaUsuarios')}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowRight className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <Slider {...settings}>
                                                {
                                                    ListaClientesPrivate.length !== 0 ? (
                                                        ListaClientesPrivate.map(({ idCredenciais, nome, email }) =>
                                                            <CardListarUsuariosPrivate key={idCredenciais} nome={nome} idCliente={idCredenciais} email={email} />
                                                        )) : (
                                                            <p>Nenhum resultado encontrado no momento.</p>
                                                        )
                                                }
                                            </Slider>
                                        </fieldset>
                                    </div>
                                }
                            </>
                        }
                    </div>

                </div>

            </div>

            <Footer />
        </>
    );


}

