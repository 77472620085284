import React, { useState, useEffect } from "react";

import "../ListarUsuarios/ListarUsuarios.css";

import { obterClientes, Cliente } from './functions'

import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import { LoadingButton } from "../../../components/loadingButtom";
import { ErroTabela } from "../../../components/Erros/ErroTabela";

import { TabelaListarUsuarios } from "../../../components/Tabelas/TabelaListarUsuarios";
import { CardListarUsuarios } from "../../../components/Cards/CardListarUsuarios";
import { ModalDadosPessoaisCliente } from "../../../components/modais/ModalDadosPessoaisCliente";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { ModalHistoricoFinanceiro } from "../../../components/modais/ModalHistoricoFinanceiro";

import Slider from "react-slick";
import { Button } from "../../../components/Button";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { proximoSlide, voltarSlide } from "../../Cliente/Extrato/functions";

const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    respondTo: 'slider',
    rows: 3,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlideListaUsuarios" style={{ display: 'none !important' }}></div>
};

const ListarUsuarios = () => {
    const [modalDadosPessoais, setModalDadosPessoais] = useState<boolean>(false);

    const [listaClientes, setListaClientes] = useState<Cliente[]>([]);
    const [listaPrivates, setListaPrivates] = useState<Cliente[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [erro, setErro] = useState<boolean>(false);
    const [desconectar, setDesconectar] = useState<boolean>(false);
    const [modalHistoricoFinanceiro, setModalHistoricoFinanceiro] = useState<boolean>(false);
    const [idCliente, setIdCliente] = useState<string>('');
    const [nomeCliente, setNomeCliente] = useState<string>('');
    const [reload, setReload] = useState<boolean>(false)


    useEffect(() => {
        obterClientes(setListaClientes, setListaPrivates, setLoading, setErro, setDesconectar)
    }, [reload])

    // Get the clients name which are private: Array
    const listaNomesPrivates: string[] = listaPrivates.map(chave => chave.nome);

    return (
        <>
            <Navbar />

            <div id="container">
                <Header titulo="Listar Usuarios" />
                <div className="desktopListarUsuarios">
                    <div className="caixa1">
                        <fieldset>
                            {loading ?
                                <div className="caixa1">
                                    <LoadingButton />
                                </div>
                                :
                                <>
                                    {erro ? <ErroTabela className="formatacaoTabela" handleClick={() => setReload(!reload)} /> :
                                        <table className="tabela">
                                            <thead>
                                                <tr className="gerenciarCadastroTabela">
                                                    <th className="table-secondary tableTitulo tableData colunas">
                                                        <strong>Nome</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableCotas colunas">
                                                        <strong>Email</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableOperacao colunas">
                                                        <strong>Serviço</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableOperacao colunas">
                                                        <strong>Dados Pessoais</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableOperacao colunas">
                                                        <strong>Histórico Financeiro</strong>
                                                    </th>
                                                    <th className="table-secondary tableTitulo tableOperacao colunas">
                                                        <strong>Dashboards </strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listaClientes.length !== 0 ? (
                                                listaClientes.map(({idCredenciais, nome, email}) =>
                                                   <TabelaListarUsuarios key={idCredenciais} nome={nome} setNomeCliente={setNomeCliente} idCliente={idCredenciais} setIdCliente={setIdCliente} email={email} ativo={'Sim'} servico={listaNomesPrivates.includes(nome) ? 'Private' : 'Assessoria'} setModalDadosPessoais={setModalDadosPessoais} setModalHistoricoFinanceiro={setModalHistoricoFinanceiro} />
                                                )) : (
                                                <tr>
                                                    <td colSpan={5} style={{ textAlign: "center", }}>
                                                        <p>Nenhum resultado encontrado no momento.</p>
                                                    </td>
                                                </tr>
                                                )
                                            }
                                            </tbody>
                                        </table>
                                    }
                                </>
                            }
                        </fieldset>
                    </div>
                </div>
                <div className="mobileListarUsuarios">
                    <div className="container-mobile-ListarUsuarios">

                        {erro ?
                            <div className="caixa1">
                                <ErroTabela className="formatacaoGrafico" handleClick={() => setReload(!reload)} />
                            </div>
                            :
                            <>
                                {loading ?
                                    <div className="caixa1">
                                        <LoadingButton />
                                    </div>
                                    :
                                    <div className="caixa1">
                                        <fieldset  >

                                            <div className="divBotoesExtratoMobile">
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => voltarSlide("prevArrowSlideListaUsuarios")}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowLeft className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                                <Button
                                                    className="btn__cinza btnMargin"
                                                    onClick={() => proximoSlide('nextArrowSlideListaUsuarios')}
                                                    titleButton={
                                                        <>
                                                            <AiOutlineArrowRight className="iconButtonExtrato" />
                                                        </>
                                                    }
                                                />
                                            </div>
                                            <Slider {...settings}>
                                                {
                                                    listaClientes.length !== 0 ? (
                                                        listaClientes.map(({ idCredenciais, nome }) =>
                                                            <CardListarUsuarios key={idCredenciais} nome={nome} setNomeCliente={setNomeCliente} idCliente={idCredenciais} setIdCliente={setIdCliente} tipo={'Física'} ativo={'Sim'} setModalDadosPessoais={setModalDadosPessoais} setModalHistoricoFinanceiro={setModalHistoricoFinanceiro} />
                                                        )) : (
                                                            <p>Nenhum resultado encontrado no momento.</p>
                                                        )
                                                }
                                            </Slider>
                                        </fieldset>
                                    </div>
                                }
                            </>
                        }
                    </div>

                </div>

            </div>
            {modalDadosPessoais &&
                <ModalDadosPessoaisCliente
                    modal={modalDadosPessoais}
                    setModal={() => setModalDadosPessoais(false)}
                    idCliente={idCliente}
                />
            }
            {modalHistoricoFinanceiro &&
                <ModalHistoricoFinanceiro
                    modal={modalHistoricoFinanceiro}
                    setModal={() => setModalHistoricoFinanceiro(false)}
                    idCliente={idCliente}
                    nomeCliente={nomeCliente}
                />
            }

            {desconectar && <ModalDesconectar modal={desconectar} />}
            <Footer />
        </>
    );
}

export default ListarUsuarios;
