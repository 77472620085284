import React from 'react'
import { BsFillPatchCheckFill } from "react-icons/bs";
import "./styles.css"

interface MensagemSucessoProps {
    mensagem: string,
}

export const MensagemDeSucesso = ({ mensagem }: MensagemSucessoProps) => {
    return (
        <div className='ContainerSucesso'>
            <BsFillPatchCheckFill className="IconSucesso" />
            <h4 className="textoResposta">{mensagem}</h4>
        </div>
    )
}