import React, { useState, useEffect } from "react";
import { TestarSegurancaSenha } from "./functions";

type SegurancaDeSenhaProps = {
  senha: string;
};

const SegurancaDeSenha = ({
  senha
}: SegurancaDeSenhaProps) => {
  const [tamanhoSenha, setTamanhoSenha] = useState(false);
  const [caractereEspecial, setCaracterEspecial] = useState(false);
  const [letraMaiuscula, setLetraMaiuscula] = useState(false);
  const [letraMinuscula, setLetraMinuscula] = useState(false);
  const [numeros, setNumeros] = useState(false);
  const [validacao, setValidacao] = useState(false);

  useEffect(() => {
    TestarSegurancaSenha(senha, setValidacao, setTamanhoSenha, setCaracterEspecial, setLetraMaiuscula, setLetraMinuscula, setNumeros,);
  }, [senha]);


  return (
    <>
      {validacao && senha.length !== 0 && (
        <div style={{ alignItems: "flex-start", paddingBottom: "2%" }}>
          <p style={{ color: "red" }}>
            *Para validar o campo senha é necessário:{" "}
          </p>
          <p style={caractereEspecial ? { color: "green" } : { color: "red" }}>
            - Caracteres especiais;
          </p>
          <p style={numeros ? { color: "green" } : { color: "red" }}>
            - Números;
          </p>
          <p style={letraMaiuscula ? { color: "green" } : { color: "red" }}>
            - Letras maiúsculas;
          </p>
          <p style={letraMinuscula ? { color: "green" } : { color: "red" }}>
            - Letras minúsculas;
          </p>
          <p style={tamanhoSenha ? { color: "green" } : { color: "red" }}>
            - No mínimo 7 caracteres;
          </p>
        </div>
      )}
    </>
  );
};

export default SegurancaDeSenha;
