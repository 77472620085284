import React, { useState } from "react";
import "./ModalRedefinicaoSenha.css";
import { handleFechar, solicitarRedefinicao } from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";

import Modal from "react-bootstrap/Modal";

export interface Mensagem {
  mensagem: string,
  mostrar: boolean,
}

const ModalRedefinicaoSenha = ({ mostrar, fecharModal }: any) => {
  const [email, setEmail] = useState<string>("");
  const [mensagem, setMensagem] = useState<Mensagem>({
    mensagem: "",
    mostrar: false,
  });
  const [sucesso, setSucesso] = useState<boolean>(false);

  return (
    <>
      <Modal show={mostrar} onHide={fecharModal}>
        <form onSubmit={(e) => handleFechar(e, setSucesso, setEmail)}>
          <Modal.Header closeButton>
            <Modal.Title>Alterar senha</Modal.Title>
          </Modal.Header>
          {!sucesso ? (
            <>
              <Modal.Body>
                <div className="modal-body">
                  <h6 className="modal_titulo">
                    Enviaremos para seu e-mail um link para a alteração da
                    senha:
                  </h6>
                  <Input
                    type="email"
                    className="form-control txtValorAporte"
                    label="Digite o e-mail cadastrado:"
                    placeholder=" "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {mensagem.mostrar && (
                    <p className="mensagem_erro">{mensagem.mensagem}</p>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div style={{ flex: 1 }}>
                  <Button
                    classNameButton="btnEnviarEmail"
                    onClick={() => solicitarRedefinicao(setSucesso, email, setMensagem, mensagem)}
                    titleButton="Enviar e-mail"
                  />
                </div>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Body>
                <div className="modal-body">
                  <h6 className="modal_titulo">
                    As Informações para Nova Senha foram enviadas no e-mail
                    informado.
                  </h6>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div style={{ flex: 1 }}>
                  <Button
                    classNameButton="btnEnviarEmail"
                    onClick={(e) => handleFechar(e, setSucesso, setEmail)}
                    titleButton="Concluir"
                    type="submit"
                  />
                </div>
              </Modal.Footer>
            </>
          )}
        </form>
      </Modal>
    </>
  );
};

export default ModalRedefinicaoSenha;
