import React, { useContext } from "react";

import { Button } from "../../Button";
import { MensagemDeErro } from "../../RespostasDosFormularios/MensagemDeErro";
import {
  UsuarioContextType,
  usuarioContext,
} from "../../../context/tipoUsuario";
import {
  removerDadosUsuario,
  removerDadosUsuario2,
  apagarPerfilUsuario,
  apagarTipoUsuario
} from "../../../services/cookies";

import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

interface ModalRespostaDosFormulariosProps {
  modal: boolean;
}

export const ModalDesconectar = ({
  modal,
}: ModalRespostaDosFormulariosProps) => {
  const { atualizarUsuario } = useContext<UsuarioContextType>(usuarioContext);

  return (
    <Modal show={modal} backdrop="static">
      <Modal.Header>
        <Modal.Title>Usuário desconectado.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MensagemDeErro
          mensagem={
            "Você foi desconectado. Faça o login novamente para continuar."
          }
        />
        <Link style={{ textDecoration: "none" }} to="/">
          <Button
            onClick={() => {
              removerDadosUsuario();
              removerDadosUsuario2();
              apagarPerfilUsuario()
              apagarTipoUsuario()
              atualizarUsuario({ autenticado: false, tipo: null });
            }}
            titleButton="OK"
          />
        </Link>
      </Modal.Body>
    </Modal>
  );
};
