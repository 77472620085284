import React, { useState } from "react";

import { handleStatus } from "./functions";

import {
    guardarDadosUsuario,
    guardarDadosUsuario2,
    obterDadosUsuario2,
} from "../../../services/cookies";
import { operacaoFinanceiraAprovacao } from "../../../services/operacaoFinanceira";
import { renovacaoToken } from "../../../services/usuario";
import { Button } from "../../Button";
import { LoadingButton } from "../../loadingButtom";
import { ModalAprovar } from "../../modais/ModalAprovar";
import { ModalComprovante } from '../../modais/ModalComprovante'

import { VscFilePdf } from "react-icons/vsc";
import { AiOutlineCheckCircle, AiOutlineMinusCircle } from "react-icons/ai";

interface TabelaValidacaoAporteProps {
    dados: RespostaOperacaoFinanceira;
    dadosMap: OperacoesFinanceiras;
    IdServicoFinanceiro: number;
    setModal: React.Dispatch<React.SetStateAction<boolean>>;
    setReload: React.Dispatch<React.SetStateAction<boolean>>;
}


interface OperacoesFinanceiras {
    dataSolicitacao: Date;
    valor: number;
    tipo: "Aporte" | "Saque";
    status: "EmEspera" | "Efetuado" | "Rejeitado";
    urlImagemComprovante?: any;
}

interface Conta {
    servicoFinanceiroConta: string;
    operacoesFinanceiras: Array<OperacoesFinanceiras>;
}

interface RespostaOperacaoFinanceira {
    idCredenciais: string;
    nome: string;
    conta: Conta;
}

export const CardValidarAporte = ({
    dados,
    dadosMap,
    IdServicoFinanceiro,
    setModal,
    setReload,
}: TabelaValidacaoAporteProps) => {
    const [loadingAprovar, setLoadingAprovar] = useState<boolean>(false);
    const [erroAprovar, setErroAprovar] = useState<boolean>(false);
    const [modalAprovar, setModalAprovar] = useState<boolean>(false);
    const [modalRejeitar, setModalRejeitar] = useState<boolean>(false);
    const [comprovanteModal, setComprovanteModal] = useState<boolean>(false);



    return (
        <>
            <div className="caixa1 caixaListarUsuarios">
                <fieldset>
                    <div className="FieldsetDivTexto">
                        {dadosMap.urlImagemComprovante && (
                            <VscFilePdf id="pdfIcon" onClick={() => setComprovanteModal(true)} />
                        )}
                        <h5>{dados.nome}</h5>
                        <h6>R$ {dadosMap.valor.toFixed(2)}</h6>
                    </div>
                    <div className="divButton">
                        {loadingAprovar ? (
                            <LoadingButton />
                        ) : (
                            <>
                                <Button
                                    onClick={() => setModalAprovar(true)}
                                    className="btn__verde botaoCircular cardBtn"
                                    titleButton={
                                        <>
                                            <AiOutlineCheckCircle className="iconButtonExtrato" />
                                            <p className="textoBotao">Aprovar</p>
                                        </>
                                    }
                                />
                                <Button
                                    onClick={() => {
                                        /*setModalRejeitar(true),*/
                                        handleStatus(
                                            dados.idCredenciais,
                                            dadosMap.dataSolicitacao,
                                            "Rejeitado",
                                            dadosMap.valor,
                                            dadosMap.tipo,
                                            setLoadingAprovar,
                                            IdServicoFinanceiro,
                                            setReload,
                                            setErroAprovar,
                                            setModal,

                                        );
                                    }}
                                    className="btn__vermelho botaoCircular cardBtn"
                                    titleButton={
                                        <>
                                            <AiOutlineMinusCircle className="iconButtonExtrato" />
                                            <p className="textoBotao">Reprovar</p>
                                        </>
                                    }
                                />
                            </>
                        )}
                        {erroAprovar && (
                            <strong style={{ fontSize: 10, color: "red" }}>
                                Ocorreu um erro não previsto, por favor tente novamente.
                            </strong>
                        )}
                    </div>
                </fieldset>
            </div>
            {modalAprovar && (
                <ModalAprovar
                    modal={modalAprovar}
                    IdServicoFinanceiro={IdServicoFinanceiro}
                    setModal={setModalAprovar}
                    setReload={setReload}
                    setErroAprovar={setErroAprovar}
                    dataSolicitacao={dadosMap.dataSolicitacao}
                    idCredenciaisCliente={dados.idCredenciais}
                    status={"Efetuado"}
                    tipo={dadosMap.tipo}
                    valor={dadosMap.valor}
                    setModalDesconectar={setModal}
                />
            )}
            {comprovanteModal && (
                <ModalComprovante
                    modal={comprovanteModal}
                    setModal={setComprovanteModal}
                    comprovanteBase64={dadosMap.urlImagemComprovante}
                />
            )}
            {/* {modalRejeitar &&
                <ModalRejeitar
                    modal={modalRejeitar}
                    IdServicoFinanceiro={IdServicoFinanceiro}
                    setModal={setModalRejeitar}
                    setReload={setReload}
                    setErroAprovar={setErroAprovar}
                    dataSolicitacao={dadosMap.DataSolicitacao}
                    idCredenciaisCliente={dados.IdCredenciais}
                    status={"Rejeitado"}
                    tipo={dadosMap.Tipo}
                    valor={dadosMap.Valor}
                />} */}
        </>
    );
};
