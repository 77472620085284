import React from "react";

import { renovacaoToken } from '../../../services/usuario'
import { getTodosClienteServicosFinanceiros } from "../../../services/cliente";
import { obterDadosUsuario2, guardarDadosUsuario2 } from '../../../services/cookies'
import { Cliente } from "../ListarUsuarios/functions";

interface obterTodosOsClientesPorServicoFinanceiroProps {
  idServicoFinanceiro: number,
  numeroPagina: number,
  tamanhoPagina: number,
  setLoading: React.Dispatch<boolean>,
  setErro: React.Dispatch<boolean>,
  setListaClientesPrivate: React.Dispatch<React.SetStateAction<Cliente[]>>,
  setDesconectar: React.Dispatch<boolean>
}

export const FunctionObterClientesPrivate = ({
  idServicoFinanceiro,
  numeroPagina,
  tamanhoPagina,
  setLoading,
  setErro,
  setListaClientesPrivate,
  setDesconectar
}: obterTodosOsClientesPorServicoFinanceiroProps) => {
  function obterTodosOsClientesPrivate() {
    setLoading(true)

    const { email, urlAvatar, id, token, tokenRenovacao, } = obterDadosUsuario2()

    getTodosClienteServicosFinanceiros(token, idServicoFinanceiro, numeroPagina, tamanhoPagina)
      .then(res1 => {
        setListaClientesPrivate(res1.data.resultado.clientes)

      })
      .catch(err => {
        if (err.toString() === "Error: Request failed with status code 401") {
          renovacaoToken(token, tokenRenovacao)
            .then(res2 => {
              guardarDadosUsuario2(
                res2.data.camposAdicionais.tokenAcesso,
                res2.data.camposAdicionais.tokenRenovacao,
                email.toString(),
                id.toString(),
                urlAvatar
              )

              getTodosClienteServicosFinanceiros(res2.data.camposAdicionais.tokenAcesso, idServicoFinanceiro, numeroPagina, tamanhoPagina)
                .then(res3 => {
                  setListaClientesPrivate(res3.data.resultado.clientes)

                })
            })
            .catch(() => setDesconectar(true))
        } else {
          setErro(true)
        }
      })
      .finally(() => setLoading(false))
  }

  return {
    obterTodosOsClientesPrivate
  }


}






