import React from "react"
import { salvarContaParaRecebimento } from "../../../services/cliente"
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../../services/cookies"
import { renovacaoToken } from "../../../services/usuario"

interface ContaProps {
    nomeFavorecido?: string;
    tipoDocumento?: string;
    numeroDocumento?: string;
    tipoChavePix?: string;
    chavePix?: string;
    numeroConta?: string;
    agencia?: string;
    nomeBanco?: string;
    codigoBanco?: number;
    apelido?: string
}

export const salvarConta = (setSucesso: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, setLoading: React.Dispatch<boolean>, handleReload: () => void, { agencia, chavePix, codigoBanco, nomeBanco, nomeFavorecido, numeroConta, numeroDocumento, tipoChavePix, tipoDocumento, apelido }: ContaProps) => {
    const { email, id, token, tokenRenovacao } = obterDadosUsuario2()
    setLoading(true)
    salvarContaParaRecebimento(id, token, nomeFavorecido, tipoDocumento, numeroDocumento, tipoChavePix, chavePix, numeroConta, agencia, nomeBanco, codigoBanco, apelido).then(res => {
        setSucesso(true)
    }).catch(err => {
        if (err.toString() === "Error: Request failed with status code 401") {
            renovacaoToken(token, tokenRenovacao).then(res => {
                guardarDadosUsuario2(
                    res.data.camposAdicionais.tokenAcesso,
                    res.data.camposAdicionais.tokenRenovacao,
                    email.toString(),
                    id.toString()
                );
                salvarContaParaRecebimento(id, token, nomeFavorecido, tipoDocumento, numeroDocumento, tipoChavePix, chavePix, numeroConta, agencia, nomeBanco, codigoBanco, apelido).then(res => {
                    setSucesso(true)
                })

            })
                .catch(() => setDesconectar(true))
        } else {
            setErro(true)
        }
    }).finally(() => {
        setLoading(false)
        handleReload()
    })
}

export const handleSalvarConta = (setSucesso: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setErroPix: React.Dispatch<boolean>, setErroDadosBancarios: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, setLoading: React.Dispatch<boolean>, handleReload: () => void, { agencia, chavePix, codigoBanco, nomeBanco, nomeFavorecido, numeroConta, numeroDocumento, tipoChavePix, tipoDocumento, apelido }: ContaProps) => {
    setSucesso(false)
    setErro(false)
    setErroPix(false)
    setErroDadosBancarios(false)

    if ((tipoChavePix.trim() || chavePix.trim()) && (!nomeFavorecido.trim() && !tipoDocumento.trim() && !numeroDocumento.trim() && !numeroConta.trim() && !agencia.trim() && !nomeBanco.trim() && !codigoBanco)) {
        if (!tipoChavePix.trim() || !chavePix.trim() || !apelido.trim()) {
            setErroPix(true)
        } else {
            salvarConta(setSucesso, setErro, setDesconectar, setLoading, handleReload, { tipoChavePix, chavePix, nomeFavorecido, tipoDocumento, numeroDocumento, numeroConta, agencia, nomeBanco, codigoBanco, apelido })
        }
    } else if ((!tipoChavePix.trim() && !chavePix.trim()) && (nomeFavorecido.trim() || tipoDocumento.trim() || numeroDocumento.trim() || numeroConta.trim() || agencia.trim() || nomeBanco.trim() || codigoBanco)) {
        if (!nomeFavorecido.trim() || !tipoDocumento.trim() || !numeroDocumento.trim() || !numeroConta.trim() || !agencia.trim() || !nomeBanco.trim() || !codigoBanco || !apelido.trim()) {
            setErroDadosBancarios(true)
        } else {
            salvarConta(setSucesso, setErro, setDesconectar, setLoading, handleReload, { tipoChavePix, chavePix, nomeFavorecido, tipoDocumento, numeroDocumento, numeroConta, agencia, nomeBanco, codigoBanco, apelido })
        }

    }

}

export const handleClose = (setModal: React.Dispatch<boolean>, setSucesso: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setErroPix: React.Dispatch<boolean>, setErroDadosBacarios: React.Dispatch<boolean>, setNomeFavorecido: React.Dispatch<string>, setTipoDocumento: React.Dispatch<string>, setNumeroDocumento: React.Dispatch<string>, setNumeroConta: React.Dispatch<string>, setAgencia: React.Dispatch<string>, setNomeBanco: React.Dispatch<string>, setCodigoBanco: React.Dispatch<number>, setChavePix: React.Dispatch<string>, setTipoChavePix: React.Dispatch<string>) => {
    setModal(false)
    setSucesso(false)
    setErro(false)
    setErroPix(false)
    setErroDadosBacarios(false)
    setChavePix('')
    setNomeFavorecido('')
    setTipoDocumento('')
    setNumeroDocumento('')
    setNumeroConta('')
    setAgencia('')
    setNomeBanco('')
    setCodigoBanco(0)
    setTipoChavePix('')
}