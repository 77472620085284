import React, { useEffect, useState } from "react";
import "./styles.css";
import { handleConfirm, limparCampos } from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { LoadingButton } from "../../loadingButtom";
import { MensagemDeSucesso } from "../../RespostasDosFormularios/Sucesso";
import { renovacaoToken } from "../../../services/usuario";
import {
  obterDadosUsuario2,
  guardarDadosUsuario,
} from "../../../services/cookies";
import { ModalDesconectar } from "../ModalDesconectar";
import { Erro } from "../../../pages/Cliente/AlteracaoSenha/AlteracaoSenha";

import Modal from "react-bootstrap/Modal";

interface modalProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
  tipo: string;
  labelNome?: string;
  labelDescricao?: string;
  reload: () => void;
  id?: number;
  ativo: boolean;
}

export interface DadosUsuario {
  token?: string;
  tokenRenovacao?: string;
  email: string;
  id: string;
}

export interface Sucesso {
  sucesso: boolean,
  mensagem: string,
}

export const ModalNovoServicoFinanceiro = ({
  modal,
  setModal,
  tipo,
  labelDescricao = "Descrição:",
  labelNome = "Nome:",
  reload,
  id,
  ativo,
}: modalProps) => {
  const [nome, setNome] = useState<string>("");
  const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
  const [descricao, setDescricao] = useState<string>("");
  const [estado, setEstado] = useState<boolean>(ativo);
  const [sucesso, setSucesso] = useState<Sucesso>({ sucesso: false, mensagem: "" });
  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<Erro>({ erro: false, mensagem: "" });
  const [dadosUsuario, setDadosUsuario] = useState<DadosUsuario>({
    id: "",
    email: "",
  });

  return !modalDesconectar ? (
    <Modal
      show={modal}
      backdrop="static"
      onHide={() => {
        limparCampos(setNome, setDescricao);
        setSucesso({ ...sucesso, sucesso: false });
        setModal(false);
      }}
    >
      <form onSubmit={(e) => handleConfirm(e, tipo, setErro, erro, id, nome, labelNome, descricao, labelDescricao, setLoading, estado, setNome, setDescricao, setSucesso, sucesso, reload, setModalDesconectar, dadosUsuario)}>
        <Modal.Header closeButton>
          <Modal.Title>Serviço Financeiro</Modal.Title>
        </Modal.Header>
        {!sucesso.sucesso ? (
          <>
            <Modal.Body>
              <Input
                label={tipo === "criar" ? "Nome:" : labelNome}
                placeholder=" "
                id={"nome"}
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
              />
              <Input
                label={tipo === "criar" ? "Descrição:" : labelDescricao}
                placeholder=" "
                id={"descricao"}
                value={descricao}
                onChange={(e) => {
                  setDescricao(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "enter") { }
                }}
              />
              <select
                className="selectEstadoCivil"
                placeholder={estado ? "Ativo" : "Inativo"}
                value={estado ? "Ativo" : "Inativo"}
                onChange={(e) => {
                  e.target.value === "Ativo"
                    ? setEstado(true)
                    : setEstado(false);
                }}
              >
                <option className="Estado" value={"Ativo"}>
                  Ativo
                </option>
                <option className="Estado" value={"Inativo"}>
                  Inativo
                </option>
              </select>
              {erro.erro && <p>{erro.mensagem}</p>}
            </Modal.Body>
            <Modal.Footer>
              {!loading ? (
                <>
                  <Button
                    classNameButton="btnConfirmar"
                    onClick={(e) => handleConfirm(e, tipo, setErro, erro, id, nome, labelNome, descricao, labelDescricao, setLoading, estado, setNome, setDescricao, setSucesso, sucesso, reload, setModalDesconectar, dadosUsuario)}
                    titleButton="Confirmar"
                    type="submit"
                  />
                  <Button
                    classNameButton="btnCancelar"
                    onClick={() => {
                      setSucesso({ ...sucesso, sucesso: false });
                      setModal(false);
                    }}
                    titleButton="Fechar"
                  />
                </>
              ) : (
                <div className="load_container">
                  <LoadingButton />
                </div>
              )}
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <MensagemDeSucesso mensagem={sucesso.mensagem} />
            </Modal.Body>
            <Modal.Footer>
              {!loading ? (
                <Button
                  classNameButton="btnCancelar"
                  onClick={() => {
                    limparCampos(setNome, setDescricao);
                    setSucesso({ ...sucesso, sucesso: false });
                    setModal(false);
                  }}
                  titleButton="Fechar"
                />
              ) : (
                <div className="load_container">
                  <LoadingButton />
                </div>
              )}
            </Modal.Footer>
          </>
        )}
      </form>
    </Modal>
  ) : (
    <ModalDesconectar modal={modalDesconectar} />
  );
};
