import { usuarioServicoFinanceiroTipo } from "../context/servicoFinanceiroCliente";

export const pegarCookie = (nome_cookie: string) => {
  // Adiciona o sinal de = na frente do nome do cookie
  let cookieName = " " + nome_cookie + "=";

  // Obtém todos os cookies do documento
  let cookies = document.cookie;

  // Verifica se seu cookie existe
  if (cookies.indexOf(cookieName) === -1) {
    return false;
  }

  // Remove a parte que não interessa dos cookies
  cookies = cookies.slice(cookies.indexOf(cookieName), cookies.length + 1);

  // Obtém o valor do cookie até o ;
  if (cookies.indexOf(";") !== -1) {
    cookies = cookies.slice(0, cookies.indexOf(";") + 1);
  }

  // Remove o nome do cookie e o sinal de =
  cookies = cookies.split("=")[1];

  // Retorna apenas o valor do cookie
  return decodeURI(cookies.replace(";", ""));
};

export const guardarDadosUsuario = (
  token: string,
  tokenRenovacao: string,
  email: string,
  id: string,
  urlAvatar?: string
) => {


  removerDadosUsuario();

  let time = new Date();
  let dataExpiracao = new Date();
  dataExpiracao.setDate(time.getDate() + 30);
  document.cookie = `token=${token}; Expires=${dataExpiracao}; path=/`;
  document.cookie = `tokenRenovacao=${tokenRenovacao}; Expires=${dataExpiracao}; path=/`;
  document.cookie = `emailUsuario=${email}; Expires=${dataExpiracao}; path=/`;
  document.cookie = `id=${id}; Expires=${dataExpiracao}; path=/`;
  document.cookie = `urlAvatar=${urlAvatar}; Expires=${dataExpiracao}; path=/`;
};

export const pegarDadosUsuario = () => ({
  token: pegarCookie("token"),
  tokenRenovacao: pegarCookie("tokenRenovacao"),
  email: pegarCookie("emailUsuario"),
  id: pegarCookie("id"),
  urlAvatar: pegarCookie('urlAvatar')
});

export const removerDadosUsuario = () => {
  let data = new Date(2010, 0, 0o1);
  document.cookie = `token=; expires=${data}; path=/`;
  document.cookie = `tokenRenovacao=; expires=${data}; path=/`;
  document.cookie = `emailUsuario=; expires=${data}; path=/`;
  document.cookie = `id=; expires=${data}; path=/`;
  document.cookie = `urlAvatar=; expires=${data}; path=/`;
};

export const guardarDadosUsuario2 = async (
  token: string,
  tokenRenovacao: string,
  email: string,
  id: string,
  urlAvatar?: string
) => {
  token && localStorage.setItem("token", token);
  tokenRenovacao && localStorage.setItem("tokenRenovacao", tokenRenovacao);
  email && localStorage.setItem("email", email);
  id && localStorage.setItem("id", id);
  urlAvatar && localStorage.setItem("urlAvatar", urlAvatar);
};

export const removerDadosUsuario2 = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenRenovacao");
  localStorage.removeItem("email");
  localStorage.removeItem("id");
  localStorage.removeItem("urlAvatar");
  localStorage.removeItem("UsuarioServicoFinanceiro");
};

export const obterDadosUsuario2 = () => ({
  token: localStorage.getItem("token"),
  tokenRenovacao: localStorage.getItem("tokenRenovacao"),
  email: localStorage.getItem("email"),
  id: localStorage.getItem("id"),
  urlAvatar: localStorage.getItem("urlAvatar"),
});

export const salvarPerfilUsuario = (tipoUsuario: string) => {
  localStorage.setItem("tipoUsuario", tipoUsuario.toString());
}

export const obterPerfilUsuario = () => {
  const perfis = localStorage.getItem("tipoUsuario")

  // return perfis.split(',')
  return perfis;
}

export const apagarPerfilUsuario = () => localStorage.removeItem("tipoUsuario")

//PF pu PJ
export const salvarTipoUsuario = (tipoUsuario: string) => {
  localStorage.setItem("UsuarioTipo", tipoUsuario.toString());
}

export const obterTipoUsuario = () => {
  const perfis = localStorage.getItem("UsuarioTipo")

  return perfis;
}

export const apagarTipoUsuario = () => localStorage.removeItem("UsuarioTipo")

export const salvarServiçosFinanceiros = (servicoFinanceiro: usuarioServicoFinanceiroTipo[]) => {
  localStorage.setItem("UsuarioServicoFinanceiro", JSON.stringify(servicoFinanceiro));
}

export const obterServiçosFinanceiros = () => {
  const servicoFinanceiro = JSON.parse(localStorage.getItem("UsuarioServicoFinanceiro"))

  return servicoFinanceiro;
}

export const apagarServiçosFinanceiros = () => localStorage.removeItem("UsuarioServicoFinanceiro")





