import React from 'react'
interface SelectEstadoCivilProps {
    setEstadoCivil: React.Dispatch<string>
}
export const SelectEstadoCivil = ({ setEstadoCivil }: SelectEstadoCivilProps) => {
    return (
        <select className="selectEstadoCivil" placeholder="Estado civil" onChange={(e) => setEstadoCivil(e.target.value)} >
            <option className="Estado" value="" selected disabled>Estado Civil</option>
            <option className="Estado" value="Solteiro">Solteiro</option>
            <option className="Estado" value="Casado">Casado</option>
            <option className="Estado" value="Divorciado">Divorciado</option>
            <option className="Estado" value="Separado">Separado</option>
            <option className="Estado" value="Viúvo">Viúvo</option>
        </select>
    )
}