import React, { useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TooltipOptions,
    TooltipItem,
} from 'chart.js';

import "./styles.css";
import AnimateHeight from "react-animate-height";
import { BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import { _DeepPartialObject } from "chart.js/types/utils";
import { MensagemGraficoVazio } from "../MensagemGraficoVazio";

interface GraficoLinhaProps {
    tituloGrafico: string,
    labels: string[],
    nomeDado: string,
    corBordaDado?: string,
    corBackgroundDado?: string,
    dadosGrafico: number[],
    setIndexData?: React.Dispatch<React.SetStateAction<number>>,
    tituloTooltip?: string,
    dadoTooltip?: string,
    alturaGrafico?: string,
    graficoPorcentagem?: boolean
}

export const GraficoLinha = ({
    tituloGrafico,
    labels,
    nomeDado,
    corBordaDado,
    corBackgroundDado,
    dadosGrafico,
    setIndexData,
    tituloTooltip,
    dadoTooltip,
    alturaGrafico,
    graficoPorcentagem
}: GraficoLinhaProps) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const [isFullScreen, setIsFullScreen] = useState(false);
    const divRef = useRef(null);

    useEffect(() => {
        if (divRef.current) {
            const canvas = divRef.current.querySelector('canvas');
            
            if (canvas) {
                canvas.style.marginTop = isFullScreen ? '30px' : '0px';
            }
        }

    }, [isFullScreen]);

    const dados = {
        labels,
        datasets: [
            {
                label: nomeDado,
                data: dadosGrafico,
                borderColor: corBordaDado ? corBordaDado : 'rgb(0, 0, 0, 0.5)',
                backgroundColor: corBackgroundDado ? corBackgroundDado : 'rgba(0, 0, 0, 0.8)',
            },
        ],
    };

    const tooltip: _DeepPartialObject<TooltipOptions<"line">> = {
        enabled: true,
        mode: "point",
        callbacks: {
            title: () => {
                return tituloTooltip
            },

            label: function (data: TooltipItem<"line">) {
                var multistringText = [];
                setIndexData(data.dataIndex)
                multistringText.push(dadoTooltip);

                return multistringText;
            }
        }
    }

    const [dashboardAberta, setDashboardAberta] = useState(true);

    return (
        <div className="section">
            <div
                className="section-header"
                onClick={() => setDashboardAberta(!dashboardAberta)}
            >
                <h3>{tituloGrafico}</h3>
                <span>
                    {dashboardAberta ? (
                        <BsCaretUpFill className="arrowUp" />
                    ) : (
                        <BsCaretDownFill className="arrowDown" />
                    )}
                </span>
            </div>
            <AnimateHeight
                duration={500}
                height={dashboardAberta ? 'auto' : 0}
            >
                <button className="fullscreen-button" onClick={() => setIsFullScreen(!isFullScreen)}>Tela Cheia</button>

                {dadosGrafico.length ?
                    <div ref={divRef} className={`divDashboard ${isFullScreen ? 'fullscreen' : ''}`}>
                        {isFullScreen && <button className="fullscreen-button in-fullscreen-button" onClick={() => setIsFullScreen(false)}>Fechar</button>}
                        <Line 
                            options={{
                            scales: {
                                y: {
                                    ticks: {
                                        callback: function (value: any) {
                                            return graficoPorcentagem ? value + " %" : value;
                                        }
                                    }
                                },
                            },
                            responsive: true,
                            plugins: {
                                legend: { position: 'top' },
                                tooltip: tooltip,
                                title: {
                                    display: false,

                                    font: {
                                        size: 18,
                                    },
                                    text: tituloGrafico,
                                },
                            },
                        }}
                            data={dados} />
                        
                    </div>
                    :
                    <MensagemGraficoVazio />
                }
            </AnimateHeight>
        </div>
    );
};