import React, { useContext, useState } from "react";
import { enviarsolicitacaoSaque, handleHide } from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { SelectContaBancaria } from "../../select/SelectContaBancaria";
import { MensagemDeErro } from "../../RespostasDosFormularios/MensagemDeErro";
import { MensagemDeSucesso } from "../../RespostasDosFormularios/Sucesso";
import { ModalDesconectar } from "../ModalDesconectar";
import { LoadingButton } from "../../loadingButtom";
import { DadosUsuario } from "../ModalNovoServicoFinanceiro";

import Modal from "react-bootstrap/Modal";
import { transformarValorParaBRLEmInput } from "../../../functions/MascarasMonetarias";
import { ServicoFinanceiroClienteContext, usuarioServicoFinanceiroContextType } from "../../../context/servicoFinanceiroCliente";

interface modalProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    dadosUsuario: DadosUsuario;
}

export const ModalSaque = ({ modal, setModal, dadosUsuario }: modalProps) => {
    const [valorSaque, setValorSaque] = useState<number>(0);
    const [sucesso, setSucesso] = useState<boolean>(false);
    const [falha, setFalha] = useState<boolean>(false);
    const [mensagem, setMensagem] = useState<string>("");
    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [valorSaqueMask, setValorSaqueMask] = useState<string>("");

    const { usuarioServicoFinanceiro } = useContext<usuarioServicoFinanceiroContextType>(ServicoFinanceiroClienteContext);

    return !modalDesconectar ? (
        <Modal show={modal} onHide={() => handleHide(setSucesso, setFalha, setValorSaque, setModal, setValorSaqueMask)}>
            <form onSubmit={(e) => enviarsolicitacaoSaque(e, setLoading, dadosUsuario, valorSaque, setMensagem, setSucesso, setFalha, setValorSaque, setModalDesconectar, setValorSaqueMask, usuarioServicoFinanceiro)}>
                <Modal.Header closeButton>
                    <Modal.Title>Saque</Modal.Title>
                </Modal.Header>
                {falha && <MensagemDeErro mensagem={mensagem} />}
                {sucesso ? (
                    <MensagemDeSucesso mensagem={mensagem} />
                ) : (
                    <>
                        <Modal.Body>
                            <Input
                                label="Valor do saque:"
                                maxLength={13}
                                placeholder=" "
                                id={"valor"}
                                value={usuarioServicoFinanceiro.idServicoFinanceiro === 3 ? "$ " + valorSaqueMask : "R$ " + valorSaqueMask}
                                onChange={(e) => {
                                    setValorSaqueMask(transformarValorParaBRLEmInput(e.target.value, setValorSaqueMask))
                                    setValorSaque(Number(transformarValorParaBRLEmInput(e.target.value, setValorSaqueMask).replace(/\./g, '').replace(/,/g, '.')));
                                }}
                            />
                            {/*<SelectContaBancaria />*/}
                        </Modal.Body>
                        <Modal.Footer>
                            {loading ? (
                                <LoadingButton />
                            ) : (
                                <Button
                                    classNameButton="btnAlterar"
                                    onClick={(e) => enviarsolicitacaoSaque(e, setLoading, dadosUsuario, valorSaque, setMensagem, setSucesso, setFalha, setValorSaque, setModalDesconectar, setValorSaqueMask, usuarioServicoFinanceiro)}
                                    titleButton="Confirmar"
                                    type="submit"
                                />
                            )}
                            <Button
                                classNameButton="btnCancelar"
                                onClick={() => setModal(false)}
                                titleButton="Cancelar"
                            />
                        </Modal.Footer>
                    </>
                )}
            </form>
        </Modal>
    ) : (
        <ModalDesconectar modal={modalDesconectar} />
    );
};
