import { redefinirSenha, solicitarRedefinicaoDeSenha } from "../../services/usuario";
import { Erro } from "../Cliente/AlteracaoSenha/AlteracaoSenha";

export const handleRedefinir = (
    e: React.FormEvent,
    setErro: (value: React.SetStateAction<Erro>) => void,
    erro: Erro,
    novaSenha: string,
    confirmacaoSenha: string,
    erroForcaSenha: boolean,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    email: string,
    sender: string,
    token: string,
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setFalha: (value: React.SetStateAction<boolean>) => void,

) => {
    e.preventDefault();
    setErro({ ...erro, erro: false });
    if (!(novaSenha.trim() && confirmacaoSenha.trim())) {
        setErro({
            ...erro,
            erro: true,
            mensagem: "Todos os campos devem ser preenchidos.",
        });
    } else if (!(novaSenha === confirmacaoSenha)) {
        setErro({
            ...erro,
            erro: true,
            mensagem: "Senha e confirmação de senha devem ser iguais.",
        });
    } else if (erroForcaSenha) {
        setErro({
            ...erro,
            ...erro,
            erro: true,
        });
    } else {
        setLoading(true);
        redefinirSenha(sender, email, token, novaSenha)
            .then((res) => {
                setSucesso(true);
            })
            .catch((error) => {
                setFalha(true);
            })
            .finally(() => setLoading(false));
    }
};

export const handleSolicitar = (
    setLoading: (value: React.SetStateAction<boolean>) => void,
    setSucessoSolicitacao: (value: React.SetStateAction<boolean>) => void,
    setFalhaSolicitacao: (value: React.SetStateAction<boolean>) => void,
    sender: string,
    email: string
) => {
    setLoading(true);
    solicitarRedefinicaoDeSenha(email,sender)
        .then((res) => {
            setSucessoSolicitacao(true);
        })
        .catch((err) => {
            setFalhaSolicitacao(true);
        })
        .finally(() => setLoading(false));
};