import React, { useEffect, useState } from 'react'

import './styles.css'

import { CardFormaDePagamento } from '../Cards/CardFormaDePagamento';
import { Button } from '../Button';
import { LoadingButton } from '../loadingButtom';

import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import AnimateHeight from 'react-animate-height';
import { ErroTabela } from '../Erros/ErroTabela';
import { AiOutlineReload } from 'react-icons/ai';
import { pegarFormasDePagamento } from './function';
import { ModalDesconectar } from '../modais/ModalDesconectar';


interface DadosBancarios {
    setModalNovaFormaDePagamento: React.Dispatch<boolean>;
    reload: boolean
    handleReload: () => void,
}

export const DadosBancarios = ({ setModalNovaFormaDePagamento, reload, handleReload }: DadosBancarios) => {
    const [dadosBancariosAberto, setDadosBancariosAberto] = useState(false);
    const [mostrarMensagemDeNumeroMaximoDeDadosBancarios, setMostrarMensagemDeNumeroMaximoDeDadosBancarios] = useState(false);
    const [listaDeDadosBancarios, setListaDeDadosBancarios] = useState<any[]>([])
    const [desconectar, setDesconectar] = useState<boolean>(false)
    const [erroDadosBancarios, setErroDadosBancarios] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)


    useEffect(() => {
        dadosBancariosAberto && pegarFormasDePagamento(setListaDeDadosBancarios, setErroDadosBancarios, setDesconectar, setLoading)
    }, [reload])

    useEffect(() => {
        dadosBancariosAberto && handleReload()

    }, [dadosBancariosAberto])

    return (
        <div>
            <div className="section ">
                <div
                    className="section-header"
                    onClick={() => setDadosBancariosAberto(!dadosBancariosAberto)}
                >
                    <h3>Dados Bancários</h3>
                    <span>
                        {dadosBancariosAberto ? (
                            <BsCaretUpFill className="arrowUp" />
                        ) : (
                            <BsCaretDownFill className="arrowDown" />
                        )}
                    </span>

                </div>

                <AnimateHeight
                    duration={500}
                    height={dadosBancariosAberto ? 'auto' : 0}
                >
                    {loading ?
                        <div className='caixa-loading-DadosBancarios'>
                            <LoadingButton />
                        </div> :
                        <>
                            {
                                erroDadosBancarios ? <ErroTabela className="formatacaoGrafico" titleButton={<>
                                    <AiOutlineReload className="iconButtonDashboard" />
                                </>} handleClick={handleReload} /> : <>
                                    {listaDeDadosBancarios.map((dado => <CardFormaDePagamento key={dado.chavePix ? dado.chavePix + dado.tipoChavePix : dado.nomeBanco + dado.agencia} {...dado} />))}
                                    <Button titleButton="Novo" onClick={() => {
                                        setMostrarMensagemDeNumeroMaximoDeDadosBancarios(false)
                                        if (listaDeDadosBancarios.length >= 82) {
                                            setMostrarMensagemDeNumeroMaximoDeDadosBancarios(true)
                                        } else {

                                            setModalNovaFormaDePagamento(true)
                                        }
                                    }} />
                                </>
                            }
                            {mostrarMensagemDeNumeroMaximoDeDadosBancarios && <p className='mensagem-erro-DadosBancarios'>Número máximo de dados bancários atingido.</p>}

                        </>}
                </AnimateHeight>

            </div>
            {desconectar && <ModalDesconectar modal={desconectar} />}
        </div>
    )
}