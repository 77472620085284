import React, { useState } from "react";
import './ModalComprovante.css';

import Modal from "react-bootstrap/Modal";
import { Button } from "../../Button";

interface modalProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    comprovanteBase64: string;
}
export const ModalComprovante = ({
    modal,
    setModal,
    comprovanteBase64,
}: modalProps) => {

    const [tamanhoImagem, setTamanhoImagem] = useState<boolean>(false)

    return (
        <Modal show={modal} onHide={() => setModal(false)} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Comprovante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*{comprovanteBase64.indexOf('application/pdf') == -1 &&
                <Button
                    className="btn__laranja"
                    titleButton={"Ver Tamanho Original"}
                    onClick={() => setTamanhoImagem(!tamanhoImagem)}
                />}*/}
                {comprovanteBase64.indexOf('application/pdf') == -1 &&
                    <p>Clique na imagem para ver o tamanho original:</p>}
                <div className="imagemModalComprovante">
                    {comprovanteBase64.indexOf('application/pdf') > 0 ?
                        <iframe
                            src={comprovanteBase64}
                            className='pdfComprovante'
                        >
                        </iframe>
                        : <img
                            src={comprovanteBase64}
                            className={!tamanhoImagem ? 'imagemPequena' : 'imagemGrande'}
                            onClick={() => setTamanhoImagem(!tamanhoImagem)}
                        />
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
};
