import React from "react";

import './styles.css'

import { BsFillPatchCheckFill } from "react-icons/bs";
import { Button } from "../../Button";

interface SucessoProps {
    handleClose: () => void
}

export const Sucesso = ({ handleClose }: SucessoProps) => {
    return <>
        <div className="container-sucesso-ModalNovaFormaDePagamento">
            <BsFillPatchCheckFill className="icon-ModalNovaFormaDePagamento" />
            <p className="title-ModalNovaFormaDePagamento" >Dados bancários salvos com sucesso.</p>
        </div>
        <div>
            <Button titleButton={"Fechar"} onClick={handleClose} />
        </div>
    </>
}