import React from "react";
import LegalNavbar from "../../../components/LegalNavbar/LegalNavbar";
import Footer from "../../../components/Footer/Footer";
import { Header } from "../../../components/Header";
import './Fees.css';

const Fees = () => {

    return (
        <>
            <LegalNavbar />
            <div className="container">
                <Header titulo="Fees" />
                <div className="caixa1">
                    <fieldset>
                        <h1>Fees</h1>
                        <h4 style={{ marginLeft: '1vw' }}>Spot</h4>
                        <h6 style={{ marginLeft: '1vw', marginTop: '20px' }}>Crypto</h6>
                        <h6 style={{ marginLeft: '1vw', marginBottom: '20px' }}>Fees</h6>
                        <p>BTC ETH LTC OKB OKT BCH BSV ETC EOS TRX XRP AKITA ALCX
                        ALV ANC ANW APIX APM BABYDOGE BCX BHP BLOC BLOK BZZ
                        CEL CELT CFG CGS CHE CNTM CONV COVER CQT CTC CVT CVX
                        DAO DEP DEVT DMD DMG DNA DORA DOSE EC EFI ELON ETM EXE
                        FODL GF GM GODS GUSD HDAO HYC INT INX JFI KAR KINE KISHU
                        KOL KONO LEASH LEO LITH LMCH LON LOON MCO MEME MOF
                        MON MXT NDN OMI ORBS ORS PEOPLE PICKLE PLG PMA PNK
                        POLYDOGE PPT PROPS PRQ PST RACA REVV RFUEL RIO RNT
                        ROAD SAMO SBTC SFG SPELL STARL STRK TAI TMTG TONCOIN
                        TOWN TRA TRADE TRUE TUP UBTC UST VELO VRA WGRT WIN
                    WNCG WOO XPO XPR XSR XUC YOU ZYRO</p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.20%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.25%</h6>

                        <p>USDT 1INCH AAC AAVE ABT ACT ADA AE AERGO AGLD ALGO
                        ALPHA ANT APE API3 ARDR ARK AST ATOM AUCTION AVAX AXS
                        BADGER BAL BAND BAT BCD BETH BICO BNT BORING BOX BTG
                        BTM BTT CELO CELR CFX CHAT CHZ CLV CMT COMP CRO CRV
                        CSPR CTXC CVC CVP DAI DASH DCR DGB DHT DIA DOGE DOT
                        DYDX EDEN EGLD EGT ELF EM ENJ ENS ERN FAIR FIL FLM FLOW
                        FORTH FRONT FSN FTM FUN GAL GALA GAS GHST GLM GNX GRT
                        GTO HBAR HC HEGIC ICP ICX ILV IMX IOST IOTA IQ ITC JST KAN
                        KCASH KLAY KNC KP3R KSM LAMB LAT LBA LET LINK LPT LRC LSK
                        LUNA MANA MASK MATIC MDA MDT MINA MIR MITH MKR MLN
                        MOVR MXC NANO NAS NEAR NEO NFT NMR NU NULS OM OMG
                        ONT OXT PAX PAY PERP PHA POLS QTUM REN REP RSR RVN
                        SAND SC SHIB SKL SLP SNC SNT SNX SOC SOL SRM STORJ STX
                        SUN SUSHI SWFTC SWRV TCT THETA TOPC TORN TRB TRIO TUSD
                        UMA UNI USDC UTK VALUE VIB VITE VSYS WAVES WBTC WING
                        WNXM WTC WXT XCH XEC XEM XLM XMR XTZ YEE YFI YFII YGG
                    YOYO ZEC ZEN ZIL ZKS ZRX</p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.20%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.25%</h6>

                        <h4 style={{ marginLeft: '1vw' }}>Futures</h4>
                        <h6 style={{ marginLeft: '1vw', marginTop: '20px' }}>Crypto</h6>
                        <h6 style={{ marginLeft: '1vw', marginBottom: '20px' }}>Fees</h6>
                        <p>BTC ETH LTC BCH BSV ETC EOS TRX XRP</p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.10%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.20%</h6>
                        <p>ADA DOT FIL LINK</p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.10%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.20%</h6>

                        <h4 style={{ marginLeft: '1vw' }}>Perpetual Swaps</h4>
                        <h6 style={{ marginLeft: '1vw', marginTop: '20px' }}>Crypto</h6>
                        <h6 style={{ marginLeft: '1vw', marginBottom: '20px' }}>Fees</h6>
                        <p>BTC ETH LTC BCH BSV ETC EOS TRX XRP</p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.10%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.20%</h6>

                        <p>1INCH AAVE ADA AGLD ALGO ALPHA ANT APE ATOM AVAX AXS
                        BADGER BAL BAND BAT BTM BTT CELO CHZ COMP CRV CSPR CVC
                        DASH DOGE DOT DYDX EGLD ENJ FIL FLM FTM GALA GRT ICP IMX
                        IOST IOTA JST KNC KSM LAT LINK LPT LRC LUNA MANA MASK
                        MATIC MINA MIR MKR NEAR NEO OMG ONT QTUM REN RSR RVN
                        SAND SC SHIB SNX SOL SRM STORJ SUN SUSHI THETA TRB UMA
                        UNI WAVES XCH XEM XLM XMR XTZ YFI YFII YGG ZEC ZEN ZIL ZRX                    </p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.1%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.2%</h6>

                        <p>ANC BABYDOGE BICO BNT BZZ CFX CONV CQT CRO DORA EFI
                        ELON ENS GODS KISHU LON NFT PEOPLE PERP SLP SOS STARL
                        SWRV TONCOIN TORN WNCG WNXM                    </p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.1%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.2%</h6>

                        <h4 style={{ marginLeft: '1vw' }}>Options</h4>
                        <h6 style={{ marginLeft: '1vw', marginTop: '20px' }}>Crypto</h6>
                        <h6 style={{ marginLeft: '1vw', marginBottom: '20px' }}>Fees</h6>
                        <p>BTC ETH EOS</p>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px' }}>Maker Fee: 0.1%</h6>
                        <h6 style={{ backgroundColor: '#f7f583', width: '150px', marginBottom: '40px' }}>Taker Fee: 0.2%</h6>
                    </fieldset>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Fees;