import React, { useEffect, useState, useContext } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, PluginOptionsByType } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Header } from "../../../components/Header";
import { _DeepPartialObject } from "chart.js/types/utils";
import { exibirAtivos, randomColor } from "./functions";
import { Loading } from "../../../components/Loading";
import { ErroTabela } from "../../../components/Erros/ErroTabela";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { usuarioServicoFinanceiroContextType, ServicoFinanceiroClienteContext } from "../../../context/servicoFinanceiroCliente";
import { GraficoPizza } from "../../../components/Graficos/GraficoPizza";

ChartJS.register(ArcElement, Tooltip, Legend);

export interface ativoFinanceiro {
    nome: string,
    sigla: string,
    origem: string,
    quantidade: number,
    valorUnitario: number,
    porcentagemDoAtivoNaCarteira: number,
    valorTotal: number
}

export const Ativos = () => {

    const [colorArray, setColorArray] = useState<Array<string>>([]);
    const [labels, setLabels] = useState<Array<string>>([]);
    const [porcentagem, setPorcentagem] = useState<Array<number>>([]);
    const [valorTotal, setValorTotal] = useState<Array<number>>([]);
    const [valorUnitario, setValorUnitario] = useState<Array<number>>([]);

    const { reloadServicoFinanceiro, usuarioServicoFinanceiro } = useContext<usuarioServicoFinanceiroContextType>(ServicoFinanceiroClienteContext);

    const [reloadPage, setReloadPage] = useState<boolean>(true);
    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [erroPage, setErroPage] = useState<boolean>(false);

    useEffect(() => {
        exibirAtivos(setLabels, setPorcentagem, setValorUnitario, setValorTotal, setLoading, setErroPage, setModalDesconectar, colorArray, setColorArray, usuarioServicoFinanceiro);
    }, [reloadPage, reloadServicoFinanceiro]);

    const plugins: _DeepPartialObject<PluginOptionsByType<"pie">> = {
        tooltip: {
            enabled: true,
            mode: "point",
            callbacks: {
                title: (data) => {
                    return data.map((data2) => {
                        return data2.label
                    })
                },
                label: function (data) {
                    var multistringText = ["Porcentagem do ativo na carteira: " + porcentagem[data.dataIndex].toFixed(2) + "%"];
                    return multistringText;
                }
            }
        },
    };

    return (
        <>
            <Navbar />
            {loading && <Loading />}
            <div id="container">
                {erroPage ? <ErroTabela handleClick={() => setReloadPage(!reloadPage)} className={"formatacaoGrafico"} />
                    : <>
                        <Header titulo={'Ativos'} />
                        <div className="caixa1">
                            <fieldset>
                                <GraficoPizza colorArray={colorArray} labels={labels} porcentagens={porcentagem} plugins={plugins} tituloGrafico={"Composição da Carteira"} subTituloGrafico={"(Moedas com menos de 1% de participação foram ocultadas)"} />
                            </fieldset>
                        </div>
                    </>
                }

                <div className="caixa1">
                    <fieldset>
                        <div className="col small-12">
                            <div className="col-inner pb">
                                <h4>Evolução do ativo Dólar (USDT)</h4>
                                <div className="tradingview-widget-container">
                                    <div id="tradingview_610d4cbbbbb50">
                                        <div
                                            id="tradingview_baf3f-wrapper"
                                            style={{ position: "relative", boxSizing: "content-box", width: "100%", height: "350px", margin: "0 auto !important", padding: "0 !important", fontFamily: "Arial,sans-serif" }}
                                        >
                                            <div
                                                style={{ width: "100%", height: "350px", background: "transparent", padding: "0 !important" }}>
                                                <iframe
                                                    id="tradingview_baf3f"
                                                    src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_baf3f&amp;symbol=BINANCE%3AUSDTBRL&amp;interval=D&amp;saveimage=0&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;theme=light&amp;style=1&amp;timezone=America%2FSao_Paulo&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=br&amp;utm_source=omegainvestimentos.com.br&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=BINANCE%3AUSDTBRL"
                                                    style={{ width: "100%", height: "100%", margin: "0 !important", padding: "0 !important" }}
                                                    frameBorder="0"
                                                    allowTransparency={true}
                                                    scrolling="no"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className="caixa1">
                    <fieldset>
                        <div className="col small-12">
                            <div className="col-inner pb">
                                <h4>Evolução do ativo Ethereum</h4>
                                <div className="tradingview-widget-container">
                                    <div id="tradingview_610d4cbbbbb58">
                                        <div
                                            id="tradingview_347b1-wrapper"
                                            style={{ position: "relative", boxSizing: "content-box", width: "100%", height: "350px", margin: "0 auto !important", padding: "0 !important", fontFamily: "Arial,sans-serif" }}
                                        >
                                            <div style={{ width: "100%", height: "350px", background: "transparent", padding: "0 !important" }}>
                                                <iframe
                                                    id="tradingview_347b1"
                                                    src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_347b1&amp;symbol=BINANCE%3AETHUSDT&amp;interval=D&amp;saveimage=0&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;theme=light&amp;style=1&amp;timezone=America%2FSao_Paulo&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=br&amp;utm_source=omegainvestimentos.com.br&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=BINANCE%3AETHUSDT"
                                                    style={{ width: "100%", height: "100%", margin: "0 !important", padding: "0 !important" }}
                                                    frameBorder="0"
                                                    allowTransparency={true}
                                                    scrolling="no"
                                                    allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>

                <div className="caixa1">
                    <fieldset>
                        <div className="col small-12">
                            <div className="col-inner pb">
                                <h4>Evolução do ativo Bitcoin</h4>
                                <div className="tradingview-widget-container">
                                    <div id="tradingview_610d4cbbbbb5a">
                                        <div id="tradingview_48621-wrapper" style={{ position: "relative", boxSizing: "content-box", width: "100%", height: "350px", margin: "0 auto !important", padding: "0 !important", fontFamily: "Arial,sans-serif" }}>
                                            <div style={{ width: "100%", height: "350px", background: "transparent", padding: "0 !important" }}>
                                                <iframe
                                                    id="tradingview_48621"
                                                    src="https://s.tradingview.com/widgetembed/?frameElementId=tradingview_48621&amp;symbol=BINANCE%3ABTCUSDT&amp;interval=D&amp;saveimage=0&amp;toolbarbg=f1f3f6&amp;studies=%5B%5D&amp;theme=light&amp;style=1&amp;timezone=America%2FSao_Paulo&amp;studies_overrides=%7B%7D&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=br&amp;utm_source=omegainvestimentos.com.br&amp;utm_medium=widget&amp;utm_campaign=chart&amp;utm_term=BINANCE%3ABTCUSDT"
                                                    style={{ width: "100%", height: "100%", margin: "0 !important", padding: "0 !important" }}
                                                    frameBorder="0"
                                                    allowTransparency={true}
                                                    scrolling="no"
                                                    allowFullScreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <Footer />
            {modalDesconectar && <ModalDesconectar modal={modalDesconectar} />}
        </>
    );
};
