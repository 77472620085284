import { obterHistoricoFechamento } from "../../../services/fechamento";
import { obterDadosUsuario2, guardarDadosUsuario, guardarDadosUsuario2 } from "../../../services/cookies";
import { obterFundoFinanceiro, renovacaoToken } from "../../../services/usuario";
import { Fechamentos } from "../HistoricoFechamento/HistoricoFechamento";
import { DateFormatter } from "../../../functions/DateFormatter";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { obterIdFundoAcessoria } from "../../../services/fundoFinanceiro";
import { obterRankingDePosicoes } from "../../../services/gestor";

interface numerosAleatoriosProps {
    min: number,
    max: number,
    times: number
}

export interface RankingClientes {
    idCredenciais: string,
    nome: string,
    totalCotas: number
}

export const gerarNumerosAleatorios = (min: number, max: number, times: number) => {
    const arrayAleatorios = []

    for (let i = 0; i < times; i++) {
        arrayAleatorios.push(Math.floor(Math.random() * (max - min) + min))
    }

    return arrayAleatorios
}

export const gerarCoresAleatorias = (
    quantidade: number,
    colorArray: string[]
) => {
    for (let i = 0; i < quantidade; i++) {
        //newArray.push("#" + Math.floor(Math.random() * 16777215).toString(16));
        colorArray.push("#4c2305");
        colorArray.push("#662f06");
        colorArray.push("#7f3b08");
        colorArray.push("#99470a");
        colorArray.push("#b2530b");
        colorArray.push("#cc5f0d");
        colorArray.push("#e56b0f");
        colorArray.push("#ff7711");
        colorArray.push("#ff8428");
        colorArray.push("#ff9240");
        colorArray.push("#ff9f58");
        colorArray.push("#ffad70");
        colorArray.push("#ffbb88");
    }
    return colorArray
};

export async function handleGraficosDashboards(
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void,
    setErrorGrafico: (value: React.SetStateAction<boolean>) => void,
    dataInicial: Date,
    dataFinal: Date,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setRendimentosDia: (value: React.SetStateAction<number[]>) => void,
    setValoresTotais: (value: React.SetStateAction<number[]>) => void,
    setLabels: (value: React.SetStateAction<string[]>) => void,
    setValoresAcumulados: (value: React.SetStateAction<number[]>) => void,
    idFundoFinaceiro: string,
    token: string,
    tokenRenovacao: string

) {
    setLoadingGrafico(true);
    setErrorGrafico(false);
    let dataInicialZerada = new Date(new Date(dataInicial).setHours(0)).toISOString()
    await obterHistoricoFechamento(dataInicialZerada, dataFinal.toISOString(), false, token, idFundoFinaceiro).then((res) => {
        pegaDadosGrafico(res.data.resultado.fechamentos, setRendimentosDia, setValoresTotais, setLabels, setValoresAcumulados, setLoadingGrafico)
    }).catch(async (err) => {
        if (err.toString() === "Error: Request failed with status code 401") {
            await renovacaoToken(token, tokenRenovacao).then(async (res2) => {
                guardarDadosUsuario(
                    res2.data.camposAdicionais.tokenAcesso,
                    res2.data.camposAdicionais.tokenRenovacao,
                    obterDadosUsuario2().email.toString(),
                    obterDadosUsuario2().id.toString()
                );
                guardarDadosUsuario2(
                    res2.data.camposAdicionais.tokenAcesso,
                    res2.data.camposAdicionais.tokenRenovacao,
                    obterDadosUsuario2().email.toString(),
                    obterDadosUsuario2().id.toString()
                );
                await obterHistoricoFechamento(dataInicial.toISOString(), dataFinal.toISOString(), false, res2.data.camposAdicionais.tokenAcesso, idFundoFinaceiro,).then((res3) => {
                    pegaDadosGrafico(res3.data.resultado.fechamentos, setRendimentosDia, setValoresTotais, setLabels, setValoresAcumulados, setLoadingGrafico)

                }).catch((err) => {
                    setErrorGrafico(true);
                    setLoadingGrafico(false);
                })
            }).catch((err) => {
                setModalDesconectar(true);
                setLoadingGrafico(false);
            })
        }
        else {
            setErrorGrafico(true);
            setLoadingGrafico(false);
        }
    })
}

export const pegaDadosGrafico = (
    arrayFechamentos: Fechamentos[],
    setRendimentosDia: (value: React.SetStateAction<number[]>) => void,
    setValoresTotais: (value: React.SetStateAction<number[]>) => void,
    setLabels: (value: React.SetStateAction<string[]>) => void,
    setValoresAcumulados: (value: React.SetStateAction<number[]>) => void,
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void,

) => {
    let rendimentoParciais: number[] = []
    let valoresParciais: number[] = []
    let valoresAcumuladosParciais: number[] = []
    let labelsParciais: string[] = []
    arrayFechamentos.map((fechamentoDiario, index, array) => {
        rendimentoParciais = [...rendimentoParciais, fechamentoDiario.valorTotalDolar]
        labelsParciais = [...labelsParciais, DateFormatter(fechamentoDiario.data)]
        index === 0 ? valoresParciais = [0] : valoresParciais = [...valoresParciais, ((fechamentoDiario.valorCota / array[index - 1].valorCota) - 1) * 100]

        valoresAcumuladosParciais = [...valoresAcumuladosParciais, (fechamentoDiario.valorCota / 1101.10) * 100 - 100]
    })


    setRendimentosDia(rendimentoParciais)
    setValoresTotais(valoresParciais)
    setValoresAcumulados(valoresAcumuladosParciais)
    setLabels(labelsParciais)
    setLoadingGrafico(false);
}

export async function handleGraficosRankingClientes(
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void,
    setErrorGrafico: (value: React.SetStateAction<boolean>) => void,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setPorcentagensRanking: React.Dispatch<React.SetStateAction<number[]>>,
    setLabelsRanking: React.Dispatch<React.SetStateAction<string[]>>
) {
    let { token, tokenRenovacao } = obterDadosUsuario2();
    setLoadingGrafico(true);
    setErrorGrafico(false);
    await obterRankingDePosicoes(1, 1000, token).then((res) => {
        mapearDadosRankingParaGrafico(res.data.resultado.clientes, setPorcentagensRanking, setLabelsRanking, setLoadingGrafico)
    }).catch(async (err) => {
        if (err.toString() === "Error: Request failed with status code 401") {
            await renovacaoToken(token, tokenRenovacao).then(async (res2) => {
                guardarDadosUsuario(
                    res2.data.camposAdicionais.tokenAcesso,
                    res2.data.camposAdicionais.tokenRenovacao,
                    obterDadosUsuario2().email.toString(),
                    obterDadosUsuario2().id.toString()
                );
                guardarDadosUsuario2(
                    res2.data.camposAdicionais.tokenAcesso,
                    res2.data.camposAdicionais.tokenRenovacao,
                    obterDadosUsuario2().email.toString(),
                    obterDadosUsuario2().id.toString()
                );
                await obterRankingDePosicoes(1, 1000, token).then((res3) => {
                    mapearDadosRankingParaGrafico(res3.data.resultado.clientes, setPorcentagensRanking, setLabelsRanking, setLoadingGrafico)
                }).catch((err) => {
                    setErrorGrafico(true);
                    setLoadingGrafico(false);
                })
            }).catch((err) => {
                setModalDesconectar(true);
                setLoadingGrafico(false);
            })
        }
        else {
            setErrorGrafico(true);
            setLoadingGrafico(false);
        }
    })
}

export const mapearDadosRankingParaGrafico = (
    arrayRankingClientes: RankingClientes[],
    setPorcentagensRanking: React.Dispatch<React.SetStateAction<number[]>>,
    setLabelsRanking: React.Dispatch<React.SetStateAction<string[]>>,
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void

) => {
    let porcentagensRanking: number[] = []
    let labelsRanking: string[] = []
    arrayRankingClientes.map((item, index, array) => {
        porcentagensRanking = [...porcentagensRanking, item.totalCotas]
        labelsRanking = [...labelsRanking, (item.nome)]
    })

    setPorcentagensRanking(porcentagensRanking)
    setLabelsRanking(labelsRanking)
    setLoadingGrafico(false);
}

export const obterIdFundoFinanceiroAssessoria = (
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void,
    setErrorGrafico: (value: React.SetStateAction<boolean>) => void,
    dataInicial: Date,
    dataFinal: Date,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setRendimentosDia: (value: React.SetStateAction<number[]>) => void,
    setValoresTotais: (value: React.SetStateAction<number[]>) => void,
    setLabels: (value: React.SetStateAction<string[]>) => void,
    setValoresAcumulados: (value: React.SetStateAction<number[]>) => void,

) => {
    let { email, id, token, tokenRenovacao, urlAvatar } = obterDadosUsuario2();
    obterIdFundoAcessoria(token)
        .then((res) => {
            handleGraficosDashboards(
                setLoadingGrafico,
                setErrorGrafico,
                dataInicial,
                dataFinal,
                setModalDesconectar,
                setRendimentosDia,
                setValoresTotais,
                setLabels,
                setValoresAcumulados,
                res.data.resultado.fundoFinanceiro.id,
                token,
                tokenRenovacao,
            )

        })
        .catch((err) => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao)
                    .then(res => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        )
                        obterIdFundoAcessoria(res.data.camposAdicionais.tokenAcesso)
                            .then((res1) => {
                                handleGraficosDashboards(
                                    setLoadingGrafico,
                                    setErrorGrafico,
                                    dataInicial,
                                    dataFinal,
                                    setModalDesconectar,
                                    setRendimentosDia,
                                    setValoresTotais,
                                    setLabels,
                                    setValoresAcumulados,
                                    res1.data.resultado.fundoFinanceiro.id,
                                    res.data.camposAdicionais.tokenAcesso,
                                    res.data.camposAdicionais.tokenRenovacao,
                                )

                            })
                            .catch(() => {
                                setErrorGrafico(true)
                            })
                    }).catch(() => {
                        setModalDesconectar(true)
                    })
            } else {
                setErrorGrafico(true)
            }
        }).finally(() => {
            setLoadingGrafico(false)
        })
}

export const obterIdFundoFinanceiroPrivate = (
    setLoadingGrafico: (value: React.SetStateAction<boolean>) => void,
    setErrorGrafico: (value: React.SetStateAction<boolean>) => void,
    dataInicial: Date,
    dataFinal: Date,
    setModalDesconectar: React.Dispatch<React.SetStateAction<boolean>>,
    setRendimentosDia: (value: React.SetStateAction<number[]>) => void,
    setValoresTotais: (value: React.SetStateAction<number[]>) => void,
    setLabels: (value: React.SetStateAction<string[]>) => void,
    setValoresAcumulados: (value: React.SetStateAction<number[]>) => void,
    idCredenciais: string

) => {
    let { email, id, token, tokenRenovacao, urlAvatar } = obterDadosUsuario2();

    obterFundoFinanceiro(token, idCredenciais)
        .then((res) => {
            const fundoPrivate = res.data.resultado.clientes.filter((fundo: { id: string, servicoFinanceiro: string }) => fundo.servicoFinanceiro === "Private")[0]
            handleGraficosDashboards(
                setLoadingGrafico,
                setErrorGrafico,
                dataInicial,
                dataFinal,
                setModalDesconectar,
                setRendimentosDia,
                setValoresTotais,
                setLabels,
                setValoresAcumulados,
                fundoPrivate.id,
                token,
                tokenRenovacao,
            )
        })
        .catch((err) => {

            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao)
                    .then(res => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        )
                        obterFundoFinanceiro(res.data.camposAdicionais.tokenAcesso, idCredenciais)
                            .then((res1) => {
                                const fundoPrivate = res.data.resultado.clientes.filter((fundo: { id: string, servicoFinanceiro: string }) => fundo.servicoFinanceiro === "Private")[0]
                                handleGraficosDashboards(
                                    setLoadingGrafico,
                                    setErrorGrafico,
                                    dataInicial,
                                    dataFinal,
                                    setModalDesconectar,
                                    setRendimentosDia,
                                    setValoresTotais,
                                    setLabels,
                                    setValoresAcumulados,
                                    fundoPrivate.id,
                                    res.data.camposAdicionais.tokenAcesso,
                                    res.data.camposAdicionais.tokenRenovacao,
                                )
                            })
                            .catch(() => {
                                setErrorGrafico(true)
                            })
                    }).catch(() => {
                        setModalDesconectar(true)
                    })
            } else {
                setErrorGrafico(true)
            }
        }).finally(() => {
            setLoadingGrafico(false)
        })

}
