import React from 'react'
import { Button } from '../../Button'
import './style.css'

interface TabelaServicosFinanceirosProps {
    id: any,
    nome: string,
    descricao: string,
    ativo: any,
    setModal: React.Dispatch<React.SetStateAction<boolean>>,
    setLabelDescricao: React.Dispatch<React.SetStateAction<string>>,
    setLabelNome: React.Dispatch<React.SetStateAction<string>>,
    setIdServicoParaAtualizar: React.Dispatch<React.SetStateAction<number>>,
    setAtivo: React.Dispatch<React.SetStateAction<boolean>>,
    setTipo: React.Dispatch<React.SetStateAction<string>>,
}
export const TabelaServicosFinanceiros = ({ id, nome, descricao, ativo,
    setModal, setLabelDescricao, setLabelNome,
    setIdServicoParaAtualizar, setAtivo, setTipo
}: TabelaServicosFinanceirosProps) => {
    return (
        <tr className="linhasAtivosFinanceiros">
            <th>{nome}</th>
            <th>{ativo ? "Ativo" : "Inativo"}</th>
            <th>{descricao}</th>
            <th>
                <Button
                    onClick={() => {
                        setLabelDescricao(descricao);
                        setLabelNome(nome);
                        setIdServicoParaAtualizar(id);
                        setAtivo(ativo);
                        setTipo("atualizar");
                        setModal(true);
                    }}
                    titleButton="Alterar"
                />
            </th>
        </tr>
    )
}