import React, { useState, FC, ReactNode, ReactChild } from "react";

type usuarioTipoType = "UsuarioAssessoria" | "UsuarioCustodia" | "Funcionario" | "Administrador" | null;

export interface IUsuario {
  tipo: usuarioTipoType;
  autenticado: boolean;
  children?: ReactNode | undefined;
}

export type UsuarioContextType = {
  usuario: IUsuario;
  atualizarUsuario: (usuario: IUsuario) => void;
  removerUsuario: () => void;
};

export const usuarioContext = React.createContext<UsuarioContextType | null>(
  null
);

const UsuarioProvider = ({ children }: { children: React.ReactNode }) => {
  const [usuario, setUsuario] = useState<IUsuario>({
    tipo: null,
    autenticado: false,
  });

  const atualizarUsuario = ({ tipo, autenticado }: IUsuario) => {
    setUsuario({
      ...usuario,
      tipo,
      autenticado,
    });
  };

  const removerUsuario = () => {
    setUsuario({ tipo: null, autenticado: false });
  };

  return (
    <usuarioContext.Provider
      value={{ usuario, atualizarUsuario, removerUsuario }}
    >
      {children}
    </usuarioContext.Provider>
  );
};

export default UsuarioProvider;
