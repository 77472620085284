import React from 'react'
import { guardarDadosUsuario2, obterDadosUsuario2 } from '../../../services/cookies'
import { obterTodosFuncionarios } from '../../../services/gestor'
import { renovacaoToken, ativarUsuario, desativarUsuario } from '../../../services/usuario'
import { Funcionario } from './ListarFuncionarios'


//TODO: Adicionar o numero de paginas dinâmico,
export const obterFuncionarios = (numeroPagina: number, tamanhoPagina: number, listaFuncionarios: Funcionario[], setListaFuncionarios: React.Dispatch<Funcionario[]>, setNumeroDePaginas: React.Dispatch<number>, setErro: React.Dispatch<boolean>, setLoading: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>, StatusFuncionario: boolean) => {
    setLoading(true)

    const { email, urlAvatar, id, token, tokenRenovacao, } = obterDadosUsuario2()

    obterTodosFuncionarios(token, numeroPagina, tamanhoPagina, StatusFuncionario)
        .then(res => {
            const listaFuncionarios = res.data.resultado.funcionarios.filter((funcionario: any) => funcionario.idCredenciais !== id)
            setListaFuncionarios(listaFuncionarios)
            setNumeroDePaginas(res.data.resultado.totalRegistros / tamanhoPagina)
        })
        .catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao)
                    .then(res => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        )

                        obterTodosFuncionarios(res.data.camposAdicionais.tokenAcesso, numeroPagina, tamanhoPagina, StatusFuncionario)
                            .then(res => {
                                const listaFuncionarios = res.data.resultado.funcionarios.filter((funcionario: any) => funcionario.idCredenciais !== id)
                                setListaFuncionarios(listaFuncionarios)
                                setNumeroDePaginas(res.data.resultado.totalRegistros / tamanhoPagina)
                            })
                    })
                    .catch(() => setDesconectar(true))
            } else {
                setErro(true)
            }
        })
        .finally(() => setLoading(false))
}

export const paginarLista = (lista: any[], paginaAtual: number, itensPorPagina: number, setListaPaginada: React.Dispatch<any[]>) => {
    const listaFitrada = lista.filter((item, index) => {
        if ((index <= (paginaAtual * itensPorPagina) - 1) && (index >= (paginaAtual - 1) * itensPorPagina))
            return item
    })
    setListaPaginada(listaFitrada)
}

export const ativarFuncionario = (idCredenciais: string, setReload: React.Dispatch<boolean>, reload: boolean, setDesconectar: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>) => {
    const { token, tokenRenovacao, email, id, urlAvatar } = obterDadosUsuario2()
    ativarUsuario(token, idCredenciais)
        .then(res => setReload(!reload))
        .catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao)
                    .then(res => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        )

                        ativarUsuario(token, idCredenciais)
                            .then(res => { setReload(!reload) })
                    })
                    .catch(() => setDesconectar(true))
            }
            else {
                setErro(true)
            }
        })
}

export const desativarFuncionario = (idCredenciais: string, setReload: React.Dispatch<boolean>, reload: boolean, setDesconectar: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>) => {
    const { token, tokenRenovacao, email, id, urlAvatar } = obterDadosUsuario2()
    desativarUsuario(token, idCredenciais)
        .then(res => { setReload(!reload) })
        .catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao)
                    .then(res => {
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        )

                        desativarUsuario(token, idCredenciais)
                            .then(res => { setReload(!reload) })
                    })
                    .catch(() => setDesconectar(true))
            }
            else {
                setErro(true)
            }
        })
}