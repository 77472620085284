import React from 'react'
import { Cliente } from '../../DadosClientePF/index'
import { obterDadosPessoaisPF } from '../../../services/cliente'
import { guardarDadosUsuario2, obterDadosUsuario2 } from '../../../services/cookies'
import { renovacaoToken } from '../../../services/usuario'

export const obterDadosCadastrais = async (idCredencias: string, setDadosCliente: React.Dispatch<Cliente>, setLoading: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>) => {
    setLoading(true)

    const { email, urlAvatar, id, token, tokenRenovacao, } = obterDadosUsuario2()

    await obterDadosPessoaisPF(token, idCredencias)
        .then(res => {
            setDadosCliente(res.data.resultado.dadosPessoais);
        })
        .catch(async (err) => {
            if (err.toString() === "Error: Request failed with status code 401") {
                await renovacaoToken(token, tokenRenovacao)
                    .then(async (res) => {
                        await obterDadosPessoaisPF(res.data.camposAdicionais.tokenAcesso, idCredencias)
                            .then(res => {
                                setDadosCliente(res.data.resultado.dadosPessoais);
                            })
                        guardarDadosUsuario2(
                            res.data.camposAdicionais.tokenAcesso,
                            res.data.camposAdicionais.tokenRenovacao,
                            email.toString(),
                            id.toString(),
                            urlAvatar
                        );
                    }).catch(() => {
                        setDesconectar(true)
                    })
            } else {
                setErro(true)
            }
        })
        .finally(() => setLoading(false))
}