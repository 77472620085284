import React, { useState, useEffect, useContext } from "react";
import ImagemOmegaImpressao from "../../../assets/img/logo.png";
import "./HistoricoFechamento.css";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { TabelaHistoricoFechamento } from "../../../components/Tabelas/TabelaHistoricoFechamento";
import { CardHistoricoFechamento } from "../../../components/Cards/CardHistoricoFechamento";
import { Cotacoes } from '../../../components/modais/ModalHistoricoFechamento'

import DatePicker, { registerLocale } from "react-datepicker";
import { AiOutlineSearch, AiFillPrinter } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { Loading } from "../../../components/Loading";
import { ErroTabela } from "../../../components/Erros/ErroTabela";
import { handleHistoricoFechamento, obterFundoFinaceiroPrivate, obterIdFundoFinanceiroAcessoria, criaPDFHistoricoFechamento } from "./functions";

import { AiOutlineReload, AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { Button } from "../../../components/Button";
import { LoadingButton } from "../../../components/loadingButtom";
import { voltarSlide, proximoSlide, criaPDF } from "../../Cliente/Extrato/functions";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ServicoFinanceiroClienteContext, usuarioServicoFinanceiroContextType } from "../../../context/servicoFinanceiroCliente";
import { useParams } from "react-router-dom";


registerLocale("pt", pt);

const settings = {
    dots: false,
    infinite: false,
    swipeToSlide: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    variableWidth: true,
    respondTo: 'slider',
    rows: 2,
    slidesPerRow: 1,
    nextArrow: <div id="nextArrowSlide" style={{ display: 'none !important' }}></div>,
    prevArrow: <div id="prevArrowSlide" style={{ display: 'none !important' }}></div>
};

export interface Responsavel {
    idCredenciais: string,
    nome: string
}

export interface Fechamentos {
    id: number,
    data: Date,
    valorTotalDolar: number,
    valorTotalReal: number,
    valorCota: number,
    ativosFinanceiros: Cotacoes[],
    responsavel: Responsavel,
    rendimentoDiario: number
}

const HistoricoFechamento = () => {

    const [dataInicial, setDataInicial] = useState<Date>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [dataFinal, setDataFinal] = useState<Date>(new Date());
    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [reload, setReload] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [fechamentos, setFechamentos] = useState<Array<Fechamentos>>([]);

    const { idCredenciais, nomeCliente } = useParams();

    useEffect(() => {
        if (!idCredenciais) {
            obterIdFundoFinanceiroAcessoria(setError, setModalDesconectar, dataInicial, dataFinal, setFechamentos, setLoading);

        } else {
            obterFundoFinaceiroPrivate(idCredenciais, setError, setModalDesconectar, dataInicial, dataFinal, setFechamentos, setLoading);
        }
    }, [reload])

    return (
        <>
            {loading && <Loading />}
            <Navbar />
            <div id="container">
                <div className="caixaExtrato">
                    <fieldset>
                        <div className="headerExtrato">

                            <h2 className="textoNomeHeader">Histórico de Fechamento</h2>

                            <h6 id="descricaoTexto">Selecione o período: </h6>
                        </div>

                        <div id="divFieldsetHistoricoFechamento">
                            <h4>{!idCredenciais ? "Fundo Assessoria" : "Fundo " + nomeCliente}</h4>
                            <div>

                                <DatePicker
                                    className="inputData"
                                    maxDate={(new Date(new Date().setDate(new Date().getDate() - 1)))}
                                    dateFormat="dd/MM/yyyy"
                                    locale="pt"
                                    selected={dataInicial}
                                    onChange={(dataInicio: Date) => setDataInicial(dataInicio)}
                                />
                                <DatePicker
                                    className="inputData"
                                    maxDate={(new Date(new Date().setDate(new Date().getDate())))}
                                    minDate={dataInicial}
                                    dateFormat="dd/MM/yyyy"
                                    locale="pt"
                                    selected={dataFinal}
                                    onChange={(dataFim: Date) => setDataFinal(dataFim)}
                                />
                                <AiOutlineSearch className="PesquisarHistoricoFinanceiro" onClick={() => setReload(!reload)} />
                            </div>
                        </div>

                    </fieldset>
                </div>

                {error ?
                    <div className="caixaExtrato">
                        <ErroTabela
                            handleClick={() => setReload(!reload)}
                            titleButton={<>
                                <>
                                    <AiOutlineReload className="iconButtonDashboard" />
                                </>
                            </>
                            }
                            className="formatacaoGrafico"
                        />
                    </div>
                    :
                    <div className="caixa1 desktopHistoricoFechamento">
                        <fieldset>
                            <h5>Extrato de Transações</h5>
                            <table id="tblTransacoes" className="tabela">
                                <thead>
                                    <tr className="tabelaHistoricoFechamento">
                                        <th className="table-secondary tableTitulo tableData colunas">
                                            <strong>Data</strong>
                                        </th>
                                        <th className="table-secondary tableTitulo tableTipo colunas">
                                            <strong>Valor do fechamento</strong>
                                        </th>
                                        <th className="table-secondary tableTitulo tableCotas colunas">
                                            <strong>Gestor</strong>
                                        </th>
                                        <th id="colunaFechamento" className="table-secondary tableTitulo tableOperacao colunas">
                                            <strong>Ver Fechamento</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fechamentos.length !== 0 ? fechamentos.map((fechamento) =>
                                        <TabelaHistoricoFechamento
                                            idCredenciais={idCredenciais}
                                            dados={fechamento}
                                            key={fechamento.id}
                                        />
                                    ) : <tr id="trAvisoSemRegistros">
                                        <td colSpan={4} className="textCentralizar">
                                            Nenhum dado disponível para consulta neste período.
                                        </td>
                                    </tr>}
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
                }

                <div className="mobileHistoricoFechamento">
                    <div className="caixa1">
                        <fieldset>
                            <div className="divBotoesExtratoMobile">
                                <Button
                                    className="btn__cinza btnMargin"
                                    onClick={() => voltarSlide()}
                                    titleButton={
                                        <>
                                            <AiOutlineArrowLeft className="iconButtonExtrato" />
                                        </>
                                    }
                                />
                                <Button
                                    className="btn__cinza btnMargin"
                                    onClick={() => proximoSlide()}
                                    titleButton={
                                        <>
                                            <AiOutlineArrowRight className="iconButtonExtrato" />
                                        </>
                                    }
                                />
                            </div>
                            <div className="boxSlideExtrato">
                                {error ? (
                                    <div>
                                        <span className="formatacaoLoadingExtrato">
                                            Ocorreu um erro. Clique no botão para tentar novamente.
                                        </span>
                                        <Button
                                            className="btn__cinza btnMargin"
                                            onClick={() => setReload(!reload)}
                                            titleButton={
                                                <>
                                                    <AiOutlineReload
                                                        className="iconButtonExtrato"
                                                        onClick={() => setReload(!reload)} />
                                                </>
                                            }
                                        />
                                    </div>
                                ) : loading ? (
                                    <span className="formatacaoLoadingExtrato">
                                        <LoadingButton />
                                    </span>
                                ) : (
                                    <Slider {...settings}>
                                        {fechamentos.length !== 0 ? (
                                            fechamentos.map((fechamento) => (
                                                <CardHistoricoFechamento
                                                    dados={fechamento}
                                                    key={fechamento.id}
                                                />
                                            ))
                                        ) : <span>Nenhum registro encontrado no momento.</span>
                                        }
                                    </Slider>
                                )}
                            </div>
                        </fieldset>
                    </div>
                </div>
                <Button
                    className="btnAlterar btnMargin desktopExtrato"
                    onClick={() => criaPDFHistoricoFechamento()}
                    style={{ display: "inline-flex" }}
                    titleButton={
                        <>
                            <AiFillPrinter className="iconButton" />
                            Imprimir
                        </>
                    }
                />
            </div>
            <Footer />
            {modalDesconectar && <ModalDesconectar modal={modalDesconectar} />}
        </>
    );
};

export default HistoricoFechamento;
