import React, { useState, useEffect } from "react";

import "./GerenciarServicos.css";

import { obterServicos } from "./functions";

import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import { Header } from "../../../components/Header";
import { Button } from "../../../components/Button";
import { ModalNovoServicoFinanceiro } from "../../../components/modais/ModalNovoServicoFinanceiro";
import { Loading } from "../../../components/Loading";
import { TabelaServicosFinanceiros } from "../../../components/Tabelas/TabelaServicosFinanceiros";
import { CardServicosFinanceiros } from "../../../components/Cards/CardServicosFinanceiros";

import { AiOutlineSearch } from "react-icons/ai";

const GerenciarServicos = () => {
  const [modalNovoServicoFinanceiro, setModalNovoServicoFinanceiro] =
    useState<boolean>(false);
  const [listaDeServicos, setListaDeServicos] = useState<any[]>([]);
  const [tipo, setTipo] = useState<string>("criar");
  const [loading, setLoading] = useState<boolean>(false);
  const [erro, setErro] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [labelNome, setLabelNome] = useState<string>("");
  const [labelDescricao, setLabelDescricao] = useState<string>("");
  const [idServicoParaAtualizar, setIdServicoParaAtualizar] =
    useState<number>();
  const [filtro, setFiltro] = useState<boolean>(true);
  const [ativo, setAtivo] = useState<boolean>(true);

  useEffect(() => obterServicos(setLoading, filtro, setListaDeServicos, setErro), [reload]);

  const handleReload = () => setReload(!reload);

  return (
    <>
      <Navbar />
      {loading && <Loading />}
      <div id="container">
        <Header>
          <div className="header_container">
            <h2>Serviços Financeiros</h2>
            <div>
              <h5>Filtrar por:</h5>
              <div className="divFieldsetServicoFinanceiro selectsServicoFinanceiro">
                <select
                  id="selectServicoFinanceiro"
                  className="form-select"
                  placeholder="Ativos"
                  onChange={(e) =>
                    e.target.value === "Ativos"
                      ? setFiltro(true)
                      : setFiltro(false)
                  }
                >
                  <option className="ativo" value="Ativos">
                    Ativos
                  </option>
                  <option className="inativo" value="Inativos">
                    Inativos
                  </option>
                </select>
                <AiOutlineSearch
                  className="PesquisarDashboard"
                  onClick={() => obterServicos(setLoading, filtro, setListaDeServicos, setErro)}
                />
              </div>
            </div>
          </div>
        </Header>
        <div id="desktop" className="caixa1">
          <fieldset>
            {!erro ? (
              <>
                {listaDeServicos.length === 0 ? <p className="mensagem-servico-financeiro-vazio">Nenhum serviço financeiro encontrado</p> :
                  <table id="tblTransacoes" className="tabela">
                    <thead>
                      <tr className="tabelaServicoFinanceiro">
                        <th className="table-secondary tableTitulo tableData colunas">
                          <strong>Nome</strong>
                        </th>
                        <th className="table-secondary tableTitulo tableCotas colunas">
                          <strong>Estado</strong>
                        </th>
                        <th className="table-secondary tableTitulo tableOperacao colunas">
                          <strong>Descrição</strong>
                        </th>
                        <th className="table-secondary tableTitulo tableOperacao colunas">
                          <strong>Controle</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listaDeServicos.map((servico) => (
                        <TabelaServicosFinanceiros
                          key={servico.id}
                          ativo={servico.ativo}
                          nome={servico.nome}
                          descricao={servico.descricao}
                          id={servico.id}
                          setLabelDescricao={setLabelDescricao}
                          setLabelNome={setLabelNome}
                          setAtivo={setAtivo}
                          setIdServicoParaAtualizar={setIdServicoParaAtualizar}
                          setModal={setModalNovoServicoFinanceiro}
                          setTipo={setTipo}
                        />
                      ))}
                    </tbody>
                  </table>
                }
                <div>
                  <Button
                    onClick={() => setModalNovoServicoFinanceiro(true)}
                    className="btn__cinza"
                    titleButton="Adicionar novo"
                  />
                  {modalNovoServicoFinanceiro && (
                    <ModalNovoServicoFinanceiro
                      modal={modalNovoServicoFinanceiro}
                      reload={handleReload}
                      setModal={() => {
                        setModalNovoServicoFinanceiro(false);
                        setTipo("criar");
                      }}
                      tipo={tipo}
                      labelNome={labelNome}
                      labelDescricao={labelDescricao}
                      id={idServicoParaAtualizar}
                      ativo={ativo}
                    />
                  )}
                </div>
              </>
            ) : (
              <div>
                <h5 className="texto">
                  Não foi possível carregar os serviços Financeiros
                </h5>
                <p className="texto">Clique abaixo para tentar novamente.</p>
                <Button titleButton="Aqui" onClick={() => obterServicos(setLoading, filtro, setListaDeServicos, setErro)} />
              </div>
            )}
          </fieldset>
        </div>
        <div id="mobile">
          {listaDeServicos.length === 0 ? <p className="mensagem-servico-financeiro-vazio">Nenhum serviço financeiro encontrado</p> :
            <>
              {listaDeServicos.map((servico) => (
                <CardServicosFinanceiros
                  ativo={servico.ativo}
                  nome={servico.nome}
                  descricao={servico.descricao}
                  id={servico.id}
                  setLabelDescricao={setLabelDescricao}
                  setLabelNome={setLabelNome}
                  setAtivo={setAtivo}
                  setIdServicoParaAtualizar={setIdServicoParaAtualizar}
                  setModal={setModalNovoServicoFinanceiro}
                  setTipo={setTipo}
                />
              ))}
            </>
          }
        </div>
      </div>

      <Footer />
    </>
  );
};

export default GerenciarServicos;
