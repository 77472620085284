import React from "react";
import { NavigateFunction } from "react-router-dom";
import { obterDadosUsuario2, guardarDadosUsuario2 } from "../../../services/cookies";
import { alterarSenha, renovacaoToken } from "../../../services/usuario";
import { Erro } from "./AlteracaoSenha";

export const salvarSenha = async (senhaAtual: string, novaSenha: string, confirmaçãoSenha: string, setLoading: React.Dispatch<boolean>, setSucesso: React.Dispatch<boolean>, setErroSenhaAtual: React.Dispatch<any>, erroSenhaAtual: any, setErro: React.Dispatch<any>, erro: any, setDesconectar: React.Dispatch<boolean>) => {

    setLoading(true)
    setErro({ ...erro, erro: false })
    setErroSenhaAtual({ ...erroSenhaAtual, erro: false })

    const { email, urlAvatar, id, token, tokenRenovacao } = obterDadosUsuario2()
    if (verificarDados(setErro, novaSenha, confirmaçãoSenha, erro)) {
        await alterarSenha(id, token, senhaAtual, novaSenha).then(res => {
            setSucesso(true)
        }).catch(err => {
            if (err.toString() === "Error: Request failed with status code 401") {
                renovacaoToken(token, tokenRenovacao).then(res => {
                    guardarDadosUsuario2(
                        res.data.camposAdicionais.tokenAcesso,
                        res.data.camposAdicionais.tokenRenovacao,
                        email,
                        id,
                        urlAvatar
                    )

                    alterarSenha(id, res.data.camposAdicionais.tokenAcesso, senhaAtual, novaSenha).then(res => {
                        setSucesso(true)
                    }).catch(err => {
                        if (err.response.data.mensagem === "Incorrect password.") {
                            setErroSenhaAtual({ mensagem: "Insira uma senha valida e tente novamente.", erro: true });
                        }
                    })
                }).catch(() => setDesconectar(true))
            } else if (err.response.data.mensagem === "Incorrect password.") {
                setErroSenhaAtual({ mensagem: "Insira uma senha valida e tente novamente.", erro: true });
            } else {
                setErro({ mensagem: "Algo deu errado, aguarde alguns instates e tente novamente mais tarde.", erro: true });
            }
        }).finally(() => setLoading(false))
    }
    else {
        setLoading(false)
    }
}

export const verificarDados = (setErro: React.Dispatch<any>, novaSenha: string, confirmacaoNovaSenha: string, erro: Erro) => {
    setErro({ ...erro, erro: false });
    if (!(novaSenha.trim() && confirmacaoNovaSenha.trim())) {
        setErro({
            ...erro,
            erro: true,
            mensagem: "Todos os capos devem ser preenchidos.",
        });
        return false
    } else if (!(novaSenha === confirmacaoNovaSenha)) {

        setErro({
            ...erro,
            erro: true,
            mensagem: "Senha e confirmação de senha devem ser iguais.",
        });
        return false
    }
    return true
};

export const irParaHome = (navigate: NavigateFunction) => {

    navigate("/home");
}