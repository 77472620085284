export const ItensCliente = [
    {
        title: 'Home',
        path: '/home',
        cName: 'nav-text',
    },
    {
        title: 'Aporte e Saque',
        path: '/aport-e-saque',
        cName: 'nav-text',
    },
    {
        title: 'Ativos',
        path: '/ativos',
        cName: 'nav-text',
    },
    {
        title: 'Extrato',
        path: '/extrato',
        cName: 'nav-text',
    },
    // {
    //     title: 'Dashboards',
    //     path: '/dashboards',
    //     cName: 'nav-text',
    // },
    {
        title: 'Relatórios',
        path: '/relatorios',
        cName: 'nav-text',
    },
    {
        title: 'FAQ',
        path: '/faq',
        cName: 'nav-text',
    },
    {
        title: 'Contato',
        path: '/contato',
        cName: 'nav-text',
    },

];

export const ItensFuncionario = [
    {
        title: 'Home',
        path: '/home',
        cName: 'nav-text',
    },
    {
        title: 'Fechamento',
        cName: 'nav-text',
        itens: [
            {
                title: 'Assessoria',
                cName: 'nav-text',
                subItens: [
                    {
                        title: 'Novo',
                        path: '/fechamento',
                        cName: 'nav-text',
                    },
                    {
                        title: 'Histórico',
                        path: '/gerenciar/historico-fechamento',
                        cName: 'nav-text',
                    }
                ]
            },
            {
                title: 'Private',
                cName: 'nav-text',
                subItens: [
                    {
                        title: 'Listar Private',
                        path: '/funcionario/clientes-private',
                        cName: 'nav-text',
                    }
                ]
            },
        ]
    },
    {
        title: "Clientes",
        cName: 'nav-text',
        itens: [
            {
                title: 'Todos',
                cName: 'nav-text',
                path: "/usuarios",
            },
            {
                title: 'Solicitações de Cadastro',
                path: '/gerenciar/cadastro',
                cName: 'nav-text',
            },
        ]

    },
    {
        title: 'Solicitações',
        cName: 'nav-text',
        itens: [
            {
                title: 'Saque',
                path: '/solicitacoes/saque',
                cName: 'nav-text',
            },
            {
                title: 'Aporte',
                path: '/validacao/aporte',
                cName: 'nav-text',
            },
        ]
    },
    {
        title: 'Funcionários',
        cName: 'nav-text',
        itens: [
            {
                title: 'Novo',
                path: '/funcionario/cadastro',
                cName: 'nav-text',
            },
            {
                title: 'Gerenciar',
                cName: 'nav-text',
                path: "/funcionarios",
            },
        ]
    },
    {
        title: 'Gestão',
        cName: 'nav-text',
        itens: [
            {
                title: 'Templates de E-mail',
                path: '/gerenciar/template-email',
                cName: 'nav-text',
            },
            // {
            //     title: 'Dashboards Gerenciais',
            //     path: '/gerenciar/dashboards',
            //     cName: 'nav-text',
            // },

        ]
    },
]

export const ItensGestor = [
    {
        title: 'Home',
        path: '/home',
        cName: 'nav-text',
    },
    {
        title: 'Fechamento',
        cName: 'nav-text',
        itens: [
            {
                title: 'Assessoria',
                cName: 'nav-text',
                subItens: [
                    {
                        title: 'Novo',
                        path: '/fechamento',
                        cName: 'nav-text',
                    },
                    {
                        title: 'Histórico',
                        path: '/gerenciar/historico-fechamento',
                        cName: 'nav-text',
                    }
                ]
            },
            {
                title: 'Private',
                cName: 'nav-text',
                subItens: [
                    {
                        title: 'Listar Private',
                        path: '/funcionario/clientes-private',
                        cName: 'nav-text',
                    }
                ]
            },
        ]
    },
    {
        title: "Clientes",
        cName: 'nav-text',
        itens: [
            {
                title: 'Todos',
                cName: 'nav-text',
                path: "/usuarios",
            },
            {
                title: 'Solicitações de Cadastro',
                path: '/gerenciar/cadastro',
                cName: 'nav-text',
            },
        ]

    },
    {
        title: 'Solicitações',
        cName: 'nav-text',
        itens: [
            {
                title: 'Saque',
                path: '/solicitacoes/saque',
                cName: 'nav-text',
            },
            {
                title: 'Aporte',
                path: '/validacao/aporte',
                cName: 'nav-text',
            },
        ]
    },
    {
        title: 'Funcionários',
        cName: 'nav-text',
        itens: [
            {
                title: 'Novo',
                path: '/funcionario/cadastro',
                cName: 'nav-text',
            },
            {
                title: 'Gerenciar',
                cName: 'nav-text',
                path: "/funcionarios",
            },
        ]
    },
    {
        title: 'Gestão',
        cName: 'nav-text',
        itens: [
            {
                title: 'Templates de E-mail',
                path: '/gerenciar/template-email',
                cName: 'nav-text',
            },
            {
                title: 'Usuario App',
                path: '/gerenciar/ClientesApp',
                cName: 'nav-text',
            },
            // {
            //     title: 'Dashboards Gerenciais',
            //     path: '/gerenciar/dashboards',
            //     cName: 'nav-text',
            // },

        ]
    },
]
