import React, { useState } from "react";
import "./RedefinirSenha.css";
import { handleRedefinir, handleSolicitar } from "./functions";

import { Button } from "../../components/Button";
import Footer from "../../components/Footer/Footer";
import { Header } from "../../components/Header";
import { Input } from "../../components/Input";
import SegurancaDeSenha from "../../components/SegurancaSenha/SegurancaSenha";
import { Loading } from "../../components/Loading";
import { Erro } from "../Cliente/AlteracaoSenha/AlteracaoSenha";
import { useNavigate } from "react-router";

const RedefinirSenha = () => {
  const [novaSenha, setNovaSenha] = useState<string>("");
  const [confirmacaoSenha, setConfirmacaoSenha] = useState<string>("");
  const [erroForcaSenha, setErroForcaSenha] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false);
  const [falha, setFalha] = useState<boolean>(false);
  const [erro, setErro] = useState<Erro>({ erro: false, mensagem: "" });
  const [sucessoSolicitacao, setSucessoSolicitacao] = useState<boolean>(false);
  const [falhaSolicitacao, setFalhaSolicitacao] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

    const sender = urlParams.get("Sender");
    let email = urlParams.get("Email")!;
    let token = urlParams.get("Token")!;

    const navigate = useNavigate()

  return (
    <>
      {loading && <Loading />}
      <Footer />
      <div id="container">
        <Header titulo="Criar uma Nova Senha" />
        <div id="divForm" className="titulo1 ">
          <fieldset>
            {!sucesso && !falha ? (
              <form onSubmit={(e) => handleRedefinir(
                e,
                setErro,
                erro,
                novaSenha,
                confirmacaoSenha,
                erroForcaSenha,
                setLoading,
                email,
                sender,
                token,
                setSucesso,
                setFalha,
              )}>
                <div className="inputsNovaSenha">
                  <Input
                    className="form-control"
                    placeholder=" "
                    label="Digite a nova senha"
                    type={"password"}
                    value={novaSenha}
                    senha={true}
                    required
                    onChange={(e: any) => {
                      setNovaSenha(e.target.value);
                    }}
                  />
                  <Input
                    className="form-control"
                    placeholder=" "
                    label="Confirme a nova senha"
                    type={"password"}
                    value={confirmacaoSenha}
                    senha={true}
                    required
                    onChange={(e: any) => setConfirmacaoSenha(e.target.value)}
                  />
                </div>
                <SegurancaDeSenha senha={novaSenha} />
                {erro.erro && <p>{erro.mensagem}</p>}
                <Button
                  className="btnAlterar"
                  titleButton="Alterar"
                  onClick={(e) => handleRedefinir(
                    e,
                    setErro,
                    erro,
                    novaSenha,
                    confirmacaoSenha,
                    erroForcaSenha,
                    setLoading,
                    email,
                    sender,
                    token,
                    setSucesso,
                    setFalha,
                  )}
                />
              </form>
            ) : (
              <>
                {sucesso ? (
                  <div className="conteudo__div">
                       <p>Senha alterada com sucesso! Para retornar ao login, <a className="MensagemClicavel" onClick={() => navigate('/')}>Clique aqui</a></p>
                  </div>
                ) : (
                  <>
                    {!sucessoSolicitacao && !falhaSolicitacao ? (
                      <div className="conteudo__div">
                        <h5>Algo deu errado ao criar sua nova senha.</h5>
                        <p>
                          Clique a baixo para solicitar uma nova redefinição de
                          senha.
                        </p>
                        <Button
                          className="btnAlterar"
                          titleButton="Aqui"
                          onClick={() => handleSolicitar(
                            setLoading,
                            setSucessoSolicitacao,
                            setFalhaSolicitacao,
                            sender,
                            email
                          )}
                        />
                      </div>
                    ) : (
                      <>
                        {sucessoSolicitacao ? (
                          <div className="conteudo__div">
                            <h5>
                              Uma nova solicitação de redefinição de senha foi
                              enviada ao seu email.
                            </h5>
                          </div>
                        ) : (
                          <div className="conteudo__div">
                            <h5>Algo deu errado na sua solicitação.</h5>
                            <p>Clique a baixo para tentar novamente.</p>
                            <Button
                              className="btnAlterar"
                              titleButton="Aqui"
                              onClick={() => handleSolicitar(
                                setLoading,
                                setSucessoSolicitacao,
                                setFalhaSolicitacao,
                                sender,
                                email
                              )}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default RedefinirSenha;
