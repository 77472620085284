import React from "react"
import { Cliente } from "."
import { obterDadosPessoaisPF } from "../../services/cliente"
import { guardarDadosUsuario2, obterDadosUsuario2 } from "../../services/cookies"
import { renovacaoToken } from "../../services/usuario"

export const obterDadosCadastrais = (setDadosPessoais: React.Dispatch<Cliente>, setLoading: React.Dispatch<boolean>, setErro: React.Dispatch<boolean>, setDesconectar: React.Dispatch<boolean>) => {
    setLoading(true)
    setErro(false)
    const { id, token, tokenRenovacao, email } = obterDadosUsuario2()

    obterDadosPessoaisPF(token, id).then(res => {
        setDadosPessoais(res.data.resultado.dadosPessoais)
    }).catch(async (err) => {
        if (err.toString() === "Error: Request failed with status code 401") {
            await renovacaoToken(token, tokenRenovacao).then(async (res) => {
                guardarDadosUsuario2(
                    res.data.camposAdicionais.tokenAcesso,
                    res.data.camposAdicionais.tokenRenovacao,
                    email.toString(),
                    id.toString()
                );
                await obterDadosPessoaisPF(res.data.camposAdicionais.tokenAcesso, id).then(res => {
                    setDadosPessoais(res.data.resultado.dadosPessoais)
                })
            }).catch(() => {
                setDesconectar(true)
            })
        } else {
            setErro(true)
        }
    }).finally(() => setLoading(false))
}

export const handleMaskCPF = (cpf: string) => {

    cpf = cpf.replace(/\D/g, "");

    cpf = cpf.replace(/^(\d{3})(\d)/g, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2");

    return cpf;
}

export function handleMaskCEP(
    e: any,
) {
    e = e.replace(/\D/g, "");
    e = e.replace(/^(\d{2})(\d)/g, "$1.$2");
    e = e.replace(/(\d{3})(\d)/, "$1-$2");
    return e
}

export function handleMaskTelefone(
    e: string,
) {
    e = e.replace(/\D/g, "");

    e = e.replace(/^(\d{2})(\d)/g, "($1) $2");
    e = e.replace(/(\d{5})(\d)/, "$1-$2");

    return e
}


export function handleMaskRenda(
    e: string,
) {
    e = e.replace(/[^,.0-9]/g, "");
    e = e.replace(",", ".");
    var count = 0;
    for (let i = 0; i <= e.length; i++) {
        if (e[i] === ".") {
            count = count + 1;
            if (count >= 2) {
                e = e.replace(/[.]/, "");
            }
        }
    }


    return e = "R$ " + e;
}