import React from "react";
import { usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
import { Dados } from "../../../pages/Cliente/AporteSaque/AporteSaque";
import { guardarDadosUsuario, guardarDadosUsuario2, removerDadosUsuario, removerDadosUsuario2 } from "../../../services/cookies";
import { operacaoFinanceiraAporteSaque } from "../../../services/operacaoFinanceira";
import { renovacaoToken } from "../../../services/usuario";

export const handleDocumentoUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImagensDocumentacao: (value: React.SetStateAction<string | ArrayBuffer>) => void,
) => {
    let file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        setImagensDocumentacao(reader.result);
    };
};

export const enviarSolicitacaoDeAporte = async (
    e: React.FormEvent,
    dadosUsuario: Dados,
    setLoading: (value: React.SetStateAction<boolean>) => void,
    valorAporte: number,
    imagensDocumentacao: string | ArrayBuffer | null,
    setMensagem: (value: React.SetStateAction<string>) => void,
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setFalha: (value: React.SetStateAction<boolean>) => void,
    setValorAporte: (value: React.SetStateAction<number>) => void,
    setModalDesconectar: (value: React.SetStateAction<boolean>) => void,
    usuarioServicoFinanceiro: usuarioServicoFinanceiroTipo

) => {
    if (valorAporte) {
        e.preventDefault();

        setLoading(true);

        await operacaoFinanceiraAporteSaque(
            dadosUsuario.id,
            usuarioServicoFinanceiro.idServicoFinanceiro,
            valorAporte,
            "APORTE",
            dadosUsuario.token,
            imagensDocumentacao
        )
            .then((res) => {
                setMensagem(
                    "Seu aporte foi solicitado com sucesso. Aguarde a análise de nossa equipe."
                );
                setSucesso(true);
                setFalha(false);
                setLoading(false);
                setValorAporte(0);
            })
            .catch(async (error) => {
                switch (error.response.status) {
                    case 400:
                        if (
                            valorAporte === 0 ||
                            valorAporte === null ||
                            valorAporte === undefined
                        ) {
                            setFalha(true);
                            setSucesso(false);
                            setMensagem(
                                "Verifique o preenchimento do campo de aporte e tente novamente."
                            );
                        }
                        break;
                    case 401:
                        await renovacaoToken(
                            dadosUsuario.token,
                            dadosUsuario.tokenRenovacao
                        )
                            .then(async (res2) => {
                                guardarDadosUsuario(
                                    res2.data.camposAdicionais.tokenAcesso,
                                    res2.data.camposAdicionais.tokenRenovacao,
                                    dadosUsuario.email.toString(),
                                    dadosUsuario.id.toString()
                                );
                                guardarDadosUsuario2(
                                    res2.data.camposAdicionais.tokenAcesso,
                                    res2.data.camposAdicionais.tokenRenovacao,
                                    dadosUsuario.email.toString(),
                                    dadosUsuario.id.toString()
                                );
                                await operacaoFinanceiraAporteSaque(
                                    dadosUsuario.id,
                                    usuarioServicoFinanceiro.idServicoFinanceiro,
                                    valorAporte,
                                    "APORTE",
                                    res2.data.camposAdicionais.tokenAcesso,
                                    imagensDocumentacao
                                )
                                    .then((res2) => {
                                        setMensagem(
                                            "Seu aporte foi solicitado com sucesso. Aguarde a análise de nossa equipe."
                                        );
                                        setSucesso(true);
                                        setFalha(false);
                                        setLoading(false);
                                    })
                                    .catch((e2) => {
                                        setFalha(true);
                                        setSucesso(false);
                                        setLoading(false);
                                        switch (e2.response.status) {
                                            case 400:
                                                if (
                                                    valorAporte === 0 ||
                                                    valorAporte === null ||
                                                    valorAporte === undefined
                                                )
                                                    setMensagem(
                                                        "Verifique o preenchimento do campo de aporte e tente novamente."
                                                    );
                                                break;
                                            case 401:
                                                setModalDesconectar(true);
                                                setMensagem(
                                                    "Você foi desconectado. Faça o login e tente novamente!"
                                                );
                                                break;
                                            case 404:
                                                setMensagem(
                                                    "Usuário não credenciado à operação solicitada."
                                                );
                                                break;
                                            case 500:
                                                setMensagem(
                                                    "Algo deu errado, tente novamente mais tarde."
                                                );
                                                break;
                                        }
                                    });
                            })
                            .catch((err3) => {
                                removerDadosUsuario();
                                removerDadosUsuario2();
                                setModalDesconectar(true);
                            });
                        break;
                    case 404:
                        setFalha(true);
                        setSucesso(false);
                        setMensagem("Usuário não credenciado à operação solicitada.");
                        break;
                    case 500:
                        setFalha(true);
                        setSucesso(false);
                        setMensagem("Algo deu errado, tente novamente mais tarde.");
                        break;
                }
                setValorAporte(0);
            }).finally(() => {
                setLoading(false)
            });
    } else {
        setFalha(true)
        setMensagem(
            "Verifique o preenchimento do campo de aporte e tente novamente."
        );
    }
};

export const handleHide = (
    setModal: (modal: boolean) => void,
    setSucesso: (value: React.SetStateAction<boolean>) => void,
    setFalha: (value: React.SetStateAction<boolean>) => void,
    setValorAporte: (value: React.SetStateAction<number>) => void,
    setValorAporteMask: (value: React.SetStateAction<string>) => void,
    setImagensDocumentacao: React.Dispatch<string | ArrayBuffer | null>,
) => {
    setModal(false);
    setSucesso(false);
    setFalha(false);
    setValorAporte(0);
    setValorAporteMask("");
    setImagensDocumentacao('')
};

