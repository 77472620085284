import React, { useState } from "react";

import './styles.css'

import { salvarAlteracoes } from "./function";
import { handleMaskCEP } from "../ModalAlterarDadosCadastrais/functions";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { Sucesso } from '../ModalAlterarDadosCadastrais/Sucesso'
import { Erro } from '../ModalAlterarDadosCadastrais/erro'
import { LoadingButton } from "../../loadingButtom";

import { Modal } from "react-bootstrap";
import { ModalDesconectar } from "../ModalDesconectar";

interface ModalAlterarDadosCadastraisPJProps {
    modal: boolean,
    setModal: React.Dispatch<boolean>,
    setReload: React.Dispatch<boolean>,
    reload: boolean,
    dados: {
        cep: string,
        rua: string,
        complemento: string,
        bairro: string,
        cidade: string,
        estado: string,
    }
}

export const ModalAlterarDadosCadastraisPJ = ({ modal, setModal, dados, setReload, reload }: ModalAlterarDadosCadastraisPJProps) => {

    const [rua, setRua] = useState<string>('')
    const [complemento, setComplemento] = useState<string>('')
    const [bairro, setBairro] = useState<string>('')
    const [cidade, setCidade] = useState<string>('')
    const [estado, setEstado] = useState<string>('')
    const [cep, setCep] = useState<string>('')
    const [maskCEP, setMaskCEP] = useState<string>("");

    const [sucesso, setSucesso] = useState<boolean>(false)
    const [erro, setErro] = useState<boolean>(false)
    const [desconectar, setDesconectar] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const {
        bairro: bairroPlaceholde,
        cep: cePlaceholde,
        cidade: cidadePlaceholde,
        complemento: complementoPlaceholde,
        estado: estadoPlaceholde,
        rua: ruaPlaceholde
    } = dados;

    const closeModal = () => {
        setSucesso(false)
        setErro(false)
        setLoading(false)
        setModal(false)
    }

    const handleSave = () => {
        salvarAlteracoes(rua ? rua : ruaPlaceholde, complemento ? complemento : complementoPlaceholde, bairro ? bairro : bairroPlaceholde, cidade ? cidade : cidadePlaceholde, estado ? estado : estadoPlaceholde, cep ? cep : cePlaceholde, setSucesso, setErro, setDesconectar, setLoading)
    }

    return (
        <Modal show={modal} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title>Alterar meus dados</Modal.Title>
            </Modal.Header>
            {erro ? <Erro handleClose={closeModal} handleReload={() => setErro(false)} /> :
                <>
                    {sucesso ? <Sucesso handleClose={() => {
                        closeModal()
                        setReload(!reload)
                    }} /> :
                        <>
                            <form id="formPJ" onSubmit={handleSave}>
                                <Modal.Body>
                                    <Input id="txtCep" maxLength={10} placeholder="Cep" label={!maskCEP ? 'CEP: ' + cePlaceholde : "CEP:"} value={maskCEP} onChange={(e) => {
                                        setCep(e.target.value.replace('.', ''.replace('-', '')))
                                        handleMaskCEP(e.target.value, setRua, setBairro, setEstado, setCidade, setMaskCEP, cep)
                                    }} />
                                    <Input id="txtRua" placeholder="Rua" label={!rua ? 'Rua: ' + ruaPlaceholde : "Rua:"} value={rua} onChange={(e) => setRua(e.target.value)} />
                                    <Input id="txtBairro" placeholder="Bairro" label={!bairro ? 'Bairro: ' + bairroPlaceholde : "Bairro:"} value={bairro} onChange={(e) => setBairro(e.target.value)} />
                                    <Input id="txtCidade" placeholder="Cidade" label={!cidade ? 'Cidade: ' + cidadePlaceholde : "Cidade:"} value={cidade} onChange={(e) => setCidade(e.target.value)} />
                                    <Input id="txtEstado" placeholder="UF" label={!estado ? 'Estado: ' + estadoPlaceholde : "Estado:"} value={estado} onChange={(e) => setEstado(e.target.value)} />
                                    <Input id="txtComplemento" placeholder="Complemento e número" label={!complemento ? 'Complemento: ' + complementoPlaceholde : "Complemento:"} value={complemento} onChange={(e) => setComplemento(e.target.value)} />
                                </Modal.Body>
                                <Modal.Footer>
                                    {loading ? < LoadingButton /> :
                                        <>
                                            <Button htmlFor="formPJ" type="submit" titleButton="Salvar" onClick={handleSave} />
                                            <Button titleButton="Cancelar" onClick={closeModal} />
                                        </>}
                                </Modal.Footer>
                            </form>
                        </>}
                </>
            }
            {desconectar && <ModalDesconectar modal={desconectar} />}
        </Modal>
    )
}