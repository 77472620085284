import React, { useContext, useEffect, useState } from "react";
import { criaPDF, exibirExtrato, proximoSlide, voltarSlide } from "./functions";
import "./Extrato.css";

import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Button } from "../../../components/Button";
import { SelectMes } from "../../../components/select/SelectMeses";
import { SelectAno } from "../../../components/select/SelectAnos";
import { SelectTipoServico } from "../../../components/select/SelectTipoServico";
import { ModalDesconectar } from "../../../components/modais/ModalDesconectar";
import { TabelaExtrato } from "../../../components/Tabelas/TabelaExtrato";
import { CardExtrato } from "../../../components/Cards/CardExtrato";
import { LoadingButton } from "../../../components/loadingButtom";

import "slick-carousel/slick/slick.css";
import Dropdown from "react-bootstrap/Dropdown";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlineSearch, AiFillPrinter, AiOutlineReload, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Slider from "react-slick";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from "date-fns/locale/pt";
import { ServicoFinanceiroClienteContext, usuarioServicoFinanceiroContextType, usuarioServicoFinanceiroTipo } from "../../../context/servicoFinanceiroCliente";
registerLocale("pt", pt);

export interface RequisicaoExtrato {
    dataExecucao: Date;
    dataSolicitacao: Date;
    quantidadeCotas: number;
    tipo: "Aporte" | "Saque";
    status: "EmEspera" | "Efetuado" | "Rejeitado";
    valor: number;
    urlImagemComprovante: string;
}

export const Extrato = () => {

    const [selectTipoServico, setSelectTipoServico] = useState<string>("");
    const [dataInicial, setDataInicial] = useState<Date>(new Date(new Date().setDate(new Date().getDate() - 1)));
    const [dataFinal, setDataFinal] = useState<Date>(new Date());
    const [dataExecucao, setDataExecucao] = useState<boolean>(false);
    const [dados, setDados] = useState<Array<RequisicaoExtrato>>([]);
    const [modalDesconectar, setModalDesconectar] = useState<boolean>(false);

    const [erroPage, setErroPage] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [reloadPage, setReloadPage] = useState<boolean>(true);

    const { reloadServicoFinanceiro, usuarioServicoFinanceiro } = useContext<usuarioServicoFinanceiroContextType>(ServicoFinanceiroClienteContext);

    useEffect(() => {
        exibirExtrato(dataInicial, dataFinal, setDados, setErroPage, setLoading, setModalDesconectar, usuarioServicoFinanceiro);
    }, [dataInicial, dataFinal, reloadPage, reloadServicoFinanceiro]);

    const settings = {
        dots: false,
        infinite: false,
        swipeToSlide: false,
        slidesToShow: 1,
        draggable: false,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        variableWidth: true,
        respondTo: 'slider',
        rows: 2,
        slidesPerRow: 1,
        nextArrow: <div id="nextArrowSlide" style={{ display: 'none !important' }}></div>,
        prevArrow: <div id="prevArrowSlide" style={{ display: 'none !important' }}></div>
    };

    return (
        <>
            <Navbar />
            <div id="container">
                <div className="caixaExtrato">
                    <fieldset>
                        <div className="headerExtrato">
                            <h2 className="textoNomeHeader">Extrato</h2>
                            <h6 id="descricaoTexto">Selecione os campos: </h6>
                        </div>
                        <div id="divFieldsetExtrato">
                            <DatePicker
                                className="inputData"
                                maxDate={(new Date(new Date().setDate(new Date().getDate() - 1)))}
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={dataInicial}
                                onChange={(dataInicio: Date) => setDataInicial(dataInicio)}
                            />
                            <DatePicker
                                className="inputData"
                                maxDate={(new Date(new Date().setDate(new Date().getDate())))}
                                minDate={dataInicial}
                                dateFormat="dd/MM/yyyy"
                                locale="pt"
                                selected={dataFinal}
                                onChange={(dataFim: Date) => setDataFinal(dataFim)}
                            />
                            <AiOutlineSearch className="PesquisarHistoricoFinanceiro" onClick={() => setReloadPage(!reloadPage)} />
                        </div>
                    </fieldset>
                </div>
                <div className="mobileExtrato">
                    <div className="caixa1">
                        <fieldset>
                            <div className="divBotoesExtratoMobile">
                                <Button
                                    className="btn__cinza btnMargin"
                                    onClick={() => voltarSlide()}
                                    titleButton={
                                        <>
                                            <AiOutlineArrowLeft className="iconButtonExtrato" />
                                        </>
                                    }
                                />
                                <Button
                                    className="btn__laranja btnMargin"
                                    onClick={() => criaPDF()}
                                    titleButton={'Imprimir'}
                                />
                                <Button
                                    className="btn__cinza btnMargin"
                                    onClick={() => proximoSlide()}
                                    titleButton={
                                        <>
                                            <AiOutlineArrowRight className="iconButtonExtrato" />
                                        </>
                                    }
                                />
                            </div>
                            <div className="boxSlideExtrato">
                                {erroPage ? (
                                    <div>
                                        <span className="formatacaoLoadingExtrato">
                                            Ocorreu um erro. Clique no botão para tentar novamente.
                                        </span>
                                        <Button
                                            className="btn__cinza btnMargin"
                                            onClick={() => proximoSlide()}
                                            titleButton={
                                                <>
                                                    <AiOutlineReload
                                                        className="iconButtonExtrato"
                                                        onClick={() => setReloadPage(!reloadPage)} />
                                                </>
                                            }
                                        />
                                    </div>
                                ) : loading ? (
                                    <span className="formatacaoLoadingExtrato">
                                        <LoadingButton />
                                    </span>
                                ) : (
                                    <Slider {...settings}>
                                        {dados.length != 0 ? (
                                            dados.map((value: RequisicaoExtrato) => (
                                                <CardExtrato
                                                    key={value.dataSolicitacao.toString() + value.valor}
                                                    dados={value}
                                                    dataExecucao={dataExecucao}
                                                />
                                            ))
                                        ) : <span>Nenhum registro encontrado no momento.</span>
                                        }
                                    </Slider>
                                )}
                            </div>
                        </fieldset>
                    </div>
                </div>

                <div className="caixa1 desktopExtrato">
                    <fieldset>
                        <div>
                            <h5>Extrato de Transações</h5>
                        </div>
                        <table id="tblTransacoes" className="tabela">
                            <thead>
                                <tr className="textPeriodoMobile">
                                    <th className="table-secondary tableTitulo tableData colunas">
                                        <strong>Status</strong>
                                    </th>
                                    <th className="table-secondary tableTitulo tableData colunas select-data">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                                <strong style={{ color: "#f7931b" }}>{dataExecucao ? "DATA EXECUÇÃO" : "DATA SOLICITAÇÃO"}</strong>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setDataExecucao(false)}>
                                                    Data Solicitação
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => setDataExecucao(true)}>
                                                    Data Execução
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </th>
                                    <th className="table-secondary tableTitulo tableTipo colunas">
                                        <strong>Tipo</strong>
                                    </th>
                                    {
                                        usuarioServicoFinanceiro.idServicoFinanceiro !== 3 && <th className="table-secondary tableTitulo tableCotas colunas">
                                            <strong>Cotas</strong>
                                        </th>
                                    }
                                    <th className="table-secondary tableTitulo tableOperacao colunas">
                                        <strong>Valor Operação</strong>
                                    </th>
                                    <th id="colunaComprovante" className="table-secondary tableTitulo tableOperacao colunas">
                                        <strong>Ver Comprovante</strong>
                                    </th>
                                </tr>
                            </thead>
                            {erroPage ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={6}>
                                            <span>Ocorreu um erro. Por favor tente novamente.  </span>
                                            <AiOutlineReload
                                                onClick={() => setReloadPage(!reloadPage)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>

                            ) : loading ? (
                                <tbody>
                                    <tr>
                                        <td colSpan={6}>
                                            <span className="formatacaoLoadingExtrato">
                                                <LoadingButton />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : dados.length != 0 ? (
                                dados.map((value: RequisicaoExtrato) => (
                                    <tbody>
                                        <TabelaExtrato
                                            key={value.dataSolicitacao.toString() + value.valor}
                                            fonteBold={true}
                                            dados={value}
                                            servicoFinanceiro={usuarioServicoFinanceiro.idServicoFinanceiro}
                                            dataExecucao={dataExecucao}
                                        />
                                    </tbody>

                                ))
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan={6}>
                                            <span className="formatacaoLoadingExtrato">
                                                Nenhum dado encontrado no momento para o período.
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </fieldset>
                </div>
                <Button
                    className="btnAlterar btnMargin desktopExtrato"
                    onClick={() => criaPDF()}
                    style={{ display: "inline-flex" }}
                    titleButton={
                        <>
                            <AiFillPrinter className="iconButton" />
                            Imprimir
                        </>
                    }
                />
            </div>
            <Footer />
            <ModalDesconectar modal={modalDesconectar} />
        </>
    );
};
