import React, { HTMLProps, useState } from "react";
import "./index.css";
import { checkRequiredInput, mostrarPassword } from "./functions";

import { AiOutlineEye } from "react-icons/ai";

interface inputProps extends HTMLProps<HTMLInputElement> {
  placeholder?: string;
  label?: string;
  type?: string;
  id?: string;
  ref?: any;
  className?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  senha?: boolean;
  table?: boolean;
}

export const Input = ({
  placeholder,
  label,
  type,
  id,
  className,
  required,
  onChange,
  senha,
  table,
  ...rest
}: inputProps) => {

  const [changeClassRequired, setchangeClassRequired] = useState<string>(
    className ? className : "form-control"
  );
  const [requiredInput, setrequiredInput] = useState("");
  const [styleRequired, serStyleRequired] = useState<any>({});
  const [passwordShown, setPasswordShown] = useState(false);

  return required ? (
    <div
      className={
        type === "file" ? "" : `form-floating ${table ? "input-table" : "mb-3"}`
      }
    >
      <input
        id={id}
        // type={type ? type : "text"}
        type={passwordShown ? "text" : type ? type : "text"}
        onChange={(e: any) => {
          onChange && onChange(e);
          checkRequiredInput(e.target.value, setchangeClassRequired, setrequiredInput, serStyleRequired, className);
        }}
        className={changeClassRequired}
        placeholder={placeholder + requiredInput}
        style={styleRequired}
        {...rest}
      />
      {senha && (
        <div>
          <button type="button" onClick={() => mostrarPassword(setPasswordShown)} className="eyeButton esconderBotao">
            <AiOutlineEye className="eyeButton" />
          </button>
        </div>
      )}
      {type === "file" ? (
        <></>
      ) : (
        <label htmlFor="floatingPassword">{label + requiredInput}</label>
      )}
    </div>
  ) : (
    <div
      className={type === "file" ? "" : `form-floating ${table ? "" : "mb-3"}`}
    >
      <input
        id={id}
        type={type ? type : "text"}
        className={`${changeClassRequired} ${table && "input-table"}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange && onChange(e)
        }
        placeholder={placeholder}
        {...rest}
      />
      {type === "file" ? (
        <></>
      ) : (
        <label htmlFor="floatingPassword">{label}</label>
      )}
    </div>
  );
};
