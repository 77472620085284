
import React, { useEffect, useState } from "react";

import './styles.css'

import { handleSubmit, limparCampos, handleMaskCEP, handleMaskRenda } from "./functions";

import { Button } from "../../Button";
import { Input } from "../../Input";
import { SelectEstadoCivil } from "../../select/SelectEstadoCivil/SelectEstadoCivil";
import { Erro } from "./erro";
import { LoadingButton } from "../../loadingButtom";

import Modal from "react-bootstrap/Modal";
import { Sucesso } from "./Sucesso";
import { ModalDesconectar } from "../ModalDesconectar";
import { obterDadosCadastrais } from "../../DadosClientePF/functions";
interface modalProps {
    modal: boolean;
    setModal: (modal: boolean) => void;
    reload: () => void;
}
export const ModalAlterarDadosCadastrais = ({
    modal,
    setModal,
    reload,
}: modalProps) => {
    const [rua, setRua] = useState<string>("");
    const [complemento, setComplemento] = useState<string>('')
    const [bairro, setBairro] = useState<string>('')
    const [cidade, setCidade] = useState<string>('')
    const [estado, setEstado] = useState<string>('')
    const [cep, setCep] = useState<string>('')
    const [maskCEP, setMaskCEP] = useState<string>("");
    const [profissao, setProficao] = useState<string>('')
    const [renda, setRenda] = useState<number>(0)
    const [makRenda, setMaskRenda] = useState<string>('')

    const [estadoCivil, setEstadoCivil] = useState<string>('')

    const [sucesso, setSucesso] = useState<boolean>(false)
    const [erro, setErro] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [desconectar, setDesconectar] = useState<boolean>(false)

    const [dadosUsuario, setDadosUsuario] = useState<any>({})

    const [loadingDadosUsuario, setLoadingDadosUsuario] = useState<boolean>(false)


    useEffect(() => {
        obterDadosCadastrais(setDadosUsuario, setLoadingDadosUsuario, setErro, setDesconectar)
    }, [])

    return (
        <Modal show={modal} onHide={() => {
            setModal(false)
            limparCampos(setRua, setComplemento, setBairro, setCidade, setEstado, setCep, setProficao, setRenda, setMaskRenda, setMaskCEP, setEstadoCivil, setErro, setSucesso)
        }}>
            <form onSubmit={(e) => handleSubmit(e, dadosUsuario, '', rua, complemento, bairro, cidade, estado, cep, profissao, renda, estadoCivil, setLoading, setErro, setSucesso, setDesconectar, reload)}>
                <Modal.Header closeButton>
                    <Modal.Title>Alterar meus dados</Modal.Title>
                </Modal.Header>
                {loadingDadosUsuario ? <Modal.Body className="container-loadingDadosUsuario-ModalAlterarDadosCadastrais"><LoadingButton /></Modal.Body> : <>
                    {erro ? <Erro handleClose={() => {
                        setModal(false)
                        limparCampos(setRua, setComplemento, setBairro, setCidade, setEstado, setCep, setProficao, setRenda, setMaskRenda, setMaskCEP, setEstadoCivil, setErro, setSucesso)
                    }} handleReload={() => setErro(false)} />
                        :
                        <>
                            {sucesso ? <Sucesso handleClose={() => {
                                setModal(false)
                                limparCampos(setRua, setComplemento, setBairro, setCidade, setEstado, setCep, setProficao, setRenda, setMaskRenda, setMaskCEP, setEstadoCivil, setErro, setSucesso)
                            }} /> :

                                <>
                                    <Modal.Body>
                                        <fieldset>
                                            <Input id="txtCep" maxLength={10} placeholder="Cep" label={!maskCEP ? 'CEP: ' + dadosUsuario.cep : "CEP:"} value={maskCEP} onChange={(e) => {
                                                handleMaskCEP(e.target.value, setRua, setBairro, setEstado, setCidade, setMaskCEP, cep)
                                            }} />
                                            <Input id="txtRua" placeholder="Rua" label={!rua ? 'Rua: ' + dadosUsuario.rua : "Rua:"} value={rua} onChange={(e) => setRua(e.target.value)} />
                                            <Input id="txtBairro" placeholder="Bairro" label={!bairro ? 'Bairro: ' + dadosUsuario.bairro : "Bairro:"} value={bairro} onChange={(e) => setBairro(e.target.value)} />
                                            <Input id="txtCidade" placeholder="Cidade" label={!cidade ? 'Cidade: ' + dadosUsuario.cidade : "Cidade:"} value={cidade} onChange={(e) => setCidade(e.target.value)} />
                                            <Input id="txtEstado" placeholder="UF" label={!estado ? 'Estado: ' + dadosUsuario.estado : "Estado:"} value={estado} onChange={(e) => setEstado(e.target.value)} />
                                            <Input id="txtComplemento" placeholder="Complemento e número" label={!complemento ? 'Complemento: ' + dadosUsuario.complemento : "Complemento:"} value={complemento} onChange={(e) => setComplemento(e.target.value)} />
                                            <Input id="txtProficao" placeholder="Profissão" label={!profissao ? 'Profissão: ' + dadosUsuario.profissao : "Profissão:"} value={profissao} onChange={(e) => setProficao(e.target.value)} />
                                            <Input id="txtRenda" placeholder="Renda" label={!makRenda ? "Renda: R$ " + dadosUsuario.renda : "Renda:"} value={makRenda} onChange={(e) => { handleMaskRenda(e.target.value, setMaskRenda, setRenda) }} />
                                            <SelectEstadoCivil setEstadoCivil={setEstadoCivil} />
                                        </fieldset>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        {loading ? <LoadingButton /> :
                                            <>
                                                <Button
                                                    onClick={(e) => handleSubmit(e, dadosUsuario, '', rua, complemento, bairro, cidade, estado, cep, profissao, renda, estadoCivil, setLoading, setErro, setSucesso, setDesconectar, reload)}
                                                    titleButton={"Salvar"}
                                                    type="submit"
                                                />
                                                <Button
                                                    classNameButton="btnCancelar"
                                                    onClick={() => {
                                                        limparCampos(setRua, setComplemento, setBairro, setCidade, setEstado, setCep, setProficao, setRenda, setMaskRenda, setMaskCEP, setEstadoCivil, setErro, setSucesso)
                                                        setModal(false)
                                                    }}
                                                    titleButton={"Cancelar"}
                                                />
                                            </>
                                        }
                                    </Modal.Footer>
                                </>}
                        </>}
                </>
                }
            </form>
            {desconectar && <ModalDesconectar modal={desconectar} />}
        </Modal>
    );
};
