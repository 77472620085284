import React from "react";

import './AlteracaoSenha.css'

import { irParaHome } from "./functions";

import { BsFillPatchCheckFill } from "react-icons/bs";
import { Button } from "../../../components/Button";

import { useNavigate } from "react-router-dom";

export const Sucesso = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="container-sucesso-AlteracaoSenha">
                <BsFillPatchCheckFill className='icone-sucesso-AlteracaoSenha' />
                <p className="text-sucesso-AlteracaoSenha">Senha alterada com sucesso.</p>
            </div>
            <Button titleButton={'Confirmar'} onClick={() => irParaHome(navigate)} />
        </>
    )
}